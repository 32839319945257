import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import type { BatteryInfoResponse } from '@/features/resource/endpoints/resources'
import { GET_BATTERY_INFO_API_ID, getBatteryInfo } from '@/features/resource/endpoints/resources'
import type { UseQueryOptions } from '@/types/queries'

const REFETCH_BATTERY_INFO_INTERVAL = 5_000 // 5 seconds

type BatteryInfoQueryParams = {
  controlPortId: string
}

export type UseBatteryInfoQueryResult = {
  batteryInfo?: BatteryInfoResponse
  stopPolling: () => void
  restartPolling: () => void
}

export function useBatteryInfoWithPollingQuery(
  params: BatteryInfoQueryParams,
  options?: UseQueryOptions<BatteryInfoResponse>,
): UseBatteryInfoQueryResult {
  const [refetchingBatteryInfoEnabled, setRefetchingBatteryInfoEnabled] = useState<boolean>(true)

  const { data } = useQuery({
    ...options,
    refetchInterval: REFETCH_BATTERY_INFO_INTERVAL,
    enabled: refetchingBatteryInfoEnabled,
    queryKey: [GET_BATTERY_INFO_API_ID, params],
    queryFn: () => getBatteryInfo(params.controlPortId),
  })

  function restartPolling() {
    setRefetchingBatteryInfoEnabled(true)
  }

  function stopPolling() {
    setRefetchingBatteryInfoEnabled(false)
  }

  return {
    batteryInfo: data,
    stopPolling,
    restartPolling,
  }
}
