import type { ManualAcceptFlow, MarketProgram } from '@/features/bidding/constants'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

export type GroupedBidParams = {
  deliveryDay: MarketDate
  portfolioCode: string
  marketProgram: MarketProgram
}

export const buildBidEditLink = (manualAcceptFlow: ManualAcceptFlow, params: GroupedBidParams): string =>
  buildLinkWithBidLinkParameters(`/bidding/manual-accept/${manualAcceptFlow}?`, params)

export const buildBidViewLink = (params: GroupedBidParams): string =>
  buildLinkWithBidLinkParameters(`/bidding/view?`, params)

export const buildBidCapacityLink = (params: GroupedBidParams): string =>
  buildLinkWithBidLinkParameters('/bidding/offer/create/capacity?', params)

export const buildBidConfirmationLink = (params: GroupedBidParams): string =>
  buildLinkWithBidLinkParameters('/bidding/offer/confirm/capacity?', params)

export const buildBidPriceConfirmationLink = (params: GroupedBidParams): string =>
  buildLinkWithBidLinkParameters('/bidding/offer/confirm/price?', params)

export const buildBidPriceLink = (params: GroupedBidParams): string =>
  buildLinkWithBidLinkParameters('/bidding/offer/create/price?', params)

const buildLinkWithBidLinkParameters = (link: string, params: GroupedBidParams): string => {
  link += `marketProgram=${params.marketProgram}`
  link += `&deliveryDay=${params.deliveryDay.toISODate()}`
  link += `&portfolioCode=${params.portfolioCode}`
  return link
}
