import ArrowBack from '@mui/icons-material/ArrowBack'
import type { CSSProperties } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomButton from '@/components/inputs/CustomButton'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import { useAuth } from '@/features/authentication/contexts/AuthContext'

const LIST_STYLE: CSSProperties = {
  listStylePosition: 'inside',
}
export const PrivacyPolicy = () => {
  const { loggedInUser } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <MainContentContainer>
      {!loggedInUser && <CustomTypography variant={'h1'}>Privacy Policy</CustomTypography>}
      <CustomTypography paragraph variant={'h4'}>
        Last updated: December 2023
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        Your privacy is important to us. This Privacy Notice explains what personal data Sympower B.V. and its
        subsidiaries (“Sympower”) process about you, why we process it, and how we use it.
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        This Privacy Notice covers how we collect and use your data when you are granted access to our web-based user
        interface (“FlexPortal”).
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        The data controller of your data is the Sympower legal entity that manages the contractual relationship between
        you and Sympower, and it can be found in the agreement you have entered with Sympower.
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        Sympower B.V. and its subsidiaries participate in a group-wide IT system (“System”) in order to harmonize
        Sympower’s IT infrastructure and its use. Regarding data processing within the framework of Sympower’s internal
        administration and joint procedures through the System, Sympower B.V. and its subsidiaries act as joint
        controllers. Sympower B.V. is responsible for the System and is at your disposal as your central contact point.
      </CustomTypography>
      <CustomTypography variant={'h3'}>What types of personal data do we collect?</CustomTypography>
      <CustomTypography variant={'h4'}>Data provided by you</CustomTypography>
      <CustomTypography paragraph variant="body1">
        When you register as a user of our FlexPortal, we may collect from you the following data:
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        <ul style={LIST_STYLE}>
          <li>your name and surname</li>
          <li>your email address and username</li>
          <li>your job title and the name of the company you work for</li>
          <li>your telephone number</li>
        </ul>
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        The data is used for creating, granting you access to, and maintaining your account in accordance with the
        agreement between you and Sympower.
      </CustomTypography>
      <CustomTypography variant={'h4'}>Data collected when using the FlexPortal</CustomTypography>
      <CustomTypography paragraph variant="body1">
        To optimize the functionality and appearance of the FlexPortal, we use Google Maps to display locations and
        Google Fonts to display external fonts. We have also implemented Google Analytics to analyze how our application
        is used. This means that Google can identify your IP address. These are services provided by Google Ireland
        Limited. You can access Google’s privacy policy at this link:{' '}
        <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">
          https://policies.google.com/privacy
        </a>
        {/*
         */}
        .
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        To detect broken lines of code, crashes and errors, we use Sentry. When an error occurs, Sentry collects your
        username and IP address to detect the error and help us solve it to restore the full functionality of the
        FlexPortal. You can read the data processing agreement in place with Sentry{' '}
        <a href="https://sentry.io/legal/dpa/" rel="noreferrer" target="_blank">
          here
        </a>
        {/*
         */}
        .
      </CustomTypography>
      <CustomTypography variant={'h3'}>Why do we collect personal data?</CustomTypography>
      <CustomTypography paragraph variant="body1">
        The justified reasons for processing your personal data may vary. The following legal bases may apply:
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        <ul style={LIST_STYLE}>
          <li>
            our legitimate interest in relation to our business operations and the achievement of our commercial goals
          </li>
          <li>entering into and managing a contract with you</li>
          <li>in some cases, we will ask for your consent</li>
          <li> compliance with our legal obligations.</li>
        </ul>
      </CustomTypography>
      <CustomTypography variant={'h3'}>How do we secure your data?</CustomTypography>

      <CustomTypography paragraph variant="body1">
        At Sympower, we take appropriate measures to protect your data that are consistent with applicable data
        protection and data security laws and regulations. Your data is safely stored in our systems and all endpoints
        protected with token authentication and authorisation.
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        Other entities of the Sympower group may have access to your data but access is restricted on a need-to-know
        basis and is only granted to fulfil the purposes for which the data has been collected.
      </CustomTypography>

      <CustomTypography paragraph variant="body1">
        Your data may be processed in IT systems not hosted by Sympower. In particular, we use Amazon Cognito for access
        management. We always ensure that these systems offer an appropriate level of data protection and we always
        enter into data processing agreements where applicable (you can read the data processing agreement in place with
        Amazon Cognito{' '}
        <a
          href="https://docs.aws.amazon.com/whitepapers/latest/navigating-gdpr-compliance/aws-data-processing-addendum-dpa.html"
          rel="noreferrer"
          target="_blank"
        >
          here
        </a>
        {/*
         */}
        ). We do not share your data with any other third party and we do not sell your data to anyone.
      </CustomTypography>
      <CustomTypography variant={'h3'}>How long do we keep your data?</CustomTypography>
      <CustomTypography paragraph variant="body1">
        We will only keep your information for as long as is necessary for the purposes for which it was collected. We
        will only keep your information for as long as you have access to the FlexPortal in accordance with your
        contract with Sympower. If the contract with Sympower is terminated, your data will be deleted 3 months after
        the termination date.
      </CustomTypography>
      <CustomTypography variant={'h3'}>What are your rights and choices?</CustomTypography>
      <CustomTypography paragraph variant="body1">
        You are always entitled to obtain from us information about how we process your personal data, as well as a copy
        of the personal data in our records. You also have the right to object to the processing, correction, update, or
        delete of your personal data, as well as data portability of your personal data to a third party, and to report
        any misuse of your personal data.
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        To make things easy for you, you can check and change your data directly in the FlexPortal.
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        For any questions, or if you wish to exercise your rights described above, you can always contact us at{' '}
        <a href={'mailto:info@sympower.net'}>info@sympower.net</a>.
      </CustomTypography>
      <CustomTypography paragraph variant="body1">
        If you believe that your personal data is not processed in accordance with privacy law, you can also lodge a
        complaint to the local Supervisory Authority.
      </CustomTypography>
      {loggedInUser && (
        <CustomButton color="primary" startIcon={<ArrowBack />} variant="outlined" onClick={() => navigate(-1)}>
          {t('common.navigation.back')?.toUpperCase()}
        </CustomButton>
      )}
    </MainContentContainer>
  )
}
