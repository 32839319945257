import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { updateActivationGroup } from '@/features/activationGroup/endpoints/activationGroups'
import type { ActivationGroup, ApiUpdateActivationGroup } from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseUpdateActivationGroupMutationParams = {
  activationGroupUuid: string
}
export type UseUpdateActivationGroupMutationResult = Omit<
  UseMutationResult<ActivationGroup, Error, ApiUpdateActivationGroup>,
  'mutateAsync'
> & {
  updateActivationGroup: UseMutationResult<ActivationGroup, Error, ApiUpdateActivationGroup>['mutateAsync']
}

export function useUpdateActivationGroupMutation(
  params: UseUpdateActivationGroupMutationParams,
  options?: UseMutationOptions<ActivationGroup, ApiUpdateActivationGroup>,
): UseUpdateActivationGroupMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiUpdateActivationGroup) => updateActivationGroup(params.activationGroupUuid, variables),
  })

  return { updateActivationGroup: mutateAsync, ...mutationResult }
}
