import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomIconButton from '@/components/inputs/CustomIconButton'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends PrequalificationPatternsDataGridToolbarProps {}
}

export type PrequalificationPatternsDataGridToolbarProps = {
  onAddNewPrequalificationPattern: () => void
}

const PrequalificationPatternsDataGridToolbar = ({
  onAddNewPrequalificationPattern,
}: PrequalificationPatternsDataGridToolbarProps) => {
  const { t } = useTranslation()

  return (
    <GridToolbar
      actions={
        <CustomIconButton
          Icon={AddOutlinedIcon}
          aria-label={t('activation_groups.create_prequalification_pattern_button_label')}
          color="primary"
          variant="solid"
          onClick={onAddNewPrequalificationPattern}
        />
      }
    />
  )
}

export default PrequalificationPatternsDataGridToolbar
