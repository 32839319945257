import { AddOutlined, DeleteOutlined } from '@mui/icons-material'
import { Box, Divider, IconButton, InputAdornment, Stack, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useCallback, useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import TextFieldController from '@/components/inputs/TextFieldController'
import type { CustomerSettingsFormValues } from '@/features/customer/components/settings/CustomerSettingsForm'
import validatePhone from '@/utils/phone'

const MAX_PHONE_COUNT = 10

const EmptyPhoneDisclaimer = () => {
  return (
    <>
      <CustomTypography sx={{ ml: 2 }} variant={'body1'}>
        {t('customer_settings.form.notification_preferences.no_phones')}
      </CustomTypography>
      <Divider />
    </>
  )
}

export const CustomerSmsNotificationAccordion = () => {
  const { trigger, watch, setValue, formState, clearErrors } = useFormContext<CustomerSettingsFormValues>()
  const { fields, append } = useFieldArray({
    name: 'notificationPreferences.phoneNumbers',
    rules: {
      maxLength: MAX_PHONE_COUNT,
    },
  })

  const isSmsEnabled = watch('notificationPreferences.isSmsEnabled')
  const phoneNumbers = watch('notificationPreferences.phoneNumbers')

  const triggerPhoneValidation = useCallback(() => {
    void trigger('notificationPreferences.phoneNumbers')
  }, [trigger])

  useEffect(() => {
    triggerPhoneValidation()
  }, [triggerPhoneValidation, isSmsEnabled])

  return (
    <Stack gap={1}>
      <CustomTypography gutterBottom variant={'h3'}>
        {t('customer_settings.form.notification_preferences.sms_description')}
      </CustomTypography>
      <Divider />
      {phoneNumbers?.length === 0 ? (
        <EmptyPhoneDisclaimer />
      ) : (
        <Stack gap={1}>
          {fields.map((field, index) => (
            <Stack key={field.id} gap={1}>
              <Stack alignItems={'flex-start'} direction="row">
                <TextFieldController
                  key={field.id}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                  }}
                  controllerProps={{
                    rules: {
                      required:
                        isSmsEnabled && !phoneNumbers?.[0]
                          ? t('customer_settings.form.notification_preferences.phone_required_error').toString()
                          : undefined,
                      validate: (value) => {
                        if (!validatePhone(value)) {
                          return t('customer_settings.form.notification_preferences.phone_invalid_error').toString()
                        }
                        return true
                      },
                    },
                  }}
                  error={formState.errors?.notificationPreferences?.phoneNumbers?.[index] !== undefined}
                  helperText={formState.errors?.notificationPreferences?.phoneNumbers?.[index]?.message}
                  name={`notificationPreferences.phoneNumbers.${index}`}
                  onChange={(event) => {
                    // users are only allowed to enter numbers
                    const value = event.target.value.replace(/\D/g, '')
                    setValue(`notificationPreferences.phoneNumbers.${index}`, value)
                  }}
                />

                <Tooltip
                  title={
                    isSmsEnabled && fields.length === 1
                      ? t('customer_settings.form.notification_preferences.phone_required_error')
                      : undefined
                  }
                >
                  <Box>
                    <IconButton
                      aria-label="delete-phone"
                      disabled={isSmsEnabled && fields.length === 1}
                      size={'small'}
                      sx={{
                        ml: 1,
                      }}
                      onClick={() => {
                        const updatedFields = phoneNumbers
                        updatedFields?.splice(index, 1)
                        clearErrors(`notificationPreferences.phoneNumbers.${index}`)
                        setValue('notificationPreferences.phoneNumbers', updatedFields)
                      }}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Stack>
              <Divider />
            </Stack>
          ))}
        </Stack>
      )}
      <Stack direction="row" justifyContent={'flex-end'}>
        <Tooltip
          placement="top"
          title={
            fields.length === MAX_PHONE_COUNT
              ? t('customer_settings.form.notification_preferences.phone_max_count')
              : undefined
          }
        >
          <Box>
            <IconButton
              aria-label="add-phone"
              disabled={fields.length === MAX_PHONE_COUNT}
              size={'small'}
              sx={{
                mt: 1,
              }}
              onClick={() => append('')}
            >
              <AddOutlined />
            </IconButton>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
