import type { GridColDef } from '@mui/x-data-grid'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DURATION_FORMAT, HOUR_h_MINUTE_m_SECONDS_s_FORMAT } from '@/constants/dateTimeFormats'
import { ActivationDetailsToolbar } from '@/features/activation/components/ActivationDetailsToolbar'
import type { ActivatedResource } from '@/features/activation/types/activation'
import { useActivatedResourcesQuery } from '@/features/customer/hooks/useActivatedResourcesQuery'
import { useActivationQuery } from '@/features/customer/hooks/useActivationQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'
import type { Customer } from '@/features/customer/types/customer'
import getDuration, { convertToTimeZoneDateTime } from '@/utils/time'

const DASH_VALUE = '-'

function formatActivatedResourceDuration(startTime?: string, endTime?: string) {
  if (!startTime || !endTime) {
    return DASH_VALUE
  }
  return getDuration(startTime, endTime).toFormat(DURATION_FORMAT).replace('00h ', '')
}

function formatResourceActivationTime(customerTimeZone: string, iso?: string) {
  return iso ? convertToTimeZoneDateTime(customerTimeZone, iso).toFormat(HOUR_h_MINUTE_m_SECONDS_s_FORMAT) : DASH_VALUE
}

function getMarketPrograms(customer?: Customer) {
  return new Map(customer?.services?.map((mp) => [mp.id, mp.type]))
}

export const CustomerActivationDetails = () => {
  const { customer, customerDetailsCommonBreadcrumbs, isFetchingCustomer, setPageConfig } = useCustomerDetails()
  const { t } = useTranslation()
  const { activationId } = useParams()

  const { activation } = useActivationQuery(
    {
      customerUuid: customer.uuid!,
      activationId: Number(activationId),
      location: customer.location,
    },
    { enabled: !!customer && !isFetchingCustomer },
  )

  const marketProgram = getMarketPrograms(customer).get(activation?.serviceId)

  const { activatedResources, isFetching } = useActivatedResourcesQuery(
    {
      location: customer.location,
      activationId: activation?.id,
      serviceId: activation?.serviceId,
      customerUuid: customer.uuid,
    },
    { enabled: !!activation && !!customer && !isFetchingCustomer },
  )

  const customerTimeZone =
    useCustomerSettingsQuery({ uuid: customer.uuid! }).customerSettings?.localization?.timeZone ?? 'UTC'

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.activation_details'),
      breadcrumbs: [
        ...customerDetailsCommonBreadcrumbs,
        {
          text: t('customer_details.tabs.activations'),
          to: `/customers/${customer.uuid}/activations`,
        },
      ],
      appBarContent: null,
      activeTab: CustomerDetailsRouteInformation.ACTIVATIONS.navigationPath,
    })
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'wireName',
      headerName: t('customer_details.activations.details.resource.label'),
      flex: 1,
    },
    {
      field: 'wireSectionName',
      headerName: t('customer_details.activations.details.section.label'),
      flex: 1,
      valueGetter: (value: ActivatedResource['wireSectionName']) => {
        return value ?? DASH_VALUE
      },
    },
    {
      field: 'activatedAt',
      headerName: t('customer_details.activations.details.start_time.label'),
      flex: 1,
      valueFormatter: (value: ActivatedResource['activatedAt']) => {
        return formatResourceActivationTime(customerTimeZone, value)
      },
    },
    {
      field: 'deactivatedAt',
      headerName: t('customer_details.activations.details.end_time.label'),
      flex: 1,
      valueFormatter: (value: ActivatedResource['deactivatedAt']) => {
        return formatResourceActivationTime(customerTimeZone, value)
      },
    },
    {
      field: 'duration',
      headerName: t('customer_details.activations.details.duration.label'),
      flex: 1,
      valueGetter: (_, activatedResources: ActivatedResource) => {
        return formatActivatedResourceDuration(activatedResources.activatedAt, activatedResources.deactivatedAt)
      },
    },
  ]

  if (!customer || isFetchingCustomer || !activation) return null

  return (
    <CustomDataGrid
      columns={columns}
      isLoading={isFetching}
      rows={activatedResources ?? []}
      slotProps={{
        toolbar: {
          activation,
          marketProgram,
          customerTimeZone: customerTimeZone,
        },
      }}
      slots={{
        toolbar: ActivationDetailsToolbar,
      }}
    />
  )
}
