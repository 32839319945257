import { zodResolver } from '@hookform/resolvers/zod'
import { Close, SaveOutlined } from '@mui/icons-material'
import { Box, FormHelperText, Grid, InputAdornment, Stack, TextField } from '@mui/material'
import { green } from '@mui/material/colors'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomButton from '@/components/inputs/CustomButton'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { DEFAULT_INTERFACE_LANGUAGE, INTERFACE_LANGUAGES } from '@/constants/interfaceLanguages'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { updatePersonalInfo } from '@/features/user/endpoints/users'
import { getUserPersonalInfoFormSchema } from '@/features/user/schemas'
import type { User } from '@/features/user/types/user'
import type { UserPersonalInfo } from '@/features/user/types/userPersonalInfo'
import { errorHandler } from '@/utils/errorHandler'

interface UserProfileFormProps {
  user: Partial<User>
}

export const UserProfileForm = (props: UserProfileFormProps) => {
  const { user } = props
  const { loggedInUser, refreshSession } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const form = useForm<UserPersonalInfo>({
    mode: 'onBlur',
    defaultValues: {
      givenName: user?.givenName,
      familyName: user?.familyName,
      email: user?.email,
      isMFAEnabled: user?.isMFAEnabled ?? false,
      phone: user?.phone ?? '',
      uiLanguage: user?.uiLanguage ?? DEFAULT_INTERFACE_LANGUAGE.value,
    },
    resolver: zodResolver(getUserPersonalInfoFormSchema(t)),
  })

  const { pushAlert } = useAlertContext()

  useWatch({
    control: form.control,
  })

  const onSubmit = () => {
    if (!user.id) {
      return
    }

    form.setValue('numeralLanguage', form.getValues().uiLanguage) // FIXME: reconsider what to do with numeralLanguage, for now it is the same as uiLanguage
    updatePersonalInfo(user.id, form.getValues())
      .then(() => {
        refreshSession()
        if (loggedInUser) {
          loggedInUser.uiLanguage = form.getValues().uiLanguage
        }
        navigate(-1)
      })
      .catch((err) => {
        const error = errorHandler(err, t('user_profile.errors.update_profile_error'))

        pushAlert({
          severity: 'error',
          message: error.message,
        })
      })
  }

  const onCancel = () => {
    navigate(-1)
  }

  const userRole = user.role ?? null

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container mt={2} spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField disabled fullWidth label={t('common.username')} value={user.username} />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              disabled
              fullWidth
              label={t('user_profile.settings.form.role_label')}
              value={userRole ? t(`users.role.${userRole}`) : ''}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextFieldController label={t('user_profile.settings.form.given_name_label')} name="givenName" />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextFieldController label={t('user_profile.settings.form.family_name_label')} name="familyName" />
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack spacing={2}>
              <TextFieldController required label={t('user_profile.settings.form.email_label')} name="email" />

              <Box>
                <TextFieldController
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                  }}
                  label={t('user_profile.settings.form.phone_label')}
                  name="phone"
                  required={user.isMFAEnabled}
                  onChange={(event) => {
                    // users are only allowed to enter numbers
                    const value = event.target.value.replace(/\D/g, '')

                    form.setValue('phone', value)
                  }}
                />

                {!form.formState.errors.phone && user.isMFAEnabled && (
                  <FormHelperText sx={{ color: green[500] }}>
                    {t('user_profile.settings.form.mfa_phone_required_hint')}
                  </FormHelperText>
                )}
              </Box>

              <SelectFieldController
                id="interface-language"
                label={t('user_form.form.interface_language_label')}
                name="uiLanguage"
                options={Object.values(INTERFACE_LANGUAGES).map((lang) => ({
                  id: lang.value,
                  value: lang.value,
                  label: t(`common.interface_language.${lang.value}`),
                }))}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" mt={2} spacing={2}>
          <CustomButton
            color="primary"
            startIcon={<SaveOutlined />}
            type="submit"
            variant="contained"
            onSubmit={onSubmit}
          >
            {t('common.button.save')}
          </CustomButton>
          <CustomButton color="primary" startIcon={<Close />} type="reset" variant="outlined" onClick={onCancel}>
            {t('common.button.cancel')}
          </CustomButton>
        </Stack>
      </form>
    </FormProvider>
  )
}
