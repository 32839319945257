import * as Sentry from '@sentry/react'

export function logCognitoExceptionInSentry(error: any) {
  Sentry.captureException(error, {
    extra: {
      apiResponse: error,
    },
  })
}

export function logMessageInSentry(message: string, context: any = {}) {
  Sentry.captureMessage(message, context)
}
