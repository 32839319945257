import type { TypographyProps } from '@mui/material'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import type { Resource } from '@/features/resource/types'
import { isMeasurementInaccurate } from '@/features/resource/utils/isMeasurementInaccurate'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

export type ResourceEstimatedPowerFieldProps = {
  resource: Resource
  typographyVariant?: TypographyProps['variant']
}

const MeasurementInnacurateTextStyled = styled(
  'span',
  {},
)(({ theme }) => ({
  color: theme.palette.error.main,
}))

const ResourceEstimatedPower = ({ resource, typographyVariant = 'body1' }: ResourceEstimatedPowerFieldProps) => {
  const { t } = useTranslation()
  const estimatedPower = resource.estimatedPower

  // Check if estimatedPower is null and return "N/A"
  if (estimatedPower === null) {
    return (
      <Tooltip placement="top" title={t('resources.table.no_power_measurements')}>
        <CustomTypography sx={{ lineHeight: 'inherit' }} variant={typographyVariant}>
          N/A
        </CustomTypography>
      </Tooltip>
    )
  }

  // Format the estimatedPower value, including "0"
  return (
    <CustomTypography sx={{ lineHeight: 'inherit' }} variant={typographyVariant}>
      {`${convertEnergyPower(estimatedPower, 'kilowatts')} `}

      {isMeasurementInaccurate(resource) && (
        <Tooltip title={t('resources.table.measurement_inaccurate')}>
          <MeasurementInnacurateTextStyled aria-label={t('resources.table.measurement_inaccurate')}>
            ~
          </MeasurementInnacurateTextStyled>
        </Tooltip>
      )}
    </CustomTypography>
  )
}

export default ResourceEstimatedPower
