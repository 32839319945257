import { Route, Routes } from 'react-router-dom'

import NavigateToError from '@/features/error/pages/NavigateToError'
import ExampleFeatureIndex from '@/features/exampleFeature/pages/ExampleFeatureIndex'

export default function ExampleFeatureRoutes() {
  return (
    <Routes>
      <Route index element={<ExampleFeatureIndex />} path="/" />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
