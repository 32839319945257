import type { Portfolio } from '@/features/bidding/types/bid'

export enum BiddingFlow {
  CAPACITY_FLOW = 'CAPACITY_FLOW',
  CAPACITY_AND_PRICE_FLOW = 'CAPACITY_AND_PRICE_FLOW',
}

export default (portfolio: Portfolio): BiddingFlow => {
  if (portfolio.countryCode.toLowerCase() === 'fi') {
    return BiddingFlow.CAPACITY_AND_PRICE_FLOW
  }

  return BiddingFlow.CAPACITY_FLOW
}
