import { Route, Routes } from 'react-router-dom'

import { DisturbancesRouteInformation } from '@/features/disturbance/constants'
import DisturbanceActivatedResources from '@/features/disturbance/pages/DisturbanceActivatedResources'
import NavigateToError from '@/features/error/pages/NavigateToError'

import DisturbancesIndex from './pages/DisturbancesIndex'

export default function DisturbancesRoutes() {
  return (
    <Routes>
      <Route element={<DisturbancesIndex />} path={DisturbancesRouteInformation.INDEX.routePath} />
      <Route
        element={<DisturbanceActivatedResources />}
        path={DisturbancesRouteInformation.DISTURBANCE_ACTIVATED_RESOURCE.routePath}
      />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
