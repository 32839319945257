import { Divider, Stack } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import InlineLabeledTextField from '@/components/dataDisplay/InlineLabeledTextField'
import { formatCapacity, formatRevenue } from '@/features/customer/utils/revenueUtils/formatRevenueData'

type RevenuesMonthlySummaryProps = {
  capacity: number
  sold: number
  revenue: number
}

export const RevenueMonthlySummary = ({ capacity, sold, revenue }: RevenuesMonthlySummaryProps) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" divider={<Divider flexItem orientation="vertical" />} gap={2}>
      <InlineLabeledTextField label={t('customer_revenue.capacity')} text={formatCapacity(t, capacity)} width="30%" />
      <InlineLabeledTextField label={t('customer_revenue.sold')} text={formatCapacity(t, sold)} width="25%" />
      <InlineLabeledTextField
        label={t('customer_revenue.revenue.label')}
        text={formatRevenue(t, revenue)}
        width="35%"
      />
    </Stack>
  )
}
