import { FormControlLabel, Stack, Switch } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTimeRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro'
import type { DateTime } from 'luxon'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import { ONE_DAY_AGO, TODAY } from '@/constants/dateTime'
import { Period } from '@/constants/period'

type MeasuredPowerFiltersProps = {
  startTime: DateTime
  endTime: DateTime
  showOnlyTotal: boolean
  setShowOnlyTotal: Dispatch<SetStateAction<boolean>>
  setStartTime: Dispatch<SetStateAction<DateTime>>
  setEndTime: Dispatch<SetStateAction<DateTime>>
}

function MeasuredPowerFilters({
  startTime,
  endTime,
  showOnlyTotal,
  setShowOnlyTotal,
  setStartTime,
  setEndTime,
}: Readonly<MeasuredPowerFiltersProps>) {
  const [periodSelection, setPeriodSelection] = useState<Period>(Period.PAST_24_HOURS)

  const { i18n, t } = useTranslation()

  const handleChange = (event) => {
    setPeriodSelection(event.target.value)
    setEndTime(TODAY)

    switch (event.target.value) {
      case Period.CUSTOM:
        setStartTime(ONE_DAY_AGO)
        break
      case Period.PAST_24_HOURS:
        setStartTime(ONE_DAY_AGO)
        break
      case Period.PAST_12_HOURS:
        setStartTime(TODAY.minus({ hours: 12 }))
        break
      case Period.PAST_6_HOURS:
        setStartTime(TODAY.minus({ hours: 6 }))
        break
      case Period.PAST_3_HOURS:
        setStartTime(TODAY.minus({ hours: 3 }))
        break
      case Period.PAST_HOUR:
        setStartTime(TODAY.minus({ hours: 1 }))
        break
    }
  }

  const handleOnAccept = (newValue) => {
    const [newStartTime, newEndTime] = newValue

    const diff = newEndTime?.diff(newStartTime!, ['hours', 'minutes'])
    const diffObj = diff?.toObject() ?? null
    const differenceInHours = diffObj.hours ? parseInt(diffObj.hours.toFixed()) : 0
    const differenceInMinutes = diffObj.minutes ? parseInt(diffObj.minutes.toFixed()) : 0

    // Do not set date time range if the difference is more than 24 hours
    if (differenceInHours >= 24 && differenceInMinutes > 0) return

    setStartTime(newStartTime!)
    setEndTime(newEndTime!)
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <CustomSelectField
        label={t('common.period.label')}
        options={[
          { id: 'custom', value: Period.CUSTOM, label: t('common.period.custom') },
          { id: 'last-24-hours', value: Period.PAST_24_HOURS, label: t('common.period.past_24_hours') },
          { id: 'last-12-hours', value: Period.PAST_12_HOURS, label: t('common.period.past_12_hours') },
          { id: 'last-6-hours', value: Period.PAST_6_HOURS, label: t('common.period.past_6_hours') },
          { id: 'last-3-hours', value: Period.PAST_3_HOURS, label: t('common.period.past_3_hours') },
          { id: 'last-hour', value: Period.PAST_HOUR, label: t('common.period.past_hour') },
        ]}
        size="medium"
        value={periodSelection}
        onChange={handleChange}
      />

      <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
        <DateTimeRangePicker
          ampm={false}
          disableFuture={true}
          disabled={periodSelection !== Period.CUSTOM}
          maxDateTime={(startTime.plus({ days: 1 }) as unknown as DateTime) ?? TODAY}
          slots={{ field: SingleInputDateTimeRangeField }}
          sx={{ width: '320px' }}
          timeSteps={{ minutes: 1 }}
          value={[startTime, endTime]}
          views={['day', 'hours', 'minutes']}
          onAccept={handleOnAccept}
        />
      </LocalizationProvider>

      <FormControlLabel
        control={<Switch checked={showOnlyTotal} onChange={(e) => setShowOnlyTotal(e.target.checked)} />}
        label={t('measurements.filter.show_only_total_measured_power')}
      />
    </Stack>
  )
}

export default MeasuredPowerFilters
