import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { startPrequalification } from '@/features/activationGroup/endpoints/activationGroups'
import type { StartPrequalification } from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseStartPrequalificationMutationResult = Omit<
  UseMutationResult<void, Error, StartPrequalification>,
  'mutateAsync'
> & {
  startPrequalification: UseMutationResult<void, Error, StartPrequalification>['mutateAsync']
}

export function useStartPrequalificationMutation(
  options?: UseMutationOptions<void, StartPrequalification>,
): UseStartPrequalificationMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: StartPrequalification) => startPrequalification(variables),
  })

  return { startPrequalification: mutateAsync, ...mutationResult }
}
