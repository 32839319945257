import type { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { z } from 'zod'

import { ActivationScheduleType, ApiVolumeRangeUnit, PrequalificationLane } from '@/features/activationGroup/types'
import { MarketProgram } from '@/features/bidding/constants'

const MAX_FILE_SIZE = 5242880

export const getCreateActivationGroupSchema = (t: TFunction) =>
  z.object({
    name: z.string().min(1, t('activation_groups.create_form.name_required_error')),
    portfolio: z.string().min(1, t('activation_groups.create_form.portfolio_required_error')),
    marketProgram: z.nativeEnum(MarketProgram, {
      invalid_type_error: t('activation_groups.create_form.market_program_invalid_error'),
      required_error: t('activation_groups.create_form.market_program_required_error'),
    }),
    biddableVolumeRange: z
      .object({
        min: z.coerce
          .number()
          .gt(0, t('activation_groups.create_form.biddable_volume_range_min_greater_than_zero_error')),
        max: z.coerce.number(),
        unit: z.nativeEnum(ApiVolumeRangeUnit, {
          invalid_type_error: t('activation_groups.create_form.biddable_volume_range_unit_invalid_error'),
        }),
      })
      .refine(
        (data) => {
          if (data?.min) {
            return data.max > data.min
          }

          return true
        },
        {
          message: t('activation_groups.create_form.biddable_volume_range_max_greater_than_min_error'),
          path: ['max'],
        },
      ),
  })

export const getUpdateActivationGroupSchema = (t: TFunction) =>
  z.object({
    name: z.string().min(1, t('activation_groups.create_form.name_required_error')),
    biddableVolumeRange: z
      .object({
        min: z.coerce
          .number()
          .gt(0, t('activation_groups.create_form.biddable_volume_range_min_greater_than_zero_error')),
        max: z.coerce.number(),
        unit: z.nativeEnum(ApiVolumeRangeUnit, {
          invalid_type_error: t('activation_groups.create_form.biddable_volume_range_unit_invalid_error'),
        }),
      })
      .refine(
        (data) => {
          if (data?.min) {
            return data.max > data.min
          }

          return true
        },
        {
          message: t('activation_groups.create_form.biddable_volume_range_max_greater_than_min_error'),
          path: ['max'],
        },
      ),
  })

export const getAssignResourceToActivationGroupSchema = (t: TFunction) =>
  z.object({
    resourceUuid: z
      .string({
        required_error: t('activation_groups.assign_resource_form.resource_required_error'),
      })
      .uuid({ message: t('activation_groups.assign_resource_form.resource_invalid_error') }),
    activationPriority: z.coerce
      .number({
        invalid_type_error: t('activation_groups.assign_resource_form.priority_invalid_error'),
      })
      .int(t('activation_groups.assign_resource_form.priority_invalid_error'))
      .min(0, t('activation_groups.assign_resource_form.priority_out_of_bounds_error'))
      .max(1000, t('activation_groups.assign_resource_form.priority_out_of_bounds_error'))
      .nullable(),
    isUsedForMinCapacityPrequalification: z.boolean(),
  })

export const getCreateScheduleSchema = (t: TFunction) =>
  z
    .object({
      activationGroupUuid: z.string(),
      startAt: z.coerce.date({
        errorMap: (error) => {
          if (error.code === 'invalid_date') {
            return {
              message: t('activation_groups.create_schedule_form.start_at_invalid_error'),
            }
          }

          return {
            message: error.message!,
          }
        },
      }),
      endAt: z.coerce.date({
        errorMap: (error) => {
          if (error.code === 'invalid_date') {
            return {
              message: t('activation_groups.create_schedule_form.end_at_invalid_error'),
            }
          }

          return {
            message: error.message!,
          }
        },
      }),
      soldCapacity: z.coerce
        .number({
          required_error: t('activation_groups.create_schedule_form.sold_capacity_required_error'),
          invalid_type_error: t('activation_groups.create_schedule_form.sold_capacity_invalid_error'),
        })
        .min(0, t('activation_groups.create_schedule_form.sold_capacity_min_error')),
    })
    .refine(
      ({ startAt, endAt }) => {
        if (!startAt || !endAt) {
          return true
        }

        const luxonStartAt = DateTime.fromISO(startAt.toISOString())
        const luxonEndAt = DateTime.fromISO(endAt.toISOString())

        return luxonStartAt.startOf('second') < luxonEndAt.startOf('second')
      },
      { message: t('activation_groups.create_schedule_form.end_at_before_start_at_error'), path: ['endAt'] },
    )

export const getGenericCreateScheduleSchema = (t: TFunction) =>
  z
    .object({
      activationGroupUuid: z.string(),
      activationTriggerChannel: z
        .number({
          required_error: t('activation_groups.create_schedule_form.activation_trigger_channel_required_error'),
        })
        .min(0, t('activation_groups.create_schedule_form.activation_trigger_channel_required_error')),
      scheduleType: z.nativeEnum(ActivationScheduleType, {
        required_error: t('activation_groups.create_schedule_form.schedule_type_required_error'),
      }),
      startAt: z.coerce.date({
        errorMap: (error) => {
          if (error.code === 'invalid_date') {
            return {
              message: t('activation_groups.create_schedule_form.start_at_invalid_error'),
            }
          }

          return {
            message: error.message!,
          }
        },
      }),
      endAt: z.coerce.date({
        errorMap: (error) => {
          if (error.code === 'invalid_date') {
            return {
              message: t('activation_groups.create_schedule_form.end_at_invalid_error'),
            }
          }

          return {
            message: error.message!,
          }
        },
      }),
      volume: z.coerce
        .number({
          required_error: t('activation_groups.create_schedule_form.volume_required_error'),
          invalid_type_error: t('activation_groups.create_schedule_form.volume_invalid_error'),
        })
        .min(0, t('activation_groups.create_schedule_form.volume_min_error')),
    })
    .refine(
      ({ startAt, endAt }) => {
        if (!startAt || !endAt) {
          return true
        }

        const luxonStartAt = DateTime.fromISO(startAt.toISOString())
        const luxonEndAt = DateTime.fromISO(endAt.toISOString())

        return luxonStartAt.startOf('second') < luxonEndAt.startOf('second')
      },
      { message: t('activation_groups.create_schedule_form.end_at_before_start_at_error'), path: ['endAt'] },
    )
    .refine(
      ({ scheduleType, activationTriggerChannel }) => {
        if (scheduleType === ActivationScheduleType.PQ && activationTriggerChannel === 0) {
          return false
        }

        return true
      },
      {
        message: t('activation_groups.create_schedule_form.pq_activation_trigger_channel_illegal_error'),
        path: ['activationTriggerChannel'],
      },
    )
    .refine(
      ({ scheduleType, activationTriggerChannel }) => {
        if (scheduleType === ActivationScheduleType.DEMO && activationTriggerChannel !== 0) {
          return false
        }

        return true
      },
      {
        message: t('activation_groups.create_schedule_form.demo_activation_trigger_channel_illegal_error'),
        path: ['activationTriggerChannel'],
      },
    )

export const getCreatePrequalificationPatternSchema = (t: TFunction) =>
  z.object({
    activationGroupUuid: z.string(),
    name: z.string().min(1, t('activation_groups.create_form.name_required_error')),
    pattern: z
      .any()
      .refine((file) => Boolean(file), t('activation_groups.create_prequalification_pattern_form.csv_required_error'))
      .refine(
        (file) => file?.size <= MAX_FILE_SIZE,
        t('activation_groups.create_prequalification_pattern_form.file_too_big_error'),
      )
      .refine(
        (file) => file?.type === 'text/csv',
        t('activation_groups.create_prequalification_pattern_form.csv_required_error'),
      ),
  })

// eslint-disable-next-line sonarjs/no-identical-functions
export const getGenericCreatePrequalificationPatternSchema = (t: TFunction) =>
  z.object({
    activationGroupUuid: z.string(),
    name: z.string().min(1, t('activation_groups.create_form.name_required_error')),
    pattern: z
      .any()
      .refine((file) => Boolean(file), t('activation_groups.create_prequalification_pattern_form.csv_required_error'))
      .refine(
        (file) => file?.size <= MAX_FILE_SIZE,
        t('activation_groups.create_prequalification_pattern_form.file_too_big_error'),
      )
      .refine(
        (file) => file?.type === 'text/csv',
        t('activation_groups.create_prequalification_pattern_form.csv_required_error'),
      ),
  })

export const getStartPrequalificationSchema = (t: TFunction) =>
  z.object({
    patternUuid: z.string({
      required_error: t('activation_groups.start_prequalification_form.pattern_required_error'),
    }),
    prequalificationLane: z.nativeEnum(PrequalificationLane, {
      required_error: t('activation_groups.start_prequalification_form.prequalification_lane_required_error'),
    }),
    isMinCapacity: z.boolean(),
  })

export const getGenericStartPrequalificationSchema = (t: TFunction) =>
  z.object({
    patternUuid: z.string({
      required_error: t('activation_groups.start_prequalification_form.pattern_required_error'),
    }),
    activationTriggerChannel: z.number({
      required_error: t('activation_groups.start_prequalification_form.activation_trigger_channel_required_error'),
    }),
    isMinCapacity: z.boolean(),
  })

export const getAbortPrequalificationSchema = (t: TFunction) =>
  z.object({
    prequalificationLane: z.nativeEnum(PrequalificationLane, {
      required_error: t('activation_groups.abort_prequalification_form.prequalification_lane_required_error'),
    }),
  })
