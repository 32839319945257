import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/material'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_FILTER_MODEL, DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { COLUMN_WITH_MEDIUM_ICON_WIDTH } from '@/constants/layout'
import ActivationGroupResourcesDataGridToolbar from '@/features/activationGroup/components/ActivationGroupResourcesDataGridToolbar'
import AssignResourceDialog from '@/features/activationGroup/components/AssignResourceDialog'
import UnassignResourceConfirmationButton from '@/features/activationGroup/components/UnassignResourceConfirmationButton'
import { useActivationGroupResourcesQuery } from '@/features/activationGroup/hooks/useActivationGroupResourcesQuery'
import { useActivationGroupDetails } from '@/features/activationGroup/pages/ActivationGroupDetails'
import type { ApiActivationGroupResource } from '@/features/activationGroup/types'
import LifecycleStageChip from '@/features/resource/components/LifecycleStageChip'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

type ActivationGroupResourcesDataGridProps = {
  activationGroupUuid: string
}

type ActivationGroupResourceRenderCell = GridRenderCellParams<ApiActivationGroupResource>

const ActivationGroupResourcesDataGrid = ({ activationGroupUuid }: ActivationGroupResourcesDataGridProps) => {
  const { t } = useTranslation()
  const { resources, isLoading } = useActivationGroupResourcesQuery({ activationGroupUuid })
  const [openAssignResourceDialog, setOpenAssignResourceDialog] = useState(false)
  const { activationGroup } = useActivationGroupDetails()

  const columns: GridColDef<ApiActivationGroupResource>[] = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 2,
    },
    {
      field: 'name',
      headerName: t('common.name'),
      flex: 1,
    },
    {
      field: 'verifiedCapacity',
      headerName: t('activation_groups.resources.table.header.verified_capacity'),
      flex: 1,
      valueGetter: (value: ApiActivationGroupResource['verifiedCapacity']) => convertEnergyPower(value, 'kilowatts'),
    },
    {
      field: 'activationPriority',
      headerName: t('activation_groups.resources.table.header.activation_priority'),
      flex: 1,
    },
    {
      field: 'lifecycleStage',
      headerName: t('activation_groups.resources.table.header.lifecycle_stage'),
      flex: 1,
      renderCell: ({ row: { lifecycleStage } }: ActivationGroupResourceRenderCell) => (
        <LifecycleStageChip lifecycleStage={lifecycleStage} />
      ),
    },
    {
      field: 'isUsedForMinCapacityPrequalification',
      headerName: t('activation_groups.resources.table.header.is_used_for_min_capacity_prequalification'),
      flex: 1,
      renderCell: ({ row: { isUsedForMinCapacityPrequalification } }: ActivationGroupResourceRenderCell) => (
        <Stack justifyContent="center" sx={{ height: 1 }}>
          {isUsedForMinCapacityPrequalification ? (
            <CheckIcon
              color="success"
              titleAccess={t('activation_groups.resources.table.is_used_for_min_capacity_prequalification')}
            />
          ) : (
            <CloseIcon
              color="error"
              titleAccess={t('activation_groups.resources.table.is_not_used_for_min_capacity_prequalification')}
            />
          )}
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      width: COLUMN_WITH_MEDIUM_ICON_WIDTH,
      renderCell: ({ row }: ActivationGroupResourceRenderCell) => (
        <UnassignResourceConfirmationButton
          key={row.uuid}
          activationGroup={activationGroup}
          activationGroupResources={resources ?? []}
          unassignedResource={row}
        />
      ),
    },
  ]

  function handleOpenAssignResourceDialog() {
    setOpenAssignResourceDialog(true)
  }

  function handleCloseAssignResourceDialog() {
    setOpenAssignResourceDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        disableColumnSorting
        aria-label={t('activation_groups.resources.table.title')}
        columns={columns}
        getRowId={(row) => row.uuid}
        initialState={{
          filter: {
            filterModel: DEFAULT_DATA_GRID_FILTER_MODEL,
          },
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          sorting: {
            sortModel: [],
          },
        }}
        isLoading={isLoading}
        rows={resources ?? []}
        slotProps={{ toolbar: { onAssignResourceToActivatinGroup: handleOpenAssignResourceDialog } }}
        slots={{ toolbar: ActivationGroupResourcesDataGridToolbar }}
      />

      <AssignResourceDialog
        activationGroup={activationGroup}
        open={openAssignResourceDialog}
        resources={resources ?? []}
        onClose={handleCloseAssignResourceDialog}
      />
    </>
  )
}

export default ActivationGroupResourcesDataGrid
