import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import Stack from '@mui/material/Stack'

import CustomIconButton from '@/components/inputs/CustomIconButton'

function CancelConfirmIconButtons() {
  return (
    <Stack direction="row" gap={0.5}>
      <CustomIconButton Icon={ClearIcon} color="default" size="small" type="reset" variant="solid" />
      <CustomIconButton Icon={CheckIcon} color="success" size="small" type="submit" variant="solid" />
    </Stack>
  )
}

export default CancelConfirmIconButtons
