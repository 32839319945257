import { Stack } from '@mui/material'
import type { FC, ReactNode } from 'react'

import DatabricksIcon from '@/assets/icons/databricks.svg'
import GrafanaIcon from '@/assets/icons/grafana.svg'
import VolueIcon from '@/assets/icons/volue.svg'
import CustomButton from '@/components/inputs/CustomButton'
import { MarketProgram } from '@/features/bidding/constants'
import type { Portfolio } from '@/features/bidding/types/bid'

interface Props {
  marketProgram: MarketProgram | undefined
  portfolio: Portfolio | undefined
}

const ExternalReferenceButtons: FC<Props> = ({ marketProgram, portfolio }) => {
  const filteredButtons = EXTERNAL_REFERENCE_LINKS.filter((button) => {
    const matchPriceArea = button.priceArea === portfolio?.priceArea || button.priceArea === undefined
    const matchMarketProgram = button.marketProgram === marketProgram || button.marketProgram === undefined
    const matchPartner = button.partnerCode === portfolio?.partnerCode || button.partnerCode === undefined

    return matchPriceArea && matchMarketProgram && matchPartner
  })

  return (
    <Stack sx={{ flexDirection: 'row', gap: 1 }}>
      {filteredButtons.map((item) => (
        <CustomButton
          key={item.link}
          color="inheritWhite"
          size="large"
          startIcon={item.icon}
          variant="outlined"
          onClick={() => window.open(item.link)}
        >
          {item.title}
        </CustomButton>
      ))}
    </Stack>
  )
}

type ExternalReferenceButton = {
  title: string
  link: string
  icon: ReactNode
  priceArea?: string
  partnerCode?: string
  marketProgram?: MarketProgram
}

const EXTERNAL_REFERENCE_LINKS: ExternalReferenceButton[] = [
  {
    title: 'Grafana',
    link: 'https://grafana.sympower.net/d/upOTU197z/bixia?orgId=5&var-location=bx3&var-partner=All',
    icon: <img alt="Grafana icon" src={GrafanaIcon} />,
    marketProgram: MarketProgram.FCRD_UP,
    partnerCode: 'SE-BIXIA',
  },
  {
    title: 'Grafana',
    link: 'https://grafana.sympower.net/d/uf4KObCZk/vattenfall?from=now-24h&orgId=2&to=now&var-location=se&var-partner=All',
    icon: <img alt="Grafana icon" src={GrafanaIcon} />,
    marketProgram: MarketProgram.FCRD_UP,
    partnerCode: 'SE-VATTENFALL',
  },
  {
    title: 'Databricks',
    link: 'https://dbc-5b304b3f-f6e5.cloud.databricks.com/sql/editor/78f006e0-5472-4099-93d2-177ed41afa99?o=3259736349780563#',
    icon: <img alt="Databricks icon" src={DatabricksIcon} />,
    priceArea: 'SE3',
    marketProgram: MarketProgram.FCRD_UP,
    partnerCode: 'SE-VATTENFALL',
  },
  {
    title: 'Volue',
    link: 'https://app.volueinsight.com/#tab/power/953/46',
    icon: <img alt="Volue icon" src={VolueIcon} />,
    marketProgram: MarketProgram.FCRD_UP,
  },
]
export default ExternalReferenceButtons
