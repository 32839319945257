import { Route, Routes } from 'react-router-dom'

import NavigateToError from '@/features/error/pages/NavigateToError'
import MeasurementsIndex from '@/features/measurement/pages/MeasurementsIndex'

function MeasurementsRoutes() {
  return (
    <Routes>
      <Route element={<MeasurementsIndex />} path="/" />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}

export default MeasurementsRoutes
