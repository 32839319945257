import { t } from 'i18next'
import { z } from 'zod'

export const UserRoleSchema = z.enum(
  [
    'administrators',
    'partner_administrators',
    'electricians',
    'activation_agents',
    'resource_owners',
    'customer_managers',
  ],
  {
    errorMap: () => ({ message: t('users.role.validations.required') }),
  },
)
export type UserRole = z.infer<typeof UserRoleSchema>

export const USER_ROLES: Record<string, { value: UserRole }> = {
  ADMINISTRATORS: {
    value: 'administrators',
  },
  PARTNER_ADMINISTRATORS: {
    value: 'partner_administrators',
  },
  ELECTRICIANS: {
    value: 'electricians',
  },
  ACTIVATION_AGENTS: {
    value: 'activation_agents',
  },
  RESOURCE_OWNERS: {
    value: 'resource_owners',
  },
  CUSTOMER_MANAGERS: {
    value: 'customer_managers',
  },
}
