import ReactECharts from 'echarts-for-react'

import { COMMON_CHART_OPTIONS } from '@/features/bessDashboard/constants/chart'
import type { FormattedRow } from '@/features/bessDashboard/types'
import { constructDateString, filterByMarketProgram, getTimeZoneString } from '@/features/bessDashboard/utils/chart'

type InteractionForecastChartProps = {
  actuals: FormattedRow[]
  marketProgram: string
}

function InteractionChart({ actuals, marketProgram }: Readonly<InteractionForecastChartProps>) {
  const priceAxisGlobalMax = Math.max(...actuals.map((item) => Number(item['capacity_price_per_MW_h'])))

  const filteredActuals = filterByMarketProgram(actuals, marketProgram)

  const option = {
    ...COMMON_CHART_OPTIONS,
    title: {
      text: `Price-Volume Interaction`,
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all',
        },
      ],
    },
    legend: {
      data: ['Price', 'Procured/offered'],
      top: '6%',
    },
    dataZoom: [
      {
        // throttle: 1, // Throttle the dataZoom eventsx
        realtime: true,
        start: 50,
        end: 100,
        xAxisIndex: [0, 1],
        labelFormatter: (value) => {
          const date = new Date(filteredActuals[value].ds)
          const { dayPart, timePart } = constructDateString(date)
          return dayPart + ' ' + timePart + ' ' + getTimeZoneString()
        },
      },
    ],
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        // axisLine: { onZero: false },
        data: filteredActuals.map((item) => item.ds),
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
    ],
    yAxis: [
      {
        name: 'Procured/offered percentage',
        type: 'value',
        axisTick: {
          show: true,
        },
        alignTicks: true,
        axisLabel: {
          formatter: (value) => `${Number(value * 100).toFixed(0)} %`,
        },
      },
      {
        name: 'Price',
        min: 0,
        max: priceAxisGlobalMax,
        type: 'value',
        axisLabel: {
          formatter: (value) => `${Number(value).toFixed(0)} €/MW,h`,
        },
      },
    ],

    series: [
      {
        name: 'Procured/offered',
        type: 'line',
        smooth: false,
        step: 'end',
        data: filteredActuals.map((item) => Number(item.procured_capacity_MW) / Number(item.offered_capacity_MW)),
        itemStyle: {
          color: '#334155',
        },
        showSymbol: false,
      },
      {
        name: 'Price',
        type: 'line',
        smooth: false,
        step: 'end',
        data: filteredActuals.map((item) => item.capacity_price_per_MW_h),
        itemStyle: {
          color: '#a78bfa',
        },
        showSymbol: false,
        yAxisIndex: 1,
        // xAxisIndex: 1,
      },
    ],
  }

  return <ReactECharts notMerge option={option} style={{ height: '500px', padding: '0' }} />
}

export default InteractionChart
