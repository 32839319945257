import type { TFunction } from 'i18next'

import type { ControlPortCategory } from '@/features/resource/types'

export function getControlTypeLabel(controlType: ControlPortCategory | null, t: TFunction<'translation'>): string {
  if (!controlType) {
    return ''
  }

  switch (controlType) {
    case 'RELAY':
      return t('resources.signal_types.relay')
    case 'ANALOG':
      return t('resources.signal_types.analog')
    default:
      return ''
  }
}
