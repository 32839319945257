import type { TFunction } from 'i18next'

import type { IntegrationStatus } from '@/features/integration/types'

const BASE_TRANSLATION_KEY = 'component.integration_status_chip'

export function getIntegrationStatusLabel(status: IntegrationStatus, t: TFunction<'translation'>): string {
  switch (status) {
    case 'ENABLED':
      return t(`${BASE_TRANSLATION_KEY}.enabled`)
    case 'DISABLED':
      return t(`${BASE_TRANSLATION_KEY}.disabled`)
    default:
      return t(`${BASE_TRANSLATION_KEY}.unknown`)
  }
}
