import type { GridPaginationModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import {
  GET_DISTURBANCE_ACTIVATED_RESOURCES_API_ID,
  getDisturbanceActivatedResources,
} from '@/features/disturbance/endpoints/disturbances'
import type { DisturbanceActivatedResource, DisturbanceActivatedResourcesPage } from '@/features/disturbance/types'
import type { UseQueryOptions } from '@/types/queries'

export type UseActivatedResourcesParams = {
  disturbanceUuid: string
  pagination: GridPaginationModel
}

export type UseActivatedResourcesQueryResult = Omit<UseQueryResult, 'data'> & {
  activatedResources: DisturbanceActivatedResource[] | null
  currentPage: number
  totalCount: number
}

export function useDisturbanceActivatedResourcesQuery(
  params: UseActivatedResourcesParams,
  options?: UseQueryOptions<DisturbanceActivatedResourcesPage>,
): UseActivatedResourcesQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryFn: () => getDisturbanceActivatedResources(params.disturbanceUuid, params.pagination),
    queryKey: [GET_DISTURBANCE_ACTIVATED_RESOURCES_API_ID, params],
  })

  return {
    ...queryResult,
    activatedResources: data?.activatedResources ?? null,
    currentPage: data?.currentPage ?? 0,
    totalCount: data?.totalCount ?? 0,
  }
}
