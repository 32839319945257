import type { CSSProperties } from 'react'

type TopCircleProps = {
  color: string
  style?: CSSProperties
}

const TopCircle = ({ color, style }: TopCircleProps) => {
  return (
    <svg fill="none" height="144" style={style} viewBox="0 0 389 144" width="389" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="148"
        id="mask0_169_48511"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="389"
        x="0"
        y="-4"
      >
        <rect fill={color} height="148" width="389" y="-4" />
      </mask>
      <g mask="url(#mask0_169_48511)">
        <path
          clipRule="evenodd"
          d="M194.5 144C301.919 144 389 56.9194 389 -50.5C389 -157.919 301.919 -245 194.5 -245C87.0806 -245 0 -157.919 0 -50.5C0 56.9194 87.0806 144 194.5 144ZM194.5 60.5304C255.82 60.5304 305.53 10.8204 305.53 -50.5C305.53 -111.82 255.82 -161.53 194.5 -161.53C133.179 -161.53 83.4695 -111.82 83.4695 -50.5C83.4695 10.8204 133.179 60.5304 194.5 60.5304Z"
          fill={color}
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

export default TopCircle
