import { useMutation } from '@tanstack/react-query'

import { deleteRestriction } from '@/features/customer/endpoints/restrictions'

export type UseDeleteRestrictionMutationResult = ReturnType<typeof useDeleteRestrictionMutation>

export function useDeleteRestrictionMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; restrictionId: number; location: string }) =>
      deleteRestriction(variables.customerUuid, variables.restrictionId, variables.location),
  })

  return {
    deleteRestriction: mutateAsync,
    ...mutationResult,
  }
}
