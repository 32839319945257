import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Login from './Login'

function getParsedSearchParams(searchParams: URLSearchParams): string {
  // Using the property .size makes the tests fail, so we use .toString().length instead
  const searchParamsSize = searchParams.toString().length

  if (!searchParamsSize) {
    return ''
  }

  // removes the redirectTo parameter from the search params
  searchParams.delete('redirectTo')

  return `?${searchParams.toString()}`
}

function LoginWithRedirect() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const parsedSearchParams = getParsedSearchParams(searchParams)
    const redirectToUrl = `${location.pathname}${parsedSearchParams}`

    if (redirectToUrl) {
      const redirectSearchParams = new URLSearchParams()

      redirectSearchParams.set('redirectTo', redirectToUrl)

      navigate(`/?${redirectSearchParams.toString()}`)
    }
  }, [location.search, location.pathname])

  return <Login />
}

export default LoginWithRedirect
