import { ErrorOutline } from '@mui/icons-material'
import type { Checkbox, Switch } from '@mui/material'
import { Box, Tooltip } from '@mui/material'
import type { ComponentProps } from 'react'
import type { Control } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CheckboxController from '@/components/inputs/CheckboxController'
import type { CustomAccordionProps } from '@/components/layouts/CustomAccordion'
import CustomAccordion from '@/components/layouts/CustomAccordion'
import { CustomerEmailNotificationAccordion } from '@/features/customer/components/settings/CustomerEmailNotificationAccordion'
import type { CustomerSettingsFormValues } from '@/features/customer/components/settings/CustomerSettingsForm'
import { CustomerSmsNotificationAccordion } from '@/features/customer/components/settings/CustomerSmsNotificationAccordion'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

const supportedCountries: CountryIdentifier[] = ['fi']

interface ToggleProps {
  name: 'isFinancialVisible' | 'notificationPreferences.isSmsEnabled' | 'notificationPreferences.isEmailEnabled'
  control: Control<CustomerSettingsFormValues>
  disabled?: boolean
  disabledReason?: string | null
}

const ToggleControl = (props: ToggleProps) => {
  function handleCheckboxClick(evt: { stopPropagation: () => void }) {
    evt.stopPropagation()
  }

  const toggleProps: ComponentProps<typeof Checkbox | typeof Switch> = {
    disabled: props?.disabled,
    edge: 'start',
    inputProps: { 'aria-label': props.name },
  }

  return (
    <CheckboxController
      {...toggleProps}
      controllerProps={{ control: props.control }}
      name={props.name}
      onClick={handleCheckboxClick}
    />
  )
}

interface CustomerServicesSettingsSectionProps {
  hasCustomerUserRole: boolean
}

export const CustomerServicesSettingsSection = ({ hasCustomerUserRole }: CustomerServicesSettingsSectionProps) => {
  const { t } = useTranslation()
  const { branding } = useWhitelabelConfigurationContext()
  const { control, watch, formState } = useFormContext<CustomerSettingsFormValues>()

  const isSmsEnabled = watch('notificationPreferences.isSmsEnabled')
  const phoneNumbers = watch('notificationPreferences.phoneNumbers')
  const isEmailEnabled = watch('notificationPreferences.isEmailEnabled')
  const email = watch('notificationPreferences.email')
  const location = watch('location')
  const isSmsWithErrors = !isSmsEnabled && getRegisteredPhoneNumbers()?.length === 0

  function getEmailAccordionDescription() {
    const hasRegisteredEmail = !!email

    if (hasRegisteredEmail) {
      return t('customer_settings.form.notification_preferences.email_registered_count', {
        count: 1,
      })
    }
  }

  function getRegisteredPhoneNumbers() {
    return phoneNumbers?.filter((phoneNumber) => !!phoneNumber)
  }

  function getSmsAccordionDescription() {
    const registeredPhoneNumbers = getRegisteredPhoneNumbers()

    if (registeredPhoneNumbers?.length === 0) {
      return undefined
    }

    if (registeredPhoneNumbers?.length === 1) {
      return t('customer_settings.form.notification_preferences.single_phone_registered')
    }

    return t('customer_settings.form.notification_preferences.phone_registered_count', {
      count: registeredPhoneNumbers?.length,
    })
  }

  function getPhoneNumberErrors() {
    return formState.errors?.notificationPreferences?.phoneNumbers?.filter?.((error) => !!error) ?? []
  }

  function getSmsNotificationErrorLabel() {
    //remove nulls
    const phoneNumbersErrors = getPhoneNumberErrors()

    if (phoneNumbersErrors && phoneNumbersErrors.length > 0) {
      const error = phoneNumbersErrors[0]

      return error?.message
    }
  }

  const isEmailWithErrors = !isEmailEnabled && !email

  const notificationPreferencesAccordionProps: CustomAccordionProps[] = [
    {
      title: t('customer_settings.form.notification_preferences.sms_title'),
      description: getSmsAccordionDescription(),
      statusProps:
        getPhoneNumberErrors().length > 0
          ? {
              label: getSmsNotificationErrorLabel() ?? '',
              color: 'error',
              icon: <ErrorOutline />,
            }
          : undefined,
      toggleNode: (
        <Tooltip
          placement="top-start"
          title={
            isSmsWithErrors
              ? t('customer_settings.form.notification_preferences.sms_notifications_disabled_reason')
              : undefined
          }
        >
          <Box>
            <ToggleControl control={control} disabled={isSmsWithErrors} name={'notificationPreferences.isSmsEnabled'} />
          </Box>
        </Tooltip>
      ),

      children: <CustomerSmsNotificationAccordion />,
    },
    {
      title: t('customer_settings.form.notification_preferences.email_title'),
      description: getEmailAccordionDescription(),
      statusProps: formState.errors.notificationPreferences?.email
        ? {
            label: formState.errors.notificationPreferences.email.message ?? '',
            color: 'error',
            icon: <ErrorOutline />,
          }
        : undefined,
      children: <CustomerEmailNotificationAccordion />,
      toggleNode: (
        <Tooltip
          placement="top-start"
          title={
            isEmailWithErrors
              ? t('customer_settings.form.notification_preferences.email_notifications_disabled_reason')
              : undefined
          }
        >
          <Box>
            <ToggleControl
              control={control}
              disabled={isEmailWithErrors}
              name={'notificationPreferences.isEmailEnabled'}
            />
          </Box>
        </Tooltip>
      ),
    },
  ]

  const financialVisibilityProps: CustomAccordionProps = {
    title: t('customer_settings.form.financial_visibility_title'),
    toggleNode: <ToggleControl control={control} name="isFinancialVisible" />,
  }

  const accordionsProps: CustomAccordionProps[] = shouldRenderFinancialVisibility(
    location as CountryIdentifier,
    hasCustomerUserRole,
  )
    ? [financialVisibilityProps, ...notificationPreferencesAccordionProps]
    : [...notificationPreferencesAccordionProps]

  return (
    <Box>
      <CustomTypography gutterBottom variant="h4">
        {t('customer_settings.form.services_settings_title', { applicationName: branding.name })}
      </CustomTypography>
      <Box>
        {accordionsProps.map((props) => (
          <CustomAccordion key={props.title} {...props} />
        ))}
      </Box>
    </Box>
  )
}

function shouldRenderFinancialVisibility(location: string, hasCustomerUserRole: boolean) {
  return supportedCountries.includes(location as CountryIdentifier) && !hasCustomerUserRole
}
