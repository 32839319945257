import { FormControlLabel, Switch } from '@mui/material'
import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid'
import { gridFilterModelSelector, GridToolbarFilterButton, useGridApiContext } from '@mui/x-data-grid'
import type { ChangeEvent } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import GridSearchOutlined from '@/components/dataDisplay/GridSearchOutlined'
import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CountryFilter from '@/components/datagrid/CountryFilter'
import MarketProgramFilter from '@/components/datagrid/MarketProgramFilter'
import type { MarketProgramType } from '@/types/marketProgramType'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends CustomersDataGridToolbarProps {}
}

type CustomersDataGridToolbarProps = {
  marketProgramTypes: MarketProgramType[]
  countryOptions: CountryIdentifier[]
  showCountryFilter: boolean
  showDisabledCustomers: boolean
  onToggleDisabledCustomersClick: (value: boolean) => void
}

const SERVICES_FILTER_NAME = 'services'
const COUNTRY_FILTER_NAME = 'country'

export const DEFAULT_DATA_GRID_CUSTOMERS_FILTER_MODEL: GridFilterModel = {
  items: [
    {
      id: 'services',
      field: 'services',
      operator: 'contains',
      value: '',
    },
    {
      id: 'country',
      field: 'country',
      operator: 'is',
      value: '',
    },
  ],
  quickFilterValues: [],
}

function CustomersDataGridToolbar({
  countryOptions,
  marketProgramTypes,
  showCountryFilter,
  showDisabledCustomers,
  onToggleDisabledCustomersClick,
}: Readonly<CustomersDataGridToolbarProps>) {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()
  const marketProgramOptions = useMemo(
    () =>
      marketProgramTypes.map((marketProgram) => ({
        id: marketProgram,
        value: marketProgram,
        label: t(`common.market_program.${marketProgram}`),
      })),
    [marketProgramTypes],
  )

  const filterModel = gridFilterModelSelector(apiRef)
  const marketProgramFilterValue = getFilterItemValue(filterModel, SERVICES_FILTER_NAME) ?? ''
  const countryFilterValue = getFilterItemValue(filterModel, COUNTRY_FILTER_NAME) ?? ''

  function handleFilterChange(filterName: string, items: GridFilterItem) {
    updateDataGridFilter(apiRef, filterName, items)
  }

  function handleDisabledFilterToggle(evt: ChangeEvent<HTMLInputElement>) {
    onToggleDisabledCustomersClick(evt.target.checked)
  }

  return (
    <GridToolbar
      filters={
        <>
          <GridSearchOutlined
            label={t('common.navigation.search')}
            placeholder={t('customer.search.placeholder')}
            quickFilterParser={(searchInput: string) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />

          <MarketProgramFilter
            showAllOption
            marketProgramOptions={marketProgramOptions}
            value={marketProgramFilterValue}
            onChange={(event) => {
              handleFilterChange('services', {
                id: 'services',
                field: 'services',
                operator: 'contains',
                value: event.target.value,
              })
            }}
          />

          {showCountryFilter && (
            <CountryFilter
              countryOptions={countryOptions}
              field="country"
              value={countryFilterValue}
              onChange={(items) => handleFilterChange(COUNTRY_FILTER_NAME, items[0])}
            />
          )}

          <FormControlLabel
            control={<Switch checked={showDisabledCustomers} onChange={handleDisabledFilterToggle} />}
            label={t('component.filter.show_disabled')}
            sx={{ m: 0 }}
          />
          <GridToolbarFilterButton />
        </>
      }
    />
  )
}

export default CustomersDataGridToolbar
