import { Route, Routes } from 'react-router-dom'

import NavigateToError from '@/features/error/pages/NavigateToError'
import { IntegrationsRouteInformation } from '@/features/integration/constants'
import CustomerControllers from '@/features/integration/pages/CustomerControllers'
import IntegrationsCustomers from '@/features/integration/pages/IntegrationsCustomers'
import IntegrationsIndex from '@/features/integration/pages/IntegrationsIndex'

import CustomerResources from './pages/CustomerResources'
import IntegrationCustomerDetails from './pages/IntegrationCustomerDetails'

export default function IntegrationsRoutes() {
  return (
    <Routes>
      {/* It's not clear yet what we will show in the index page, but let's keep it for now  */}
      <Route index element={<IntegrationsIndex />} path={IntegrationsRouteInformation.INDEX.routePath} />

      {/* The "index" page in our case will be the /integrations/customers */}
      <Route element={<IntegrationsCustomers />} path={IntegrationsRouteInformation.CUSTOMERS.routePath} />

      <Route element={<IntegrationCustomerDetails />} path={IntegrationsRouteInformation.CUSTOMER_DETAILS.routePath}>
        <Route index element={<CustomerControllers />} />

        <Route
          index
          element={<CustomerControllers />}
          path={IntegrationsRouteInformation.CUSTOMER_CONTROLLERS.routePath}
        />

        <Route index element={<CustomerResources />} path={IntegrationsRouteInformation.CUSTOMER_RESOURCES.routePath} />

        <Route element={<NavigateToError statusCode={404} />} path="*" />
      </Route>

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
