import type { Theme } from '@mui/material'
import { createTheme } from '@mui/material/styles'

import { components } from '@/features/whitelabel/common/components'
import { typographyOptions } from '@/features/whitelabel/common/typography'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

export const useMuiTheme: (...args: object[]) => Theme = (...args: object[]) => {
  const { typography, palette } = useWhitelabelConfigurationContext()

  const muiTypography = typographyOptions(typography)

  return createTheme(
    {
      components,
      typography: muiTypography,
      palette: {
        primary: {
          main: palette.primary,
        },
        secondary: {
          main: palette.secondary || palette.primary,
        },
        background: {
          default: palette.background,
        },
        text: {
          primary: palette.text,
        },
        success: {
          main: palette.success,
        },
        warning: {
          main: palette.warning,
        },
        error: {
          main: palette.error,
        },
      },
    },
    ...args,
  )
}
