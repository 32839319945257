import { useTranslation } from 'react-i18next'

import error403Src from '@/assets/errors/403.png'
import ErrorPageLayout from '@/features/error/components/ErrorPageLayout'

const Error403 = () => {
  const { t } = useTranslation()

  return (
    <ErrorPageLayout
      description={t('error_pages.403.description')}
      image={{
        alt: t('error_pages.403.image_alt'),
        src: error403Src,
      }}
      solutions={[t('error_pages.403.solutions.ask_for_access')]}
      statusCode={403}
      title={t('error_pages.403.title')}
    />
  )
}

export default Error403
