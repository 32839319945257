import AddIcon from '@mui/icons-material/AddOutlined'
import DeleteAllIcon from '@mui/icons-material/DeleteSweepOutlined'
import { DialogContent, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import FormDialogActions from '@/components/feedback/FormDialogActions'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { DEFAULT_CURRENCY } from '@/features/bidding/constants'

import type { Mode } from './EditablePriceBox'
import EditablePriceBox from './EditablePriceBox'

export type BiddingPricesDialogProps = {
  initialPrices: number[]
  open: boolean
  locales: string
  onClose: () => void
  onSubmit: (updatedPrices: Record<number, number | null>) => void
}

const BODY_CELL_HEIGHT = '52px'
const HEADER_CELL_HEIGHT = '56px'

const BiddingPricesDialog = ({ open, initialPrices, locales, onClose, onSubmit }: BiddingPricesDialogProps) => {
  const { t } = useTranslation()
  const [prices, setPrices] = useState<Record<number, number | null>>(buildPricesMap(initialPrices))
  const [fieldError, setFieldError] = useState<string | null>(null)
  // It controls when a price is being edited
  const [isEditing, setIsEditing] = useState(false)
  const [showNewPriceField, setShowNewPriceField] = useState(false)

  const sortedPrices = Object.values(prices)
    .filter((price): price is number => price !== null)
    .sort((a, b) => a - b)

  const commonEditablePriceBoxProps = {
    currency: DEFAULT_CURRENCY,
    disabled: isEditing,
    error: fieldError ?? undefined,
    locales,
    onModeChange: handleModeChange,
    onPriceChange: handlePriceChange,
  }

  const handlePricesDelete = () =>
    setPrices((prevState) => Object.keys(prevState).reduce((acc, key) => ({ ...acc, [Number(key)]: null }), {}))

  const handlePriceDelete = (deletedPrice: number) => setPrices((prevState) => ({ ...prevState, [deletedPrice]: null }))

  /**
   * This event handler is triggered when an individual price is submitted. It updates the price value.
   */
  const handlePriceSubmit = (prevPrice: number, newPrice: number) =>
    setPrices((prevState) => ({
      ...prevState,
      [prevPrice]: newPrice,
    }))

  /**
   * We use the price change handler mainly to validate the price introduced. We perform the following validations:
   *
   * - The price cannot be null.
   * - The price must be greater than 0.
   * - The price cannot already exist.
   *
   * To check if the price already exists, we filter the prices array to exclude the price that is being edited and check if the
   * new price is already present.
   */
  function handlePriceChange(prevPrice: number, price: number | null) {
    if (price === null) {
      setFieldError(t('bidding.bidding_prices_dialog.price_required_error'))
      return
    }

    if (price <= 0) {
      setFieldError(t('bidding.bidding_prices_dialog.price_greater_than_zero_error'))
      return
    }

    const oldPrices = Object.values(prices)
    const filteredPrices = oldPrices.filter((value) => value !== prevPrice)

    if (filteredPrices.includes(price)) {
      setFieldError(t('bidding.bidding_prices_dialog.price_duplicated_error', { price }))
      return
    }

    setFieldError(null)
  }

  function handlePriceAdd(_: number, newPrice: number) {
    handleHideNewPriceField()
    setPrices((prevState) => ({ ...prevState, [newPrice]: newPrice }))
  }

  function handleModeChange(newMode: Mode) {
    setIsEditing(newMode === 'edit')
  }

  function handleShowNewPriceField() {
    setShowNewPriceField(true)
    setIsEditing(true)
  }

  function handleHideNewPriceField() {
    setShowNewPriceField(false)
    setIsEditing(false)
  }

  function handleClose() {
    onClose()
    setShowNewPriceField(false)
    setIsEditing(false)
    setFieldError(null)
  }

  function handleSubmit() {
    onSubmit(prices)
    onClose()
  }

  return (
    <CustomDialog open={open} title={t('bidding.bidding_prices_dialog.title')} onClose={handleClose}>
      <DialogContent sx={{ paddingTop: '0 !important', marginTop: 2.5 }}>
        <Table stickyHeader aria-label={t('bidding.bidding_prices_dialog.prices_table.title')}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  paddingRight: 0,
                  paddingLeft: 1.25,
                  paddingY: 0,
                  height: HEADER_CELL_HEIGHT,
                  backgroundColor: 'white',
                }}
              >
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  {t('bidding.bidding_prices_dialog.prices_table.price_per_mw_header')}

                  <CustomIconButton
                    Icon={DeleteAllIcon}
                    color="error"
                    disabled={isEditing}
                    iconProps={{ titleAccess: t('bidding.bidding_prices_dialog.delete_all_button_label') }}
                    variant="solid"
                    onClick={handlePricesDelete}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Text is shown when there are no prices. It is not shown when adding the first price */}
            {sortedPrices.length === 0 && !showNewPriceField && (
              <TableRow>
                <TableCell sx={{ paddingRight: 0, paddingY: 0, paddingLeft: 1.25, height: BODY_CELL_HEIGHT }}>
                  {t('bidding.bidding_prices_dialog.no_prices')}
                </TableCell>
              </TableRow>
            )}

            {/* List of prices */}
            {sortedPrices.map((price) => (
              <TableRow key={price}>
                <TableCell sx={{ paddingX: 0, paddingY: 0, height: BODY_CELL_HEIGHT }}>
                  <EditablePriceBox
                    {...commonEditablePriceBoxProps}
                    initialPrice={price}
                    onDelete={handlePriceDelete}
                    onSubmit={handlePriceSubmit}
                  />
                </TableCell>
              </TableRow>
            ))}

            {/* This field is shown when a user wants to add a new price */}
            {showNewPriceField && (
              <TableRow>
                <TableCell sx={{ paddingX: 0, paddingY: 0, height: BODY_CELL_HEIGHT }}>
                  <EditablePriceBox
                    {...commonEditablePriceBoxProps}
                    initialMode="edit"
                    initialPrice={0}
                    onCancel={handleHideNewPriceField}
                    onSubmit={handlePriceAdd}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>

      <Stack direction="row" justifyContent="flex-end" paddingX={3} paddingY={0.625}>
        <CustomIconButton
          Icon={AddIcon}
          color="primary"
          disabled={isEditing}
          iconProps={{ titleAccess: t('bidding.bidding_prices_dialog.add_price_label_button') }}
          variant="solid"
          onClick={handleShowNewPriceField}
        />
      </Stack>
      <FormDialogActions isLoading={isEditing} onCancel={handleClose} onSubmit={handleSubmit} />
    </CustomDialog>
  )
}

// Transform the initial prices array into a map where the key and value are the same
const buildPricesMap = (initialPrices: number[]): Record<number, number> =>
  initialPrices.reduce((acc, price) => ({ ...acc, [price]: price }), {})

export default BiddingPricesDialog
