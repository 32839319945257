import { DateTime } from 'luxon'

import type { ActivationGroup } from '@/features/activationGroup/types'
import { Status } from '@/features/bidding/constants'
import type { BidMetaData } from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

export default (deliveryDay: MarketDate, activationGroup: ActivationGroup): BidMetaData => ({
  status: Status.DRAFT,
  createdAt: DateTime.now(),
  deliveryDay: deliveryDay,
  portfolio: activationGroup.portfolio,
  marketProgram: activationGroup.marketProgram,
  activationGroupUuid: activationGroup.uuid,
})
