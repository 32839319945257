import type { SelectChangeEvent } from '@mui/material'
import type { TFunction } from 'i18next'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import { getFutureDeliveryDays } from '@/features/bidding/utils/date/getFutureDeliveryDays'

type Props = { value: string; onChange: (event: SelectChangeEvent) => void }

const DeliveryWindowSelector: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation()

  const blankOption = { value: '', label: '-', id: `filter-item-blank` }

  return (
    <CustomSelectField
      data-testid="delivery-window-selector"
      id="deliveryDay"
      label={t('bidding.overview.header.market_window')}
      options={[blankOption, ...getMarketWindowOptions(t)]}
      sx={{ minWidth: FIELD_WIDTH }}
      value={value}
      onChange={onChange}
    />
  )
}

const getMarketWindowOptions = (t: TFunction): SelectFieldOption<string>[] =>
  getFutureDeliveryDays().map((deliveryDay, i) => ({
    id: `marketWindow-filter-item-${deliveryDay.toISODate()}`,
    value: deliveryDay.toISODate(),
    label: t('bidding.overview.market_window_date', { days: i }),
  }))

export default DeliveryWindowSelector
