import type { AxiosInstance } from 'axios'

export function setDeployedVersionHeader(client: AxiosInstance, deployedVersion: string) {
  client.defaults.headers.common['X-FlexPortal-Version'] = deployedVersion
}

/**
 * Natively, axios considers only 2xx as success request. This function will make axios consider 3xx codes as success
 * as well, so client requests will throw an error when the response status is 4xx or 5xx.
 */
export function set3xxValidStatus(client: AxiosInstance) {
  client.defaults.validateStatus = (status) => status >= 200 && status < 399
}
