import { useMutation } from '@tanstack/react-query'

import type { CreateServiceRuleRequest } from '@/features/availability/types/serviceRule'
import { createServiceRule } from '@/features/customer/endpoints/serviceRules'

export type UseCreateServiceRuleMutationResult = ReturnType<typeof useCreateServiceRuleMutation>

export function useCreateServiceRuleMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: {
      customerUuid: string
      serviceId: number | undefined
      serviceRule: CreateServiceRuleRequest
    }) => createServiceRule(variables.customerUuid, variables.serviceId, variables.serviceRule),
  })

  return {
    createServiceRule: mutateAsync,
    ...mutationResult,
  }
}
