import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent } from '@mui/material'
import type { DateTime } from 'luxon'
import type { UseFormReturn } from 'react-hook-form'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormDialogActions from '@/components/feedback/FormDialogActions'
import DesktopDateTimePickerController from '@/components/inputs/DesktopDateTimePickerController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { TODAY } from '@/constants/dateTime'
import { getCreateScheduleSchema } from '@/features/activationGroup/schemas'
import type { CreateSchedule } from '@/features/activationGroup/types'

type CreateScheduleDialogFormProps = {
  activationGroupUuid: string
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: CreateSchedule) => void
}

type EndAtDatePickerProps = {
  form: UseFormReturn<CreateSchedule>
}

const EndAtDatePicker = ({ form }: EndAtDatePickerProps) => {
  const { t } = useTranslation()
  const startAtValue = useWatch({ name: 'startAt', control: form.control })

  return (
    <DesktopDateTimePickerController
      ampm={false}
      disabled={!startAtValue}
      label={t('activation_groups.create_schedule_form.end_at_label')}
      // The cast is necessary because the value is a DateTime while editing,
      // even though the submitted value is converted to an ISO string.
      minDateTime={(startAtValue as unknown as DateTime) ?? TODAY}
      name="endAt"
      slotProps={{
        textField: {
          id: 'schedule-end-at',
          required: true,
          inputProps: {
            'aria-label': t('activation_groups.create_schedule_form.end_at_label'),
          },
        },
      }}
      views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
    />
  )
}

/**
 * Contains all the logic related to the form to create a new activation group schedule.
 *
 * It is used within the CreateScheduleDialog component
 */
const CreateScheduleDialogForm = ({
  activationGroupUuid,
  isLoading,
  onCancel,
  onSubmit,
}: CreateScheduleDialogFormProps) => {
  const { t } = useTranslation()
  const form = useForm<CreateSchedule>({
    defaultValues: {
      activationGroupUuid,
    },
    resolver: zodResolver(getCreateScheduleSchema(t)),
  })

  function handleSubmit(data: CreateSchedule) {
    onSubmit(data)
  }

  return (
    <FormProvider {...form}>
      <form
        noValidate
        aria-label={t('activation_groups.create_schedule_form.title')}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <DesktopDateTimePickerController
            ampm={false}
            label={t('activation_groups.create_schedule_form.start_at_label')}
            minDate={TODAY}
            name="startAt"
            slotProps={{
              textField: {
                id: 'schedule-start-at',
                required: true,
                inputProps: {
                  'aria-label': t('activation_groups.create_schedule_form.start_at_label'),
                },
              },
            }}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
          />

          <EndAtDatePicker form={form} />

          <TextFieldController
            required
            id="schedule-sold-capacity"
            label={t('activation_groups.create_schedule_form.sold_capacity_label')}
            name="soldCapacity"
            type="number"
          />
        </DialogContent>

        <FormDialogActions isLoading={isLoading} onCancel={onCancel} />
      </form>
    </FormProvider>
  )
}

export default CreateScheduleDialogForm
