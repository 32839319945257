import ReactECharts from 'echarts-for-react'

import type { ExampleFeatureValue } from '@/features/exampleFeature/types'

type ExampleFeatureChartProps = {
  exampleFeatureValues: ExampleFeatureValue[]
}

function ExampleFeatureChart({ exampleFeatureValues }: Readonly<ExampleFeatureChartProps>) {
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      bottom: 0,
      itemGap: 30,
      show: true,
      itemWidth: 15,
      icon: 'rect',
      data: exampleFeatureValues.map((item) => item.name),
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: exampleFeatureValues.map((item) => ({
      type: 'line',
      stack: 'Total',
      ...item,
    })),
  }

  return <ReactECharts notMerge={true} option={option} style={{ height: '400px' }} />
}

export default ExampleFeatureChart
