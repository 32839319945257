import { Stack } from '@mui/material'
import type { FC } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

const ALTERNATIVE_TEXT = '-'

type InlineLabeledTextFieldProps = {
  label: string
  text?: string | null
  width?: string
}
const InlineLabeledTextField: FC<InlineLabeledTextFieldProps> = ({ label, text, width }) => (
  <Stack alignItems="center" direction="row" gap={1} width={width}>
    <CustomTypography fontSize={14} variant="h4">
      {label + ':'}
    </CustomTypography>
    <CustomTypography fontSize={14} variant="h6">
      {text ?? ALTERNATIVE_TEXT}
    </CustomTypography>
  </Stack>
)

export default InlineLabeledTextField
