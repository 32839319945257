import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createActivationGroupSchedule } from '@/features/activationGroup/endpoints/activationGroups'
import type { ActivationGroupSchedule, CreateSchedule } from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseCreateScheduleMutationResult = Omit<
  UseMutationResult<ActivationGroupSchedule, Error, CreateSchedule>,
  'mutateAsync'
> & {
  createSchedule: UseMutationResult<ActivationGroupSchedule, Error, CreateSchedule>['mutateAsync']
}

export function useCreateScheduleMutation(
  options?: UseMutationOptions<ActivationGroupSchedule, CreateSchedule>,
): UseCreateScheduleMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: CreateSchedule) => createActivationGroupSchedule(variables),
  })

  return { createSchedule: mutateAsync, ...mutationResult }
}
