import type { SxProps } from '@mui/material'
import Paper from '@mui/material/Paper'
import type { ReactNode } from 'react'

type MainContentContainerProps = {
  children: ReactNode
  sx?: SxProps
}

function MainContentContainer({ children, sx }: Readonly<MainContentContainerProps>) {
  return (
    <Paper
      sx={{
        mt: 3,
        p: 3,
        ...sx,
      }}
    >
      {children}
    </Paper>
  )
}

export default MainContentContainer
