import type { TFunction } from 'i18next'

import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import type { ResourceType } from '@/features/resource/types'

type LowercaseResourceType = Lowercase<ResourceType>

export function getResourceTypeTranslation(resourceType: ResourceType, t: TFunction<'translation'>): string {
  return t(`resources.types.${resourceType.toLowerCase() as LowercaseResourceType}`)
}

export function getResourceTypeOptions(
  resourceTypes: ResourceType[],
  t: TFunction<'translation'>,
): SelectFieldOption<ResourceType>[] {
  return resourceTypes.map((resourceType) => ({
    id: resourceType,
    label: getResourceTypeTranslation(resourceType, t),
    value: resourceType,
  }))
}
