import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { ActivatedResource } from '@/features/activation/types/activation'
import { GET_ACTIVATED_RESOURCES_API_ID, getActivatedResources } from '@/features/customer/endpoints/activations'
import type { UseQueryOptions } from '@/types/queries'

type ActivatedResourcesQueryParams = {
  customerUuid?: string
  activationId?: number
  serviceId?: number
  location?: string
}
export type UseActivatedResourcesQueryResult = Omit<UseQueryResult, 'data'> & {
  activatedResources: ActivatedResource[] | null
}

export function useActivatedResourcesQuery(
  params: ActivatedResourcesQueryParams,
  options?: UseQueryOptions<ActivatedResource[]>,
): UseActivatedResourcesQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_ACTIVATED_RESOURCES_API_ID, params],
    queryFn: () => getActivatedResources(params.customerUuid, params.activationId, params.serviceId, params.location),
  })

  return { activatedResources: data, ...queryResult }
}
