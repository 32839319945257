import { Stack } from '@mui/material'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import CustomAccordion from '@/components/layouts/CustomAccordion'

type UserAccountAccordionProps = {
  children: ReactNode
  defaultExpanded: boolean
}

const UserInterfaceAccordion = ({ children, defaultExpanded }: UserAccountAccordionProps) => {
  const { t } = useTranslation()

  return (
    <CustomAccordion
      defaultExpanded={defaultExpanded}
      description={t('user_form.form.description_interface')}
      title={t('user_form.form.title_interface')}
    >
      <Stack direction="column" gap={3}>
        {children}
      </Stack>
    </CustomAccordion>
  )
}

export default UserInterfaceAccordion
