import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, Stack, Tooltip } from '@mui/material'
import { grey } from '@mui/material/colors'
import type { FC } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

const LABEL_COLOR = '#9e9e9e'
const ALTERNATIVE_TEXT = '-'

const filledVariantStyle = {
  bgcolor: grey[50],
  borderRadius: 1,
  p: 2,
}

type LabeledTextFieldProps = {
  label: string
  text?: string | null
  variant?: 'default' | 'filled'
  width?: string
}
const LabeledTextField: FC<LabeledTextFieldProps> = ({ label, text, variant, width }) => (
  <Box sx={{ ...(variant === 'filled' ? filledVariantStyle : {}) }} width={width ?? '100%'}>
    <CustomTypography color={LABEL_COLOR} variant="caption">
      {label}
    </CustomTypography>
    <CustomTypography>{text ?? ALTERNATIVE_TEXT}</CustomTypography>
  </Box>
)

type LabeledTextFieldWithTooltipProps = {
  label: string
  tooltip: string
  text?: string | null
}
export const LabeledTextFieldWithTooltip: FC<LabeledTextFieldWithTooltipProps> = ({ label, tooltip, text }) => (
  <Box width={'100%'}>
    <Stack alignItems="center" direction="row" gap={1}>
      <CustomTypography color={LABEL_COLOR} variant="caption">
        {label}
      </CustomTypography>
      <Tooltip title={tooltip}>
        <HelpOutlineIcon fontSize="small" sx={{ color: LABEL_COLOR }} />
      </Tooltip>
    </Stack>
    <CustomTypography>{text ?? ALTERNATIVE_TEXT}</CustomTypography>
  </Box>
)

export default LabeledTextField
