import { AddTaskOutlined } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import type { Status } from '@/features/bidding/constants'
import { ManualAcceptFlow } from '@/features/bidding/constants'
import { isManuallyEditable } from '@/features/bidding/utils/bidStatus'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import { buildBidEditLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'

interface ManuallyEditOptionProps {
  groupedBidParams: GroupedBidParams
  statuses: Status[]
}

const ManuallyEditOption = ({ groupedBidParams, statuses }: ManuallyEditOptionProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      {statuses.some((status) => isManuallyEditable(status)) && (
        <MenuItem
          aria-label={t('bidding.options.edit_accepted')}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            navigate(buildBidEditLink(ManualAcceptFlow.EDIT, groupedBidParams))
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <AddTaskOutlined />
            </Grid>
            <Grid item xs="auto">
              {t('bidding.options.edit_accepted')}
            </Grid>
          </Grid>
        </MenuItem>
      )}
    </>
  )
}

export default ManuallyEditOption
