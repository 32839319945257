import type { GridPaginationModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  GET_PREQUALIFICATIONS_API,
  getGenericPrequalifications,
} from '@/features/activationGroup/endpoints/activationGroups'
import type { GenericPrequalification, GenericPrequalificationsPage } from '@/features/activationGroup/types'
import type { UseQueryOptions } from '@/types/queries'

type UseGenericPrequalificationsQueryParams = {
  patternUuid: string
  pagination: GridPaginationModel
}

export type UseGenericPrequalificationsQueryResult = Omit<UseQueryResult, 'data'> & {
  prequalifications: GenericPrequalification[] | null
  currentPage: number
  totalCount: number
}

export function useGenericPrequalificationsQuery(
  params: UseGenericPrequalificationsQueryParams,
  options?: UseQueryOptions<GenericPrequalificationsPage>,
): UseGenericPrequalificationsQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_PREQUALIFICATIONS_API, params],
    queryFn: () => getGenericPrequalifications(params.patternUuid, params.pagination),
  })

  return {
    ...queryResult,
    prequalifications: data?.prequalifications ?? null,
    currentPage: data?.currentPage ?? 0,
    totalCount: data?.totalCount ?? 0,
  }
}

export function useInvalidateGenericPrequalificationsQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_PREQUALIFICATIONS_API] })
}
