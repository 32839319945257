import ReactECharts from 'echarts-for-react'

import { COMMON_CHART_OPTIONS } from '@/features/bessDashboard/constants/chart'
import type { FormattedRow } from '@/features/bessDashboard/types'
import {
  constructDateString,
  filterByMarketProgram,
  filterByType,
  findOverallMinMaxForColumns,
  getTimeZoneString,
  renderItem,
} from '@/features/bessDashboard/utils/chart'

type ForecastChartProps = {
  filterType?: string | null
  forecastValues: FormattedRow[]
  marketProgram: string
}

function ForecastChart({ filterType = null, forecastValues, marketProgram }: Readonly<ForecastChartProps>) {
  const minMax = findOverallMinMaxForColumns(forecastValues, 'forecast-lo-80', 'forecast-hi-80')

  let data = filterByMarketProgram(forecastValues, marketProgram)

  if (filterType) {
    data = filterByType(data, filterType)
  }

  const highData = data.map((d, i) => [i, d['forecast-lo-80'], d['forecast-hi-80']])
  const lowData = data.map((d, i) => [i, d['forecast-lo-50'], d['forecast-hi-50']])

  const unit = data[0]?.unit?.toString() ?? ''

  const option = {
    ...COMMON_CHART_OPTIONS,
    title: {
      text: filterType ?? '',
    },
    grid: {
      bottom: 80,
    },
    legend: {
      data: ['Actual', 'Forecast', 'P80', 'P50'],
      top: '6%',
    },
    dataZoom: [
      {
        // throttle: 1, // Throttle the dataZoom eventsx
        start: 50,
        end: 100,
        labelFormatter: (value) => {
          const date = new Date(data[value].ds)
          const { dayPart, timePart } = constructDateString(date)
          return dayPart + ' ' + timePart + ' ' + getTimeZoneString()
        },
      },
    ],
    xAxis: {
      type: 'category',
      boundaryGap: false,
      // axisLine: { onZero: false },
      data: data.map(function (item) {
        return item.ds
      }),

      axisLabel: {
        formatter: (value) => {
          const date = new Date(value)
          const { dayPart, timePart } = constructDateString(date)
          // Return the date and time, separated by a newline for the two-line format
          return `${timePart}\n${dayPart}`
        },
        rotate: 0,
      },
    },
    yAxis: {
      type: 'value',
      axisTick: {
        show: true,
      },
      min: -30,
      max: Number(minMax.overallMax + 10),
      axisLabel: {
        formatter: (value) => `${Number(value).toFixed(0)} ${unit}`,
      },
    },
    series: [
      {
        name: 'Actual',
        type: 'line',
        smooth: false,
        step: 'end',
        data: data.map((item) => (item.historical === 'true' ? item.actual : null)),
        lineStyle: {
          width: 3,
          // type: 'dashed',
        },
        itemStyle: {
          color: '#333',
        },
        showSymbol: false,
      },
      {
        name: 'Forecast',
        type: 'line',
        smooth: false,
        step: 'end',
        data: data.map((item) => item.forecast),
        lineStyle: {
          width: 2,
          // type: 'dashed',
        },
        itemStyle: {
          color: 'rgba(69, 170, 242, 1.0)',
        },
        showSymbol: false,
      },

      {
        name: 'P80',
        type: 'custom',
        data: highData,
        animation: false,
        animationDuration: 1000, // Adjust to your needs
        animationEasing: 'linear', // Linear easing for smooth motion
        renderItem: renderItem,
        itemStyle: { color: 'rgba(69, 170, 242, 0.2)' },
        yAxisIndex: 0,
        xAxisIndex: 0,
        encode: {
          tooltip: [1, 2],
        },
        dimensions: [null, 'P80 lo', 'P80 hi'],
      },
      {
        name: 'P50',
        type: 'custom',
        data: lowData,
        animation: false,
        animationDuration: 1000, // Adjust to your needs
        animationEasing: 'linear', // Linear easing for smooth motion
        itemStyle: { color: 'rgba(69, 170, 242, 0.4)' },
        renderItem: renderItem,
        yAxisIndex: 0,
        xAxisIndex: 0,
        encode: {
          tooltip: [1, 2],
        },
        dimensions: [null, 'P50 lo', 'P50 hi'],
      },
    ],
  }

  return <ReactECharts notMerge option={option} style={{ height: '500px', padding: '0' }} />
}

export default ForecastChart
