import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import GenericStartPrequalificationDialogForm from '@/features/activationGroup/components/GenericStartPrequalificationDialogForm'
import { useInvalidateGenericPrequalificationsQuery } from '@/features/activationGroup/hooks/useGenericPrequalificationsQuery'
import { useGenericStartPrequalificationMutation } from '@/features/activationGroup/hooks/useGenericStartPrequalificationMutation'
import type { GenericStartPrequalification } from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type GenericStartPrequalificationDialogProps = {
  patternUuid: string
  open: boolean
  onClose: () => void
}

const GenericStartPrequalificationDialog = ({
  patternUuid,
  open,
  onClose,
}: GenericStartPrequalificationDialogProps) => {
  const { t } = useTranslation()
  const { startPrequalification, isPending } = useGenericStartPrequalificationMutation()
  const invalidatePrequalificationsQuery = useInvalidateGenericPrequalificationsQuery()
  const { pushAlert } = useAlertContext()

  function handleClose() {
    onClose()
  }

  async function handleSubmit(data: GenericStartPrequalification) {
    try {
      await startPrequalification(data)
      await invalidatePrequalificationsQuery()

      pushAlert({
        message: t('activation_groups.start_prequalification_success_message'),
        severity: 'success',
      })
      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.start_prequalification_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog open={open} title={t('activation_groups.start_prequalification_dialog.title')} onClose={handleClose}>
      <GenericStartPrequalificationDialogForm
        isLoading={isPending}
        patternUuid={patternUuid}
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </CustomDialog>
  )
}

export default GenericStartPrequalificationDialog
