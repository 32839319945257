import type { GridColDef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { DURATION_FORMAT, HOUR_h_MINUTE_m_SECONDS_s_FORMAT } from '@/constants/dateTimeFormats'
import { ActivationDetailsToolbar } from '@/features/activation/components/ActivationDetailsToolbar'
import type { ActivatedResource } from '@/features/activation/types/activation'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useActivatedResourcesQuery } from '@/features/customer/hooks/useActivatedResourcesQuery'
import { useActivationQuery } from '@/features/customer/hooks/useActivationQuery'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import type { Customer } from '@/features/customer/types/customer'
import getDuration, { convertToTimeZoneDateTime } from '@/utils/time'

const DASH_VALUE = '-'

function formatActivatedResourceDuration(startTime?: string, endTime?: string) {
  if (!startTime || !endTime) {
    return DASH_VALUE
  }
  return getDuration(startTime, endTime).toFormat(DURATION_FORMAT).replace('00h ', '')
}

function formatResourceActivationTime(customerTimeZone: string, iso?: string) {
  return iso ? convertToTimeZoneDateTime(customerTimeZone, iso).toFormat(HOUR_h_MINUTE_m_SECONDS_s_FORMAT) : DASH_VALUE
}

function getMarketPrograms(customer?: Customer) {
  return new Map(customer?.services?.map((mp) => [mp.id, mp.type]))
}

export const ActivationDetails = () => {
  const { loggedInUser } = useAuth()
  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid },
    { enabled: Boolean(customerUuid) },
  )

  const { t } = useTranslation()
  const { id } = useParams()

  const { activation } = useActivationQuery(
    {
      customerUuid: customerUuid,
      activationId: Number(id),
      location: customer ? customer.location : undefined,
    },
    { enabled: !!customer && !isFetchingCustomer },
  )

  const marketProgram = getMarketPrograms(customer ?? undefined).get(activation?.serviceId)

  const { activatedResources, isFetching } = useActivatedResourcesQuery(
    {
      location: customer ? customer.location : undefined,
      activationId: activation?.id,
      serviceId: activation?.serviceId,
      customerUuid: customerUuid,
    },
    { enabled: !!activation && !!customer && !isFetchingCustomer },
  )

  const customerTimeZone =
    useCustomerSettingsQuery({ uuid: customerUuid }).customerSettings?.localization?.timeZone ?? 'UTC'

  const columns: GridColDef[] = [
    {
      field: 'wireName',
      headerName: t('customer_details.activations.details.resource.label'),
      flex: 1,
    },
    {
      field: 'wireSectionName',
      headerName: t('customer_details.activations.details.section.label'),
      flex: 1,
      valueGetter: (value: ActivatedResource['wireSectionName']) => {
        return value ?? DASH_VALUE
      },
    },
    {
      field: 'activatedAt',
      headerName: t('customer_details.activations.details.start_time.label'),
      flex: 1,
      valueFormatter: (value: ActivatedResource['activatedAt']) => {
        return formatResourceActivationTime(customerTimeZone, value)
      },
    },
    {
      field: 'deactivatedAt',
      headerName: t('customer_details.activations.details.end_time.label'),
      flex: 1,
      valueFormatter: (value: ActivatedResource['deactivatedAt']) => {
        return formatResourceActivationTime(customerTimeZone, value)
      },
    },
    {
      field: 'duration',
      headerName: t('customer_details.activations.details.duration.label'),
      flex: 1,
      valueGetter: (_, activatedResource: ActivatedResource) => {
        return formatActivatedResourceDuration(activatedResource.activatedAt, activatedResource.deactivatedAt)
      },
    },
  ]

  if (!customer || isFetchingCustomer || !activation) return null

  return (
    <CustomDataGrid
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
        sorting: {
          sortModel: [],
        },
      }}
      isLoading={isFetching}
      rows={activatedResources ?? []}
      slotProps={{
        toolbar: {
          activation,
          marketProgram,
          customerTimeZone,
        },
      }}
      slots={{
        toolbar: ActivationDetailsToolbar,
      }}
    />
  )
}
