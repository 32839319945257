import Typography from '@mui/material/Typography'
import type { GridColDef } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { formatDateTimeWithLocale } from '@/utils/time'

interface ExceptionDatesDataGridProps {
  exceptionDates: string[]
}

export const ExceptionDatesDataGrid = (props: ExceptionDatesDataGridProps) => {
  const { t, i18n } = useTranslation()

  const columns: GridColDef[] = [
    {
      field: 'id',
      width: 200,
      type: 'string',
    },
  ]

  const exceptionDates = props.exceptionDates
  exceptionDates.sort((a, b) => a.localeCompare(b))
  const exceptionDateTexts = exceptionDates.map((date) => ({
    id: formatDateTimeWithLocale(DateTime.fromISO(date), i18n.language),
  }))

  return (
    <>
      <Typography variant="h6">
        {t('customer_details.tabs.availability.availability_details.exception_dates.title')}
      </Typography>

      <CustomDataGrid
        disableGutterBottom
        disableGutterTop
        disableRowSelectionOnClick
        columnHeaderHeight={0}
        columns={columns}
        includeWrapper={false}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
              page: 0,
            },
          },
        }}
        isRowSelectable={() => false}
        pageSizeOptions={[5, 10, 25]}
        rows={exceptionDateTexts}
      />
    </>
  )
}
