import { styled } from '@mui/material/styles'
import type { GridToolbarQuickFilterProps } from '@mui/x-data-grid'
import { GridToolbarQuickFilter } from '@mui/x-data-grid'

type GridSearchOutlinedProps = GridToolbarQuickFilterProps & {
  width?: string
}

const SEARCH_DEBOUNCE_TIME_MS = 500

const StyledGrid = styled(GridToolbarQuickFilter)<GridSearchOutlinedProps>(({ width }) => ({
  width,
  paddingBottom: 0,
}))

function GridSearchOutlined({ width = '300px', ...props }: GridSearchOutlinedProps) {
  return <StyledGrid variant="outlined" width={width} {...props} debounceMs={SEARCH_DEBOUNCE_TIME_MS} />
}

export default GridSearchOutlined
