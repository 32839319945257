import type { LinkProps } from '@mui/material'
import { Link } from '@mui/material'
import type { ElementType } from 'react'

type CustomLinkProps<RootComponent extends ElementType> = Omit<
  LinkProps<RootComponent>,
  'color' | 'variant' | 'variantMapping' | 'href'
> & {
  color?: 'primary' | 'inherit'
  href?: string
}

function CustomLink<RootComponent extends ElementType>(props: CustomLinkProps<RootComponent>) {
  return (
    <Link
      {...props}
      sx={{
        ':focus': {
          border: '2px solid #005FCC',
          borderRadius: '4px',
        },
      }}
      variant="body1"
    />
  )
}

export default CustomLink
