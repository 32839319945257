import type { CheckboxProps } from '@mui/material'
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material'
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

export type CheckboxControllerProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  CheckboxProps,
  'name'
> & {
  name: TName
  label?: string
  // It enables showing field validation errors. Its default value is true
  showError?: boolean
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'render' | 'name'>
}

function CheckboxField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Readonly<Omit<CheckboxControllerProps<TFieldValues, TName>, 'controllerProps'>>) {
  if (!props.label) {
    return <Checkbox checked={Boolean(props.value)} {...props} />
  }

  return <FormControlLabel control={<Checkbox checked={Boolean(props.value)} {...props} />} label={props.label} />
}

function CheckboxController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ label, name, showError = true, controllerProps, ...fieldProps }: CheckboxControllerProps<TFieldValues, TName>) {
  const { control } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field, fieldState: { error, invalid } }) =>
        showError ? (
          <FormControl error={invalid}>
            <CheckboxField {...field} checked={field.value} {...fieldProps} label={label} />

            {invalid && showError && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        ) : (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} name={name} {...fieldProps} />}
            label={label}
          />
        )
      }
    />
  )
}

export default CheckboxController
