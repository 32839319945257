import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import type { getActivationGroupParams } from '@/features/activationGroup/endpoints/activationGroups'
import { GET_ACTIVATION_GROUPS_API, getActivationGroups } from '@/features/activationGroup/endpoints/activationGroups'
import type { ActivationGroup } from '@/features/activationGroup/types'
import type { UseQueryOptions } from '@/types/queries'

export type UseActivationGroupsQueryResult = Omit<UseQueryResult, 'data'> & {
  activationGroups: ActivationGroup[] | null
}

export function useActivationGroupsQuery(
  params?: getActivationGroupParams,
  options?: UseQueryOptions<ActivationGroup[]>,
): UseActivationGroupsQueryResult {
  const { data = null, ...queryResult } = useQuery<ActivationGroup[]>({
    ...options,
    queryFn: () => getActivationGroups(params),
    queryKey: [GET_ACTIVATION_GROUPS_API, params],
  })

  return {
    ...queryResult,
    activationGroups: data,
  }
}

export function useInvalidateActivationGroupsQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_ACTIVATION_GROUPS_API] })
}
