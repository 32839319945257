import { Stack } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { DateTime } from 'luxon'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomFormControl from '@/components/inputs/CustomFormControl'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { ONE_YEAR_AGO, TODAY } from '@/constants/dateTime'
import { Period } from '@/constants/period'
import { CustomerSelector } from '@/features/customer/components/CustomerSelector'

type CustomerActivationsAppBarProps = {
  dateOfFirstActivation: DateTime
  fromDate: DateTime
  periodSelection: Period
  toDate: DateTime
  setFromDate: Dispatch<SetStateAction<DateTime>>
  setPeriodSelection: Dispatch<SetStateAction<Period>>
  setToDate: Dispatch<SetStateAction<DateTime>>
}

function CustomerActivationsAppBar({
  dateOfFirstActivation,
  fromDate,
  periodSelection,
  toDate,
  setFromDate,
  setPeriodSelection,
  setToDate,
}: Readonly<CustomerActivationsAppBarProps>) {
  const { t, i18n } = useTranslation()
  const { customerUuid } = useParams()

  const handleChange = (event) => {
    setPeriodSelection(event.target.value)
    switch (event.target.value) {
      case Period.ALL_TIME:
        setFromDate(dateOfFirstActivation)
        setToDate(TODAY)
        break
      case Period.PAST_12_MONTHS:
        setFromDate(ONE_YEAR_AGO)
        setToDate(TODAY)
        break
      case Period.PAST_MONTH:
        setFromDate(TODAY.minus({ months: 1 }))
        setToDate(TODAY)
        break
    }
  }

  return (
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <CustomerSelector customerUuid={customerUuid} />

        <CustomSelectField
          label={t('common.period.label')}
          options={[
            { id: 'all-time', value: Period.ALL_TIME, label: t('common.period.all_time') },
            { id: 'twelve-months', value: Period.PAST_12_MONTHS, label: t('common.period.past_12_months') },
            { id: 'past-month', value: Period.PAST_MONTH, label: t('common.period.past_month') },
            { id: 'custom', value: Period.CUSTOM, label: t('common.period.custom') },
          ]}
          size="medium"
          value={periodSelection}
          variant="outlinedWhite"
          onChange={handleChange}
        />

        <CustomFormControl variant="outlinedWhite">
          <DatePicker
            label={t('common.period.from')}
            maxDate={toDate}
            slotProps={{ textField: { size: 'medium' } }}
            value={fromDate}
            views={['month', 'year']}
            onChange={(newValue) => {
              if (newValue) {
                setFromDate(newValue)
                setPeriodSelection(Period.CUSTOM)
              }
            }}
          />
        </CustomFormControl>

        <CustomFormControl variant="outlinedWhite">
          <DatePicker
            label={t('common.period.to')}
            maxDate={TODAY}
            minDate={fromDate}
            slotProps={{ textField: { size: 'medium' } }}
            value={toDate}
            views={['month', 'year']}
            onChange={(newValue) => {
              if (newValue) {
                setToDate(newValue)
                setPeriodSelection(Period.CUSTOM)
              }
            }}
          />
        </CustomFormControl>
      </Stack>
    </LocalizationProvider>
  )
}

export default CustomerActivationsAppBar
