import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  GET_CUSTOMER_MARKET_PROGRAMS_API_ID,
  getCustomerMarketPrograms,
} from '@/features/customer/endpoints/customerMarketPrograms'
import type { MarketProgramContract } from '@/features/customer/types/marketProgramContract'
import type { UseQueryOptions } from '@/types/queries'

type CustomerMarketProgramsQueryResultParams = {
  uuid: string
  location?: string
}
export type UseCustomerMarketProgramsQueryResult = Omit<UseQueryResult, 'data'> & {
  marketProgramContracts: MarketProgramContract[] | null
}

export function useCustomerMarketProgramsQuery(
  params: CustomerMarketProgramsQueryResultParams,
  options?: UseQueryOptions<MarketProgramContract[]>,
): UseCustomerMarketProgramsQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    enabled: Boolean(params.uuid),
    queryKey: [GET_CUSTOMER_MARKET_PROGRAMS_API_ID, params],
    queryFn: () => getCustomerMarketPrograms(params.uuid, params.location),
  })

  return { marketProgramContracts: data, ...queryResult }
}

export function useInvalidateCustomerMarketProgramsQuery() {
  const queryClient = useQueryClient()

  return (params: CustomerMarketProgramsQueryResultParams) =>
    queryClient.invalidateQueries({ queryKey: [GET_CUSTOMER_MARKET_PROGRAMS_API_ID, params] })
}
