import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

function FullPageSpinner() {
  return (
    <Grid container alignItems="center" direction="row" justifyContent="center" sx={{ height: '100vh' }}>
      <CircularProgress title="Full page spinner" />
    </Grid>
  )
}

export default FullPageSpinner
