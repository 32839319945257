import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomDataGrid, { DEFAULT_FILTERS } from '@/components/dataDisplay/CustomDataGrid'
import { DURATION_FORMAT } from '@/constants/dateTimeFormats'
import CreateScheduleDialog from '@/features/activationGroup/components/CreateScheduleDialog'
import GenericCreateScheduleDialog from '@/features/activationGroup/components/GenericCreateScheduleDialog'
import ScheduleDataGridToolbar from '@/features/activationGroup/components/SchedulesDataGridToolbar'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useActivationGroupSchedulesQuery } from '@/features/activationGroup/hooks/useActivationGroupSchedulesQuery'
import { useGenericActivationGroupSchedulesQuery } from '@/features/activationGroup/hooks/useGenericActivationGroupSchedulesQuery'
import { useActivationGroupDetails } from '@/features/activationGroup/pages/ActivationGroupDetails'
import type { ActivationGroupSchedule, GenericActivationGroupSchedule } from '@/features/activationGroup/types'
import { isGenericPrequalificationApi } from '@/features/activationGroup/utils/genericPrequalificationPredicates'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'
import getDuration from '@/utils/time'

type ViewProps = {
  activationGroupUuid: string
}

function GenericView({ activationGroupUuid }: Readonly<ViewProps>) {
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [pagination, setPagination] = useState({ pageSize: 10, page: 0 })
  const { t } = useTranslation()
  const { isLoading, activationGroupSchedules, totalActivationGroupSchedules } =
    useGenericActivationGroupSchedulesQuery({
      activationGroupUuid: activationGroupUuid ?? '',
      pagination,
    })

  const columns = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 3,
    },
    {
      field: 'startAt',
      headerName: t('activation_groups.schedules.table.header.start'),
      flex: 2,
      valueFormatter: (value: GenericActivationGroupSchedule['startAt']) => new Date(value).toLocaleString(),
    },
    {
      field: 'endAt',
      headerName: t('activation_groups.schedules.table.header.end'),
      flex: 2,
      valueFormatter: (value: GenericActivationGroupSchedule['endAt']) => new Date(value).toLocaleString(),
    },
    {
      field: 'scheduleType',
      headerName: t('activation_groups.schedules.table.header.schedule_type'),
      flex: 1,
    },
    {
      field: 'portfolioChannel',
      headerName: t('activation_groups.schedules.table.header.portfolio_channel'),
      flex: 2,
      valueGetter: (_, schedule: GenericActivationGroupSchedule) =>
        schedule.portfolioCode + ' @ ' + schedule.activationTriggerChannel,
    },
    {
      field: 'volume',
      headerName: t('activation_groups.schedules.table.header.volume'),
      flex: 1,
      valueFormatter: (value: GenericActivationGroupSchedule['volume']) =>
        value ? convertEnergyPower(value, 'kilowatts') : '-',
    },
    {
      field: 'duration',
      headerName: t('activation_groups.schedules.table.header.duration'),
      flex: 1,
      valueGetter: (_, schedule: GenericActivationGroupSchedule) =>
        getDuration(schedule.startAt, schedule.endAt).toFormat(DURATION_FORMAT).replace('00h ', ''),
    },
  ]

  function handleOpenCreateDialog() {
    setOpenCreateDialog(true)
  }

  function handleCloseCreateDialog() {
    setOpenCreateDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        disableColumnSorting
        aria-label={t('activation_groups.table.title')}
        columns={columns}
        getRowId={(row) => row.uuid}
        initialState={DEFAULT_FILTERS}
        isLoading={isLoading}
        paginationMode="server"
        paginationModel={pagination}
        rowCount={totalActivationGroupSchedules ?? 0}
        rows={activationGroupSchedules ?? []}
        slotProps={{ toolbar: { onAddNewSchedule: handleOpenCreateDialog } }}
        slots={{ toolbar: ScheduleDataGridToolbar }}
        onPaginationModelChange={setPagination}
      />
      <GenericCreateScheduleDialog
        activationGroupUuid={activationGroupUuid ?? ''}
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
      />
    </>
  )
}

function LegacyView({ activationGroupUuid }: Readonly<ViewProps>) {
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [pagination, setPagination] = useState({ pageSize: 10, page: 0 })
  const { t } = useTranslation()
  const { isLoading, activationGroupSchedules, totalActivationGroupSchedules } = useActivationGroupSchedulesQuery({
    activationGroupUuid: activationGroupUuid ?? '',
    pagination,
  })

  const columns = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 2,
    },
    {
      field: 'soldCapacity',
      headerName: t('activation_groups.schedules.table.header.sold_capacity'),
      flex: 1,
      valueFormatter: (value: ActivationGroupSchedule['soldCapacity']) =>
        value ? convertEnergyPower(value, 'kilowatts') : '-',
    },
    {
      field: 'startAt',
      headerName: t('activation_groups.schedules.table.header.start'),
      flex: 1,
      valueFormatter: (value: ActivationGroupSchedule['startAt']) => new Date(value).toLocaleString(),
    },
    {
      field: 'endAt',
      headerName: t('activation_groups.schedules.table.header.end'),
      flex: 1,
      valueFormatter: (value: ActivationGroupSchedule['endAt']) => new Date(value).toLocaleString(),
    },
    {
      field: 'duration',
      headerName: t('activation_groups.schedules.table.header.duration'),
      flex: 1,
      valueGetter: (_, schedule: ActivationGroupSchedule) =>
        getDuration(schedule.startAt, schedule.endAt).toFormat(DURATION_FORMAT).replace('00h ', ''),
    },
  ]

  function handleOpenCreateDialog() {
    setOpenCreateDialog(true)
  }

  function handleCloseCreateDialog() {
    setOpenCreateDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        disableColumnSorting
        aria-label={t('activation_groups.table.title')}
        columns={columns}
        getRowId={(row) => row.uuid}
        initialState={DEFAULT_FILTERS}
        isLoading={isLoading}
        paginationMode="server"
        paginationModel={pagination}
        rowCount={totalActivationGroupSchedules ?? 0}
        rows={activationGroupSchedules ?? []}
        slotProps={{ toolbar: { onAddNewSchedule: handleOpenCreateDialog } }}
        slots={{ toolbar: ScheduleDataGridToolbar }}
        onPaginationModelChange={setPagination}
      />

      <CreateScheduleDialog
        activationGroupUuid={activationGroupUuid ?? ''}
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
      />
    </>
  )
}

function ActivationGroupSchedules() {
  const { activationGroupUuid } = useParams()
  const { t } = useTranslation()

  const { setPageConfig, activationGroup } = useActivationGroupDetails()

  useEffect(() => {
    setPageConfig({
      title: t('activation_groups.tabs.schedules'),
      activeTab: ActivationGroupRouteInformation.ACTIVATION_GROUP_SCHEDULES.routePath,
    })
  }, [])

  if (!activationGroupUuid) {
    return null
  }

  return isGenericPrequalificationApi(activationGroup) ? (
    <GenericView activationGroupUuid={activationGroupUuid} />
  ) : (
    <LegacyView activationGroupUuid={activationGroupUuid} />
  )
}

export default ActivationGroupSchedules
