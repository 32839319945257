import type { GridPaginationModel } from '@mui/x-data-grid'
import type { AxiosError } from 'axios'
import axios from 'axios'
import { DateTime } from 'luxon'

import environment from '@/environment'
import type {
  ApiDisturbanceActivatedResourcesResponse,
  ApiDisturbanceResponse,
  DisturbanceActivatedResourcesPage,
  DisturbancesPage,
} from '@/features/disturbance/types'

export const DISTURBANCES_API_URL = `${environment.services.resourceSelectionManagerApiUrl}/disturbances`
export const GET_DISTURBANCES_API_ID = 'GET_DISTURBANCES'
export const GET_DISTURBANCE_ACTIVATED_RESOURCES_API_ID = 'GET_DISTURBANCE_ACTIVATED_RESOURCES'

const buildPagination = (pagination: GridPaginationModel) => {
  const params = new URLSearchParams()

  params.append('page', String(pagination.page))
  params.append('pageSize', String(pagination.pageSize))

  return params
}

export async function getDisturbances(pagination: GridPaginationModel): Promise<DisturbancesPage> {
  const response = await axios.get<ApiDisturbanceResponse>(DISTURBANCES_API_URL, {
    params: buildPagination(pagination),
  })

  return {
    disturbances: response.data.results.map((disturbance) => ({
      ...disturbance,
      startedAt: DateTime.fromISO(disturbance.startedAt),
      endedAt: disturbance.endedAt ? DateTime.fromISO(disturbance.endedAt) : null,
      finishedAt: disturbance.finishedAt ? DateTime.fromISO(disturbance.finishedAt) : null,
    })),
    currentPage: response.data.currentPage,
    totalCount: response.data.totalResults,
  }
}

export async function getDisturbanceActivatedResources(
  uuid: string,
  pagination: GridPaginationModel,
): Promise<DisturbanceActivatedResourcesPage> {
  try {
    const response = await axios.get<ApiDisturbanceActivatedResourcesResponse>(
      `${DISTURBANCES_API_URL}/${uuid}/activated-resources`,
      {
        params: buildPagination(pagination),
      },
    )

    return {
      activatedResources: response.data.results.map((activatedResource) => ({
        ...activatedResource,
        askedToActivateAt: DateTime.fromISO(activatedResource.askedToActivateAt),
        activatedAt: activatedResource.activatedAt ? DateTime.fromISO(activatedResource.activatedAt) : null,
        askedToDeactivateAt: activatedResource.askedToDeactivateAt
          ? DateTime.fromISO(activatedResource.askedToDeactivateAt)
          : null,
        deactivatedAt: activatedResource.deactivatedAt ? DateTime.fromISO(activatedResource.deactivatedAt) : null,
      })),
      currentPage: response.data.currentPage,
      totalCount: response.data.totalResults,
    }
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return {
        activatedResources: [],
        currentPage: 0,
        totalCount: 0,
      }
    }

    throw err
  }
}
