import * as Sentry from '@sentry/react'

import environment from '@/environment'

import { isAxiosRequestCanceled, isTokenExpired } from './utils/checks'

export function initSentry(envName: string) {
  // Let's make sure we don't initialize Sentry client many times
  if (Sentry.isInitialized()) return

  Sentry.init({
    beforeSend: (event, hint: any) => {
      const error = hint?.originalException ? hint.originalException : null

      if (isTokenExpired(error)) return null
      if (isAxiosRequestCanceled(error)) return null

      return event
    },
    dsn: environment.sentryDSN,
    environment: envName,
    ignoreErrors: ['ResizeObserver loop completed with undelivered notifications'],
    integrations: [
      Sentry.extraErrorDataIntegration(),
      Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
      Sentry.browserTracingIntegration(),
    ],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.2,
  })
}
