import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { renderDuration } from '@/features/customer/components/availability/restriction/form/renderDuration'
import { formatLocalizedDateMed } from '@/features/customer/components/availability/utils'
import type { RestrictionForm } from '@/features/customer/types/restrictionForm'

interface RestrictionConfigurationInfoSectionProps {
  customerTimeZone: string
}

export const RestrictionConfigurationInfoSection = (props: RestrictionConfigurationInfoSectionProps) => {
  const { t, i18n } = useTranslation()
  const { getValues } = useFormContext<RestrictionForm>()
  const { startDate, endDate, startTime, endTime, type } = getValues()

  return (
    <Box
      sx={{
        bgcolor: grey[200],
        borderRadius: 3,
        p: 2,
      }}
      width={'100%'}
    >
      <CustomTypography paragraph variant={'h3'}>
        {`${t('customer_details.tabs.availability.common.configuration_section.intro')}:`}
      </CustomTypography>
      <CustomTypography component="div" variant={'h4'}>
        {`${t('customer_details.tabs.availability.common.configuration_section.unavailability.summary', {
          duration: renderDuration(type, endDate, endTime, startDate, startTime),
          start_date: formatLocalizedDateMed(props.customerTimeZone, i18n.language, startDate),
          end_date: formatLocalizedDateMed(props.customerTimeZone, i18n.language, endDate),
        })}`}
      </CustomTypography>
    </Box>
  )
}
