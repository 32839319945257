import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_USERS_API_ID, getUsers } from '@/features/user/endpoints/users'
import type { User } from '@/features/user/types/user'
import type { UseQueryOptions } from '@/types/queries'

type UsersQueryParams = {
  partnerCode?: string | null
}
export type UseUsersQueryResult = Omit<UseQueryResult, 'data'> & {
  users?: User[]
}

export function useUsersQuery(params: UsersQueryParams, options?: UseQueryOptions<User[]>): UseUsersQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_USERS_API_ID, params],
    queryFn: () => getUsers(params.partnerCode),
  })

  return { users: data, ...queryResult }
}
