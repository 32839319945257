import type { ReactNode } from 'react'

import CustomAppBar from '@/components/layouts/CustomAppBar'

type PageAppBarProps = {
  appBarContent?: ReactNode
}

function PageAppBar({ appBarContent }: Readonly<PageAppBarProps>) {
  return (
    <CustomAppBar
      position="static"
      sx={{ mt: 2, borderRadius: '3px' }}
      toolbarProps={{ disableGutters: true, sx: { px: 1.5, py: 0 } }}
    >
      {appBarContent}
    </CustomAppBar>
  )
}

export default PageAppBar
