import { Stack } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid'
import type { ReactNode } from 'react'

interface TableToolbarProps {
  filters?: ReactNode
  actions?: ReactNode
  bulkActions?: ReactNode
}

/**
 * This component is an extension of GridToolbarContainer from Material UI Data Grid. It applies some style
 * modifications like:
 *
 * - Min height of the toolbar is 64px
 * - Space between filters and actions is 8px.
 * - Filters and actions are aligned to the left and right respectively.
 */
const GridToolbar = ({ filters, actions, bulkActions }: TableToolbarProps) => {
  return (
    <Stack>
      <GridToolbarContainer sx={{ justifyContent: 'space-between', px: 1.25, minHeight: '64px' }}>
        <Stack direction="row" sx={{ alignItems: 'stretch', gap: 1 }}>
          {filters}
        </Stack>

        <Stack direction="row" sx={{ alignItems: 'stretch', gap: 1 }}>
          {actions}
        </Stack>
      </GridToolbarContainer>

      {bulkActions && <Stack>{bulkActions}</Stack>}
    </Stack>
  )
}

export default GridToolbar
