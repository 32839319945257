import { useTranslation } from 'react-i18next'

import error500Src from '@/assets/errors/500.png'
import ErrorPageLayout from '@/features/error/components/ErrorPageLayout'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

const Error500 = () => {
  const { t } = useTranslation()
  const { mailto } = useWhitelabelConfigurationContext()

  return (
    <ErrorPageLayout
      description={t('error_pages.500.description')}
      image={{
        alt: t('error_pages.500.image_alt'),
        src: error500Src,
      }}
      solutions={[
        t('error_pages.500.solutions.refresh_page'),
        t('error_pages.common.solutions.contact_support', {
          supportMail: mailto.support,
        }),
      ]}
      statusCode={500}
      title={t('error_pages.500.title')}
    />
  )
}

export default Error500
