import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'
import OfferedBidPtusDataGrid from '@/features/bidding/components/capacity/offer/OfferedBidPtusDataGrid'
import { useInvalidateBidHistoriesQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import { useInvalidateGroupedBidsPageQuery } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import { useMarketClosingWarning } from '@/features/bidding/hooks/useMarketClosingWarning'
import { useSaveBidsMutation } from '@/features/bidding/hooks/useSaveBidsMutation'
import type { BidMetaData, BidPtu, BulkFillMode, OfferedAndForecastPtu } from '@/features/bidding/types/bid'
import { calculateBulkFillCapacity } from '@/features/bidding/utils/calculations/calculateBulkFillCapacity'
import { bidFromOfferedAndForecastPtus } from '@/features/bidding/utils/model/createBid'

type OfferedBidFormProps = {
  ptus: OfferedAndForecastPtu[]
  bidMetaData: BidMetaData
  pastPtusToCopy: BidPtu[]
}

const OfferedBidForm = ({ ptus, bidMetaData, pastPtusToCopy }: Readonly<OfferedBidFormProps>) => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const [bidPtus, setBidPtus] = useState<OfferedAndForecastPtu[]>(ptus)
  const invalidateBidHistories = useInvalidateBidHistoriesQuery()
  const invalidateGroupedBids = useInvalidateGroupedBidsPageQuery()
  const { saveBids } = useSaveBidsMutation()
  const { showMarketClosingWarning } = useMarketClosingWarning()
  const deliveryISODate = bidMetaData.deliveryDay.toISODate()

  useEffect(() => {
    copyPastePtus()
  }, [pastPtusToCopy])

  useEffect(() => {
    showMarketClosingWarning(bidMetaData.deliveryDay)
  }, [deliveryISODate])

  const onFillAll = async (bulkMode: BulkFillMode, bulkFillValue: number | undefined) => {
    if (bulkFillValue === undefined) return

    const newBidPtus: OfferedAndForecastPtu[] = bidPtus.map((ptu) => {
      const capacityWatts = calculateBulkFillCapacity(bulkMode, ptu, bulkFillValue)
      if (!capacityWatts) return ptu

      return { ...ptu, offeredVolume: { quantity: capacityWatts, unit: 'WATTS' } }
    })

    setBidPtus(newBidPtus)
    await saveDraft(newBidPtus)
  }

  const processBidPtuUpdate = async (newPtu: OfferedAndForecastPtu): Promise<OfferedAndForecastPtu> => {
    const value = Number(newPtu.offeredVolume.quantity)
    const isValid = Number.isInteger(value) && value >= 0
    if (!isValid)
      return Promise.reject(new Error(`We expect the value ${value} to be an integer greater or equal to 0`))

    const newBidPtus = bidPtus.map(
      (prevPtu): OfferedAndForecastPtu =>
        prevPtu.ptu.start.equals(newPtu.ptu.start) ? { ...prevPtu, offeredVolume: newPtu.offeredVolume } : prevPtu,
    )
    setBidPtus(newBidPtus)
    await saveDraft(newBidPtus)
    return Promise.resolve(newPtu)
  }

  // TODO AF-490: Make sure we have fresh data on the price bidding page
  const saveDraft = async (newBidPtus: OfferedAndForecastPtu[]) => {
    await saveBids([bidFromOfferedAndForecastPtus(bidMetaData, newBidPtus)])
    await invalidateBidHistories()
    await invalidateGroupedBids()
  }

  const copyPastePtus = async () => {
    if (pastPtusToCopy?.length === 0) {
      return
    }

    const newBidPtus = bidPtus.map((ptu): OfferedAndForecastPtu => {
      const pastPtu = pastPtusToCopy.find((pastPtu) => pastPtu.ptu.start.hour === ptu.ptu.start.hour)
      return !pastPtu ? ptu : { ...ptu, offeredVolume: pastPtu.volume }
    })

    setBidPtus(newBidPtus)

    await saveDraft(newBidPtus)

    pushAlert({
      message: t('bidding.create_bid.bid_copied_successfully'),
      severity: 'success',
    })
  }

  return (
    <OfferedBidPtusDataGrid
      bidMetaData={bidMetaData}
      processRowUpdate={processBidPtuUpdate}
      ptus={bidPtus}
      onFillAll={onFillAll}
    />
  )
}

export default OfferedBidForm
