import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { load as loadFonts } from 'webfontloader'

import { FONT_WEIGHTS } from '@/features/whitelabel/common/typography'
import { DEFAULT_SYMPOWER_CONFIG } from '@/features/whitelabel/default'
import { useWhitelabelConfigurationQuery } from '@/features/whitelabel/hooks/useWhitelabelConfigurationQuery'
import type { WhitelabelConfiguration } from '@/features/whitelabel/types/whitelabelConfiguration'

type WhitelabelProviderProps = {
  children: ReactNode
}

const WhitelabelContext = createContext<WhitelabelConfiguration>(DEFAULT_SYMPOWER_CONFIG)

function ThemeProvider(props: Readonly<WhitelabelProviderProps>) {
  const { whitelabelConfiguration, isPending } = useWhitelabelConfigurationQuery({ hostname: window.location.hostname })

  const [config, setConfig] = useState(DEFAULT_SYMPOWER_CONFIG)

  useEffect(() => {
    if (whitelabelConfiguration && !isPending) {
      setConfig(whitelabelConfiguration)
      setFavicon(whitelabelConfiguration.branding.favicon)
      setTitle(whitelabelConfiguration.branding.name)
      loadFonts({
        google: {
          families: [
            `${whitelabelConfiguration.typography.main}:${FONT_WEIGHTS}`,
            `${whitelabelConfiguration.typography.heading}:${FONT_WEIGHTS}`,
            `${whitelabelConfiguration.typography.fallback}:${FONT_WEIGHTS}`,
          ],
        },
      })
    }
  }, [whitelabelConfiguration, isPending])

  return <WhitelabelContext.Provider value={config}>{props.children}</WhitelabelContext.Provider>
}

const useWhitelabelConfigurationContext = () => useContext(WhitelabelContext)

function setFavicon(favicon: string) {
  document.head.insertAdjacentHTML('beforeend', `<link rel="icon" href="${favicon}" />`)
}

function setTitle(title: string) {
  document.title = title
}

export { ThemeProvider, useWhitelabelConfigurationContext, WhitelabelContext }
