import { Stack } from '@mui/material'
import * as React from 'react'

import { RevenueMonthlyAccordions } from '@/features/customer/components/revenue/RevenueMonthlyAccordions'
import { RevenueSummaryCards } from '@/features/customer/components/revenue/RevenueSummaryCards'
import type { RevenueDataRow } from '@/features/customer/types/revenue'

type RevenuesContentProps = {
  revenueData: RevenueDataRow[]
  selectedYear: string
  isLoadingRevenueTimeSeries: boolean
}

export const RevenueContent = ({ revenueData, selectedYear, isLoadingRevenueTimeSeries }: RevenuesContentProps) => {
  const capacityForYear = revenueData
    .map((revenue) => revenue.capacity)
    .reduce((totalCapacity, revenueCapacity) => totalCapacity + revenueCapacity, 0)
  const soldCapacityForYear = revenueData
    .map((revenue) => revenue.soldCapacity)
    .reduce((totalSold, soldCapacity) => totalSold + soldCapacity, 0)
  const revenueForYear = revenueData
    .map((revenue) => revenue.revenue)
    .reduce((totalRevenue, revenue) => totalRevenue + revenue, 0)

  return (
    <Stack direction="column" gap={3}>
      <RevenueSummaryCards
        capacityForYear={capacityForYear}
        isLoadingRevenueTimeSeries={isLoadingRevenueTimeSeries}
        revenuesForYear={revenueForYear}
        selectedYear={selectedYear}
        soldCapacityForYear={soldCapacityForYear}
      />
      <RevenueMonthlyAccordions isLoadingRevenueTimeSeries={isLoadingRevenueTimeSeries} revenueData={revenueData} />
    </Stack>
  )
}
