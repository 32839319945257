export function isApiError(error: any) {
  return error && Boolean(error.response)
}

export function hasPayload(error: any) {
  return error?.response?.config && Boolean(error.response.config.data)
}

export function isTokenExpired(error: any) {
  return error && error?.response?.data?.errorCode === 'token-expired'
}

export function isAxiosRequestCanceled(error: any) {
  return error && error?.message === 'config is undefined'
}

export function isNetworkError(error: any): boolean {
  const regex = /network error/i
  return regex.test(error)
}
