import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import PageHeader from '@/components/layouts/PageHeader'
import { useActivationGroupQuery } from '@/features/activationGroup/hooks/useActivationGroupQuery'
import { ActivationGroupTabs } from '@/features/activationGroup/pages/components/ActivationGroupTabs'
import type { ActivationGroup } from '@/features/activationGroup/types'

export type ActivationGroupDetailsPageConfig = {
  title: string
  activeTab: string
}

type ContextType = {
  activationGroup: ActivationGroup
  pageConfig: ActivationGroupDetailsPageConfig | null
  setPageConfig: Dispatch<SetStateAction<ActivationGroupDetailsPageConfig>>
}

function ActivationGroupDetails() {
  const { t } = useTranslation()
  const { activationGroupUuid } = useParams()
  const { activationGroup, isLoading } = useActivationGroupQuery({ activationGroupUuid: activationGroupUuid ?? null })

  const [pageConfig, setPageConfig] = useState<ActivationGroupDetailsPageConfig>({
    title: '',
    activeTab: '',
  })

  if (!activationGroup) {
    // TODO: Render not found error page
    return null
  }

  return (
    <>
      <PageHeader
        breadcrumbItems={[
          {
            text: t('component.page_header.activation_groups'),
            to: '/activation-groups',
          },
          { text: activationGroup.code },
        ]}
        pageTitle={pageConfig.title}
      />

      {pageConfig.activeTab !== '' && <ActivationGroupTabs pageConfig={pageConfig} />}

      {isLoading ? (
        <FullPageSpinner />
      ) : (
        <Outlet
          context={
            {
              pageConfig,
              setPageConfig,
              activationGroup,
            } satisfies ContextType
          }
        />
      )}
    </>
  )
}

export function useActivationGroupDetails() {
  return useOutletContext<ContextType>()
}

export default ActivationGroupDetails
