import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Button, Divider, FormControlLabel, Stack, Switch } from '@mui/material'
import Typography from '@mui/material/Typography'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useCallback, useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomIconButton from '@/components/inputs/CustomIconButton'
import { ZONED_TODAY } from '@/constants/dateTime'
import type { ExceptionDate, ServiceRuleForm } from '@/features/customer/types/serviceRuleForm'

export const ServiceRuleExceptionDatesSection = ({ timezone }: { timezone: string }) => {
  const { t, i18n } = useTranslation()
  const { trigger, watch, setValue, formState, clearErrors } = useFormContext<ServiceRuleForm>()
  const { fields, append } = useFieldArray({
    name: 'exceptionDates',
  })

  const exceptionDates = watch('exceptionDates')

  const triggerValidation = useCallback(() => {
    void trigger('exceptionDates')
  }, [trigger])

  useEffect(() => {
    triggerValidation()
  }, [triggerValidation, exceptionDates])

  return (
    <>
      <Typography variant="h6">
        {t('customer_details.tabs.availability.availability_details.exception_dates.title')}
      </Typography>
      <Stack divider={<Divider />} spacing={2}>
        {fields.map((field, index) => (
          <Stack key={field.id} alignItems={'flex-start'} direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={exceptionDates[index]?.range}
                  onChange={() => {
                    const value = exceptionDates[index]
                    value.range = !value.range
                    setValue(`exceptionDates.${index}`, value)
                    triggerValidation()
                  }}
                />
              }
              label={t('customer_details.tabs.availability.availability_details.exception_dates.range.label')}
              name={`exceptionDates.${index}`}
            />
            <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
              <DatePicker
                label={
                  exceptionDates[index]?.range
                    ? t('customer_details.tabs.availability.availability_details.exception_dates.range.from')
                    : ''
                }
                slotProps={{
                  textField: {
                    error: !!formState.errors?.exceptionDates?.[index]?.startDate,
                    helperText: formState.errors?.exceptionDates?.[index]?.startDate?.message,
                  },
                }}
                sx={{
                  width: '100%',
                }}
                value={exceptionDates[index]?.startDate}
                views={['day', 'month', 'year']}
                onChange={(date) => {
                  const value = exceptionDates[index]
                  value.startDate = date!
                  setValue(`exceptionDates.${index}`, value)
                  triggerValidation()
                }}
              />
              {exceptionDates[index]?.range && (
                <DatePicker
                  label={t('customer_details.tabs.availability.availability_details.exception_dates.range.to')}
                  slotProps={{
                    textField: {
                      error: !!formState.errors?.exceptionDates?.[index]?.endDate,
                      helperText: formState.errors?.exceptionDates?.[index]?.endDate?.message,
                    },
                  }}
                  sx={{
                    width: '100%',
                  }}
                  value={exceptionDates[index]?.endDate}
                  views={['day', 'month', 'year']}
                  onChange={(date) => {
                    const value = exceptionDates[index]
                    value.endDate = date!
                    setValue(`exceptionDates.${index}`, value)
                    triggerValidation()
                  }}
                />
              )}
            </LocalizationProvider>
            <CustomIconButton
              Icon={DeleteOutlinedIcon}
              aria-label={'delete-exception-date'}
              sx={{
                mt: 1,
              }}
              variant="plain"
              onClick={() => {
                const updatedFields = exceptionDates
                updatedFields?.splice(index, 1)
                clearErrors(`exceptionDates.${index}`)
                setValue('exceptionDates', updatedFields)
              }}
            />
          </Stack>
        ))}
        <Button
          startIcon={<AddOutlinedIcon />}
          sx={{
            maxInlineSize: 'fit-content',
          }}
          variant="outlined"
          onClick={() => {
            const defaultExceptionDate = {
              range: true,
              startDate: ZONED_TODAY(timezone),
              endDate: ZONED_TODAY(timezone).plus({ days: 1 }),
            } as ExceptionDate
            append(defaultExceptionDate)
          }}
        >
          {t('customer_details.tabs.availability.availability_details.exception_dates.add_exception_date')}
        </Button>
      </Stack>
    </>
  )
}
