import axios from 'axios'

import { getCountryAsAlpha2Code } from '@/constants/country'
import environment from '@/environment'
import type { ApiBidExport } from '@/features/bidding/types/api'
import type { BidExport } from '@/features/bidding/types/bidExports'
import { toInternalBidExport } from '@/features/bidding/utils/model/modelConversion'

export const GET_BID_EXPORTS_API_KEY = 'GET_BID_EXPORTS'

const PORTFOLIO_MANAGER_API_URL = environment.services.portfolioManagerApiUrl

export type BidExportsQueryParams = {
  countryCode: CountryIdentifier
}

export const getBidExports = async (params: BidExportsQueryParams): Promise<BidExport[]> => {
  const response = await axios.get(`${PORTFOLIO_MANAGER_API_URL}/bid/exports`, { params: buildUrlParams(params) })
  const apiBidExports: ApiBidExport[] = response.data
  return apiBidExports.map(toInternalBidExport)
}

export const getBidExportFile = async (bidExportId: number): Promise<Blob> => {
  const response = await axios.get(`${PORTFOLIO_MANAGER_API_URL}/bid/exports/files/${bidExportId}`)
  const fileContent: string = response.data
  return new Blob([fileContent], { type: 'text/tab-separated-values' })
}

const buildUrlParams = (params: BidExportsQueryParams) => {
  const urlParams = new URLSearchParams()
  if (params.countryCode) urlParams.append('countryCode', getCountryAsAlpha2Code(params.countryCode))
  return urlParams
}
