import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { MarketProgramType } from '@/types/marketProgramType'

type MarketProgramFilterTabsProps = {
  activeMarketPrograms: MarketProgramType[]
  value?: MarketProgramType
  onChange?: (newValue: MarketProgramType) => void
}
export const MarketProgramFilterTabs = (props: MarketProgramFilterTabsProps) => {
  if (!props.activeMarketPrograms) return null

  const { t } = useTranslation()

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        scrollButtons="auto"
        value={props.value}
        variant="scrollable"
        onChange={(event, value) => {
          props.onChange?.(value)
        }}
      >
        {props.activeMarketPrograms.map((marketProgramType) => (
          <Tab
            key={marketProgramType}
            label={t(`common.market_program.${marketProgramType}`)}
            value={marketProgramType}
          />
        ))}
      </Tabs>
    </Box>
  )
}
