import type { TFunction } from 'i18next'

import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import type { MarketProgramType } from '@/types/marketProgramType'

export function getMarketProgramOptions(
  marketPrograms: MarketProgramType[],
  t: TFunction<'translation'>,
): SelectFieldOption<MarketProgramType>[] {
  return marketPrograms.map((marketProgram) => ({
    id: marketProgram,
    label: t(`common.market_program.${marketProgram}`),
    value: marketProgram,
  }))
}
