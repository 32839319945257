import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { startGenericPrequalification } from '@/features/activationGroup/endpoints/activationGroups'
import type { GenericStartPrequalification } from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseGenericStartPrequalificationMutationResult = Omit<
  UseMutationResult<void, Error, GenericStartPrequalification>,
  'mutateAsync'
> & {
  startPrequalification: UseMutationResult<void, Error, GenericStartPrequalification>['mutateAsync']
}

export function useGenericStartPrequalificationMutation(
  options?: UseMutationOptions<void, GenericStartPrequalification>,
): UseGenericStartPrequalificationMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: GenericStartPrequalification) => startGenericPrequalification(variables),
  })

  return { startPrequalification: mutateAsync, ...mutationResult }
}
