import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_GRID_FREQUENCY_API_ID, getCurrentGridFrequency } from '@/features/dashboard/endpoints/gridFrequency'
import type { GridFrequency } from '@/features/dashboard/types/gridFrequency'
import type { UseQueryOptions } from '@/types/queries'

type LocationQueryParams = {
  location: string
}
export type UseCurrentGridFrequencyQueryResult = Omit<UseQueryResult, 'data'> & {
  currentGridFrequency: GridFrequency | null
}

export function useCurrentGridFrequencyQuery(
  params: LocationQueryParams,
  options?: UseQueryOptions<GridFrequency>,
): UseCurrentGridFrequencyQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_GRID_FREQUENCY_API_ID, params],
    queryFn: () => getCurrentGridFrequency(params.location),
  })

  return {
    currentGridFrequency: data,
    ...queryResult,
  }
}
