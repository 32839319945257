import { CardContent } from '@mui/material'
import type { ComponentProps } from 'react'

type CustomCardContentProps = ComponentProps<typeof CardContent>

/**
 * It customizes the CardActions component from MUI. The Sympower customizations are:
 *
 * - It modifies the padding of the component.
 */
const CustomCardContent = ({ sx, ...props }: CustomCardContentProps) => {
  return <CardContent {...props} sx={{ ...sx, padding: 3, paddingTop: 2 }} />
}

export default CustomCardContent
