import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { CustomerTabs } from '@/features/customer/pages/components/CustomerTabs'
import type { Customer } from '@/features/customer/types/customer'
import type { Breadcrumb } from '@/types/breadcrumb'

export type CustomerDetailsPageConfig = {
  title: string
  breadcrumbs: Breadcrumb[]
  appBarContent: ReactNode
  activeTab: string
}

type ContextType = {
  customer: Customer
  customerDetailsCommonBreadcrumbs: Breadcrumb[]
  isFetchingCustomer: boolean
  pageConfig: CustomerDetailsPageConfig | null
  setPageConfig: Dispatch<SetStateAction<CustomerDetailsPageConfig>>
}

function CustomerDetails() {
  const { t } = useTranslation()
  const { customerUuid } = useParams()
  const [pageConfig, setPageConfig] = useState<CustomerDetailsPageConfig>({
    title: '',
    breadcrumbs: [],
    appBarContent: null,
    activeTab: '',
  })
  const { customer, isFetching } = useCustomerQuery({ uuid: customerUuid! }, { enabled: Boolean(customerUuid) })

  if (!customerUuid || !customer || isFetching) return null

  const customerDetailsCommonBreadcrumbs = [
    {
      text: t('component.page_header.customers'),
      to: '/customers',
    },
    customer.name
      ? {
          text: t('component.page_header.customer.details', { customer: customer.name }),
          to: `/customers/${customer.uuid}`,
        }
      : {
          text: t('component.page_header.unknown_customer.details'),
          to: `/customers/${customer.uuid}`,
        },
  ]

  return (
    <>
      <PageHeader
        appBarContent={pageConfig.appBarContent}
        breadcrumbItems={pageConfig.breadcrumbs}
        pageTitle={pageConfig.title}
      />
      {pageConfig.activeTab !== '' && <CustomerTabs customer={customer} pageConfig={pageConfig} />}

      <Outlet
        context={
          {
            customer,
            customerDetailsCommonBreadcrumbs,
            isFetchingCustomer: isFetching,
            pageConfig,
            setPageConfig,
          } satisfies ContextType
        }
      />
    </>
  )
}

export function useCustomerDetails() {
  return useOutletContext<ContextType>()
}

export default CustomerDetails
