import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Card, Stack, Typography } from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import TextFieldController from '@/components/inputs/TextFieldController'
import CustomCardActions from '@/components/layouts/CustomCardActions'
import CustomCardContent from '@/components/layouts/CustomCardContent'
import ErrorMessage from '@/features/resource/components/ErrorMessage'
import type { AddSteeringRangeRequest, UpdateSteeringRangeRequest } from '@/features/resource/endpoints/resources'
import type { ResourceSteeringRangeSchemaType } from '@/features/resource/schemas'
import { ResourceSteeringRangeSchema } from '@/features/resource/schemas'
import type { ResourceSteeringRange } from '@/features/resource/types'
import { convertToKilo } from '@/features/resource/utils/convertToKilo'
import { convertToWatts } from '@/features/resource/utils/convertToWatts'

type SteeringRangeFormProps = {
  initialData?: ResourceSteeringRange
  onSave: (data: AddSteeringRangeRequest | UpdateSteeringRangeRequest) => void
  onCancel: () => void
  onDelete?: () => void
}

const SteeringRangeForm = ({ initialData, onSave, onCancel, onDelete }: SteeringRangeFormProps) => {
  const { t } = useTranslation()
  const methods = useForm<ResourceSteeringRangeSchemaType>({
    defaultValues: initialData
      ? {
          max: {
            valueType: initialData.max.valueType,
            value: convertToKilo(initialData.max.value),
            minSecondsOnThisLevel: initialData.max.minSecondsOnThisLevel,
            maxSecondsOnThisLevel: initialData.max.maxSecondsOnThisLevel,
          },
          min: {
            valueType: initialData.min.valueType,
            value: convertToKilo(initialData.min.value),
            minSecondsOnThisLevel: initialData.min.minSecondsOnThisLevel,
            maxSecondsOnThisLevel: initialData.min.maxSecondsOnThisLevel,
          },
          step: {
            valueType: initialData.step.valueType,
            value: convertToKilo(initialData.step.value),
            minSecondsOnThisLevel: initialData.step.minSecondsOnThisLevel,
            maxSecondsOnThisLevel: initialData.step.maxSecondsOnThisLevel,
          },
        }
      : {
          max: { valueType: 'ABSOLUTE', value: null, minSecondsOnThisLevel: null, maxSecondsOnThisLevel: null },
          min: { valueType: 'ABSOLUTE', value: null, minSecondsOnThisLevel: null, maxSecondsOnThisLevel: null },
          step: { valueType: 'ABSOLUTE', value: null, minSecondsOnThisLevel: null, maxSecondsOnThisLevel: null },
        },
    resolver: zodResolver(ResourceSteeringRangeSchema),
    mode: 'onChange',
  })

  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = methods
  watch('max.valueType')
  watch('min.valueType')

  const onSubmit = (data: ResourceSteeringRangeSchemaType) => {
    const convertedData = {
      max: { ...data.max, value: convertToWatts(data.max.value) },
      min: { ...data.min, value: convertToWatts(data.min.value) },
      step: { ...data.step, value: convertToWatts(data.step.value) },
    }
    onSave(convertedData)
  }

  const valueTypeOptions = [
    { id: 'ABSOLUTE', value: 'ABSOLUTE', label: t('resources.steering.steering_ranges.table.value_type_absolute') },
    { id: 'DYNAMIC', value: 'DYNAMIC', label: t('resources.steering.steering_ranges.table.value_type_dynamic') },
  ]

  const renderRangeEntry = (name: 'max' | 'min' | 'step', label: string) => {
    const isError = name === 'max' || name === 'min' ? !!errors.maxmin : false

    return (
      <Box>
        <Typography sx={{ mb: 2 }} variant="subtitle2">
          {label}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box flexBasis="25%" flexGrow={1}>
            <Controller
              name={`${name}.valueType`}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectField
                  fullWidth
                  error={isError || !!error}
                  label={t('resources.steering.steering_ranges.table.value_type')}
                  options={valueTypeOptions}
                  {...field}
                />
              )}
            />
          </Box>
          <Box flexBasis="25%" flexGrow={1}>
            <TextFieldController
              error={isError || !!errors[name]?.value}
              label={t('resources.steering.steering_ranges.table.value')}
              name={`${name}.value`}
              placeholder="Value (kW)"
              type="number"
              onChange={(e) => {
                const value = e.target.value === '' ? null : Number(e.target.value)
                methods.setValue(`${name}.value`, value)
              }}
            />
            <ErrorMessage errors={errors} name={`${name}.value`} />
          </Box>
          <Box flexBasis="25%" flexGrow={1}>
            <TextFieldController
              label={t('resources.steering.steering_ranges.table.min_time_on_level')}
              name={`${name}.minSecondsOnThisLevel`}
              placeholder="Min. seconds on this level"
              type="number"
              onChange={(e) => {
                const value = e.target.value === '' ? null : Number(e.target.value)
                methods.setValue(`${name}.minSecondsOnThisLevel`, value)
              }}
            />
          </Box>
          <Box flexBasis="25%" flexGrow={1}>
            <TextFieldController
              label={t('resources.steering.steering_ranges.table.max_time_on_level')}
              name={`${name}.maxSecondsOnThisLevel`}
              placeholder="Max. seconds on this level"
              type="number"
              onChange={(e) => {
                const value = e.target.value === '' ? null : Number(e.target.value)
                methods.setValue(`${name}.maxSecondsOnThisLevel`, value)
              }}
            />
          </Box>
        </Stack>
      </Box>
    )
  }

  return (
    <FormProvider {...methods}>
      <Card sx={{ bgcolor: 'rgba(20, 132, 160, 0.04)' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomCardContent>
            <Stack spacing={2}>
              {renderRangeEntry('max', t('resources.steering.steering_ranges.table.entryType.max'))}
              {renderRangeEntry('min', t('resources.steering.steering_ranges.table.entryType.min'))}
              {renderRangeEntry('step', t('resources.steering.steering_ranges.table.entryType.step'))}
              <ErrorMessage errors={errors} name="maxmin" />
            </Stack>
          </CustomCardContent>
          <CustomCardActions
            deleteProps={
              onDelete ? { onDelete, text: t('resources.steering.steering_ranges.delete'), withIcon: true } : undefined
            }
            sx={{ justifyContent: 'space-between' }}
          >
            <Stack direction="row" spacing={1}>
              <CustomButton type="submit" variant="contained">
                {t('common.button.save')}
              </CustomButton>
              <CustomButton variant="outlined" onClick={onCancel}>
                {t('common.button.cancel')}
              </CustomButton>
            </Stack>
          </CustomCardActions>
        </form>
      </Card>
    </FormProvider>
  )
}

export default SteeringRangeForm
