import type { GridColDef, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid'
import { GridFilterInputDate } from '@mui/x-data-grid'
import { DateTime } from 'luxon'

function createFilterOperator(
  label: string,
  value: string,
  condition: (cellValue: DateTime, filterValue: DateTime, startDate: DateTime, endDate: DateTime) => boolean,
  customerTimeZone: string,
): GridFilterOperator {
  return {
    label: label,
    value: value,
    getApplyFilterFn: ({ field, value: filterValue, operator }: GridFilterItem, column: GridColDef) => {
      if (!field || !filterValue || !operator) {
        return null
      }

      return (paramValue, row) => {
        if (column.type !== 'date') {
          return false
        }

        if (!paramValue) {
          return false
        }

        const startDate = DateTime.fromISO(row.startDate, {
          zone: DateTime.utc().zone,
        }).setZone(customerTimeZone)
        const endDate = DateTime.fromISO(row.endDate, {
          zone: DateTime.utc().zone,
        }).setZone(customerTimeZone)

        if (!startDate || !endDate) {
          return false
        }

        return condition(
          paramValue as DateTime,
          DateTime.fromISO(filterValue, { zone: customerTimeZone }),
          startDate,
          endDate,
        )
      }
    },
    InputComponentProps: {
      type: 'date',
    },
    InputComponent: GridFilterInputDate,
  }
}

export function getGridDateIncludesOperators(customerTimeZone: string): GridFilterOperator[] {
  return [
    createFilterOperator(
      'includes',
      'includes',
      (cellValue, filterValue, startDate, endDate) => filterValue >= startDate && filterValue <= endDate,
      customerTimeZone,
    ),
    createFilterOperator(
      'includes or after',
      'includesOrAfter',
      (cellValue, filterValue, startDate, endDate) =>
        filterValue <= cellValue || (filterValue >= startDate && filterValue <= endDate),
      customerTimeZone,
    ),
    createFilterOperator(
      'includes or before',
      'includesOrBefore',
      (cellValue, filterValue, startDate, endDate) =>
        filterValue >= cellValue || (filterValue >= startDate && filterValue <= endDate),
      customerTimeZone,
    ),
  ]
}
