export const IntegrationsRouteInformation = {
  INDEX: {
    routePath: '/',
    navigationPath: '/integrations',
  },
  CUSTOMERS: {
    routePath: '/customers',
    navigationPath: '/integrations/customers',
  },
  CUSTOMER_DETAILS: {
    routePath: '/customers/:customerUuid',
  },
  CUSTOMER_CONTROLLERS: {
    routePath: '/customers/:customerUuid/controllers',
    navigationPath: (customerUuid: string) => `/integrations/customers/${customerUuid}/controllers`,
  },
  CUSTOMER_RESOURCES: {
    routePath: '/customers/:customerUuid/resources',
    navigationPath: (customerUuid: string) => `/integrations/customers/${customerUuid}/resources`,
  },
} as const
