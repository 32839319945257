import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ChipWithIconAndQuantity from '@/components/dataDisplay/ChipWithIconAndQuantity'
import IntegrationIcon from '@/features/integration/components/IntegrationIcon'
import type { Integration, IntegrationType } from '@/features/integration/types'
import { getIntegrationTypeTranslation } from '@/features/integration/utils/integrationTypes'

type GroupedIntegration = Record<IntegrationType, number>

type CustomerIntegrationsCellProps = {
  integrations: Integration[]
}

function CustomerIntegrationsCell({ integrations }: Readonly<CustomerIntegrationsCellProps>) {
  const { t } = useTranslation()

  /**
   * Group the integrations together to have a count by type.
   *
   * For example, if the integrations provided is:
   * [{ integrationType: 'CLOUD' }, { integrationType: 'CLOUD' }, { integrationType: 'HARDWARE' }]
   *
   * The final object will be: { 'CLOUD': 2, 'HARDWARE': 1 }
   *
   * */
  const groupedIntegrations: Partial<GroupedIntegration> = integrations.reduce((acc, item) => {
    if (acc[item.integrationType]) {
      return {
        ...acc,
        [item.integrationType]: acc[item.integrationType] + 1,
      }
    }

    return {
      ...acc,
      [item.integrationType]: 1,
    }
  }, {})

  return (
    <Stack alignItems="center" direction="row" gap={1} height="100%">
      {Object.entries(groupedIntegrations).map(([integrationType, quantity]) => (
        <ChipWithIconAndQuantity
          key={integrationType}
          icon={<IntegrationIcon integrationType={integrationType as IntegrationType} />}
          quantity={quantity}
          tooltipText={getIntegrationTypeTranslation(integrationType as IntegrationType, t)}
        />
      ))}
    </Stack>
  )
}

export default CustomerIntegrationsCell
