import { MonetizationOnOutlined } from '@mui/icons-material'
import CampaignOutlinedIcon from '@mui/icons-material/Campaign'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import DateRangeIcon from '@mui/icons-material/DateRange'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import type { CustomerDetailsPageConfig } from '@/features/customer/pages/CustomerDetails'
import type { Customer } from '@/features/customer/types/customer'

const commonProps = {
  component: Link,
  disableRipple: true,
  iconPosition: 'start',
  style: { minHeight: 0 },
} as const

type CustomerTabsProps = {
  customer: Customer
  pageConfig: CustomerDetailsPageConfig
}

export const CustomerTabs = ({ customer, pageConfig }: CustomerTabsProps) => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()

  return (
    <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs aria-label="customer-tabs" value={pageConfig.activeTab}>
        <Tab
          {...commonProps}
          icon={<ContactMailIcon />}
          label={t('customer_details.tabs.contact_info.title')}
          to={`/customers/${customer.uuid}/contact-info`}
          value={CustomerDetailsRouteInformation.CONTACT_INFO.navigationPath}
        />
        <Tab
          {...commonProps}
          icon={<DateRangeIcon />}
          label={t('customer_details.tabs.availability.title')}
          to={`/customers/${customer.uuid}/availability`}
          value={CustomerDetailsRouteInformation.AVAILABILITY.navigationPath}
        />
        <Tab
          {...commonProps}
          icon={<CampaignOutlinedIcon />}
          label={t('customer_details.tabs.activations')}
          to={`/customers/${customer.uuid}/activations`}
          value={CustomerDetailsRouteInformation.ACTIVATIONS.navigationPath}
        />
        {loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value && customer.location === 'fi' && (
          <Tab
            {...commonProps}
            icon={<MonetizationOnOutlined />}
            label={t('customer_details.tabs.revenue.title')}
            to={`/customers/${customer.uuid}/revenue`}
            value={CustomerDetailsRouteInformation.REVENUE.navigationPath}
          />
        )}
        <Tab
          {...commonProps}
          icon={<SettingsIcon />}
          label={t('customer_details.tabs.settings.title')}
          to={`/customers/${customer.uuid}/settings`}
          value={CustomerDetailsRouteInformation.SETTINGS.navigationPath}
        />
      </Tabs>
    </Box>
  )
}
