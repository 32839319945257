import { AppBar, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import type { ComponentProps } from 'react'

type AppBarProps = ComponentProps<typeof AppBar>

type CustomAppBarProps = Omit<AppBarProps, 'color'> & {
  color?: 'primary' | 'default' | 'secondary' | 'inheritWhite' | 'transparent'
  toolbarProps?: ComponentProps<typeof Toolbar>
}

/**
 * It extends the component AppBar from MUI by:
 *
 * - Adding the possibility to set the color to 'inheritWhite' which will set the background color to white.
 * - Wrapping the children in a Toolbar component, which is the default behavior
 * - Restricting the available colors to 'primary', 'default', 'secondary', 'inheritWhite' and 'transparent'
 */
const CustomAppBar = ({ color = 'primary', children, toolbarProps, sx, ...props }: CustomAppBarProps) => {
  const theme = useTheme()

  return (
    <AppBar
      color={color === 'inheritWhite' ? 'inherit' : color}
      {...props}
      sx={{
        backgroundColor: color === 'inheritWhite' ? theme.palette.common.white : undefined,
        ...sx,
      }}
    >
      <Toolbar {...toolbarProps} variant="regular">
        {children}
      </Toolbar>
    </AppBar>
  )
}

export default CustomAppBar
