import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import type { ServiceRule } from '@/features/availability/types/serviceRule'
import { GET_SERVICE_RULES_API_ID, getServiceRules } from '@/features/customer/endpoints/serviceRules'
import type { UseQueryOptions } from '@/types/queries'

type ServiceRulesQueryParams = {
  customerUuid: string
  serviceId?: number
  location?: string
}
export type UseServiceRulesQueryResult = Omit<UseQueryResult, 'data'> & {
  serviceRules: ServiceRule[] | null
}

export function useServiceRulesQuery(
  params: ServiceRulesQueryParams,
  options?: UseQueryOptions<ServiceRule[]>,
): UseServiceRulesQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_SERVICE_RULES_API_ID, params],
    queryFn: () => getServiceRules(params.customerUuid, params.serviceId, params.location),
  })

  return { serviceRules: data, ...queryResult }
}

export function useInvalidateServiceRulesQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_SERVICE_RULES_API_ID] })
}
