import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { FormControlLabel, Switch } from '@mui/material'
import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid'
import { gridFilterModelSelector, GridToolbarFilterButton, useGridApiContext } from '@mui/x-data-grid'
import type { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import GridSearchOutlined from '@/components/dataDisplay/GridSearchOutlined'
import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { FIELD_WIDTH } from '@/constants/layout'
import type { UserRole } from '@/constants/userRoles'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'
import { OPERATOR_VALUE_HAS_ANY_OF } from '@/utils/datagrid/operators/hasAnyOf'

import CustomersFilter from './datagrid/CustomersFilter'
import RoleFilter from './datagrid/RoleFilter'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends UsersDataGridToolbarProps {}
}

type UsersDataGridToolbarProps = {
  showDisabledUsers: boolean
  authorizedRoles: UserRole[]
  availableCustomerOptions: string[]
  showCustomerFilter: boolean
  onToggleDisabledUsersClick: (value: boolean) => void
}

const ROLE_FILTER_NAME = 'role'
const ALLOW_RO_IDS_FILTER_NAME = 'allowedRoIds'

export const DEFAULT_DATA_GRID_USERS_FILTER_MODEL: GridFilterModel = {
  items: [
    {
      id: ROLE_FILTER_NAME,
      field: ROLE_FILTER_NAME,
      operator: 'is',
      value: '',
    },
    {
      id: ALLOW_RO_IDS_FILTER_NAME,
      field: ALLOW_RO_IDS_FILTER_NAME,
      operator: OPERATOR_VALUE_HAS_ANY_OF,
      value: [],
    },
  ],
  quickFilterValues: [''],
}

function UsersDataGridToolbar({
  showDisabledUsers,
  onToggleDisabledUsersClick,
  authorizedRoles,
  availableCustomerOptions,
  showCustomerFilter,
}: Readonly<UsersDataGridToolbarProps>) {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()

  const filterModel = gridFilterModelSelector(apiRef)
  const roleFilterValue = getFilterItemValue<UserRole>(filterModel, ROLE_FILTER_NAME) ?? null
  const selectedCustomers = getFilterItemValue<string[]>(filterModel, ALLOW_RO_IDS_FILTER_NAME) ?? []

  function handleRoleFilterChange(item: GridFilterItem) {
    updateDataGridFilter(apiRef, ROLE_FILTER_NAME, item)
  }

  function handleDisabledFilterToggle(evt: ChangeEvent<HTMLInputElement>) {
    onToggleDisabledUsersClick(evt.target.checked)
  }

  function handleSelectedCustomerFilter(item: GridFilterItem) {
    updateDataGridFilter(apiRef, ALLOW_RO_IDS_FILTER_NAME, item)
  }

  return (
    <GridToolbar
      actions={
        <CustomIconButton
          Icon={AddOutlinedIcon}
          aria-label={t('users.button_add_new_user')}
          color="primary"
          component={Link}
          to="/users/create"
          variant="solid"
        />
      }
      filters={
        <>
          <GridSearchOutlined
            label={t('common.navigation.search')}
            placeholder={t('user.search.placeholder')}
            quickFilterParser={(searchInput: string) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
            sx={{ maxWidth: FIELD_WIDTH }}
          />

          <RoleFilter
            field="role"
            roleOptions={authorizedRoles}
            value={roleFilterValue}
            onChange={(items) => handleRoleFilterChange(items[0])}
          />

          {showCustomerFilter && (
            <CustomersFilter
              customerOptions={availableCustomerOptions}
              field={ALLOW_RO_IDS_FILTER_NAME}
              id="selected-customers"
              value={selectedCustomers}
              onChange={(items) => handleSelectedCustomerFilter(items[0])}
            />
          )}

          <FormControlLabel
            control={<Switch checked={showDisabledUsers} onChange={handleDisabledFilterToggle} />}
            label={t('component.filter.show_disabled')}
            sx={{ m: 0 }}
          />
          <GridToolbarFilterButton />
        </>
      }
    />
  )
}

export default UsersDataGridToolbar
