import SearchOutlined from '@mui/icons-material/SearchOutlined'
import { InputAdornment, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'

interface SearchInputProps {
  searchText?: string
  search?: (searchText: string) => void
  backgroundColor?: 'hovered' | 'none'
}

function SearchInput({ searchText, search, backgroundColor }: Readonly<SearchInputProps>) {
  const theme = useTheme()

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }}
      id="navbar-search"
      placeholder="Search..."
      sx={{ backgroundColor: backgroundColor === 'hovered' ? theme.palette.action.hover : 'transparent' }}
      type="search"
      value={searchText}
      onChange={(event) => {
        search?.(event.target.value)
      }}
    />
  )
}

export default SearchInput
