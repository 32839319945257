import { Box, Grid, InputAdornment, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMutation } from '@tanstack/react-query'
import { t } from 'i18next'
import { useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'

import TextFieldController from '@/components/inputs/TextFieldController'
import { useAlertContext } from '@/contexts/AlertContext'
import { ContactCardActionButtons } from '@/features/customer/components/companyInfo/ContactCardActionButtons'
import EditableBox from '@/features/customer/components/EditableBox'
import EditableTextFieldController from '@/features/customer/components/EditableTextFieldController'
import {
  createCustomerContact,
  deleteCustomerContact,
  updateCustomerContact,
} from '@/features/customer/endpoints/customers'
import type { CustomerContact } from '@/features/customer/types/customer'
import { EMAIL_REGEX } from '@/utils/email'
import { errorHandler } from '@/utils/errorHandler'
import validatePhone from '@/utils/phone'

interface ContactCardProps {
  contact?: CustomerContact
  customerUuid?: string
  viewOnly?: boolean
  location?: string
  isFormMode?: boolean
  onCancel?: () => void
  onChange?: () => void
}

interface CustomerContactForm {
  name: string
  phone: string
  email: string
  comment: string
}

export const ContactCard = (props: ContactCardProps) => {
  const theme = useTheme()
  const [isCommentShown, setIsCommentShown] = useState(false)
  const [isFormMode, setIsFormMode] = useState(props.isFormMode || false)
  const { pushAlert } = useAlertContext()

  const form = useForm<CustomerContactForm>({
    mode: 'onBlur',
    defaultValues: {
      name: props.contact?.name ?? '',
      phone: props.contact?.phone ?? '',
      email: props.contact?.email ?? '',
      comment: props.contact?.comment ?? '',
    },
  })

  const createContactMutation = useMutation({
    mutationFn: (variables: { customerUuid: string; contact: CustomerContact }) =>
      createCustomerContact(variables.customerUuid, variables.contact),
  })

  const updatedContactMutation = useMutation({
    mutationFn: (variables: { customerUuid: string; contact: CustomerContact }) =>
      updateCustomerContact(variables.customerUuid, variables.contact),
  })

  const deleteContactMutation = useMutation({
    mutationFn: (variables: { customerUuid: string; contactId: number }) =>
      deleteCustomerContact(variables.customerUuid, variables.contactId, props.location),
  })

  useWatch({
    control: form.control,
  })

  function onSubmit() {
    const customerUuid = props.customerUuid

    if (!customerUuid || !props.location) {
      return
    }

    form.handleSubmit(async (data) => {
      let contact: CustomerContact

      if (!props.contact?.id) {
        // New contact
        contact = await createContactMutation.mutateAsync({
          customerUuid: customerUuid,
          contact: {
            ...data,
            location: props.location,
          },
        })
      } else {
        // Edit contact
        contact = await updatedContactMutation.mutateAsync({
          customerUuid: customerUuid,
          contact: {
            ...data,
            id: props.contact.id,
            location: props.location,
          },
        })
      }

      form.reset({
        name: contact.name,
        phone: contact.phone,
        email: contact.email,
        comment: contact.comment,
      })
      setIsFormMode(false)
      props.onChange?.()
    })()
  }

  function onFormCancel() {
    setIsFormMode(false)
    form.reset()
    props.onCancel?.()
  }

  async function handleDelete() {
    const customerUuid = props.customerUuid

    if (!customerUuid || !props.contact?.id) {
      return
    }

    try {
      await deleteContactMutation.mutateAsync({
        customerUuid: customerUuid,
        contactId: props.contact.id,
      })
    } catch (err) {
      const error = errorHandler(err, t('customer_details.errors.contact_delete_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }

    props.onChange?.()
  }

  return (
    <FormProvider {...form}>
      <EditableBox isEditable={isFormMode}>
        <Stack
          direction={{
            md: 'column',
            lg: 'row',
          }}
          justifyContent="space-between"
        >
          <Grid container spacing={2} width={'100%'}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} xl={6}>
                  <EditableTextFieldController
                    required
                    controllerProps={{
                      rules: {
                        required: t('customer_details.tabs.contact_info.contacts.name_required.error'),
                      },
                    }}
                    isEditable={isFormMode}
                    label={t('common.name')}
                    name="name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} md={12}>
              <EditableTextFieldController
                InputProps={{
                  startAdornment: <InputAdornment position="start">+</InputAdornment>,
                }}
                controllerProps={{
                  rules: {
                    validate: (value) => {
                      if (!validatePhone(value)) {
                        return t('customer_details.tabs.contact_info.phone_invalid.error').toString()
                      }
                    },
                  },
                }}
                isEditable={isFormMode}
                label={t('customer_details.tabs.contact_info.phone')}
                name="phone"
                onChange={(event) => {
                  // users are only allowed to enter numbers
                  const value = event.target.value.replace(/\D/g, '')

                  form.setValue('phone', value)
                }}
              />
            </Grid>
            <Grid item lg={6} md={12}>
              <EditableTextFieldController
                controllerProps={{
                  rules: {
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('customer_details.tabs.contact_info.email_invalid.error').toString(),
                    },
                  },
                }}
                isEditable={isFormMode}
                label={t('customer_details.tabs.contact_info.email')}
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              {(isCommentShown || isFormMode) && (
                <TextFieldController
                  multiline
                  disabled={!isFormMode}
                  id="outlined-multiline-static"
                  label={t('common.comment')}
                  name="comment"
                  rows={2}
                  sx={{
                    backgroundColor: isFormMode ? theme.palette.background.paper : 'rgba(250, 250, 250, 0.6)',
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: theme.palette.text.primary,
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Box minWidth={'20%'}>
            <ContactCardActionButtons
              clearFormButton={isFormMode ? { onClick: onFormCancel } : undefined}
              deleteButton={
                props.contact && isFormMode
                  ? {
                      onClick: () => {
                        handleDelete()
                      },
                    }
                  : undefined
              }
              editButton={props.contact && !isFormMode ? { onClick: () => setIsFormMode(true) } : undefined}
              showCommentButton={
                !isFormMode && props.contact?.comment
                  ? { onClick: () => setIsCommentShown(!isCommentShown) }
                  : undefined
              }
              submitFormButton={isFormMode ? { onClick: onSubmit } : undefined}
              viewOnly={props.viewOnly}
            />
          </Box>
        </Stack>
      </EditableBox>
    </FormProvider>
  )
}
