import type { GridPaginationModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  GET_ACTIVATION_GROUP_SCHEDULES_API,
  getActivationGroupSchedules,
} from '@/features/activationGroup/endpoints/activationGroups'
import type { ActivationGroupSchedule, ActivationGroupSchedulesPage } from '@/features/activationGroup/types'
import type { UseQueryOptions } from '@/types/queries'

type UseActivationGroupSchedulesQueryParams = {
  activationGroupUuid: string
  pagination: GridPaginationModel
}

export type UseActivationGroupSchedulesQueryResult = Omit<UseQueryResult, 'data'> & {
  activationGroupSchedules: ActivationGroupSchedule[] | null
  currentPage: number
  totalActivationGroupSchedules: number
}

export function useActivationGroupSchedulesQuery(
  params: UseActivationGroupSchedulesQueryParams,
  options?: UseQueryOptions<ActivationGroupSchedulesPage>,
): UseActivationGroupSchedulesQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_ACTIVATION_GROUP_SCHEDULES_API, params],
    queryFn: () => getActivationGroupSchedules(params.activationGroupUuid, params.pagination),
  })

  return {
    ...queryResult,
    activationGroupSchedules: data?.activationGroupSchedules ?? null,
    currentPage: data?.currentPage ?? 0,
    totalActivationGroupSchedules: data?.totalActivationGroupSchedules ?? 0,
  }
}

export function useInvalidateActivationGroupSchedulesQuery() {
  const queryClient = useQueryClient()

  return (activationGroupUuid: string) =>
    queryClient.invalidateQueries({ queryKey: [GET_ACTIVATION_GROUP_SCHEDULES_API, { activationGroupUuid }] })
}
