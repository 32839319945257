import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { DEFAULT_SYMPOWER_CONFIG } from '@/features/whitelabel/default'
import { getWhitelabelConfiguration } from '@/features/whitelabel/endpoints/whitelabelConfigurations'
import type { WhitelabelConfiguration } from '@/features/whitelabel/types/whitelabelConfiguration'
import type { UseQueryOptions } from '@/types/queries'
import { getDecodedSvgUrl } from '@/utils/base64'

type WhitelabelConfigurationQueryParams = {
  hostname: string
}

export type UseWhitelabelConfigurationQueryResult = Omit<UseQueryResult, 'data'> & {
  whitelabelConfiguration: WhitelabelConfiguration | null
}

export function useWhitelabelConfigurationQuery(
  params: WhitelabelConfigurationQueryParams,
  options?: UseQueryOptions<WhitelabelConfiguration>,
): UseWhitelabelConfigurationQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: ['GET_THEME_CONFIGURATION_API_ID', params],
    queryFn: async () => {
      try {
        const response = await getWhitelabelConfiguration(params.hostname)
        return {
          ...response,
          branding: {
            ...response.branding,
            coloredLogo: getDecodedSvgUrl(response.branding.coloredLogo),
            monochromeLogo: getDecodedSvgUrl(response.branding.monochromeLogo),
            favicon: getDecodedSvgUrl(response.branding.favicon),
          },
        }
      } catch {
        return DEFAULT_SYMPOWER_CONFIG
      }
    },
  })

  return { whitelabelConfiguration: data, ...queryResult }
}
