import { Box, Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import PageHeader from '@/components/layouts/PageHeader'
import TabPanel from '@/components/layouts/TabPanel'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import type { ActivationGroup } from '@/features/activationGroup/types'
import { BidVersionsView } from '@/features/bidding/components/bidView/BidVersionsView'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import { ALL_RESULTS } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useBidHistoriesPageQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import type { BidHistory } from '@/features/bidding/types/bidHistory'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'

const BidView = () => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const location = useLocation()
  const groupParams = getGroupedBidParamsFromUrl(location)
  const { activationGroups } = useActivationGroupsQuery({ countryCode: selectedCountry })
  const { bidHistoriesPage } = useBidHistoriesPageQuery(
    {
      pagination: { pageSize: ALL_RESULTS, page: 0 },
      sorting: [],
      filter: {
        items: groupParams
          ? [
              { field: 'portfolio', operator: 'equals', value: groupParams.portfolioCode },
              { field: 'marketProgram', operator: 'equals', value: groupParams.marketProgram },
              { field: 'deliveryDay', operator: 'equals', value: groupParams.deliveryDay },
            ]
          : [],
      },
      countryFilter: selectedCountry,
    },
    { enabled: !!groupParams },
  )
  const [activeTab, setActiveTab] = useState(0)

  if (!bidHistoriesPage || !groupParams || !activationGroups) {
    return <FullPageSpinner />
  }

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ justifyContent: 'left' }}>
            <CountrySelector disabled />
          </Box>
        }
        breadcrumbItems={[{ text: t('component.page_header.bidding'), to: '/bidding' }]}
        pageTitle={t('component.page_header.bid.results.label')}
      />

      {bidHistoriesPage.bidHistories.length === 1 ? (
        <BidVersionsView bidHistory={bidHistoriesPage.bidHistories[0]} />
      ) : (
        <BidViewTabs
          activationGroups={activationGroups}
          activeTab={activeTab}
          bidHistories={bidHistoriesPage.bidHistories}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  )
}

const BidViewTabs = ({
  bidHistories,
  activationGroups,
  activeTab,
  setActiveTab,
}: {
  bidHistories: BidHistory[]
  activationGroups: ActivationGroup[]
  activeTab: number
  setActiveTab: (value: number) => void
}) => {
  const sortedBidHistories = sortByActivationGroup(bidHistories, activationGroups)

  return (
    <>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs style={{ minHeight: 0 }} value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
          {sortedBidHistories.map((bidHistory, index) => (
            <Tab
              key={bidHistory.activationGroupUuid}
              label={activationGroups.find((ag) => ag.uuid === bidHistory.activationGroupUuid)?.code}
              style={{ minHeight: 0 }}
              value={index}
            />
          ))}
        </Tabs>
      </Box>

      {sortedBidHistories.map((bidHistory, index) => (
        <TabPanel key={bidHistory.activationGroupUuid} index={index} value={activeTab}>
          <BidVersionsView key={bidHistory.id} bidHistory={bidHistory} />
        </TabPanel>
      ))}
    </>
  )
}

const sortByActivationGroup = (bidHistories: BidHistory[], activationGroups: ActivationGroup[]) =>
  bidHistories.toSorted((a, b) => {
    const aActivationGroup = activationGroups.find((ag) => ag.uuid === a.activationGroupUuid)!
    const bActivationGroup = activationGroups.find((ag) => ag.uuid === b.activationGroupUuid)!

    return (
      aActivationGroup.code.localeCompare(bActivationGroup.code) ||
      aActivationGroup.uuid.localeCompare(bActivationGroup.uuid)
    )
  })

export default BidView
