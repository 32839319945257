import type { Customer } from '@/features/integration/types'

export const CUSTOMERS: Customer[] = [
  {
    id: 1,
    name: 'Customer A',
    siteName: 'Site A',
    partnerName: 'Partner A',
    currentPowerConsumption: 1234000,
    verifiedCapacity: 20000000,
    integrations: [
      {
        uuid: '998d3801-ca56-4e90-a66c-2f5ca7528e57',
        integrationType: 'CLOUD',
      },
      {
        uuid: 'a05d0105-4008-4af3-9a05-e3d1c403b49a',
        integrationType: 'CLOUD',
      },
      {
        uuid: '1dc0995a-5a21-4d5a-9cbc-9a3c6d29a347',
        integrationType: 'HARDWARE',
      },
    ],
    integrationErrors: [],
    status: 'ENABLED',
  },
  {
    id: 2,
    name: 'Customer B',
    siteName: 'Site B',
    partnerName: 'Partner B',
    currentPowerConsumption: 1234000,
    verifiedCapacity: 20000000,
    integrations: [
      {
        uuid: 'a05d0105-4008-4af3-9a05-e3d1c403b49a',
        integrationType: 'PARTNER',
      },
    ],
    integrationErrors: [],
    status: 'DISABLED',
  },
  {
    id: 3,
    name: 'Customer C',
    siteName: 'Site C',
    partnerName: 'Partner C',
    currentPowerConsumption: 1234000,
    verifiedCapacity: 20000000,
    integrations: [
      {
        uuid: '1dc0995a-5a21-4d5a-9cbc-9a3c6d29a347',
        integrationType: 'UNKNOWN',
      },
    ],
    integrationErrors: [
      {
        message: 'Signal strength low',
      },
    ],
    status: 'ENABLED',
  },
]

export const CUSTOMER: Customer = {
  id: 1,
  name: 'Customer A',
  siteName: 'Site A',
  partnerName: 'Partner A',
  currentPowerConsumption: 1234000,
  verifiedCapacity: 20000000,
  integrations: [
    {
      uuid: '998d3801-ca56-4e90-a66c-2f5ca7528e57',
      integrationType: 'CLOUD',
    },
    {
      uuid: 'a05d0105-4008-4af3-9a05-e3d1c403b49a',
      integrationType: 'HARDWARE',
    },
    {
      uuid: '1dc0995a-5a21-4d5a-9cbc-9a3c6d29a347',
      integrationType: 'PARTNER',
    },
  ],
  integrationErrors: [],
  status: 'ENABLED',
}
