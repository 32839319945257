import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import Page from '@/components/layouts/Page'
import { IntegrationsRouteInformation } from '@/features/integration/constants'

import { useIntegrationCustomerDetails } from './IntegrationCustomerDetails'

const CustomerResources = () => {
  const { t } = useTranslation()
  const { setPageConfig } = useIntegrationCustomerDetails()

  useEffect(() => {
    setPageConfig({
      activeTab: IntegrationsRouteInformation.CUSTOMER_RESOURCES.routePath,
      title: t('integration_customer_details.customer_resources.title'),
    })
  }, [])

  return (
    <Page>
      {/* TODO: Implement customer integration resources table */}
      <CustomTypography>Resources content</CustomTypography>
    </Page>
  )
}

export default CustomerResources
