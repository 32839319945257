import { Box } from '@mui/material'
import type { ReactNode } from 'react'

const BACKGROUND_COLOR = 'rgba(250, 250, 250, 0.6)'
const BORDER_STYLE = '1px dashed rgba(0, 0, 0, 0.14)'

type EditableBoxProps = {
  children: ReactNode
  isEditable: boolean
}

function EditableBox({ children, isEditable }: EditableBoxProps) {
  return isEditable ? (
    <Box sx={{ px: 2, py: 2.5, border: BORDER_STYLE, backgroundColor: BACKGROUND_COLOR }}>{children}</Box>
  ) : (
    <Box>{children}</Box>
  )
}

export default EditableBox
