import { roundWattsToNearestMwUnit } from '@/features/bidding/utils/calculations/convertToRoundedMw'

export default (
  totalPriceChunkOfferInWatts: number,
  offeredCapacityByActivationGroup: { activationGroupUuid: string; offered: number }[],
): { [activationGroupUuid: string]: number } => {
  const totalOfferedCapacityForPtuInWatts = offeredCapacityByActivationGroup.reduce(
    (total, val) => total + val.offered,
    0,
  )
  const result: { [activationGroupUuid: string]: number } = {}
  for (const activationGroupOfferedCapacity of offeredCapacityByActivationGroup) {
    const activationGroupBidRatio =
      totalOfferedCapacityForPtuInWatts !== 0
        ? activationGroupOfferedCapacity.offered / totalOfferedCapacityForPtuInWatts
        : 1 / Object.values(offeredCapacityByActivationGroup).length
    result[activationGroupOfferedCapacity.activationGroupUuid] = roundWattsToNearestMwUnit(
      totalPriceChunkOfferInWatts,
      activationGroupBidRatio,
    )
  }
  const totalSplitCapacity = Object.values(result).reduce((total, val) => total + val, 0)
  result[Object.keys(result)[0]] += totalPriceChunkOfferInWatts - totalSplitCapacity
  return result
}
