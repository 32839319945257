import type { ExampleFeaturePage, ExampleFeatureValue } from '@/features/exampleFeature/types'

const VALUES: ExampleFeatureValue[] = [
  {
    name: 'Email',
    data: [120, 132, 101, 134, 90, 230, 210],
  },
  {
    name: 'Union Ads',
    data: [220, 182, 191, 234, 290, 330, 310],
  },
  {
    name: 'Video Ads',
    data: [150, 232, 201, 154, 190, 330, 410],
  },
  {
    name: 'Direct',
    data: [320, 332, 301, 334, 390, 330, 320],
  },
  {
    name: 'Search Engine',
    data: [820, 932, 901, 934, 1290, 1330, 1320],
  },
]

export const GET_EXAMPLE_FEATURE_VALUES_API_ID = 'GET_EXAMPLE_FEATURE_VALUES'

export async function getExampleFeatureValues(): Promise<ExampleFeaturePage> {
  return Promise.resolve({
    values: VALUES,
    currentPage: 0,
    totalCount: VALUES.length,
  })
}
