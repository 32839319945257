import type { Localization } from '@mui/material/locale'
import { enUS, nlNL } from '@mui/material/locale'
import { ThemeProvider } from '@mui/material/styles'
import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { useMuiTheme } from '@/features/whitelabel/hooks/useMuiTheme'

type ErrorShellProps = {
  children?: ReactNode
}

const MUI_LOCALES: { [key: string]: Localization } = {
  en: enUS,
  nl: nlNL,
}

function ErrorShell({ children }: Readonly<ErrorShellProps>) {
  const theme = useMuiTheme(MUI_LOCALES.en)

  return <ThemeProvider theme={theme}>{children || <Outlet />}</ThemeProvider>
}

export default ErrorShell
