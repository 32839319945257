import type { SelectChangeEvent } from '@mui/material'
import { DialogContent, Stack } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import FormDialogActions from '@/components/feedback/FormDialogActions'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import type { UpdateResourcePayload } from '@/features/resource/endpoints/resources'
import { GET_RESOURCES_API_ID, updateResource } from '@/features/resource/endpoints/resources'
import type { Resource, ResourceType } from '@/features/resource/types'
import { getResourceTypeTranslation } from '@/features/resource/utils/resourceTypes'

export type EditResourceModalProps = {
  resourceName?: Resource['resourceName']
  resourceID: Resource['resourceID']
  resourceType?: Resource['resourceType']
  resourceTypeOptions: ResourceType[]
  open: boolean
  onClose: () => void
  onSubmit: () => void
  onSuccess: () => void
  onSettled: () => void
}

export type EditResourceFormData = {
  resourceName?: Resource['resourceName']
  resourceType?: Resource['resourceType']
}

const EditResourceModal = ({
  open,
  resourceType,
  resourceName,
  resourceID,
  resourceTypeOptions,
  onClose,
  onSubmit,
  onSuccess,
  onSettled,
}: EditResourceModalProps) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const form = useForm<EditResourceFormData>({
    values: { resourceName, resourceType },
  })
  const mutation = useMutation({
    mutationFn: (payload: UpdateResourcePayload) => updateResource(resourceID, payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_RESOURCES_API_ID] })

      onSuccess()
    },
    onSettled,
  })

  /**
   * We are already validating that both fields (resourceName and resourceType) are filled, so
   * that's why we can safely call mutation.mutate here using the exclamation mark.
   */
  function handleSubmit(data: EditResourceFormData) {
    mutation.mutate({
      resourceType: data.resourceType!,
      resourceName: data.resourceName!,
    })

    onSubmit()
  }

  return (
    <CustomDialog
      aria-modal
      aria-labelledby="edit-resource-dialog-label"
      open={open}
      size="extraSmall"
      title={resourceName!}
      onClose={onClose}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <DialogContent>
            <Stack sx={{ gap: 3 }}>
              <TextFieldController
                controllerProps={{
                  rules: {
                    required: t('resources.edit_modal.resource_name_field_required_error'),
                  },
                }}
                id="resource-name"
                label={t('resources.edit_modal.resource_name_field_label')}
                name="resourceName"
              />

              <SelectFieldController
                controllerProps={{
                  rules: {
                    required: t('resources.edit_modal.resource_type_field_required_error'),
                  },
                }}
                id="resource-type"
                label={t('resources.edit_modal.resource_type_field_label')}
                name="resourceType"
                options={resourceTypeOptions.map((resourceType) => ({
                  id: resourceType,
                  value: resourceType,
                  label: getResourceTypeTranslation(resourceType, t),
                }))}
                onChange={(evt: SelectChangeEvent<ResourceType>) => {
                  form.setValue('resourceType', evt.target.value as ResourceType)
                }}
              />
            </Stack>
          </DialogContent>

          <FormDialogActions onCancel={onClose} />
        </form>
      </FormProvider>
    </CustomDialog>
  )
}

export default EditResourceModal
