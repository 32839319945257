import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomIconButton from '@/components/inputs/CustomIconButton'
import AbortPrequalificationDialog from '@/features/activationGroup/components/AbortPrequalificationDialog'
import type { PrequalificationLane } from '@/features/activationGroup/types'

type AbortPrequalificationConfirmationButtonProps = {
  prequalificationLane: PrequalificationLane
  isPrequalificationActive: boolean
  prequalificationUuid: string
}

const AbortPrequalificationConfirmationButton = ({
  prequalificationLane,
  isPrequalificationActive,
  prequalificationUuid,
}: AbortPrequalificationConfirmationButtonProps) => {
  const { t } = useTranslation()
  const [openAbortPrequalificationDialog, setOpenAbortPrequalificationDialog] = useState(false)

  function handleOpenAbortPrequalificationDialog() {
    setOpenAbortPrequalificationDialog(true)
  }

  function handleCloseAbortPrequalificationDialog() {
    setOpenAbortPrequalificationDialog(false)
  }

  return (
    <>
      <CustomIconButton
        Icon={StopCircleOutlinedIcon}
        aria-label={t('activation_groups.abort_prequalification_button_label', {
          prequalificationUuid: prequalificationUuid,
        })}
        color="error"
        disabled={!isPrequalificationActive}
        onClick={handleOpenAbortPrequalificationDialog}
      />

      <AbortPrequalificationDialog
        open={openAbortPrequalificationDialog}
        prequalificationLane={prequalificationLane}
        onClose={handleCloseAbortPrequalificationDialog}
      />
    </>
  )
}

export default AbortPrequalificationConfirmationButton
