import type { DateTime } from 'luxon'

import type { Bid } from '@/features/bidding/types/bid'
import { convertToRoundedMw, round } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import type { PriceCapacity } from '@/features/bidding/utils/price/getPtuPriceCapacitiesFromBids'
import { getPtuPriceCapacitiesFromBids } from '@/features/bidding/utils/price/getPtuPriceCapacitiesFromBids'

export type HourlyPriceRowItem = {
  id: number
  ptuStart: DateTime
  availableMw: number
  remainingMw: number
  priceCapacities: PriceCapacity[]
}

export const buildHourlyPriceRowItems = (bids: Bid[]): HourlyPriceRowItem[] => {
  if (bids.length === 0) return []

  const ptuStartTimes = bids[0].offeredBid.map((ptu) => ptu.ptu.start)

  return ptuStartTimes.map((ptuStart) => {
    const priceCapacities = getPtuPriceCapacitiesFromBids(ptuStart, bids)

    const availableMw = convertToRoundedMw(
      bids.reduce(
        (total, bid) => total + bid.offeredBid.find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))!.volume.quantity,
        0,
      ),
    )
    const chunkedCapacityMw = priceCapacities.reduce((total, priceCapacity) => total + priceCapacity.capacityMw, 0)

    return {
      id: ptuStart.toMillis(),
      remainingMw: round(availableMw - chunkedCapacityMw),
      ptuStart,
      availableMw,
      priceCapacities,
    }
  })
}
