import axios from 'axios'

import environment from '@/environment'
import type { Partner } from '@/features/partner/types/partner'

export const ERROR_NAMES = {
  VALIDATION_FAILED: 'validation-failed',
}

export const GET_PARTNERS_API_ID = 'GET_PARTNERS'

export async function getPartners(): Promise<Partner[]> {
  const response = await axios.get(environment.services.partnersManagerApiUrl)
  return response.data
}

export async function createPartner(partner: Partner) {
  const response = await axios.post<Partner>(`${environment.services.partnersManagerApiUrl}`, partner)
  return response.data
}

export async function updatePartner(partner: Partner) {
  if (partner.uuid == null) {
    return
  }

  const response = await axios.put<Partner>(
    `${environment.services.partnersManagerApiUrl}/uuid/${partner.uuid}`,
    partner,
  )

  return response.data
}
