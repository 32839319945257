import type { GridPaginationModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  GET_ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS_API,
  getGenericActivationGroupPatterns,
} from '@/features/activationGroup/endpoints/activationGroups'
import type {
  GenericActivationGroupPrequalificationPattern,
  GenericActivationGroupPrequalificationPatternsPage,
} from '@/features/activationGroup/types'
import type { UseQueryOptions } from '@/types/queries'

type UseGenericActivationGroupPatternsQueryParams = {
  activationGroupUuid: string
  pagination: GridPaginationModel
}

export type UseGenericActivationGroupPrequalificationPatternsQueryResult = Omit<UseQueryResult, 'data'> & {
  activationGroupPrequalificationPatterns: GenericActivationGroupPrequalificationPattern[] | null
  currentPage: number
  totalActivationGroupPrequalificationPatterns: number
}

export function useGenericActivationGroupPrequalificationPatternsQuery(
  params: UseGenericActivationGroupPatternsQueryParams,
  options?: UseQueryOptions<GenericActivationGroupPrequalificationPatternsPage>,
): UseGenericActivationGroupPrequalificationPatternsQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS_API, params],
    queryFn: () => getGenericActivationGroupPatterns(params.activationGroupUuid, params.pagination),
  })

  return {
    ...queryResult,
    activationGroupPrequalificationPatterns: data?.activationGroupPrequalificationPatterns ?? null,
    currentPage: data?.currentPage ?? 0,
    totalActivationGroupPrequalificationPatterns: data?.totalActivationGroupPrequalificationPatterns ?? 0,
  }
}

export function useInvalidateGenericActivationGroupPrequalificationPatternsQuery() {
  const queryClient = useQueryClient()

  return (activationGroupUuid: string) =>
    queryClient.invalidateQueries({
      queryKey: [GET_ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS_API, { activationGroupUuid }],
    })
}
