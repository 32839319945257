export type AvailabilityStatus = keyof typeof AVAILABILITY_STATUSES

export const AVAILABILITY_STATUSES = {
  waiting: {
    value: 'waiting',
    sortingOrder: 1,
  },
  active: {
    value: 'active',
    sortingOrder: 2,
  },
  deactivated: {
    value: 'deactivated',
    sortingOrder: 3,
  },
  expired: {
    value: 'expired',
    sortingOrder: 4,
  },
}

export const AVAILABILITY_STATUSES_LIST = Object.keys(AVAILABILITY_STATUSES)
