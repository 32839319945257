import axios from 'axios'

import type { Restriction } from '@/features/availability/types/restriction'
import { CUSTOMERS_API_URL } from '@/features/customer/endpoints/customers'
import type { CreateRestrictionRequest, EditRestrictionRequest } from '@/features/customer/types/restriction'

export const GET_RESTRICTIONS_API_ID = 'GET_RESTRICTIONS'

export async function getRestrictions(customerUuid: string, location: string | undefined): Promise<Restriction[]> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/restrictions`, {
    params: {
      location,
    },
  })
  return response.data
}

export async function createRestriction(customerUuid: string, restriction: CreateRestrictionRequest): Promise<void> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  await axios.post(`${CUSTOMER_API_URL}/restrictions`, restriction)
}

export async function editRestriction(customerUuid: string, restriction: EditRestrictionRequest): Promise<void> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  await axios.put(`${CUSTOMER_API_URL}/restrictions/${restriction.id}`, restriction, {
    params: {
      location: restriction.location,
    },
  })
}

export async function deleteRestriction(
  customerUuid: string,
  restrictionId: number,
  location: string | undefined,
): Promise<Restriction> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.delete<Restriction>(`${CUSTOMER_API_URL}/restrictions/${restrictionId}`, {
    params: {
      location,
    },
  })
  return response.data
}
