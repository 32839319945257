import { DialogContent, DialogContentText } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import * as React from 'react'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'
import CustomButton from '@/components/inputs/CustomButton'

type Props = {
  children: React.ReactNode
  buttonProps: {
    disabled?: boolean
    startIcon: React.ReactNode
  }
  dialogProps: {
    title: string
    description: string
  }
  onSubmit: () => void
}

const ConfirmationButton: FC<Props> = ({ children, buttonProps, dialogProps, onSubmit }) => {
  const [showAlert, setShowAlert] = useState(false)

  function handleOpenAlert() {
    setShowAlert(true)
  }

  function handleCloseAlert() {
    setShowAlert(false)
  }

  async function handleConfirmAlert() {
    onSubmit()
    setShowAlert(false)
  }

  return (
    <>
      <CustomButton variant={'contained'} onClick={handleOpenAlert} {...buttonProps}>
        {children}
      </CustomButton>

      <CustomDialog
        aria-describedby={dialogProps.description}
        aria-labelledby={dialogProps.title}
        open={showAlert}
        title={dialogProps.title}
        onClose={handleCloseAlert}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogProps.description}</DialogContentText>
        </DialogContent>

        <ConfirmDialogActions onCancel={handleCloseAlert} onConfirm={handleConfirmAlert} />
      </CustomDialog>
    </>
  )
}

export default ConfirmationButton
