import { ApartmentOutlined, MonetizationOnOutlined } from '@mui/icons-material'
import CampaignOutlinedIcon from '@mui/icons-material/Campaign'
import DateRangeIcon from '@mui/icons-material/DateRange'
import SettingsIcon from '@mui/icons-material/Settings'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ListItemLink from '@/components/navigation/ListItemLink'
import { ProtectedFeature, useHasFeatureAccess } from '@/features/authentication/hooks/useHasFeatureAccess'

function CustomerNavBarItems() {
  const { t } = useTranslation()

  const hasAccessToRevenues = useHasFeatureAccess(ProtectedFeature.REVENUES)

  return (
    <>
      <ListItemLink
        icon={<DateRangeIcon />}
        isActive={location.pathname === '/customers/availability'}
        primary={t('customer_details.tabs.availability.title')}
        to="/customers/availability"
      />
      <ListItemLink
        icon={<CampaignOutlinedIcon />}
        isActive={location.pathname === '/activations'}
        primary={t('customer_details.tabs.activations')}
        to="/activations"
      />
      {hasAccessToRevenues && (
        <ListItemLink
          icon={<MonetizationOnOutlined />}
          isActive={location.pathname === '/customers/revenue'}
          primary={t('component.navbar.revenue')}
          to="/customers/revenue"
        />
      )}
      <ListItemLink
        icon={<ApartmentOutlined />}
        isActive={location.pathname === '/customers/company-info'}
        primary={t('component.navbar.company_info')}
        to="/customers/company-info"
      />
      <ListItemLink
        icon={<SettingsIcon />}
        isActive={location.pathname === '/customers/settings'}
        primary={t('customer_details.tabs.settings.title')}
        to="/customers/settings"
      />
    </>
  )
}

export default CustomerNavBarItems
