import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import {
  GET_EXAMPLE_FEATURE_VALUES_API_ID,
  getExampleFeatureValues,
} from '@/features/exampleFeature/endpoints/exampleEndpoint'
import type { ExampleFeaturePage, ExampleFeatureValue } from '@/features/exampleFeature/types'
import type { UseQueryOptions } from '@/types/queries'

export type UseDisturbancesQueryResult = Omit<UseQueryResult, 'data'> & {
  exampleFeatureValues: ExampleFeatureValue[] | null
  currentPage: number
  totalCount: number
}

export function useExampleFeatureQuery(options?: UseQueryOptions<ExampleFeaturePage>): UseDisturbancesQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryFn: getExampleFeatureValues,
    queryKey: [GET_EXAMPLE_FEATURE_VALUES_API_ID],
  })

  return {
    ...queryResult,
    exampleFeatureValues: data?.values ?? null,
    currentPage: data?.currentPage ?? 0,
    totalCount: data?.totalCount ?? 0,
  }
}
