type DisplayTimestampFrequencyPatternProps = {
  pattern: string
}

function DisplayTimestampFrequencyPattern({ pattern }: Readonly<DisplayTimestampFrequencyPatternProps>) {
  // We ignore the index 0, as it's always the string timestamp;frequency
  const [, ...entries] = pattern.split('\n')

  return (
    <ul style={{ listStyle: 'none', maxHeight: '200px', overflowY: 'auto', width: '100%' }}>
      {entries.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  )
}

export default DisplayTimestampFrequencyPattern
