import type { Theme } from '@aws-amplify/ui-react'

import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

export function useAmplifyTheme() {
  const { branding, palette } = useWhitelabelConfigurationContext()
  const amplifyTheme: Theme = {
    name: branding.name,
    tokens: {
      components: {
        button: {
          primary: {
            backgroundColor: palette.primary,
            _active: {
              backgroundColor: palette.primary,
            },
            _focus: {
              backgroundColor: palette.primary,
            },
            _hover: {
              backgroundColor: palette.primary,
            },
          },
          link: {
            color: palette.primary,
            _active: {
              backgroundColor: 'transparent',
            },
            _focus: {
              backgroundColor: 'transparent',
            },
            _hover: {
              color: palette.primary,
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  }

  return amplifyTheme
}
