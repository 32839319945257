import type { UserRole } from '@/constants/userRoles'
import { USER_ROLES } from '@/constants/userRoles'

export function getAuthorizedRoles(role?: UserRole): UserRole[] {
  switch (role) {
    case USER_ROLES.ADMINISTRATORS.value:
      return Object.values(USER_ROLES).map((role) => role.value) // all roles
    case USER_ROLES.PARTNER_ADMINISTRATORS.value:
      return [USER_ROLES.PARTNER_ADMINISTRATORS.value, USER_ROLES.RESOURCE_OWNERS.value]
    default:
      return role ? [role] : []
  }
}
