import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { ResourceTabs } from '@/features/resource/pages/components/ResourceTabs'

type ContextType = {
  resourceUuid: string
  activeTab: string | null
  setActivePage: Dispatch<SetStateAction<string>>
}

function ResourceDetailsPage() {
  const { t } = useTranslation()
  const { resourceUuid } = useParams()

  const [activeTab, setActiveTab] = useState<string>(ResourceRouteInformation.RESOURCE_GENERAL.routePath)

  if (!resourceUuid) {
    return null
  }

  return (
    <>
      <PageHeader
        breadcrumbItems={[
          {
            text: t('component.page_header.resources'),
            to: '/resources',
          },
        ]}
        pageTitle={t('resources.tabs.title')}
      />
      {activeTab !== '' && <ResourceTabs activeTab={activeTab} />}

      <Outlet
        context={
          {
            resourceUuid: resourceUuid,
            activeTab: activeTab,
            setActivePage: setActiveTab,
          } satisfies ContextType
        }
      />
    </>
  )
}

export function useResourceDetails() {
  return useOutletContext<ContextType>()
}

export default ResourceDetailsPage
