import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useTranslation } from 'react-i18next'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAbortPrequalificationMutation } from '@/features/activationGroup/hooks/useAbortPrequalificationMutation'
import { useInvalidatePrequalificationsQuery } from '@/features/activationGroup/hooks/usePrequalificationsQuery'
import type { PrequalificationLane } from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type AbortPrequalificationDialogProps = {
  prequalificationLane: PrequalificationLane
  open: boolean
  onClose: () => void
}

const AbortPrequalificationDialog = ({ prequalificationLane, open, onClose }: AbortPrequalificationDialogProps) => {
  const { t } = useTranslation()
  const { abortPrequalification, isPending } = useAbortPrequalificationMutation()
  const invalidatePrequalificationsQuery = useInvalidatePrequalificationsQuery()
  const { pushAlert } = useAlertContext()

  function handleClose() {
    onClose()
  }

  async function handleSubmit() {
    try {
      await abortPrequalification({ prequalificationLane: prequalificationLane })
      await invalidatePrequalificationsQuery()

      pushAlert({
        message: t('activation_groups.abort_prequalification_success_message'),
        severity: 'success',
      })
      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.abort_prequalification_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog
      aria-describedby={t('activation_groups.abort_prequalification_dialog.description')}
      aria-labelledby={t('activation_groups.abort_prequalification_dialog.title')}
      open={open}
      title={t('activation_groups.abort_prequalification_dialog.title')}
      onClose={handleClose}
    >
      <DialogContent>
        <DialogContentText>{t('activation_groups.abort_prequalification_dialog.description')}</DialogContentText>
      </DialogContent>

      <ConfirmDialogActions disabled={isPending} onCancel={handleClose} onConfirm={handleSubmit} />
    </CustomDialog>
  )
}

export default AbortPrequalificationDialog
