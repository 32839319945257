import { LayersOutlined } from '@mui/icons-material'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'

type EnvironmentRibbonProps = {
  name: string
}

function EnvironmentRibbon({ name }: Readonly<EnvironmentRibbonProps>) {
  const { t } = useTranslation()

  if (!name) {
    return null
  }

  const environmentName = `${name.charAt(0).toUpperCase()}${name.slice(1)}`

  return (
    <Alert icon={<LayersOutlined />} severity="warning" sx={{ color: 'white' }} variant="filled">
      {environmentName} {t('common.environment')}
    </Alert>
  )
}

export default EnvironmentRibbon
