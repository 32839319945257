import { IconChevronLeft } from '@aws-amplify/ui-react/internal'
import { Box } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import CreateBidButton from '@/features/bidding/components/CreateBidButton'
import HourlyPriceDataGrid from '@/features/bidding/components/price/offer/HourlyPriceDataGrid'
import {
  buildBidCapacityLink,
  buildBidPriceConfirmationLink,
} from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'

const BidOfferPrice = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const groupParams = getGroupedBidParamsFromUrl(location)
  if (!groupParams) return <FullPageSpinner />

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ justifyContent: 'left' }}>
            <CountrySelector disabled />
          </Box>
        }
        breadcrumbItems={[
          { text: t('component.page_header.bidding'), to: '/bidding' },
          { text: t('component.page_header.bid.todo_list'), to: '/bidding/todo-list' },
          { text: t('component.page_header.bid.create.label'), to: buildBidCapacityLink(groupParams) },
        ]}
        pageTitle={t('bidding.create_bid.price_bid')}
      />

      <HourlyPriceDataGrid groupParams={groupParams} />

      <ButtonBar>
        <CustomButton
          key="backButton"
          startIcon={<IconChevronLeft />}
          variant={'outlined'}
          onClick={() => navigate(-1)}
        >
          {t('common.navigation.back')}
        </CustomButton>

        <CreateBidButton
          dialogProps={{
            description: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.description'),
            title: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.title'),
          }}
          onSubmit={() => navigate(buildBidPriceConfirmationLink(groupParams))}
        />
      </ButtonBar>
    </>
  )
}

export default BidOfferPrice
