import axios from 'axios'

import environment from '@/environment'
import type { GridFrequency } from '@/features/dashboard/types/gridFrequency'

export const CUSTOMER_API_URL = `${environment.services.monolithGatewayManagerApiUrl}/dashboard/current-frequency`

export const GET_GRID_FREQUENCY_API_ID = 'GET_CURRENT_GRID_FREQUENCY'
export async function getCurrentGridFrequency(location: string): Promise<GridFrequency> {
  const response = await axios.get(`${CUSTOMER_API_URL}`, {
    params: {
      location,
    },
  })
  return response.data
}
