import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IntegrationsRouteInformation } from '@/features/integration/constants'
import type { IntegrationCustomerDetailsPageConfig } from '@/features/integration/types'

const commonProps = {
  component: Link,
  disableRipple: true,
  iconPosition: 'start',
  style: { minHeight: 0 },
} as const

type IntegrationCustomerDetailsTabsProps = {
  customerUuid: string
  pageConfig: IntegrationCustomerDetailsPageConfig
}

const IntegrationCustomerDetailsTabs = ({ pageConfig, customerUuid }: IntegrationCustomerDetailsTabsProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs aria-label={t('integration_customer_details.tabs.title')} value={pageConfig.activeTab}>
        <Tab
          {...commonProps}
          label={t('integration_customer_details.tabs.controllers')}
          to={IntegrationsRouteInformation.CUSTOMER_CONTROLLERS.navigationPath(customerUuid)}
          value={IntegrationsRouteInformation.CUSTOMER_CONTROLLERS.routePath}
        />
        <Tab
          {...commonProps}
          label={t('integration_customer_details.tabs.resources')}
          to={IntegrationsRouteInformation.CUSTOMER_RESOURCES.navigationPath(customerUuid)}
          value={IntegrationsRouteInformation.CUSTOMER_RESOURCES.routePath}
        />
      </Tabs>
    </Box>
  )
}

export default IntegrationCustomerDetailsTabs
