import type { Theme, TypographyProps } from '@mui/material'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import type { ElementType } from 'react'
import { forwardRef } from 'react'

export type CustomTypographyProps<C extends ElementType> = TypographyProps<C, { component?: C }>

function getGutterBottom(theme: Theme, variant: TypographyProps['variant']): string {
  switch (variant) {
    case 'h1':
      return theme.spacing(4.25)
    case 'h2':
      return theme.spacing(2.625)
    case 'h3':
      return theme.spacing(2.125)
    case 'h4':
      return theme.spacing(1.5)
    case 'h5':
      return theme.spacing(1)
    case 'h6':
      return theme.spacing(0.875)
    case 'caption':
    case 'overline':
      return theme.spacing(0.5)
    case 'subtitle2':
    case 'body2':
      return theme.spacing(0.625)
    case 'subtitle1':
    case 'body1':
      return theme.spacing(0.75)
    default:
      return '0'
  }
}
function CustomTypography<C extends ElementType>({ sx, ...props }: CustomTypographyProps<C>, ref) {
  const theme = useTheme()

  return (
    <Typography
      ref={ref}
      {...props}
      sx={{
        ...sx,
        marginBottom: props.gutterBottom ? getGutterBottom(theme, props.variant) : 'none',
      }}
    />
  )
}

export default forwardRef(CustomTypography)
