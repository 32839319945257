export const DisturbancesRouteInformation = {
  INDEX: {
    label: 'component.page_header.disturbances',
    navigationPath: () => '/disturbances',
    routePath: '/',
  },
  DISTURBANCE_ACTIVATED_RESOURCE: {
    label: 'component.page_header.disturbances',
    navigationPath: (disturbanceUuid: string) => `/disturbances/${disturbanceUuid}`,
    routePath: '/:uuid',
  },
} as const
