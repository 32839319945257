import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import { ExportCsvButton } from '@/features/customer/components/revenue/ExportCsvButton'
import type { RevenueDataRow } from '@/features/customer/types/revenue'
import { getYearSortedOptions } from '@/features/customer/utils/revenueUtils/getYearSortedOptions'

type RevenuesAppBarContentProps = {
  availableYears: string[]
  yearSelection: string
  handleYearChange: (newValue: string) => void
  selectedMarketProgramName: string
  revenueData: RevenueDataRow[]
  customerName: string
}

function RevenueAppBarContent({
  availableYears,
  yearSelection,
  handleYearChange,
  selectedMarketProgramName,
  revenueData,
  customerName,
}: Readonly<RevenuesAppBarContentProps>) {
  const { t } = useTranslation()

  const sortedYearOptions = getYearSortedOptions(availableYears)

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '15% 10%',
        justifyContent: 'space-between',
        width: '100%',
        gap: 1,
      }}
    >
      <CustomSelectField
        label={t('common.time.year')}
        options={sortedYearOptions}
        size="medium"
        value={yearSelection}
        variant="outlinedWhite"
        onChange={(event) => {
          handleYearChange(event.target.value)
        }}
      />
      <ExportCsvButton
        customerName={customerName}
        data={revenueData}
        marketProgramName={selectedMarketProgramName}
        year={yearSelection}
      />
    </Box>
  )
}

export default RevenueAppBarContent
