import { CheckCircle, TableRows } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import PageHeader from '@/components/layouts/PageHeader'
import type { MarketProgramBiddingName } from '@/features/bidding/types/bid'

export const BidOfferSuccess = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { marketProgram } = useParams<{ marketProgram: MarketProgramBiddingName }>()
  if (!marketProgram) return null

  return (
    <>
      <PageHeader
        breadcrumbItems={[
          { text: t('component.page_header.bidding'), to: '/bidding' },
          { text: t('component.page_header.bid.todo_list'), to: '/bidding/todo-list' },
        ]}
        pageTitle={t('component.page_header.bid.create.success', {
          marketProgram: t(`bidding.market_program.${marketProgram}`),
        })}
      />

      <MainContentContainer sx={{ textAlign: 'center' }}>
        <CheckCircle
          sx={{
            width: '100px',
            height: '100px',
            color: theme.palette.success.main,
          }}
        />
        <CustomTypography color={theme.palette.success.main} fontFamily={'Quicksand, Roboto, sans-serif'} variant="h4">
          {t(`bidding.create_bid.sending_succeeded.title`, {
            marketProgram: t(`bidding.market_program.${marketProgram}`),
          })}
        </CustomTypography>
        <CustomTypography variant="subtitle1">{t(`bidding.create_bid.sending_succeeded.subtitle`)}</CustomTypography>
      </MainContentContainer>

      <ButtonBar>
        <CustomButton component={Link} startIcon={<TableRows />} to="/bidding/todo-list" variant={'outlined'}>
          {t('component.page_header.bid.todo_list')}
        </CustomButton>
      </ButtonBar>
    </>
  )
}
