import { useTranslation } from 'react-i18next'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import CustomerMap from '@/features/customer/components/companyInfo/CustomerMap'
import type { GpsLocation } from '@/types/gpsLocation'

type CustomerAddressViewProps = {
  address?: string
  gpsLocation?: GpsLocation
}

export function CustomerAddressView({ address, gpsLocation }: Readonly<CustomerAddressViewProps>) {
  const { t } = useTranslation()

  return (
    <>
      <LabeledTextField label={t('customer_details.tabs.contact_info.company_info.address')} text={address} />
      <CustomerMap location={gpsLocation} />
    </>
  )
}
