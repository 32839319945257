import { Stack } from '@mui/material'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

type PageTitleProps = {
  pageTitle: string
}

function PageTitle({ pageTitle }: Readonly<PageTitleProps>) {
  return (
    <Stack sx={{ flexDirection: 'row', width: '100%' }}>
      <CustomTypography noWrap variant="h1">
        {pageTitle}
      </CustomTypography>
    </Stack>
  )
}

export default PageTitle
