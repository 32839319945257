import type { GridPaginationModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  GET_ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS_API,
  getActivationGroupPatterns,
} from '@/features/activationGroup/endpoints/activationGroups'
import type {
  ActivationGroupPrequalificationPattern,
  ActivationGroupPrequalificationPatternsPage,
} from '@/features/activationGroup/types'
import type { UseQueryOptions } from '@/types/queries'

type UseActivationGroupPatternsQueryParams = {
  activationGroupUuid: string
  pagination: GridPaginationModel
}

export type UseActivationGroupPrequalificationPatternsQueryResult = Omit<UseQueryResult, 'data'> & {
  activationGroupPrequalificationPatterns: ActivationGroupPrequalificationPattern[] | null
  currentPage: number
  totalActivationGroupPrequalificationPatterns: number
}

export function useActivationGroupPrequalificationPatternsQuery(
  params: UseActivationGroupPatternsQueryParams,
  options?: UseQueryOptions<ActivationGroupPrequalificationPatternsPage>,
): UseActivationGroupPrequalificationPatternsQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS_API, params],
    queryFn: () => getActivationGroupPatterns(params.activationGroupUuid, params.pagination),
  })

  return {
    ...queryResult,
    activationGroupPrequalificationPatterns: data?.activationGroupPrequalificationPatterns ?? null,
    currentPage: data?.currentPage ?? 0,
    totalActivationGroupPrequalificationPatterns: data?.totalActivationGroupPrequalificationPatterns ?? 0,
  }
}

export function useInvalidateActivationGroupPrequalificationPatternsQuery() {
  const queryClient = useQueryClient()

  return (activationGroupUuid: string) =>
    queryClient.invalidateQueries({
      queryKey: [GET_ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS_API, { activationGroupUuid }],
    })
}
