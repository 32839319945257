import { Route, Routes } from 'react-router-dom'

import ErrorShell from './components/ErrorShell'
import { ErrorsRouteInformation } from './constants'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'

export default function ErrorRoutes() {
  return (
    <Routes>
      <Route element={<ErrorShell />} path="/">
        <Route element={<Error403 />} path={ErrorsRouteInformation[403].routePath} />

        <Route element={<Error404 />} path={ErrorsRouteInformation[404].routePath} />
        <Route element={<Error500 />} path={ErrorsRouteInformation[500].routePath} />

        <Route element={<Error404 />} path="*" />
      </Route>
    </Routes>
  )
}
