import axios from 'axios'

import environment from '@/environment'
import type { Column, FormattedRow, Response } from '@/features/bessDashboard/types'

function formatResponse(response: any | undefined): FormattedRow[] {
  if (!response) {
    return [] // Return an empty array if response is undefined
  }

  const content = response.data

  const { columns } = content.manifest.schema
  const { data_array } = content.result

  const formattedData: FormattedRow[] = data_array.map((row: string[]) => {
    const formattedRow: FormattedRow = {}
    columns.forEach((col: Column, index: number) => {
      formattedRow[col.name] = row[index]
    })
    return formattedRow
  })

  return formattedData
}

export async function getForecastedPrices(): Promise<FormattedRow[]> {
  const sql_query = 'select * from flexportal_sharing.forecasts.price'

  const response: Response | undefined = await axios.get(
    `${environment.services.databricksSharingApiUrl}?sql-statement=${sql_query}`,
  )
  return formatResponse(response)
}

export async function getForecastedVolumes(): Promise<FormattedRow[]> {
  const sql_query = 'select * from flexportal_sharing.forecasts.volume'

  const response: Response | undefined = await axios.get(
    `${environment.services.databricksSharingApiUrl}?sql-statement=${sql_query}`,
  )
  return formatResponse(response)
}

export async function getWideActuals(): Promise<FormattedRow[]> {
  const sql_query = 'select * from flexportal_sharing.actuals.volume_price_combined'

  const response: Response | undefined = await axios.get(
    `${environment.services.databricksSharingApiUrl}?sql-statement=${sql_query}`,
  )
  return formatResponse(response)
}
