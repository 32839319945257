import axios from 'axios'

import environment from '@/environment'
import type { MarketProgram } from '@/features/customer/types/marketProgram'

export const MARKET_PROGRAMS_API_URL = `${environment.services.monolithGatewayManagerApiUrl}/marketPrograms`

export const GET_MARKET_PROGRAMS_API_ID = 'GET_MARKET_PROGRAMS'

export async function getMarketPrograms() {
  const response = await axios.get<MarketProgram[]>(MARKET_PROGRAMS_API_URL)
  return response.data
}
