import type { GridPaginationModel } from '@mui/x-data-grid'
import type { AxiosError } from 'axios'
import axios from 'axios'
import { DateTime } from 'luxon'

import { getCountryAsAlpha2Code } from '@/constants/country'
import environment from '@/environment'
import type {
  AbortPrequalification,
  ActivationGroup,
  ActivationGroupPrequalificationPattern,
  ActivationGroupPrequalificationPatternsPage,
  ActivationGroupSchedule,
  ActivationGroupSchedulesPage,
  ApiAbortPrequalification,
  ApiActivationGroup,
  ApiActivationGroupResource,
  ApiCreateActivationGroup,
  ApiCreateActivationGroupSchedule,
  ApiPrequalification,
  ApiPrequalificationPage,
  ApiStartPrequalification,
  ApiUpdateActivationGroup,
  CreatePrequalificationPattern,
  CreateSchedule,
  GenericActivationGroupPrequalificationPattern,
  GenericActivationGroupPrequalificationPatternsPage,
  GenericActivationGroupSchedule,
  GenericActivationGroupSchedulesPage,
  GenericApiAbortPrequalification,
  GenericApiCreateActivationGroupSchedule,
  GenericApiPrequalification,
  GenericApiPrequalificationPage,
  GenericApiStartPrequalification,
  GenericCreatePrequalificationPattern,
  GenericCreateSchedule,
  GenericPrequalification,
  GenericPrequalificationsPage,
  GenericStartPrequalification,
  Prequalification,
  PrequalificationsPage,
  StartPrequalification,
} from '@/features/activationGroup/types'

export const GET_ACTIVATION_GROUPS_API = 'GET_ACTIVATION_GROUPS'
export const GET_ACTIVATION_GROUP_API = 'GET_ACTIVATION_GROUP'
export const GET_ACTIVATION_GROUP_SCHEDULES_API = 'GET_ACTIVATION_GROUP_SCHEDULES'
export const GET_ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS_API = 'GET_ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS'
export const GET_ACTIVATION_GROUP_RESOURCES_API = 'GET_ACTIVATION_GROUP_RESOURCES'
export const GET_PREQUALIFICATIONS_API = 'GET_PREQUALIFICATIONS'

const buildPagination = (pagination: GridPaginationModel) => {
  const params = new URLSearchParams()

  params.append('page', String(pagination.page))
  params.append('pageSize', String(pagination.pageSize))

  return params
}

const deserializePrequalification = (prequalification: ApiPrequalification): Prequalification => ({
  ...prequalification,
  startedAt: DateTime.fromISO(prequalification.startedAt),
  endedAt: prequalification.endedAt ? DateTime.fromISO(prequalification.endedAt) : null,
})

const genericDeserializePrequalification = (prequalification: GenericApiPrequalification): GenericPrequalification => ({
  ...prequalification,
  startedAt: DateTime.fromISO(prequalification.startedAt),
  simulationStartedAt: prequalification.simulationStartedAt
    ? DateTime.fromISO(prequalification.simulationStartedAt)
    : null,
  simulationFinishedAt: prequalification.simulationFinishedAt
    ? DateTime.fromISO(prequalification.simulationFinishedAt)
    : null,
  abortedAt: prequalification.abortedAt ? DateTime.fromISO(prequalification.abortedAt) : null,
  endedAt: prequalification.endedAt ? DateTime.fromISO(prequalification.endedAt) : null,
})

export type getActivationGroupParams = {
  countryCode: CountryIdentifier
}

export const getActivationGroups = async (params?: getActivationGroupParams): Promise<ActivationGroup[]> =>
  (
    await axios.get<ApiActivationGroup[]>(`${environment.services.portfolioManagerApiUrl}/activation-group`, {
      params: buildActivationGroupsParams(params),
    })
  ).data

const buildActivationGroupsParams = (params?: getActivationGroupParams) => {
  const urlParams = new URLSearchParams()
  if (params?.countryCode) {
    urlParams.append('countryCode', getCountryAsAlpha2Code(params.countryCode))
  }
  return urlParams
}

export const getActivationGroup = async (activationGroupUuid: string): Promise<ActivationGroup> => {
  const response = await axios.get<ApiActivationGroup>(
    `${environment.services.portfolioManagerApiUrl}/activation-group/${activationGroupUuid}`,
  )
  return response.data
}

export const createActivationGroup = async (newActivationGroup: ApiCreateActivationGroup): Promise<ActivationGroup> => {
  const response = await axios.post<ApiActivationGroup>(
    `${environment.services.portfolioManagerApiUrl}/activation-group`,
    newActivationGroup,
  )
  return response.data
}

export const updateActivationGroup = async (
  activationGroupUuid: string,
  updatedActivationGroup: ApiUpdateActivationGroup,
): Promise<ActivationGroup> => {
  const response = await axios.put<ApiActivationGroup>(
    `${environment.services.portfolioManagerApiUrl}/activation-group/${activationGroupUuid}`,
    updatedActivationGroup,
  )
  return response.data
}

export const getActivationGroupSchedules = async (
  activationGroupUuid: string,
  pagination: GridPaginationModel,
): Promise<ActivationGroupSchedulesPage> => {
  const ACTIVATION_GROUP_SCHEDULES_API_URL = `${environment.services.resourceSelectionManagerApiUrl}/activation-groups/${activationGroupUuid}/activation-schedules`

  try {
    const response = await axios.get(ACTIVATION_GROUP_SCHEDULES_API_URL, {
      params: buildPagination(pagination),
    })

    return {
      activationGroupSchedules: response.data.results,
      currentPage: response.data.currentPage,
      totalActivationGroupSchedules: response.data.totalResults,
    }
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return {
        activationGroupSchedules: [],
        currentPage: 0,
        totalActivationGroupSchedules: 0,
      }
    }

    throw err
  }
}

export const getGenericActivationGroupSchedules = async (
  activationGroupUuid: string,
  pagination: GridPaginationModel,
): Promise<GenericActivationGroupSchedulesPage> => {
  const ACTIVATION_GROUP_SCHEDULES_API_URL = `${environment.services.prequalificationManagerApiUrl}/activation-groups/${activationGroupUuid}/activation-schedules`

  try {
    const response = await axios.get(ACTIVATION_GROUP_SCHEDULES_API_URL, {
      params: buildPagination(pagination),
    })

    return {
      activationGroupSchedules: response.data.results,
      currentPage: response.data.currentPage,
      totalActivationGroupSchedules: response.data.totalResults,
    }
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return {
        activationGroupSchedules: [],
        currentPage: 0,
        totalActivationGroupSchedules: 0,
      }
    }

    throw err
  }
}

export const getActivationGroupResources = async (
  activationGroupUuid: string,
): Promise<ApiActivationGroupResource[]> => {
  const response = await axios.get<ApiActivationGroupResource[]>(
    `${environment.services.portfolioManagerApiUrl}/activation-group/${activationGroupUuid}/resources`,
  )

  return response.data
}

export const createActivationGroupSchedule = async (newSchedule: CreateSchedule) => {
  const payload: ApiCreateActivationGroupSchedule = {
    activationGroupUuid: newSchedule.activationGroupUuid,
    startAt: newSchedule.startAt.toISOString(),
    endAt: newSchedule.endAt.toISOString(),
    soldCapacity: newSchedule.soldCapacity,
  }
  const url = `${environment.services.resourceSelectionManagerApiUrl}/activation-schedules`

  const response = await axios.post<ActivationGroupSchedule>(url, payload)

  return response.data
}

export const createGenericActivationGroupSchedule = async (newSchedule: GenericCreateSchedule) => {
  const payload: GenericApiCreateActivationGroupSchedule = {
    activationGroupUuid: newSchedule.activationGroupUuid,
    activationTriggerChannel: newSchedule.activationTriggerChannel,
    scheduleType: newSchedule.scheduleType,
    startAt: newSchedule.startAt.toISOString(),
    endAt: newSchedule.endAt.toISOString(),
    volume: newSchedule.volume,
  }
  const url = `${environment.services.prequalificationManagerApiUrl}/activation-schedules`

  const response = await axios.post<GenericActivationGroupSchedule>(url, payload)

  return response.data
}

export const getActivationGroupPatterns = async (
  activationGroupUuid: string,
  pagination: GridPaginationModel,
): Promise<ActivationGroupPrequalificationPatternsPage> => {
  const ACTIVATION_GROUP_PATTERNS_API_URL = `${environment.services.resourceSelectionManagerApiUrl}/activation-groups/${activationGroupUuid}/prequalification-patterns`

  try {
    const response = await axios.get(ACTIVATION_GROUP_PATTERNS_API_URL, {
      params: buildPagination(pagination),
    })

    return {
      activationGroupPrequalificationPatterns: response.data.results,
      currentPage: response.data.currentPage,
      totalActivationGroupPrequalificationPatterns: response.data.totalResults,
    }
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return {
        activationGroupPrequalificationPatterns: [],
        currentPage: 0,
        totalActivationGroupPrequalificationPatterns: 0,
      }
    }

    throw err
  }
}

export const getGenericActivationGroupPatterns = async (
  activationGroupUuid: string,
  pagination: GridPaginationModel,
): Promise<GenericActivationGroupPrequalificationPatternsPage> => {
  const ACTIVATION_GROUP_PATTERNS_API_URL = `${environment.services.prequalificationManagerApiUrl}/activation-groups/${activationGroupUuid}/prequalification-patterns`

  try {
    const response = await axios.get(ACTIVATION_GROUP_PATTERNS_API_URL, {
      params: buildPagination(pagination),
    })

    return {
      activationGroupPrequalificationPatterns: response.data.results,
      currentPage: response.data.currentPage,
      totalActivationGroupPrequalificationPatterns: response.data.totalResults,
    }
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return {
        activationGroupPrequalificationPatterns: [],
        currentPage: 0,
        totalActivationGroupPrequalificationPatterns: 0,
      }
    }

    throw err
  }
}

export const createActivationGroupPrequalificationPattern = async (
  newPrequalificationPattern: CreatePrequalificationPattern,
) => {
  // As we send a .csv file, we need to create the payload using the FormData API.
  const formData = new FormData()
  formData.append('activationGroupUuid', newPrequalificationPattern.activationGroupUuid)
  formData.append('name', newPrequalificationPattern.name)
  formData.append('pattern', newPrequalificationPattern.pattern)

  const url = `${environment.services.resourceSelectionManagerApiUrl}/prequalification-patterns`

  const response = await axios.post<ActivationGroupPrequalificationPattern>(url, formData)

  return response.data
}

export const createGenericActivationGroupPrequalificationPattern = async (
  newPrequalificationPattern: GenericCreatePrequalificationPattern,
) => {
  // As we send a .csv file, we need to create the payload using the FormData API.
  const formData = new FormData()
  formData.append('activationGroupUuid', newPrequalificationPattern.activationGroupUuid)
  formData.append('name', newPrequalificationPattern.name)
  formData.append('pattern', newPrequalificationPattern.pattern)

  const url = `${environment.services.prequalificationManagerApiUrl}/prequalification-patterns`

  const response = await axios.post<GenericActivationGroupPrequalificationPattern>(url, formData)

  return response.data
}

export const getPrequalifications = async (
  patternUuid: string,
  pagination: GridPaginationModel,
): Promise<PrequalificationsPage> => {
  const PREQUALIFICATIONS_API_URL = `${environment.services.resourceSelectionManagerApiUrl}/prequalification-patterns/${patternUuid}/prequalifications`

  try {
    const response = await axios.get<ApiPrequalificationPage>(PREQUALIFICATIONS_API_URL, {
      params: buildPagination(pagination),
    })

    return {
      prequalifications: response.data.results.map(deserializePrequalification),
      currentPage: response.data.currentPage,
      totalCount: response.data.totalResults,
    }
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return {
        prequalifications: [],
        currentPage: 0,
        totalCount: 0,
      }
    }

    throw err
  }
}

export const getGenericPrequalifications = async (
  patternUuid: string,
  pagination: GridPaginationModel,
): Promise<GenericPrequalificationsPage> => {
  const PREQUALIFICATIONS_API_URL = `${environment.services.prequalificationManagerApiUrl}/prequalification-patterns/${patternUuid}/prequalifications`

  try {
    const response = await axios.get<GenericApiPrequalificationPage>(PREQUALIFICATIONS_API_URL, {
      params: buildPagination(pagination),
    })

    return {
      prequalifications: response.data.results.map(genericDeserializePrequalification),
      currentPage: response.data.currentPage,
      totalCount: response.data.totalResults,
    }
  } catch (err: unknown) {
    const { response } = err as AxiosError

    if (response?.status === 404) {
      return {
        prequalifications: [],
        currentPage: 0,
        totalCount: 0,
      }
    }

    throw err
  }
}

export const startPrequalification = async (startPrequalificationData: StartPrequalification): Promise<void> => {
  const payload: ApiStartPrequalification = {
    prequalificationPatternUuid: startPrequalificationData.patternUuid,
    prequalificationLane: startPrequalificationData.prequalificationLane,
    isMinCapacity: startPrequalificationData.isMinCapacity,
  }
  const url = `${environment.services.resourceSelectionManagerApiUrl}/prequalifications`

  await axios.post(url, payload)
}

export const startGenericPrequalification = async (
  startPrequalificationData: GenericStartPrequalification,
): Promise<void> => {
  const payload: GenericApiStartPrequalification = {
    prequalificationPatternUuid: startPrequalificationData.patternUuid,
    activationTriggerChannel: startPrequalificationData.activationTriggerChannel,
    isMinCapacity: startPrequalificationData.isMinCapacity,
  }
  const url = `${environment.services.prequalificationManagerApiUrl}/prequalifications`

  await axios.post(url, payload)
}

export const abortGenericPrequalification = async (prequalificationUuid: string): Promise<void> => {
  const payload: GenericApiAbortPrequalification = {}
  const url = `${environment.services.prequalificationManagerApiUrl}/prequalifications/${prequalificationUuid}:abort`

  await axios.post(url, payload)
}

export const abortPrequalification = async (abortPrequalificationData: AbortPrequalification): Promise<void> => {
  const payload: ApiAbortPrequalification = {
    prequalificationLane: abortPrequalificationData.prequalificationLane,
  }
  const url = `${environment.services.resourceSelectionManagerApiUrl}/prequalifications/${abortPrequalificationData.prequalificationLane}:abort`

  // Due to the multiple instances of the resource selection mircroservice, sometimes it takes a couple of calls to hit
  // the correct instance that has the running prequalification. This is a workaround until the prequalification service
  // takes over.
  for (let attempts = 5; attempts > 0; attempts--) {
    try {
      await axios.post(url, payload)
      return // Exit once the request is successful
    } catch (error) {
      if (attempts === 1) {
        throw error // Throw error on the last attempt
      }
    }
  }
}
