import { Box } from '@mui/material'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import type { Localization } from '@mui/material/locale'
import { elGR, enUS, fiFI, huHU, nlNL, svSE } from '@mui/material/locale'
import { ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import {
  elGR as dataGridElGR,
  enUS as dataGridEnUS,
  fiFI as dataGridFiFI,
  huHU as dataGridHuHU,
  nlNL as dataGridNlNL,
  svSE as dataGridSvSE,
} from '@mui/x-data-grid/locales'
import type { Localization as DataGridLocalization } from '@mui/x-data-grid/utils/getGridLocalization'
import { Outlet } from 'react-router-dom'

import WelcomeDialog from '@/components/feedback/WelcomeDialog'
import NavBar from '@/components/navigation/NavBar'
import { AlertProvider } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useMuiTheme } from '@/features/whitelabel/hooks/useMuiTheme'

import AppHeader from './AppHeader'

const DRAWER_WIDTH = 256

const DATA_GRID_LOCALES: { [key: string]: DataGridLocalization } = {
  en: dataGridEnUS,
  nl: dataGridNlNL,
  fi: dataGridFiFI,
  sv: dataGridSvSE,
  el: dataGridElGR,
  hu: dataGridHuHU,
}

const MUI_LOCALES: { [key: string]: Localization } = {
  en: enUS,
  nl: nlNL,
  fi: fiFI,
  sv: svSE,
  el: elGR,
  hu: huHU,
}

function Shell() {
  const { loggedInUser } = useAuth()
  const theme = useMuiTheme(
    loggedInUser?.uiLanguage ? DATA_GRID_LOCALES[loggedInUser.uiLanguage] : DATA_GRID_LOCALES.en,
    loggedInUser?.uiLanguage ? MUI_LOCALES[loggedInUser.uiLanguage] : MUI_LOCALES.en,
  )

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppHeader drawerWidth={DRAWER_WIDTH} />
          <NavBar drawerWidth={DRAWER_WIDTH} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Outlet />
            </Container>
          </Box>
          <WelcomeDialog />
        </Box>
      </AlertProvider>
    </ThemeProvider>
  )
}

export default Shell
