import ErrorOutline from '@mui/icons-material/ErrorOutline'
import type { ChipOwnProps } from '@mui/material/Chip'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

import type { IntegrationStatus } from '@/features/integration/types'
import { getIntegrationStatusLabel } from '@/features/integration/utils/integrationStatus'

interface IntegrationStatusChipProps {
  hasErrors: boolean
  status: IntegrationStatus
}

const BASE_TRANSLATION_KEY = 'component.integration_status_chip'

/**
 * Displays the current integration status stage as a chip. If integration is enabled but it has errors, a warning icon is displayed.
 */
function IntegrationStatusChip({ hasErrors, status }: Readonly<IntegrationStatusChipProps>) {
  const { t } = useTranslation()

  const commonProps: ChipOwnProps = {
    label: getIntegrationStatusLabel(status, t),
    sx: { textTransform: 'uppercase' },
    variant: 'outlined',
  }

  if (status === 'ENABLED' && hasErrors) {
    return (
      <Chip
        {...commonProps}
        color="error"
        icon={<ErrorOutline titleAccess={t(`${BASE_TRANSLATION_KEY}.error_icon`)} />}
      />
    )
  }

  return <Chip {...commonProps} color="primary" disabled={status === 'DISABLED'} />
}

export default IntegrationStatusChip
