import { CircularProgress, DialogContent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import CreateActivationGroupDialogForm from '@/features/activationGroup/components/CreateActivationGroupDialogForm'
import { useInvalidateActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { useCreateActivationGroupMutation } from '@/features/activationGroup/hooks/useCreateActivationGroupMutation'
import type { ApiCreateActivationGroup, CreateActivationGroup } from '@/features/activationGroup/types'
import { usePortfoliosQuery } from '@/features/bidding/hooks/usePortfoliosQuery'
import type { Portfolio } from '@/features/bidding/types/bid'
import { errorHandler } from '@/utils/errorHandler'

interface CreateActivationGroupDialogProps {
  open: boolean
  onClose: () => void
}

const CreateActivationGroupDialog = ({ open, onClose }: CreateActivationGroupDialogProps) => {
  const { t } = useTranslation()
  const { createActivationGroup, isPending: isCreatingActivationGroupMutationPending } =
    useCreateActivationGroupMutation()
  const invalidateActivationGroupsQuery = useInvalidateActivationGroupsQuery()
  const { pushAlert } = useAlertContext()
  const { portfolios, isFetching: isFetchingPortfolios } = usePortfoliosQuery()

  function handleClose() {
    onClose()
  }

  async function handleSubmit(newActivationGroup: CreateActivationGroup) {
    try {
      const apiPayload = serializeCreateActivationGroup(newActivationGroup, portfolios ?? [])

      if (!apiPayload) {
        pushAlert({
          message: t('activation_groups.create_form.portfolio_not_found_error'),
          severity: 'error',
        })

        return
      }

      await createActivationGroup(apiPayload)
      await invalidateActivationGroupsQuery()

      pushAlert({
        message: t('activation_groups.create_activation_group_success_message'),
        severity: 'success',
      })

      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.create_activation_group_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog open={open} title={t('activation_groups.create_dialog.title')} onClose={handleClose}>
      {isFetchingPortfolios ? (
        <DialogContent>
          <Stack alignContent="center" flexDirection="row" justifyContent="center" sx={{ paddingY: 3 }}>
            <CircularProgress title={t('activation_groups.create_dialog.loading_title')} />
          </Stack>
        </DialogContent>
      ) : (
        <CreateActivationGroupDialogForm
          isLoading={isCreatingActivationGroupMutationPending}
          portfolios={portfolios ?? []}
          onCancel={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </CustomDialog>
  )
}

function serializeCreateActivationGroup(
  newActivationGroup: CreateActivationGroup,
  portfolios: Portfolio[],
): ApiCreateActivationGroup | null {
  const portfolio = portfolios?.find((p) => p.code === newActivationGroup.portfolio)

  if (!portfolio) {
    return null
  }

  return {
    code: newActivationGroup.name,
    portfolioCode: portfolio.code,
    marketProgram: newActivationGroup.marketProgram,
    // When an activation group is created, it is not associated with any resource.
    // Assigning a resource to an activation group is part of another user flow.
    resources: [],
    uuid: uuidV4(),
    biddableVolumeRange: newActivationGroup.biddableVolumeRange,
  }
}

export default CreateActivationGroupDialog
