import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ActivationGroupPrequalificationPatternsDataGrid from '@/features/activationGroup/components/ActivationGroupPrequalificationPatternsDataGrid'
import GenericActivationGroupPrequalificationPatternsDataGrid from '@/features/activationGroup/components/GenericActivationGroupPrequalificationPatternsDataGrid'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useActivationGroupDetails } from '@/features/activationGroup/pages/ActivationGroupDetails'
import { isGenericPrequalificationApi } from '@/features/activationGroup/utils/genericPrequalificationPredicates'

function ActivationGroupPrequalificationPatterns() {
  const { activationGroupUuid } = useParams()
  const { t } = useTranslation()

  const { setPageConfig, activationGroup } = useActivationGroupDetails()

  useEffect(() => {
    setPageConfig({
      title: t('activation_groups.tabs.prequalification_patterns'),
      activeTab: ActivationGroupRouteInformation.ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS.routePath,
    })
  }, [])

  if (!activationGroupUuid) {
    return null
  }

  const genericPrequalificationApi = isGenericPrequalificationApi(activationGroup)

  return (
    <>
      {genericPrequalificationApi ? (
        <GenericActivationGroupPrequalificationPatternsDataGrid activationGroupUuid={activationGroupUuid} />
      ) : (
        <ActivationGroupPrequalificationPatternsDataGrid activationGroupUuid={activationGroupUuid} />
      )}
    </>
  )
}

export default ActivationGroupPrequalificationPatterns
