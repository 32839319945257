import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TextFieldController from '@/components/inputs/TextFieldController'
import { formatLimitValue } from '@/features/customer/components/availability/utils'
import type { MarketProgramContract } from '@/features/customer/types/marketProgramContract'
import type { ServiceRuleForm } from '@/features/customer/types/serviceRuleForm'

export const ServiceRuleConditionSection = (props: { marketProgramContract: MarketProgramContract }) => {
  const { t } = useTranslation()

  const { formState } = useFormContext<ServiceRuleForm>()

  return (
    <>
      <Typography variant="h6">
        {t('customer_details.tabs.availability.availability_details.dialog.conditions_section.title')}
      </Typography>
      <Stack alignItems={'center'} direction={'row'} spacing={2}>
        <Typography variant="h4">
          {t('customer_details.tabs.availability.availability_details.dialog.conditions_section.info_message')}
        </Typography>
        <TextFieldController
          disabled
          error={formState.errors?.limitValue !== undefined}
          helperText={formState.errors?.limitValue?.message}
          label={t('customer_details.tabs.availability.availability_details.limit_value.price.label')}
          name={`limitValue`}
          sx={{ width: 200 }}
          value={formatLimitValue(
            t,
            props.marketProgramContract.service.type,
            props.marketProgramContract.contractPrice,
          )}
        />
      </Stack>
    </>
  )
}
