import { ChevronRight } from '@mui/icons-material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import type { GridRowParams } from '@mui/x-data-grid'
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import type { TFunction } from 'i18next'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomChip from '@/components/dataDisplay/CustomChip'
import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { COLUMN_WITH_MEDIUM_ICON_WIDTH } from '@/constants/layout'
import type { ActivationGroup } from '@/features/activationGroup/types'
import BidsFilterToolbar from '@/features/bidding/components/BidsFilterToolbar'
import BidStatusChip from '@/features/bidding/components/BidStatusChip'
import { Status } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { usePortfoliosQuery } from '@/features/bidding/hooks/usePortfoliosQuery'
import type { MarketProgramBiddingName, Portfolio } from '@/features/bidding/types/bid'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { buildBidCapacityLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import type { BiddingTodoItem } from '@/features/bidding/utils/model/composeBiddingTodoList'

type Props = {
  todoItems: BiddingTodoItem[]
  activationGroups: ActivationGroup[]
}

export const BiddingTodoListDataGrid: FC<Props> = ({ todoItems, activationGroups }) => {
  const { t } = useTranslation()

  const { selectedCountry } = useBiddingContext()
  const { portfolios } = usePortfoliosQuery({ countryCode: selectedCountry })

  return (
    <CustomDataGrid
      clickableRows={{ navigateTo: (params: GridRowParams<BiddingTodoItem>) => getBidCreateLink(params.row) }}
      columns={getColumns(t, portfolios ?? [])}
      getRowId={getRowId}
      initialState={{
        sorting: {
          sortModel: [
            { field: 'deliveryDay', sort: 'asc' },
            { field: 'portfolio', sort: 'asc' },
          ],
        },
        pagination: {
          paginationModel: { pageSize: 20, page: 0 },
        },
        filter: {
          filterModel: {
            items: [
              {
                id: 'deliveryDay',
                field: 'deliveryDay',
                operator: 'equals',
                value: new MarketDate().plus({ days: 2 }).toISODate(),
              },
            ],
          },
        },
      }}
      isLoading={!portfolios}
      rows={todoItems}
      slotProps={{ toolbar: { portfolios, activationGroups, showMarketWindowFilter: true } }}
      slots={{ toolbar: BidsFilterToolbar }}
    />
  )
}

const getColumns = (t: TFunction, portfolios: Portfolio[]): GridColDef<BiddingTodoItem>[] => [
  {
    field: 'portfolio',
    headerName: t('bidding.overview.header.portfolio'),
    valueGetter: (_, biddingTodoItem: BiddingTodoItem) => biddingTodoItem.portfolio.code,
    valueFormatter: (value: string) => portfolios.find((p) => p.code === value)?.name ?? '-',
    flex: 1,
  },
  {
    field: 'marketProgram',
    headerName: t('bidding.overview.header.market_program'),
    valueGetter: (_, biddingTodoItem: BiddingTodoItem) => biddingTodoItem.marketProgram,
    valueFormatter: (value: MarketProgramBiddingName) => t(`bidding.market_program.${value}`),
    flex: 1,
  },
  {
    field: 'activationGroupCodes',
    type: 'singleSelect',
    headerName: t('bidding.overview.header.activation_groups'),
    flex: 1,
    renderCell: (params: GridRenderCellParams<BiddingTodoItem>) => (
      <CustomChip
        color="primary"
        label={params.row.activationGroups.length}
        size="small"
        title={params.row.activationGroups
          .map((activationGroup) => activationGroup.code)
          .toSorted((a, b) => a.localeCompare(b))
          .join(', ')}
        variant="filled"
      />
    ),
  },
  {
    field: 'deliveryDay',
    headerName: t('bidding.overview.header.delivery_day'),
    valueGetter: (value: BiddingTodoItem['deliveryDay']) => value.toISODate(),
    valueFormatter: (value: string) => new MarketDate(value).getStartOfDay().toFormat('dd LLL, yyyy'),
    flex: 1,
  },
  {
    field: 'status',
    headerName: t('bidding.overview.header.status'),
    renderCell: (params) => (
      <Stack sx={{ flexDirection: 'row', width: 'fit-content', alignItems: 'center', height: '100%' }}>
        <BidStatusChip status={params.row.status} />
      </Stack>
    ),
    flex: 1,
  },
  {
    field: 'actions',
    headerName: '',
    width: COLUMN_WITH_MEDIUM_ICON_WIDTH,
    sortable: false,
    renderCell: (params) => (
      <IconButton data-testid="todo-create-button">
        {params.row.status === Status.DRAFT ? <EditOutlinedIcon /> : <ChevronRight />}
      </IconButton>
    ),
  },
]

const getRowId = (todoItem: BiddingTodoItem) =>
  `${todoItem.portfolio.code}-${todoItem.marketProgram}-${todoItem.deliveryDay.toISODate()}`

const getBidCreateLink = (todoItem: BiddingTodoItem) =>
  buildBidCapacityLink({
    portfolioCode: todoItem.portfolio.code,
    deliveryDay: todoItem.deliveryDay,
    marketProgram: todoItem.marketProgram,
  })
