import { DialogContent } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { t } from 'i18next'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'

interface DeleteRestrictionConfirmationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const DeleteRestrictionConfirmationDialog = (props: DeleteRestrictionConfirmationDialogProps) => {
  return (
    <CustomDialog
      open={props.open}
      size="extraSmall"
      title={t('customer_details.tabs.availability.unavailability_form.delete_dialog.confirm_dialog_title')}
      onClose={props.onClose}
    >
      <DialogContent>
        <DialogContentText>
          {t('customer_details.tabs.availability.unavailability_form.delete_dialog.confirm_dialog_description')}
        </DialogContentText>
      </DialogContent>
      <ConfirmDialogActions onCancel={props.onClose} onConfirm={props.onConfirm} />
    </CustomDialog>
  )
}
