import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/material'
import type { GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomButton from '@/components/inputs/CustomButton'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import AbortPrequalificationConfirmationButton from '@/features/activationGroup/components/AbortPrequalificationConfirmationButton'
import PrequalificationDataGridToolbar from '@/features/activationGroup/components/PrequalificationDataGridToolbar'
import StartPrequalificationDialog from '@/features/activationGroup/components/StartPrequalificationDialog'
import { usePrequalificationsQuery } from '@/features/activationGroup/hooks/usePrequalificationsQuery'
import type { Prequalification } from '@/features/activationGroup/types'
import { getGrafanaDisturbanceUrl } from '@/features/disturbance/utils/grafana'

type PrequalificationsDataGridProps = {
  patternUuid: string
}
type PrequalificationRenderCell = GridRenderCellParams<Prequalification>

const DATE_FORMAT = 'dd LLL, yyyy - HH:mm:ss'

const PrequalificationsDataGrid = ({ patternUuid }: PrequalificationsDataGridProps) => {
  const { t } = useTranslation()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(DEFAULT_DATA_GRID_PAGINATION_MODEL)
  const [openStartPrequalificationDialog, setOpenStartPrequalificationDialog] = useState(false)
  const { prequalifications, totalCount, isLoading } = usePrequalificationsQuery({
    patternUuid,
    pagination: paginationModel,
  })

  const columns: GridColDef<Prequalification>[] = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 2,
    },
    {
      field: 'prequalificationLane',
      headerName: t('activation_groups.prequalifications.table.header.prequalification_lane'),
      flex: 1,
    },
    {
      field: 'startedAt',
      headerName: t('activation_groups.prequalifications.table.header.startedAt'),
      flex: 1,
      valueFormatter: (value: Prequalification['startedAt']) => value.toFormat(DATE_FORMAT),
    },
    {
      field: 'endedAt',
      headerName: t('activation_groups.prequalifications.table.header.endedAt'),
      flex: 1,
      valueFormatter: (value: Prequalification['endedAt']) => (value ? value.toFormat(DATE_FORMAT) : '-'),
    },
    {
      field: 'is_min_capacity',
      headerName: t('activation_groups.prequalifications.table.header.is_min_capacity'),
      flex: 1,
      renderCell: ({ row: { isMinCapacity } }: PrequalificationRenderCell) =>
        isMinCapacity ? (
          <CheckIcon color="success" titleAccess={t('activation_groups.prequalifications.table.is_min_capacity')} />
        ) : (
          <CloseIcon color="error" titleAccess={t('activation_groups.prequalifications.table.is_not_min_capacity')} />
        ),
    },
    {
      field: 'abortPrequalification',
      headerName: 'Abort prequalification',
      flex: 1,
      renderCell: ({ row: { prequalificationLane, endedAt, uuid } }: PrequalificationRenderCell) => {
        return (
          <Stack alignItems="center" flexDirection="row" gap={1} sx={{ height: '100%' }}>
            <AbortPrequalificationConfirmationButton
              isPrequalificationActive={endedAt == null}
              prequalificationLane={prequalificationLane}
              prequalificationUuid={uuid}
            />
          </Stack>
        )
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      renderCell: ({ row: { startedAt, endedAt } }: PrequalificationRenderCell) => {
        return (
          <Stack alignItems="center" flexDirection="row" gap={1} sx={{ height: '100%' }}>
            <CustomButton
              component={Link}
              target="_blank"
              to={getGrafanaDisturbanceUrl({ startedAt, endedAt, priceArea: null })}
              variant="text"
            >
              {t('common.view_grafana')}
            </CustomButton>
          </Stack>
        )
      },
    },
  ]

  function handleOpenStartPrequalificationDialog() {
    setOpenStartPrequalificationDialog(true)
  }

  function handleCloseStartPrequalificationDialog() {
    setOpenStartPrequalificationDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        disableColumnSorting
        aria-label={t('activation_groups.prequalifications.table.title')}
        columns={columns}
        getRowId={(row) => row.uuid}
        isLoading={isLoading}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={totalCount}
        rows={prequalifications ?? []}
        slotProps={{
          toolbar: {
            onStartNewPrequalification: handleOpenStartPrequalificationDialog,
          },
        }}
        slots={{
          toolbar: PrequalificationDataGridToolbar,
        }}
        onPaginationModelChange={setPaginationModel}
      />

      <StartPrequalificationDialog
        open={openStartPrequalificationDialog}
        patternUuid={patternUuid}
        onClose={handleCloseStartPrequalificationDialog}
      />
    </>
  )
}

export default PrequalificationsDataGrid
