import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import type { Restriction } from '@/features/availability/types/restriction'
import { GET_RESTRICTIONS_API_ID, getRestrictions } from '@/features/customer/endpoints/restrictions'
import type { UseQueryOptions } from '@/types/queries'

type RestrictionsQueryParams = {
  customerUuid: string
  location?: string
}
export type UseRestrictionsQueryResult = Omit<UseQueryResult, 'data'> & {
  restrictions: Restriction[] | null
}
export function useRestrictionsQuery(
  params: RestrictionsQueryParams,
  options?: UseQueryOptions<Restriction[]>,
): UseRestrictionsQueryResult {
  const { data = null, ...queryResults } = useQuery({
    ...options,
    queryKey: [GET_RESTRICTIONS_API_ID, params],
    queryFn: () => getRestrictions(params.customerUuid, params.location),
  })

  return {
    restrictions: data,
    ...queryResults,
  }
}

export function useInvalidateRestrictionsQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_RESTRICTIONS_API_ID] })
}
