import { Box, Stack } from '@mui/material'
import Paper from '@mui/material/Paper'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { CustomerCompanyInfo } from '@/features/customer/components/companyInfo/CustomerCompanyInfo'
import { CustomerContacts } from '@/features/customer/components/companyInfo/CustomerContacts'
import { CustomerSelector } from '@/features/customer/components/CustomerSelector'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'

export const CustomerContactInfo = () => {
  const { loggedInUser } = useAuth()
  const { customer, customerDetailsCommonBreadcrumbs, setPageConfig } = useCustomerDetails()
  const { t } = useTranslation()
  const hasCustomerManagerUserRole = loggedInUser?.role === USER_ROLES.CUSTOMER_MANAGERS.value

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.contact_info.title'),
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      appBarContent: <CustomerSelector customerUuid={customer.uuid} />,
      activeTab: CustomerDetailsRouteInformation.CONTACT_INFO.navigationPath,
    })
  }, [])

  return (
    <Box my={3} sx={{ width: '100%' }}>
      <Stack sx={{ flexDirection: 'row', width: '100%', gap: 2 }}>
        <Paper sx={{ p: 3, height: 'fit-content', width: '100%' }}>
          <CustomerCompanyInfo customer={customer} viewOnly={hasCustomerManagerUserRole} />
        </Paper>

        <Paper sx={{ p: 3, height: 'fit-content', width: '100%' }}>
          <CustomerContacts customer={customer} viewOnly={hasCustomerManagerUserRole} />
        </Paper>
      </Stack>
    </Box>
  )
}
