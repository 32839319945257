import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import GenericCreateScheduleDialogForm from '@/features/activationGroup/components/GenericCreateScheduleDialogForm'
import { useInvalidateGenericActivationGroupSchedulesQuery } from '@/features/activationGroup/hooks/useGenericActivationGroupSchedulesQuery'
import { useGenericCreateScheduleMutation } from '@/features/activationGroup/hooks/useGenericCreateScheduleMutation'
import type { GenericCreateSchedule } from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type GenericCreateScheduleDialogProps = {
  activationGroupUuid: string
  open: boolean
  onClose: () => void
}

const GenericCreateScheduleDialog = ({ open, onClose, activationGroupUuid }: GenericCreateScheduleDialogProps) => {
  const { t } = useTranslation()
  const { createSchedule, isPending } = useGenericCreateScheduleMutation()
  const { pushAlert } = useAlertContext()
  const invalidateActivationGroupSchedulesQuery = useInvalidateGenericActivationGroupSchedulesQuery()

  function handleClose() {
    onClose()
  }

  async function handleSubmit(data: GenericCreateSchedule) {
    try {
      await createSchedule(data)
      await invalidateActivationGroupSchedulesQuery(data.activationGroupUuid)

      pushAlert({
        message: t('activation_groups.create_schedule_success_message'),
        severity: 'success',
      })

      onClose()
    } catch (err: unknown) {
      console.error('error', err)
      const error = errorHandler(err, t('activation_groups.create_schedule_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog open={open} title={t('activation_groups.create_schedule_dialog.title')} onClose={handleClose}>
      <GenericCreateScheduleDialogForm
        activationGroupUuid={activationGroupUuid}
        isLoading={isPending}
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </CustomDialog>
  )
}

export default GenericCreateScheduleDialog
