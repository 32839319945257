import { alpha, Menu, MenuItem, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import EllipsisTypography from '@/components/dataDisplay/EllipsisTypography'

interface TextListProps {
  values: string[]
  /**
   * Indicates the maximum number of strings to be displayed. The rest of the strings will be display as part of a menu.
   */
  limit?: number
}

/**
 * It is a component that displays a list of texts. It wraps a bunch of 'Text" components and allows to assign
 * properties to all of them at once (for example, a color or a variant).
 *
 * One of the main features of this component is the ability to limit the amount of texts to be displayed.
 * If the limit is exceeded, the rest of the texts will be shown as part of a menu. This is specially useful when the
 * component is used in a small space or when the amount of texts could be very large.
 */
const TextList = ({ values, limit = 0 }: TextListProps) => {
  const theme = useTheme()
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [openMenu, setOpenMenu] = useState(false)
  const { t } = useTranslation()
  const listSize = values.length
  const limitOverflow = limit >= 0 && listSize > 0 && listSize > limit
  const visibleStrings = limitOverflow ? values.slice(0, limit) : values
  const menuStrings = limitOverflow ? values.slice(limit) : []
  const menuListSize = menuStrings.length

  function handleOpenMenu() {
    setOpenMenu(true)
  }

  function handleCloseMenu() {
    setOpenMenu(false)
  }

  return (
    <Stack
      ref={anchorEl}
      sx={{
        flexDirection: 'row',
        gap: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        height: '100%',
      }}
    >
      {visibleStrings.map((value, index) => (
        <CustomTypography key={value} variant="body2">
          {value + (index < listSize - 1 ? ', ' : '')}
        </CustomTypography>
      ))}

      {limitOverflow && (
        <>
          <CustomTypography
            aria-controls={openMenu ? 'more-items-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            aria-owns={openMenu ? 'mouse-items-menu' : undefined}
            id="more-items"
            sx={{
              cursor: 'pointer',
              border: 'none',
              background: 'transparent',
              color: openMenu ? theme.palette.primary.main : theme.palette.secondary.main,
              '&:hover': { color: theme.palette.primary.main },
            }}
            variant="body2"
            onMouseEnter={handleOpenMenu}
            onMouseLeave={handleCloseMenu}
          >
            {t('component.list.more', { count: menuListSize })}
          </CustomTypography>

          <Menu
            disableAutoFocusItem
            MenuListProps={{
              'aria-labelledby': 'more-items',
            }}
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            id="more-items-menu"
            open={openMenu}
            sx={{
              pointerEvents: 'none',
              '& .MuiMenu-paper': {
                width: '200px',
                background: theme.palette.secondary.dark,
              },
              '& .MuiMenu-list': {
                padding: theme.spacing(0.5, 0),
              },
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            variant="menu"
            onClose={handleCloseMenu}
          >
            {menuStrings.map((text, index) => (
              <MenuItem
                key={text}
                disableGutters
                divider={index < menuListSize - 1}
                selected={false}
                sx={{
                  padding: theme.spacing(0.5, 1),
                  '&.MuiMenuItem-divider': {
                    borderColor: alpha(theme.palette.getContrastText(theme.palette.secondary.dark), 0.12),
                    marginTop: '1px',
                  },
                }}
              >
                <EllipsisTypography
                  sx={{
                    color: theme.palette.getContrastText(theme.palette.secondary.dark),
                    fontWeight: theme.typography.fontWeightMedium,
                    lineHeight: '14px',
                  }}
                  variant="caption"
                >
                  {text}
                </EllipsisTypography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Stack>
  )
}

export default TextList
