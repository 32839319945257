type SyncUrlManagerOptions = {
  replace?: boolean
}

export function syncUrlManagerBuilder() {
  let params = new URLSearchParams(window.location.search)
  const defaultOptions = { replace: true }

  return {
    setParams(newParams: Record<string, string>) {
      params = new URLSearchParams()

      for (const [key, value] of Object.entries(newParams)) {
        if (!value) {
          params.delete(key)
          continue
        }

        params.set(key, value)
      }
    },

    syncUrl(opts?: SyncUrlManagerOptions) {
      const options = opts ?? defaultOptions
      const pathname = window.location.pathname
      const url = params.size === 0 ? `${pathname}` : `${pathname}?${params}`

      if (options.replace) {
        window.history.replaceState({}, '', url)
      } else {
        window.history.pushState({}, '', url)
      }
    },
  }
}
