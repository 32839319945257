import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomIconButton from '@/components/inputs/CustomIconButton'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends ScheduleDataGridToolbarProps {}
}

export type ScheduleDataGridToolbarProps = {
  onAddNewSchedule: () => void
}

const ScheduleDataGridToolbar = ({ onAddNewSchedule }: ScheduleDataGridToolbarProps) => {
  const { t } = useTranslation()

  return (
    <GridToolbar
      actions={
        <CustomIconButton
          Icon={AddOutlinedIcon}
          aria-label={t('activation_groups.create_schedule_button_label')}
          color="primary"
          variant="solid"
          onClick={onAddNewSchedule}
        />
      }
    />
  )
}

export default ScheduleDataGridToolbar
