import { Box } from '@mui/material'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useCustomersQuery } from '@/features/customer/hooks/useCustomersQuery'
import { usePartnersQuery } from '@/features/partner/hooks/usePartnersQuery'
import { PartnerUserForm } from '@/features/user/components/PartnerUserForm'
import { UserForm } from '@/features/user/components/UserForm'

export default function UserCreate() {
  const { loggedInUser } = useAuth()

  const { customers } = useCustomersQuery()
  const { partners } = usePartnersQuery({ enabled: isCurrentUserAdmin() })

  function isCurrentUserAdmin() {
    return loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value
  }

  function isCurrentUserPartnerAdmin() {
    return loggedInUser?.role === USER_ROLES.PARTNER_ADMINISTRATORS.value
  }

  if (!loggedInUser) {
    return null
  }

  return (
    <Box sx={{ marginY: 3 }}>
      {isCurrentUserAdmin() && <UserForm customers={customers ?? []} partners={partners ?? []} />}

      {isCurrentUserPartnerAdmin() && <PartnerUserForm customers={customers ?? []} />}
    </Box>
  )
}
