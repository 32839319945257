import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import greekTranslation from '@/features/localization/locales/el/translation.json'
import englishTranslation from '@/features/localization/locales/en/translation.json'
import finnishTranslation from '@/features/localization/locales/fi/translation.json'
import hungarianTranslation from '@/features/localization/locales/hu/translation.json'
import dutchTranslation from '@/features/localization/locales/nl/translation.json'
import swedishTranslation from '@/features/localization/locales/sv/translation.json'

i18next
  .use(initReactI18next)
  .init({
    resources: {
      el: {
        translation: greekTranslation,
      },
      en: {
        translation: englishTranslation,
      },
      fi: {
        translation: finnishTranslation,
      },
      nl: {
        translation: dutchTranslation,
      },
      sv: {
        translation: swedishTranslation,
      },
      hu: {
        translation: hungarianTranslation,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    // In case we need something to run after the initialisation. For now, nothing necessary.
  })

export default i18next
