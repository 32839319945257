export type PowerUnit = 'watts' | 'kilowatts' | 'megawatts' | 'gigawatts'
export type EnergyUnit = 'watt_hours' | 'kilowatt_hours'

const WATTS_TO_KW = 1_000
const WATTS_TO_MW = 1_000_000
const WATTS_TO_GW = 1_000_000_000

export const UNIT_SYMBOLS: Record<PowerUnit | EnergyUnit, string> = {
  watts: 'W',
  watt_hours: 'Wh',
  kilowatts: 'kW',
  kilowatt_hours: 'kWh',
  megawatts: 'MW',
  gigawatts: 'GW',
}

/**
 * Converts and formats power values in watts to the appropriate units.
 */
export function convertEnergyPower(power: number, unit: PowerUnit | EnergyUnit) {
  switch (unit) {
    case 'gigawatts':
      return `${power / WATTS_TO_GW} ${UNIT_SYMBOLS.gigawatts}`
    case 'megawatts':
      return `${power / WATTS_TO_MW} ${UNIT_SYMBOLS.megawatts}`
    case 'kilowatts':
      return `${power / WATTS_TO_KW} ${UNIT_SYMBOLS.kilowatts}`
    case 'kilowatt_hours':
      return `${power / WATTS_TO_KW} ${UNIT_SYMBOLS.kilowatt_hours}`
    default:
      return `${power} ${UNIT_SYMBOLS.watts}`
  }
}
