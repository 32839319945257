import { DialogActions, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'

type FormDialogActionsProps = {
  disabled?: boolean
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmDialogActions = ({ disabled, onConfirm, onCancel }: FormDialogActionsProps) => {
  const { t } = useTranslation()

  return (
    <DialogActions>
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        <CustomButton disabled={disabled} onClick={onConfirm}>
          {t('common.yes')}
        </CustomButton>
        <CustomButton color="error" disabled={disabled} onClick={onCancel}>
          {t('common.no')}
        </CustomButton>
      </Stack>
    </DialogActions>
  )
}

export default ConfirmDialogActions
