import { Divider } from '@mui/material'

import ResourceCard from '@/features/resource/components/ResourceCard'
import SteeringDefaultsPanel from '@/features/resource/components/steering/SteeringDefaultsPanel'
import SteeringRangesPanel from '@/features/resource/components/steering/SteeringRangesPanel'
import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'

export type ResourceSteeringModalProps = {
  resourceUuid: string
}

const ResourceSteeringCard = ({ resourceUuid }: ResourceSteeringModalProps) => {
  const { steeringConfig } = useResourceWithPollingQuery({ id: resourceUuid! })

  return (
    <ResourceCard
      content={
        steeringConfig && (
          <>
            <SteeringDefaultsPanel resourceID={resourceUuid} steeringConfig={steeringConfig}></SteeringDefaultsPanel>
            <Divider />
            <SteeringRangesPanel resourceID={resourceUuid} steeringConfig={steeringConfig}></SteeringRangesPanel>
          </>
        )
      }
      resourceUuid={resourceUuid}
    />
  )
}

export default ResourceSteeringCard
