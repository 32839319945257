import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { DateTime } from 'luxon'

import { GET_MEASURED_POWER_API, getMeasuredPower } from '@/features/measurement/endpoints/measurements'
import type { ApiMeasuredPower, MeasuredPowerSerie } from '@/features/measurement/types/measuredPower'
import type { UseQueryOptions } from '@/types/queries'

export type UseMeasuredPowerQueryParams = {
  startTime: DateTime
  endTime: DateTime
}

export type UseMeasuredPowerQueryResult = Omit<UseQueryResult, 'data'> & {
  timeData: string[]
  measuredPowerSeries: MeasuredPowerSerie[]
}

export function useMeasuredPowerQuery(
  params: UseMeasuredPowerQueryParams,
  options?: UseQueryOptions<ApiMeasuredPower>,
): UseMeasuredPowerQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_MEASURED_POWER_API, { params }],
    queryFn: () => getMeasuredPower(params),
  })

  return { timeData: data?.timeData ?? [], measuredPowerSeries: data?.measuredPowerSeries ?? [], ...queryResult }
}

export function useInvalidateMeasuredPowerQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_MEASURED_POWER_API] })
}
