import HomeIcon from '@mui/icons-material/Home'
import type { BreadcrumbsProps } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import type { ElementType } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import CustomLink from '@/components/navigation/CustomLink'
import type { Breadcrumb } from '@/types/breadcrumb'

type CustomBreadcrumbsProps = BreadcrumbsProps & {
  items: Breadcrumb[]
  showHome: boolean
}

/**
 * CustomBreadcrumbs component uses MUI Breadcrumbs component and adds some Sympower custom behaviors:
 *
 * - Adds an extra prop "items" to render every item as a RouterLink to have navigation, but keeping the Link style
 * of MUI.
 */
function CustomBreadcrumbs({ items, showHome = false, sx, ...breadcrumbProps }: CustomBreadcrumbsProps) {
  const hasItems = items && items.length > 0
  const styles = { marginTop: 1, ...sx }

  if (!hasItems && showHome) {
    return (
      <Breadcrumbs aria-label="breadcrumb" sx={styles} {...breadcrumbProps}>
        <LinkToHome />
        {/* The empty span is used for the breadcrumbs to include the "/" separator at the end */}
        <span></span>
      </Breadcrumbs>
    )
  }

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={styles} {...breadcrumbProps}>
      {showHome && <LinkToHome />}
      {items?.map((item, index) => (
        <CustomLink key={index} color="primary" component={RouterLink as ElementType} to={item.to} underline="hover">
          {item.text}
        </CustomLink>
      ))}
      {/* The empty span is used for the breadcrumbs to include the "/" separator at the end */}
      <span></span>
    </Breadcrumbs>
  )
}
function LinkToHome() {
  return (
    <CustomLink color="primary" component={RouterLink as ElementType} sx={{ display: 'flex' }} to="/" underline="hover">
      <HomeIcon fontSize="inherit" />
    </CustomLink>
  )
}

export default CustomBreadcrumbs
