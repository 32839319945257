import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { GET_FEATURE_TOGGLES_API_ID, getFeatureToggles } from '@/features/featureToggle/endpoints/featureToggle'
import type { FeatureToggle } from '@/features/featureToggle/types/featureToggle'

export type State = {
  isEnabled: (feature: string) => boolean
  isLoading: boolean
  error: Error | null
}

export type FeatureToggleProviderProps = {
  children: ReactNode
  featureToggles?: FeatureToggle[]
}

const initialState: State = {
  isEnabled: () => false,
  isLoading: false,
  error: null,
}

const FeatureToggleContext = createContext<State>(initialState)

function FeatureToggleProvider(props: Readonly<FeatureToggleProviderProps>) {
  const location = useLocation()
  const queryClient = useQueryClient()

  const { logout, isLogged, loggedInUser } = useAuth()
  const { data, isSuccess, isFetching, isLoading, error } = useQuery({
    queryKey: [GET_FEATURE_TOGGLES_API_ID],
    queryFn: getFeatureToggles,
    enabled: isLogged,
  })

  useEffect(() => {
    if (
      isSuccess &&
      !isEnabled('CUSTOMER_USER_LOGIN_ENABLED') &&
      loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value
    ) {
      logout()
    }
  }, [isSuccess, isFetching, loggedInUser?.role])

  useEffect(() => {
    // We decided to call the endpoint to get feature toggles every time we have change the pathname
    // (every time we change the page). The feature toggles will not be updated when query parameters change, as it could
    // produce a lot of requests to the server.

    // TASK: https://sympower.atlassian.net/browse/TECH-1212
    queryClient.invalidateQueries({ queryKey: [GET_FEATURE_TOGGLES_API_ID] })
  }, [location.pathname])

  function isEnabled(featureToCheck: string, fallbackValue?: boolean): boolean {
    if (!data) return fallbackValue || false

    const found = data.toggles.find((feature) => feature.name === featureToCheck)

    return found ? found.enabled : false
  }

  const value = useMemo(
    () => ({
      error,
      isEnabled,
      isLoading,
    }),
    [error, isEnabled, isLoading],
  )

  return <FeatureToggleContext.Provider value={value} {...props} />
}

const useFeatureToggle = () => {
  return useContext(FeatureToggleContext)
}
export { FeatureToggleProvider, useFeatureToggle }
