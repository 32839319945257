import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createActivationGroup } from '@/features/activationGroup/endpoints/activationGroups'
import type { ActivationGroup, ApiCreateActivationGroup } from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseCreateActivationGroupMutationResult = Omit<
  UseMutationResult<ActivationGroup, Error, ApiCreateActivationGroup>,
  'mutateAsync'
> & {
  createActivationGroup: UseMutationResult<ActivationGroup, Error, ApiCreateActivationGroup>['mutateAsync']
}

export function useCreateActivationGroupMutation(
  options?: UseMutationOptions<ActivationGroup, ApiCreateActivationGroup>,
): UseCreateActivationGroupMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiCreateActivationGroup) => createActivationGroup(variables),
  })

  return { createActivationGroup: mutateAsync, ...mutationResult }
}
