import { Stack } from '@mui/material'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import type { CustomAccordionProps } from '@/components/layouts/CustomAccordion'
import CustomAccordion from '@/components/layouts/CustomAccordion'
import type { User } from '@/features/user/types/user'
import { UserAuthenticationStatusEnum } from '@/features/user/types/user'
import { getAuthenticationStatusInLowerCase } from '@/features/user/utils/authenticationStatusInLowerCase'

type UserAccountAccordionProps = {
  children: ReactNode
  user?: User
}

const UserAccountAccordion = ({ user, children }: UserAccountAccordionProps) => {
  const { t } = useTranslation()

  function getStatusProps(user?: User): CustomAccordionProps['statusProps'] | undefined {
    if (!user) {
      return undefined
    }

    const authenticationStatusInLowerCase = getAuthenticationStatusInLowerCase(user)

    return {
      color:
        user?.authenticationStatus === UserAuthenticationStatusEnum.RESETREQUIRED ||
        user?.authenticationStatus === UserAuthenticationStatusEnum.FORCECHANGEPASSWORD
          ? 'error'
          : 'default',
      label: t(`user.authentication_status.${authenticationStatusInLowerCase}_label`),
      tooltipLabel: t(`user.authentication_status.${authenticationStatusInLowerCase}_tooltip`),
    }
  }

  return (
    <CustomAccordion
      defaultExpanded
      description={t('user_form.form.description_user_account')}
      statusProps={getStatusProps(user)}
      title={t('user_form.form.title_user_account')}
    >
      <Stack direction="column" gap={3}>
        {children}
      </Stack>
    </CustomAccordion>
  )
}

export default UserAccountAccordion
