import type { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { z } from 'zod'

import type { AvailabilityStatus } from '@/features/availability/types/availabilityStatus'
import type { Restriction } from '@/features/availability/types/restriction'

const luxonDateTime = z.preprocess((arg) => {
  if (arg instanceof DateTime) {
    return arg.toJSDate()
  }
  return arg
}, z.date())

type RestrictionForm = Pick<Restriction, 'type' | 'serviceId'> & {
  id?: number
  startDate: DateTime
  endDate: DateTime
  startTime: DateTime
  endTime: DateTime
  allDay: boolean
  selectedResources: number[]
  location: string
  status?: AvailabilityStatus
}

const getRestrictionFormSchema = (t: TFunction) => {
  return z
    .object({
      type: z.enum(['non-repeating', 'repeating-daily']),
      startDate: luxonDateTime,
      endDate: luxonDateTime,
      startTime: luxonDateTime,
      endTime: luxonDateTime,
      location: z.string(),
      selectedResources: z.array(z.number()),
    })
    .refine(
      (schema) => {
        return schema.startDate.getDate() <= schema.endDate.getDate()
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
        path: ['endDate'],
      },
    )
    .refine(
      (schema) => {
        if (schema.type === 'repeating-daily') {
          return schema.endTime.getTime() > schema.startTime.getTime()
        }

        if (schema.type === 'non-repeating' && schema.endDate.getDate() === schema.startDate.getDate()) {
          return schema.endTime.getTime() > schema.startTime.getTime()
        }

        return true
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_time_before_start_time'),
        path: ['endTime'],
      },
    )
}

export { getRestrictionFormSchema, RestrictionForm }
