import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import { SingleSelectColumnToolbarFilter } from '@/components/inputs/SingleSelectColumnToolbarFilter'
import type { MarketProgramType } from '@/types/marketProgramType'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends ActivationDataGridToolbarProps {}
}

type ActivationDataGridToolbarProps = Readonly<{
  marketProgramOptions: Set<MarketProgramType>
}>

const SERVICE_ID_FILTER_NAME = 'serviceId'

function ActivationsDataGridToolbar({ marketProgramOptions }: ActivationDataGridToolbarProps) {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()
  const filterModel = gridFilterModelSelector(apiRef)
  const serviceId = getFilterItemValue(filterModel, SERVICE_ID_FILTER_NAME) ?? ''

  function handleFilterChange({ items }) {
    updateDataGridFilter(apiRef, SERVICE_ID_FILTER_NAME, items[0])
  }

  return (
    <GridToolbar
      filters={
        <SingleSelectColumnToolbarFilter
          currentFilterValue={serviceId}
          field="serviceId"
          getOptionLabel={(value: MarketProgramType) => (value ? t(`common.market_program.${value}`) : '-')}
          label={t('common.market_program.label')}
          options={Array.from(marketProgramOptions)
            .map((mp) => mp as string)
            .sort((a, b) => a.localeCompare(b))}
          onFilterModelChange={handleFilterChange}
        />
      }
    />
  )
}

export default ActivationsDataGridToolbar
