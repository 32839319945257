import type { GridPaginationModel } from '@mui/x-data-grid-pro'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import type { PollResourcesResponse, ResourcesResponse, Sort } from '@/features/resource/endpoints/resources'
import {
  GET_RESOURCES_API_ID,
  getResources,
  POLL_RESOURCES_API_ID,
  pollResources,
} from '@/features/resource/endpoints/resources'
import type {
  Resource,
  ResourceMetadata,
  ResourcesGlobalFilters,
  ResourcesGridFilters,
} from '@/features/resource/types'

const POLLING_INTERVAL = 5000 // Poll every 5 seconds

type POLLING_STATUS = 'IDLE' | 'PAUSED' | 'RUNNING'

export type UseResourcesWithPollingQueryResult = {
  isLoading: boolean
  resources: Resource[]
  meta?: ResourceMetadata
  error?: Error | null
  stopPolling: () => void
  restartPolling: () => void
}

/**
 * This hook is used to fetch resources from the API. It will also poll the API every 5 seconds to get the latest data
 * from them.
 */
export function useResourcesWithPollingQuery({
  filters,
  globalFilters,
  sort,
  pagination,
  interval = POLLING_INTERVAL,
}: {
  filters: ResourcesGridFilters
  globalFilters: ResourcesGlobalFilters
  sort?: Sort
  pagination: GridPaginationModel
  interval?: number
}): UseResourcesWithPollingQueryResult {
  const queryClient = useQueryClient()
  const [pollingStatus, setPollingStatus] = useState<POLLING_STATUS>('IDLE')
  const resourcesQueryKey = [GET_RESOURCES_API_ID, globalFilters, filters, pagination, sort]

  const {
    data: resourcesResponse,
    isLoading,
    error: resourcesError,
  } = useQuery<ResourcesResponse>({
    queryKey: resourcesQueryKey,
    queryFn: () =>
      getResources({
        filters: {
          resourceType: globalFilters.resourceType,
          customerId: globalFilters.customerId,
          marketProgram: filters.marketProgram,
          lifecycleStage: filters.lifecycleStage,
          countryCode: globalFilters.countryCode,
        },
        pagination: {
          limit: pagination.pageSize,
          skip: pagination.pageSize * pagination.page,
        },
        sort,
      }),
    gcTime: 0,
  })
  const resourcesData = resourcesResponse?.data ?? []
  const resourceIds = resourcesData.map((resource) => resource.resourceID) ?? []

  const { data: pollResponse, error: pollResourcesError } = useQuery<PollResourcesResponse | null>({
    queryKey: [POLL_RESOURCES_API_ID, resourceIds, sort],
    queryFn: () =>
      pollResources({
        resourceIds: resourceIds,
        sort,
      }),
    enabled: pollingStatus === 'RUNNING' && resourceIds.length > 0,
    refetchInterval: interval,
  })
  const pollResponseData = pollResponse?.data ?? null

  useEffect(() => {
    // We reset the polling mechanism every time the filters, globalFilters, pagination or sort changes. It will start
    // polling again after the timeout is reached.
    resetPolling()

    // This timeout is used to control when the polling mechanism will start.
    // We want to start polling only after the POLLING_INTERVAL time passes after getting the initial data from the API.
    const timeoutId = setTimeout(() => {
      startPolling()
    }, interval)

    return () => clearTimeout(timeoutId)
  }, [resourcesData])

  useEffect(() => {
    // It sets the resources cache query data with the latest data from the poll request.
    if (pollResponseData) {
      queryClient.setQueryData(resourcesQueryKey, (prevState: ResourcesResponse) => ({
        ...prevState,
        data: pollResponseData,
      }))
    }
  }, [pollResponseData])

  /**
   * This function is used to start the polling mechanism. It will only start if the polling status is not PAUSED, as we
   * can want to prevent to pass from PAUSED to RUNNING.
   *
   * In essence, the state transitions available are:
   *
   * IDLE -> RUNNING
   * RUNNING -> PAUSED
   * RUNNING -> IDLE
   * PAUSED -> IDLE
   */
  function startPolling() {
    setPollingStatus((prevState) => (prevState === 'PAUSED' ? prevState : 'RUNNING'))
  }

  function resetPolling() {
    setPollingStatus('IDLE')
  }

  function stopPolling() {
    setPollingStatus('PAUSED')
  }

  return {
    isLoading,
    resources: resourcesData,
    meta: resourcesResponse?.meta,
    error: resourcesError || pollResourcesError,
    stopPolling,
    restartPolling: resetPolling,
  }
}
