import { useMutation } from '@tanstack/react-query'

import { editRestriction } from '@/features/customer/endpoints/restrictions'
import type { EditRestrictionRequest } from '@/features/customer/types/restriction'

export type UseEditRestrictionMutationResult = ReturnType<typeof useEditRestrictionMutation>

export function useEditRestrictionMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; restriction: EditRestrictionRequest }) =>
      editRestriction(variables.customerUuid, variables.restriction),
  })

  return {
    editRestriction: mutateAsync,
    ...mutationResult,
  }
}
