import ReactGA from 'react-ga4'

import environment from '@/environment'

export function initGoogleAnalytics() {
  const trackId = environment.googleAnalyticsTrackId

  if (trackId) {
    ReactGA.initialize(trackId)
  }
}
