import { createContext, useContext, useState } from 'react'

const LOCAL_STORAGE_KEY = 'bidding.selectedCountry'

type State = {
  selectedCountry: CountryIdentifier
  updateSelectedCountry: (newSelectedCountry: CountryIdentifier) => void
}

const initialCountry = localStorage.getItem(LOCAL_STORAGE_KEY) ?? 'se'
const initialState: State = {
  selectedCountry: initialCountry as CountryIdentifier,
  updateSelectedCountry: () => {},
}

const BiddingContext = createContext<State>(initialState)

function BiddingContextProvider(props: any) {
  const [selectedCountry, setSelectedCountry] = useState<CountryIdentifier>(initialState.selectedCountry)

  function updateSelectedCountry(newSelectedCountry: CountryIdentifier) {
    setSelectedCountry(newSelectedCountry)
    localStorage.setItem(LOCAL_STORAGE_KEY, newSelectedCountry)
  }
  return <BiddingContext.Provider value={{ selectedCountry, updateSelectedCountry }} {...props} />
}

const useBiddingContext = () => useContext(BiddingContext)

export { BiddingContextProvider, useBiddingContext }
