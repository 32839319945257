import { t } from 'i18next'
import type { DateTime } from 'luxon'

export function renderDuration(
  type: 'repeating-daily' | 'non-repeating',
  endDate: DateTime,
  endTime: DateTime,
  startDate: DateTime,
  startTime: DateTime,
) {
  return type === 'repeating-daily'
    ? renderDailyDuration(endDate, endTime, startDate, startTime)
    : renderNonRepeatingDuration(endDate, endTime, startDate, startTime)
}

function renderDailyDuration(endDate: DateTime, endTime: DateTime, startDate: DateTime, startTime: DateTime) {
  const days = Math.ceil(endDate.diff(startDate, 'days').days)
  const hours = Math.ceil(endTime.diff(startTime, 'hours').hours)

  if (days < 0 || hours <= 0) {
    return t('customer_details.tabs.availability.common.form.error.invalid_duration')
  } else if (days > 0) {
    return `${days + 1} × ${hours} ` + t('customer_details.tabs.availability.common.form.dates_section.hours')
  } else {
    return `${hours} ` + t('customer_details.tabs.availability.common.form.dates_section.hours')
  }
}

function renderNonRepeatingDuration(endDate: DateTime, endTime: DateTime, startDate: DateTime, startTime: DateTime) {
  const endDateTime = endDate.set({ hour: endTime.hour, minute: endTime.minute }).startOf('minute')
  const startDateTime = startDate.set({ hour: startTime.hour, minute: startTime.minute }).startOf('minute')

  const duration = endDateTime.diff(startDateTime, ['days', 'hours'])
  let days = duration.days
  let hours = Math.ceil(duration.hours)

  if (hours === 24) {
    days += 1
    hours = 0
  }

  if ((days < 0 && hours <= 0) || hours < 0) {
    return t('customer_details.tabs.availability.common.form.error.invalid_duration')
  } else if (days > 0 && hours > 0) {
    return `${days} ${t('customer_details.tabs.availability.common.form.dates_section.days')}, ${hours} ${t('customer_details.tabs.availability.common.form.dates_section.hours')}`
  } else if (days > 0 && hours === 0) {
    return `${days} ${t('customer_details.tabs.availability.common.form.dates_section.days')}`
  } else {
    return `${hours} ` + t('customer_details.tabs.availability.common.form.dates_section.hours')
  }
}
