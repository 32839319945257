import type { Duration } from 'luxon'
import { DateTime } from 'luxon'

export default function getDuration(startedAt: string, endedAt: string): Duration {
  const startedAtDate = DateTime.fromISO(startedAt)
  const endedAtDate = DateTime.fromISO(endedAt)
  return endedAtDate.diff(startedAtDate)
}

export function convertToTimeZoneDateTime(customerTimeZone: string, dateStr: string) {
  return DateTime.fromISO(dateStr, { zone: DateTime.utc().zone }).setZone(customerTimeZone)
}

export function formatDateTimeWithLocale(
  dateTime: DateTime,
  language: string,
  formatOption: Intl.DateTimeFormatOptions = DateTime.DATE_MED,
) {
  const locale = language === 'en' ? 'en-GB' : language
  return dateTime.setLocale(locale).toLocaleString(formatOption)
}
