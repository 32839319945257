import type { MarketProgramType } from '@/types/marketProgramType'

export const MARKET_PROGRAMS: MarketProgramType[] = [
  'afrr-down',
  'afrr-up',
  'fcrd-down',
  'fcrd-up',
  'fcrn',
  'ffr-up',
  'load-cur',
  'max-price',
  'mfrr-down',
  'mfrr-up',
  'mfrrda-down',
  'mfrrda-up',
  'mfrr-rr-down',
  'mfrr-rr-up',
  'nordics-fcrd-down-dynamic',
  'nordics-fcrd-down-static',
  'nordics-fcrd-up-dynamic',
  'nordics-fcrd-up-static',
  'nordics-fcrn',
]
