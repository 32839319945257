import type { DialogProps } from '@mui/material'
import { Dialog, DialogTitle } from '@mui/material'

type CustomDialogProps = DialogProps & {
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge'
  title: string
}

function getWidth(size: CustomDialogProps['size']) {
  switch (size) {
    case 'extraSmall':
      return '444px'
    case 'small':
      return '600px'
    case 'medium':
      return '900px'
    case 'large':
      return '1200px'
    case 'extraLarge':
      return '1536px'
  }
}

/**
 * It modifies the MUI Autocomplete component to add some Sympower customizations:
 *
 * - Adds a new size prop, which provides an easy way to define a dialog width some of the pre-defined options.
 *
 */
const CustomDialog = ({ size, children, title, ...props }: CustomDialogProps) => {
  return (
    <Dialog
      {...props}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: getWidth(size ?? 'extraSmall'),
        },
      }}
      role="alertdialog"
    >
      <DialogTitle>{title}</DialogTitle>

      {children}
    </Dialog>
  )
}

export default CustomDialog
