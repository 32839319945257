import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useTranslation } from 'react-i18next'

import GridBulkActionsBar from '@/components/dataDisplay/GridBulkActionsBar'
import CustomButton from '@/components/inputs/CustomButton'
import { useIsFetchingAnyBid } from '@/features/bidding/hooks/useIsFetchingAnyBid'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends PastBidPtusSelectionToolbarProps {}
}

type PastBidPtusSelectionToolbarProps = {
  ptusSelected: number
  onCopyClick: () => void
  onSelectionClear: () => void
}

const PastBidSelectionToolbar = ({
  ptusSelected,
  onCopyClick,
  onSelectionClear,
}: Readonly<PastBidPtusSelectionToolbarProps>) => {
  const { t } = useTranslation()
  const isFetchingAnyBid = useIsFetchingAnyBid()

  return (
    <GridBulkActionsBar
      actions={
        <CustomButton
          disabled={isFetchingAnyBid}
          startIcon={<ContentCopyIcon />}
          variant="contained"
          onClick={onCopyClick}
        >
          {t('bidding.create_bid.copy_to_bid')}
        </CustomButton>
      }
      closeButtonProps={{ variant: 'icon', onClick: onSelectionClear }}
      countSelectedRows={ptusSelected}
    />
  )
}

export default PastBidSelectionToolbar
