import { FormControlLabel, Switch } from '@mui/material'
import type { GridFilterModel } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import GridSearchOutlined from '@/components/dataDisplay/GridSearchOutlined'
import GridToolbar from '@/components/dataDisplay/GridToolbar'
import { FIELD_WIDTH } from '@/constants/layout'

export const DEFAULT_DATA_GRID_CUSTOMER_CONTROLLERS_FILTER_MODEL: GridFilterModel = {
  items: [],
  quickFilterValues: [],
}

const CustomerControllersGridToolbar = () => {
  const { t } = useTranslation()

  return (
    <GridToolbar
      filters={
        <>
          <GridSearchOutlined
            label={t('common.navigation.search')}
            placeholder={t('integration_customer_details.customer_controllers.table.toolbar.search_placeholder')}
            quickFilterParser={(searchInput: string) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
            sx={{ minWidth: FIELD_WIDTH }}
          />

          <FormControlLabel
            control={<Switch />}
            label={t('integration_customer_details.customer_controllers.table.toolbar.errors_only')}
            sx={{ m: 0 }}
          />
        </>
      }
    />
  )
}

export default CustomerControllersGridToolbar
