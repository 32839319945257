import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Stack } from '@mui/material'

import CustomIconButton from '@/components/inputs/CustomIconButton'

interface ContactCardActionButtonsProps {
  viewOnly?: boolean
  clearFormButton?: { onClick: () => void }
  submitFormButton?: { onClick: () => void }
  showCommentButton?: { onClick: () => void }
  editButton?: { onClick: () => void }
  deleteButton?: { onClick: () => void }
}

export const ContactCardActionButtons = (props: ContactCardActionButtonsProps) => {
  const clearFormButton = (
    <CustomIconButton
      Icon={ClearIcon}
      aria-label="cancel"
      color={'default'}
      size="small"
      onClick={props.clearFormButton?.onClick}
    />
  )
  const submitFormButton = (
    <CustomIconButton
      Icon={CheckIcon}
      aria-label="save"
      color={'primary'}
      data-testid="save-button"
      size="small"
      onClick={props.submitFormButton?.onClick}
    />
  )

  const commentButton = (
    <CustomIconButton
      Icon={ChatBubbleOutlineIcon}
      aria-label="comment"
      color={'primary'}
      size="small"
      onClick={props.showCommentButton?.onClick}
    />
  )

  const editButton = (
    <CustomIconButton
      Icon={EditOutlinedIcon}
      aria-label="edit"
      color={'primary'}
      size="small"
      onClick={props.editButton?.onClick}
    />
  )

  const deleteButton = (
    <CustomIconButton
      Icon={DeleteOutlinedIcon}
      aria-label="delete"
      color={'error'}
      size="small"
      onClick={props.deleteButton?.onClick}
    />
  )

  return (
    <Stack direction={'column'} height={'100%'} justifyContent={'space-between'}>
      <Stack direction={'row'} justifyContent={'flex-end'} marginLeft={2}>
        {props.clearFormButton && clearFormButton}
        {props.submitFormButton && submitFormButton}
        {props.showCommentButton && commentButton}
        {!props.viewOnly && props.editButton && editButton}
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} marginLeft={2}>
        {props.deleteButton && deleteButton}
      </Stack>
    </Stack>
  )
}
