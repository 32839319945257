import ArrowBack from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function BackNavigationButton() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // The zIndex must be higher than 1201, as it's the zIndex of the AppHeader component
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: 64,
        position: 'fixed',
        top: 0,
        width: 'fit-content',
        zIndex: 9999,
      }}
    >
      <Button startIcon={<ArrowBack />} variant="text" onClick={() => navigate(-1)}>
        {t('common.navigation.back')?.toUpperCase()}
      </Button>
    </Box>
  )
}

export default BackNavigationButton
