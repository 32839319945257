import { convertToTimeZoneDateTime } from '@/utils/time'

export function getYearsBetweenDates(calculatedSince: string, calculatedUntil: string, timeZone: string) {
  const startDate = convertToTimeZoneDateTime(timeZone, calculatedSince)
  const endDate = convertToTimeZoneDateTime(timeZone, calculatedUntil)
  const startYear = startDate.year
  const endYear = endDate.year
  const years: number[] = []

  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }
  return years
}
