import { Amplify } from 'aws-amplify'
import React, { useEffect, useState } from 'react'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import environment from '@/environment'

type AmplifySetupWrapperProps = {
  children: React.ReactNode
}

function AmplifySetupWrapper({ children }: AmplifySetupWrapperProps) {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (environment.cognitoUserPoolId && environment.cognitoWebClientId) {
      const awsConfig = {
        aws_user_pools_id: environment.cognitoUserPoolId,
        aws_user_pools_web_client_id: environment.cognitoWebClientId,
      }

      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolId: awsConfig.aws_user_pools_id,
            userPoolClientId: awsConfig.aws_user_pools_web_client_id,
          },
        },
      })

      setIsLoading(false)
    }
  }, [environment.cognitoWebClientId, environment.cognitoUserPoolId])

  return isLoading ? <FullPageSpinner /> : <>{children}</>
}

export default AmplifySetupWrapper
