import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid2'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import PageHeader from '@/components/layouts/PageHeader'
import TabPanel from '@/components/layouts/TabPanel'
import { useAlertContext } from '@/contexts/AlertContext'
import ForecastChart from '@/features/bessDashboard/components/ForecastChart'
import InteractionChart from '@/features/bessDashboard/components/InteractionChart'
import {
  useActualQuery,
  usePriceForecastQuery,
  useVolumeForecastQuery,
} from '@/features/bessDashboard/hooks/usePriceForecastQuery'
import { getTimeZoneString } from '@/features/bessDashboard/utils/chart'

function BessDashboardIndex() {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  // fetch data
  const { data: priceForecastsData, isError, isLoading } = usePriceForecastQuery()
  const { data: volumeForecastsData } = useVolumeForecastQuery()
  const { data: actualValuesData } = useActualQuery()

  // handle chart view state
  const [selectedMarketProgram, setSelectedMarketProgram] = useState<string>('FCR-D DOWN')
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (isError) {
      pushAlert({
        message: t('bess_dashboard.get_forecast_generic_error_message'),
        severity: 'error',
      })
    }
  }, [isError])

  return (
    <>
      <PageHeader breadcrumbItems={[]} pageTitle="Finland hourly market forecasts for BESS" />

      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs aria-label="bidding tabs" value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
          <Tab label="Price Forecasts" />
          <Tab label="Volume Forecasts" />
          <Tab label="Price-volume interaction" />
          <Tab disabled label="Suggested Bids (coming soon)" />
        </Tabs>
      </Box>

      <MainContentContainer>
        {isLoading ? (
          <Grid container alignItems="center" direction="row" justifyContent="center" sx={{ height: '400px' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid container alignItems="center" justifyContent="space-between" marginBottom={4}>
              <Grid>
                <ToggleButtonGroup
                  exclusive
                  aria-label="forecast toggle"
                  size="small"
                  sx={{ p: 0 }}
                  value={selectedMarketProgram}
                  onChange={(_, newValue) => setSelectedMarketProgram(newValue)}
                >
                  <ToggleButton aria-label="FCR-D DOWN" value="FCR-D DOWN">
                    FCR-D DOWN
                  </ToggleButton>
                  <ToggleButton aria-label="FCR-D UP" value="FCR-D UP">
                    FCR-D UP
                  </ToggleButton>
                  <ToggleButton aria-label="FCR-N" value="FCR-N">
                    FCR-N
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid>
                <Typography color="textSecondary" variant="body2">
                  Times displayed in {getTimeZoneString()}
                </Typography>
              </Grid>
            </Grid>

            <TabPanel index={0} value={selectedTab}>
              <ForecastChart forecastValues={priceForecastsData ?? []} marketProgram={selectedMarketProgram} />
            </TabPanel>
            <TabPanel index={1} value={selectedTab}>
              <Box display="flex" flexDirection="row" gap={4} justifyContent="space-between">
                <Box flex={1}>
                  <ForecastChart
                    filterType="procured_capacity_MW"
                    forecastValues={volumeForecastsData ?? []}
                    marketProgram={selectedMarketProgram}
                  />
                </Box>
                <Box flex={1}>
                  <ForecastChart
                    filterType="offered_capacity_MW"
                    forecastValues={volumeForecastsData ?? []}
                    marketProgram={selectedMarketProgram}
                  />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel index={2} value={selectedTab}>
              <InteractionChart actuals={actualValuesData ?? []} marketProgram={selectedMarketProgram} />
            </TabPanel>
          </>
        )}
      </MainContentContainer>
    </>
  )
}

export default BessDashboardIndex
