import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { usePortfoliosQuery } from '@/features/bidding/hooks/usePortfoliosQuery'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends BidConfirmPriceToolbarProps {}
}

type BidConfirmPriceToolbarProps = {
  groupParams: GroupedBidParams
}

const BidConfirmPriceToolbar = ({ groupParams }: Readonly<BidConfirmPriceToolbarProps>) => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()

  const { activationGroups } = useActivationGroupsQuery()
  const { portfolios } = usePortfoliosQuery({ countryCode: selectedCountry })

  if (!portfolios || !activationGroups) return <FullPageSpinner />

  return (
    <Stack direction="row" marginY={2} spacing={1}>
      <LabeledTextField
        label={t('bidding.create_bid.select_portfolio')}
        text={portfolios.find((p) => p.code === groupParams.portfolioCode)!.name}
        variant="filled"
      />
      <LabeledTextField
        label={t('bidding.create_bid.select_market_program')}
        text={t(`bidding.market_program.${groupParams.marketProgram}`)}
        variant="filled"
      />
      <LabeledTextField
        label={t('bidding.results.bid_date')}
        text={groupParams.deliveryDay.getStartOfDay().toFormat('DD')}
        variant="filled"
      />
    </Stack>
  )
}

export default BidConfirmPriceToolbar
