import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type ElementKey = 'element1' | 'element2' | 'element3'
type TypeKey = 'absolute' | 'dynamic'

const SteeringRangesTooltipContent = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)

  const elements: ElementKey[] = ['element1', 'element2', 'element3']
  const types: TypeKey[] = ['absolute', 'dynamic']

  const FirstPage = () => (
    <>
      <Typography sx={{ color: 'white' }} variant="body2">
        {t('resources.steering.steering_ranges.tooltip.description')}
      </Typography>
      <List dense disablePadding>
        {elements.map((elem) => (
          <ListItem key={elem} disableGutters>
            <ListItemText
              primary={t(`resources.steering.steering_ranges.tooltip.${elem}`)}
              primaryTypographyProps={{ color: 'white', fontWeight: 'bold' }}
            />
          </ListItem>
        ))}
      </List>
      <Typography sx={{ color: 'white', mt: 4 }} variant="body2">
        {t('resources.steering.steering_ranges.tooltip.value_types')}
      </Typography>
      <List dense disablePadding>
        {types.map((type) => (
          <ListItem key={type} disableGutters>
            <ListItemText
              primary={t(`resources.steering.steering_ranges.tooltip.${type}`)}
              primaryTypographyProps={{ color: 'white', fontWeight: 'bold' }}
              secondary={t(`resources.steering.steering_ranges.tooltip.${type}_description`)}
              secondaryTypographyProps={{ color: 'white' }}
            />
          </ListItem>
        ))}
      </List>
    </>
  )

  const SecondPage = () => (
    <>
      <Typography sx={{ color: 'white' }} variant="body2">
        {t('resources.steering.steering_ranges.tooltip.time_constraints')}
      </Typography>
      <Typography sx={{ color: 'white', mt: 3 }} variant="body2">
        {t('resources.steering.steering_ranges.tooltip.step_explanation')}
      </Typography>
    </>
  )

  return (
    <Box>
      <Box sx={{ p: 2 }}>{page === 1 ? <FirstPage /> : <SecondPage />}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, px: 2 }}>
        {page === 2 && (
          <Button startIcon={<ArrowBackIcon />} sx={{ color: 'white' }} onClick={() => setPage(1)}>
            {t('common.button.previous')}
          </Button>
        )}
        {page === 1 && (
          <Button endIcon={<ArrowForwardIcon />} sx={{ color: 'white', ml: 'auto' }} onClick={() => setPage(2)}>
            {t('common.button.next')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default SteeringRangesTooltipContent
