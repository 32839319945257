import { Chip } from '@mui/material'
import type { GridCellParams, GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'

import DataGridEditableTextField from '@/components/dataDisplay/DataGridEditableTextField'
import { DEFAULT_CURRENCY } from '@/features/bidding/constants'
import type { HourlyPriceRowItem } from '@/features/bidding/utils/model/buildHourlyPriceRowItems'
import { isCapacityValid } from '@/features/bidding/utils/validation/isCapacityValid'

export const getPriceBidDataGridColumns = (
  rowItems: HourlyPriceRowItem[],
  editable: boolean,
  t: TFunction,
  locale: string,
  displayChip?: boolean,
): GridColDef<HourlyPriceRowItem>[] => [
  {
    field: 'ptuStart',
    headerName: t('bidding.create_bid.header.ptu'),
    valueFormatter: (ptuStart: DateTime) => ptuStart.toFormat('HH:mm'),
    flex: 1,
    headerClassName: 'default-header',
  },
  {
    field: 'availableMw',
    headerName: t('bidding.create_bid.header.available'),
    valueFormatter: (availableMw: number) => `${availableMw} MW`,
    flex: 1,
    headerClassName: 'default-header',
    renderCell: (params: GridRenderEditCellParams<HourlyPriceRowItem>) =>
      displayChip ? <Chip label={params.formattedValue} /> : params.formattedValue,
  },
  {
    field: 'remainingMw',
    headerName: t('bidding.create_bid.header.remaining'),
    valueFormatter: (remainingMw: number) => `${remainingMw} MW`,
    flex: 1,
    headerClassName: 'default-header',
    cellClassName: (params: GridCellParams<HourlyPriceRowItem>) => getNegativeValueClassName(params.row.remainingMw),
    renderCell: (params: GridRenderEditCellParams<HourlyPriceRowItem>) =>
      displayChip ? (
        <Chip classes={{ label: getNegativeValueClassName(params.row.remainingMw) }} label={params.formattedValue} />
      ) : (
        params.formattedValue
      ),
  },
  ...getPriceChunkColumns(rowItems, editable, locale),
]

const getPriceChunkColumns = (
  rowItems: HourlyPriceRowItem[],
  editable: boolean,
  locale: string,
): GridColDef<HourlyPriceRowItem>[] => {
  const prices = rowItems.flatMap((rowItem) => rowItem.priceCapacities.map((priceCapacity) => priceCapacity.price))
  const uniquePrices = Array.from(new Set(prices)).sort((a, b) => a - b)

  return uniquePrices.map((price, priceIndex) => {
    const formattedPrice = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: DEFAULT_CURRENCY,
    }).format(price)

    return {
      field: `price_${price}`,
      headerName: formattedPrice,
      editable: editable,
      flex: 1,
      valueFormatter: (value: number) => `${value ?? '-'} MW`,
      valueGetter: (_, row) => row.priceCapacities.find((pc) => pc.price === price)?.capacityMw,
      valueSetter: (value: number, row): HourlyPriceRowItem => ({
        ...row,
        priceCapacities: row.priceCapacities.map((priceCapacity) =>
          priceCapacity.price === price ? { ...priceCapacity, capacityMw: Number(value) } : priceCapacity,
        ),
      }),
      renderCell: (params) =>
        editable ? <DataGridEditableTextField readonlyField value={params.formattedValue} /> : undefined,
      renderEditCell: (params: GridRenderEditCellParams<HourlyPriceRowItem>) => (
        <DataGridEditableTextField value={params.value} onChange={onChange(params)} />
      ),
      headerClassName: () => getClassName(priceIndex),
      cellClassName: () => getClassName(priceIndex),
    }
  })
}

const onChange = (params: GridRenderEditCellParams<HourlyPriceRowItem>) => (value: string | undefined) => {
  if (value && !isCapacityValid(value)) return

  params.api.setEditCellValue({ ...params, value })
}

const getClassName = (priceIndex: number) => {
  if (priceIndex % 2 === 0) {
    return 'editable-column-light'
  } else {
    return 'editable-column-dark'
  }
}

const getNegativeValueClassName = (value: number) => {
  return value < 0 ? 'negative-value' : ''
}
