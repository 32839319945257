import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Divider, Stack } from '@mui/material'
import { useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { LabeledTextFieldWithTooltip } from '@/components/dataDisplay/LabeledTextField'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import CancelConfirmIconButtons from '@/features/customer/components/CancelConfirmIconButtons'
import { CustomerAddressInput } from '@/features/customer/components/companyInfo/CustomerAddressInput'
import { CustomerAddressView } from '@/features/customer/components/companyInfo/CustomerAddressView'
import EditableBox from '@/features/customer/components/EditableBox'
import EditableTextFieldController from '@/features/customer/components/EditableTextFieldController'
import { saveCustomerCompanyInfo } from '@/features/customer/endpoints/customers'
import type { Customer, CustomerCompanyInfo as CompanyInfo } from '@/features/customer/types/customer'
import type { GpsLocation } from '@/types/gpsLocation'

interface CustomerContactInfoProps {
  customer: Customer
  viewOnly?: boolean
}

export const CustomerCompanyInfo = ({ customer, viewOnly }: CustomerContactInfoProps) => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()
  const [currentCustomer, setCurrentCustomer] = useState<Customer>(customer)
  const [editMode, setEditMode] = useState(false)

  const form = useForm<Customer>({
    mode: 'onBlur',
    defaultValues: customer,
  })

  useWatch({
    control: form.control,
  })

  function onSubmit() {
    if (!currentCustomer.uuid) {
      return
    }

    const customerForm = form.getValues()
    const companyInfo: CompanyInfo = {
      name: customerForm.name,
      address: customerForm.address,
      gpsLocation: customerForm.gpsLocation,
      location: customerForm.location,
    }
    saveCustomerCompanyInfo(currentCustomer.uuid, companyInfo).then((updatedCustomer) => {
      setCurrentCustomer(updatedCustomer)
      setEditMode(false)
    })
  }

  function enterEditMode() {
    setEditMode(true)
  }

  function exitEditMode() {
    form.reset(currentCustomer)
    setEditMode(false)
  }

  function onChangeAddress(address: string, gpsLocation: GpsLocation) {
    form.setValue('address', address, { shouldTouch: true })
    form.setValue('gpsLocation', gpsLocation ?? { lat: undefined, lon: undefined }, { shouldTouch: true })
  }

  return (
    <FormProvider {...form}>
      <Stack noValidate component="form" sx={{ gap: 2 }} onReset={exitEditMode} onSubmit={form.handleSubmit(onSubmit)}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <CustomTypography fontSize={20} variant="h4">
            {t('customer_details.tabs.contact_info.company_info.title')}
          </CustomTypography>
          {!viewOnly && (
            <Box>
              <>
                {editMode ? (
                  <CancelConfirmIconButtons />
                ) : (
                  <CustomIconButton
                    Icon={EditOutlinedIcon}
                    color="primary"
                    size="small"
                    variant="solid"
                    onClick={enterEditMode}
                  />
                )}
              </>
            </Box>
          )}
        </Stack>
        <EditableBox isEditable={editMode}>
          <Stack
            direction={{
              md: 'column',
              lg: 'row',
            }}
            sx={{ justifyContent: 'space-between', flexDirection: { md: 'column', lg: 'row' }, gap: 1 }}
          >
            <EditableTextFieldController
              required
              controllerProps={{
                rules: {
                  required: t('customer_details.tabs.contact_info.company_info.form_name_required').toString(),
                },
              }}
              isEditable={editMode}
              label={t('customer_details.tabs.contact_info.company_info.name')}
              name="name"
            />

            {loggedInUser?.role !== USER_ROLES.PARTNER_ADMINISTRATORS.value && (
              <LabeledTextFieldWithTooltip
                label={t('customer_details.tabs.contact_info.company_info.symbolic_name')}
                text={customer.symbolicName}
                tooltip={t('customer_details.tabs.contact_info.company_info.symbolic_name_hint')}
              />
            )}
          </Stack>

          <Divider flexItem orientation="horizontal" style={{ marginBottom: '16px', marginTop: '16px' }} />
          {editMode ? (
            <CustomerAddressInput
              address={currentCustomer.address}
              gpsLocation={currentCustomer.gpsLocation}
              onChangeAddress={onChangeAddress}
            />
          ) : (
            <CustomerAddressView address={currentCustomer.address} gpsLocation={currentCustomer.gpsLocation} />
          )}
        </EditableBox>
      </Stack>
    </FormProvider>
  )
}
