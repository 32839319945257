import type { GridFilterOperator } from '@mui/x-data-grid'

export const OPERATOR_VALUE_HAS_ANY_OF = 'hasAnyOf'

export function getGridMultiselectHasAnyOfOperator(): GridFilterOperator {
  return {
    label: 'has any of',
    value: OPERATOR_VALUE_HAS_ANY_OF,
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value.length) {
        return null
      }

      return (values: string[]) => {
        return values.some((value) => filterItem.value.includes(value))
      }
    },
  }
}
