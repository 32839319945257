import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomIconButton from '@/components/inputs/CustomIconButton'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends ActivationGroupResourcesDataGridToolbarProps {}
}

export type ActivationGroupResourcesDataGridToolbarProps = {
  onAssignResourceToActivatinGroup: () => void
}

const ActivationGroupResourcesDataGridToolbar = ({
  onAssignResourceToActivatinGroup,
}: ActivationGroupResourcesDataGridToolbarProps) => {
  const { t } = useTranslation()

  return (
    <GridToolbar
      actions={
        <CustomIconButton
          Icon={AddOutlinedIcon}
          aria-label={t('activation_groups.assign_resource_to_activation_group_button_label')}
          color="primary"
          variant="solid"
          onClick={onAssignResourceToActivatinGroup}
        />
      }
    />
  )
}

export default ActivationGroupResourcesDataGridToolbar
