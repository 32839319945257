import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Page from '@/components/layouts/Page'
import CustomerControllersGrid from '@/features/integration/components/CustomerControllersGrid'
import { IntegrationsRouteInformation } from '@/features/integration/constants'

import { useIntegrationCustomerDetails } from './IntegrationCustomerDetails'

const CustomerControllers = () => {
  const { t } = useTranslation()
  const { customer, setPageConfig } = useIntegrationCustomerDetails()

  useEffect(() => {
    setPageConfig({
      activeTab: IntegrationsRouteInformation.CUSTOMER_CONTROLLERS.routePath,
      title: t('integration_customer_details.customer_controllers.title'),
    })
  }, [])

  if (!customer) {
    return null
  }

  return (
    <Page>
      <CustomerControllersGrid customer={customer} />
    </Page>
  )
}

export default CustomerControllers
