import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { abortPrequalification } from '@/features/activationGroup/endpoints/activationGroups'
import type { AbortPrequalification } from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseAbortPrequalificationMutationResult = Omit<
  UseMutationResult<void, Error, AbortPrequalification>,
  'mutateAsync'
> & {
  abortPrequalification: UseMutationResult<void, Error, AbortPrequalification>['mutateAsync']
}

export function useAbortPrequalificationMutation(
  options?: UseMutationOptions<void, AbortPrequalification>,
): UseAbortPrequalificationMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: AbortPrequalification) => abortPrequalification(variables),
  })

  return { abortPrequalification: mutateAsync, ...mutationResult }
}
