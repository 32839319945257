import type { GridPaginationModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_DISTURBANCES_API_ID, getDisturbances } from '@/features/disturbance/endpoints/disturbances'
import type { Disturbance, DisturbancesPage } from '@/features/disturbance/types'
import type { UseQueryOptions } from '@/types/queries'

type UseDisturbancesQueryParams = {
  pagination: GridPaginationModel
}

export type UseDisturbancesQueryResult = Omit<UseQueryResult, 'data'> & {
  disturbances: Disturbance[] | null
  currentPage: number
  totalCount: number
}

export function useDisturbancesQuery(
  params: UseDisturbancesQueryParams,
  options?: UseQueryOptions<DisturbancesPage>,
): UseDisturbancesQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryFn: () => getDisturbances(params.pagination),
    queryKey: [GET_DISTURBANCES_API_ID, params],
  })

  return {
    ...queryResult,
    disturbances: data?.disturbances ?? null,
    currentPage: data?.currentPage ?? 0,
    totalCount: data?.totalCount ?? 0,
  }
}
