import { useMutation } from '@tanstack/react-query'

import type { EditServiceRuleRequest } from '@/features/availability/types/serviceRule'
import { editServiceRule } from '@/features/customer/endpoints/serviceRules'

export type UseEditServiceRuleMutationResult = ReturnType<typeof useEditServiceRuleMutation>

export function useEditServiceRuleMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; serviceRule: EditServiceRuleRequest }) =>
      editServiceRule(variables.customerUuid, variables.serviceRule),
  })

  return {
    editServiceRule: mutateAsync,
    ...mutationResult,
  }
}
