import { Stack } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import { CustomerSelector } from '@/features/customer/components/CustomerSelector'
import { ExportCsvButton } from '@/features/customer/components/revenue/ExportCsvButton'
import type { RevenueDataRow } from '@/features/customer/types/revenue'

type CustomerRevenueAppBarProps = {
  availableYears: string[]
  yearSelection: string
  setYearSelection: Dispatch<SetStateAction<string>>
  selectedMarketProgramName: string
  revenueData: RevenueDataRow[]
  customerName: string
}

function CustomerRevenueAppBar({
  availableYears,
  yearSelection,
  setYearSelection,
  selectedMarketProgramName,
  revenueData,
  customerName,
}: Readonly<CustomerRevenueAppBarProps>) {
  const { t } = useTranslation()
  const { customerUuid } = useParams()

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={1}
      sx={{ width: '100%' }} // Add this line
    >
      <Stack direction="row" spacing={1}>
        <CustomerSelector customerUuid={customerUuid} />
        <CustomSelectField
          label={t('common.time.year')}
          options={availableYears.map((year, index) => ({
            id: index.toString(),
            label: year.toString(),
            value: year.toString(),
          }))}
          size="medium"
          value={yearSelection}
          variant="outlinedWhite"
          onChange={(event) => {
            setYearSelection(event.target.value)
          }}
        />
      </Stack>
      <ExportCsvButton
        customerName={customerName}
        data={revenueData}
        marketProgramName={selectedMarketProgramName}
        year={yearSelection}
      />
    </Stack>
  )
}

export default CustomerRevenueAppBar
