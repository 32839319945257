import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import {
  getForecastedPrices,
  getForecastedVolumes,
  getWideActuals,
} from '@/features/bessDashboard/endpoints/databricksDeltaShare'
import type { FormattedRow } from '@/features/bessDashboard/types'
import type { UseQueryOptions } from '@/types/queries'

export type UseForecastQueryResult<T> = Omit<UseQueryResult, 'data'> & {
  data: T
}

export function usePriceForecastQuery(
  options?: UseQueryOptions<FormattedRow[]>,
): UseForecastQueryResult<FormattedRow[]> {
  const { data, ...queryResult } = useQuery<any>({
    ...options,
    queryFn: getForecastedPrices,
    queryKey: ['GET_PRICE_FORECASTS'],
  })

  return {
    ...queryResult,
    data: data,
  }
}

export function useVolumeForecastQuery(
  options?: UseQueryOptions<FormattedRow[]>,
): UseForecastQueryResult<FormattedRow[]> {
  const { data, ...queryResult } = useQuery<any>({
    ...options,
    queryFn: getForecastedVolumes,
    queryKey: ['GET_VOLUME_FORECASTS'],
  })

  return {
    ...queryResult,
    data: data,
  }
}

export function useActualQuery(options?: UseQueryOptions<FormattedRow[]>): UseForecastQueryResult<FormattedRow[]> {
  const { data, ...queryResult } = useQuery<any>({
    ...options,
    queryFn: getWideActuals,
    queryKey: ['GET_ACTUAL_VOLUME_PRICE'],
  })

  return {
    ...queryResult,
    data: data,
  }
}
