import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import GenericCreatePrequalificationPatternDialogForm from '@/features/activationGroup/components/GenericCreatePrequalificationPatternDialogForm'
import { useInvalidateGenericActivationGroupPrequalificationPatternsQuery } from '@/features/activationGroup/hooks/useGenericActivationGroupPrequalificationPatternsQuery'
import { useGenericCreatePrequalificationPatternMutation } from '@/features/activationGroup/hooks/useGenericCreatePrequalificationPatternMutation'
import type { GenericCreatePrequalificationPattern } from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type GenericCreatePrequalificationPatternDialogProps = {
  activationGroupUuid: string
  open: boolean
  onClose: () => void
}

function GenericCreatePrequalificationPatternDialog({
  open,
  onClose,
  activationGroupUuid,
}: Readonly<GenericCreatePrequalificationPatternDialogProps>) {
  const { t } = useTranslation()
  const { createPrequalificationPattern, isPending } = useGenericCreatePrequalificationPatternMutation()
  const { pushAlert } = useAlertContext()
  const invalidateGenericActivationGroupPrequalificationPatternsQuery =
    useInvalidateGenericActivationGroupPrequalificationPatternsQuery()

  function handleClose() {
    onClose()
  }

  async function handleSubmit(data: GenericCreatePrequalificationPattern) {
    try {
      await createPrequalificationPattern(data)
      await invalidateGenericActivationGroupPrequalificationPatternsQuery(data.activationGroupUuid)

      pushAlert({
        message: t('activation_groups.create_prequalification_pattern_success_message'),
        severity: 'success',
      })

      onClose()
    } catch (err: unknown) {
      const error = errorHandler(err, t('activation_groups.create_prequalification_pattern_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog
      open={open}
      title={t('activation_groups.create_prequalification_pattern_dialog.title')}
      onClose={handleClose}
    >
      <GenericCreatePrequalificationPatternDialogForm
        activationGroupUuid={activationGroupUuid}
        isLoading={isPending}
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </CustomDialog>
  )
}

export default GenericCreatePrequalificationPatternDialog
