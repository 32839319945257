import { Badge, Box } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import type { Theme } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import type { ComponentProps } from 'react'

type AvatarProps = ComponentProps<typeof Avatar>

type CustomAvatarProps = Omit<AvatarProps, 'sizes' | 'color'> & {
  size?: 'small' | 'medium' | 'large'
  color?: 'default' | 'secondary'
  showBadge?: boolean
}

function getSize(size: CustomAvatarProps['size']) {
  switch (size) {
    case 'small':
      return 24
    case 'medium':
      return 32
    default:
      return 40
  }
}

function getFontSize(size: CustomAvatarProps['size']) {
  if (size === 'small') {
    return '12px'
  }

  return '20px'
}

function getBgColor(theme: Theme, color: CustomAvatarProps['color']) {
  if (color === 'secondary') {
    return theme.palette.secondary.dark
  }

  return 'default'
}

function getAvatarStyles(theme: Theme, size: CustomAvatarProps['size'], color: CustomAvatarProps['color']) {
  const avatarSize = getSize(size)
  const fontSize = getFontSize(size)
  const bgColor = getBgColor(theme, color)

  return {
    width: avatarSize,
    height: avatarSize,
    fontSize,
    backgroundColor: bgColor,
  }
}

function getBadgeStyles(size: CustomAvatarProps['size'], variant: CustomAvatarProps['variant']) {
  const badgeSize = size === 'small' ? 4 : 8
  const position = variant !== 'circular' ? 2 : undefined

  return {
    width: badgeSize,
    height: badgeSize,
    minWidth: badgeSize,
    border: '2px solid #fff',
    bottom: position,
    right: position,
  }
}

/**
 * CustomAvatar component uses MUI Avatar component and adds some Sympower custom behaviors:
 *
 * - Adds an extra prop "size" to control the size of the avatar.
 * - Adds an extra prop "color" to control the background color of the avatar, which can be "default" or "secondary".
 * - Adds an extra prop "showBadge" to show a badge on the avatar, which actually has different size depending on the
 * Avatar size.
 */
const CustomAvatar = ({ size = 'large', color = 'default', sx, showBadge, ...props }: CustomAvatarProps) => {
  const theme = useTheme()
  const avatarStyles = getAvatarStyles(theme, size, color)

  if (showBadge) {
    return (
      <Box>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          color="success"
          overlap="circular"
          sx={{
            '& .MuiBadge-badge': {
              boxSizing: 'content-box',
              borderRadius: '100px',
            },

            '& .MuiBadge-dot': {
              ...getBadgeStyles(size, props.variant),
            },
          }}
          variant="dot"
        >
          <CustomAvatar {...props} sx={{ ...sx, ...avatarStyles }} />
        </Badge>
      </Box>
    )
  }

  return <Avatar {...props} sx={{ ...sx, ...avatarStyles }} />
}

export default CustomAvatar
