import type { UserRole } from '@/constants/userRoles'
import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import type { CustomerSettingsPayload } from '@/features/customer/types/customer'

type CustomerSettingsField = keyof CustomerSettingsPayload

type ProtectedFeatureRequirements = {
  allowedLocations?: string[]
  allowedRoles?: UserRole[]
  customerSettingsField?: CustomerSettingsField
  allowedPartnerCodes?: string[]
}

const ProtectedFeature: { [key: string]: ProtectedFeatureRequirements } = {
  ACTIVATION_GROUPS: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value],
  },
  ADMINS: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value, USER_ROLES.PARTNER_ADMINISTRATORS.value],
  },
  BIDDING: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value],
  },
  DISTURBANCES: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value],
  },
  EXAMPLE_FEATURE: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value],
  },
  BESS_DASHBOARD: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value],
  },
  INTEGRATIONS: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value],
  },
  PARTNERS: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value],
  },
  RESOURCES: {
    allowedRoles: [USER_ROLES.ADMINISTRATORS.value],
  },
  CUSTOMER: {
    allowedRoles: [USER_ROLES.RESOURCE_OWNERS.value],
  },
  CUSTOMER_DETAILS: {
    allowedRoles: [
      USER_ROLES.ADMINISTRATORS.value,
      USER_ROLES.PARTNER_ADMINISTRATORS.value,
      USER_ROLES.CUSTOMER_MANAGERS.value,
    ],
  },
  REVENUES: {
    allowedRoles: [USER_ROLES.RESOURCE_OWNERS.value],
    allowedLocations: ['fi'],
    customerSettingsField: 'isFinancialVisible',
    allowedPartnerCodes: ['SYMPOWER'],
  },
}

/**
 * useHasFeatureAccess checks for location and/or role against requirements.
 *
 * In the case we do not provide a field of the protectFeature, we do not restrict it.
 * For example, if we do not pass the allowedRoles field, every role is allowed.
 */
function useHasFeatureAccess(protectedFeature: ProtectedFeatureRequirements) {
  let hasAllowedLocation = true
  let hasAllowedRole = true
  let hasAllowedCustomerSetting = true
  let hasAllowedPartnerCode = true

  const { loggedInUser } = useAuth()
  const userRole = loggedInUser?.role
  const firstAllowedRoId = loggedInUser?.allowedRoIds?.[0]
  const { customerSettings } = useCustomerSettingsQuery(
    { uuid: firstAllowedRoId! },
    {
      enabled:
        userRole === USER_ROLES.RESOURCE_OWNERS.value && !!firstAllowedRoId && !!protectedFeature.customerSettingsField,
    },
  )
  const { customer } = useCustomerQuery(
    { uuid: firstAllowedRoId },
    {
      enabled: userRole === USER_ROLES.RESOURCE_OWNERS.value && !!firstAllowedRoId,
    },
  )

  // Location
  if (
    protectedFeature.allowedLocations &&
    protectedFeature.allowedLocations.length > 0 &&
    loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value
  ) {
    const location = customer?.location ?? ''
    hasAllowedLocation = protectedFeature.allowedLocations.includes(location)
  }

  // Role
  if (protectedFeature.allowedRoles && protectedFeature.allowedRoles.length > 0) {
    hasAllowedRole = protectedFeature.allowedRoles.includes(userRole!)
  }

  // Customer settings
  if (protectedFeature.customerSettingsField) {
    const customerSetting = customerSettings?.[protectedFeature.customerSettingsField]
    if (typeof customerSetting === 'boolean') {
      hasAllowedCustomerSetting = customerSetting
    }
  }

  // Partner code (Direct or indirect)
  if (protectedFeature.allowedPartnerCodes) {
    const partnerCode = customer?.partner?.partnerCode ?? ''
    hasAllowedPartnerCode = protectedFeature.allowedPartnerCodes.includes(partnerCode)
  }
  return hasAllowedRole && hasAllowedLocation && hasAllowedCustomerSetting && hasAllowedPartnerCode
}

export { ProtectedFeature, useHasFeatureAccess }
