import type { ActivationGroup, ActivationGroupUuid } from '@/features/activationGroup/types'
import { getActivationGroupByUuid } from '@/features/activationGroup/utils/activationGroups'
import type { BidOverviewRowItem } from '@/features/bidding/components/overview/BidsOverviewDataGrid'
import type { Status } from '@/features/bidding/constants'
import type { GroupedBid } from '@/features/bidding/types/groupedBid'
import getBidAverage from '@/features/bidding/utils/calculations/getBidAverage'
import getBidTotal from '@/features/bidding/utils/calculations/getBidTotal'
import { getLatestBid } from '@/features/bidding/utils/getLatestBid'

export const buildBidOverviewRowItem = (
  groupedBid: GroupedBid,
  activationGroups: ActivationGroup[],
): BidOverviewRowItem => {
  const activationGroupUuids: ActivationGroupUuid[] = Object.keys(groupedBid.bids)
  const bids = Object.values(groupedBid.bids)

  const averageOfferedCapacity = bids.reduce((total, bid) => total + getBidAverage(bid.offeredBid), 0) / bids.length
  const averageAcceptedCapacity =
    bids.reduce((total, bid) => total + (bid.acceptedBid ? getBidAverage(bid.acceptedBid) : 0), 0) / bids.length

  const totalOfferedCapacity = bids.reduce((total, bid) => total + getBidTotal(bid.offeredBid), 0)
  const totalAcceptedCapacity = bids.reduce(
    (total, bid) => total + (bid.acceptedBid ? getBidTotal(bid.acceptedBid) : 0),
    0,
  )

  const uniqueStatuses: Status[] = [...new Set(bids.map((bid) => bid.status))]
  const latestBid = getLatestBid(bids)
  return {
    portfolio: groupedBid.portfolio,
    marketProgram: groupedBid.marketProgram,
    deliveryDay: groupedBid.deliveryDay,
    createdAt: latestBid.createdAt,
    uniqueStatuses: uniqueStatuses,
    activationGroupCodes: activationGroupUuids.map((uuid) => getActivationGroupByUuid(activationGroups, uuid)!.code),
    averageOfferedCapacity: averageOfferedCapacity,
    averageAcceptedCapacity: averageAcceptedCapacity,
    totalOfferedCapacity: totalOfferedCapacity,
    totalAcceptedCapacity: totalAcceptedCapacity,
  }
}
