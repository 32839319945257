import type { DateTime } from 'luxon'
import type { z } from 'zod'

import type {
  getAbortPrequalificationSchema,
  getAssignResourceToActivationGroupSchema,
  getCreateActivationGroupSchema,
  getCreatePrequalificationPatternSchema,
  getCreateScheduleSchema,
  getGenericCreatePrequalificationPatternSchema,
  getGenericCreateScheduleSchema,
  getGenericStartPrequalificationSchema,
  getStartPrequalificationSchema,
  getUpdateActivationGroupSchema,
} from '@/features/activationGroup/schemas'
import type { MarketProgram } from '@/features/bidding/constants'
import type { Portfolio } from '@/features/bidding/types/bid'
import type { ResourceLifecycleStage } from '@/features/resource/types'

export enum ApiVolumeRangeUnit {
  WATTS = 'WATTS',
  WATT_HOURS = 'WATT_HOURS',
}

export type ApiBiddableVolumeRange = {
  unit: ApiVolumeRangeUnit
  min: number
  max: number
}

export enum PrequalificationLane {
  PQ1 = 'PQ1',
  PQ2 = 'PQ2',
}

export enum ActivationScheduleType {
  BID = 'BID',
  PQ = 'PQ',
  DEMO = 'DEMO',
}

export type ApiActivationGroup = {
  uuid: string
  code: string
  portfolio: Portfolio
  marketProgram: MarketProgram
  biddableVolumeRange: ApiBiddableVolumeRange | null
}

export type ApiCreateActivationGroup = {
  uuid: string
  code: string
  portfolioCode: string
  marketProgram: MarketProgram
  biddableVolumeRange: ApiBiddableVolumeRange | null
  resources: ApiPartialActivationGroupResource[]
}

export type ApiUpdateActivationGroup = {
  code: string
  biddableVolumeRange: ApiBiddableVolumeRange | null
  resources: ApiPartialActivationGroupResource[]
}

export type ApiActivationGroupResource = {
  uuid: string
  activationPriority: number
  isUsedForMinCapacityPrequalification: boolean
  name: string
  // Verified capaciy in watts
  verifiedCapacity: number
  lifecycleStage: ResourceLifecycleStage
}

export type ApiPartialActivationGroupResource = {
  uuid: string
  activationPriority: number | null
  isUsedForMinCapacityPrequalification: boolean
}

export type ApiPrequalification = {
  uuid: string
  startedAt: string
  endedAt: string | null
  prequalificationLane: PrequalificationLane
  isMinCapacity: boolean
}

export type GenericApiPrequalification = {
  uuid: string
  startedAt: string
  simulationStartedAt: string | null
  simulationFinishedAt: string | null
  abortedAt: string | null
  endedAt: string | null
  portfolioCode: string
  activationTriggerChannel: number
  isMinCapacity: boolean
}

export type ApiPrequalificationPage = {
  results: ApiPrequalification[]
  currentPage: number
  totalResults: number
  totalPages: number
}

export type GenericApiPrequalificationPage = {
  results: GenericApiPrequalification[]
  currentPage: number
  totalResults: number
  totalPages: number
}

/**
 * Dates are in ISO 8601 format
 */
export type ApiCreateActivationGroupSchedule = {
  activationGroupUuid: string
  startAt: string
  endAt: string
  // Sold capacity in watts
  soldCapacity: number
}

/**
 * Dates are in ISO 8601 format
 */
export type GenericApiCreateActivationGroupSchedule = {
  activationGroupUuid: string
  activationTriggerChannel: number
  scheduleType: ActivationScheduleType
  startAt: string
  endAt: string
  volume: number
}

export type ApiStartPrequalification = {
  prequalificationPatternUuid: string
  prequalificationLane: PrequalificationLane
  isMinCapacity: boolean
}

export type ApiAbortPrequalification = {
  prequalificationLane: PrequalificationLane
}

export type GenericApiStartPrequalification = {
  prequalificationPatternUuid: string
  activationTriggerChannel: number
  isMinCapacity: boolean
}

export type GenericApiAbortPrequalification = object

export type ActivationGroupUuid = string

export enum ActivationGroupState {
  LIVE = 'LIVE',
  NOT_LIVE = 'NOT_LIVE',
}

export type ActivationGroup = {
  uuid: ActivationGroupUuid
  code: string
  portfolio: Portfolio
  marketProgram: MarketProgram
  biddableVolumeRange: ApiBiddableVolumeRange | null
  state?: ActivationGroupState
}

export type ActivationGroupSchedule = {
  uuid: string
  startAt: string
  endAt: string
  soldCapacity: number
  origin: 'LOCAL' | 'EXTERNAL'
}

export type GenericActivationGroupSchedule = {
  uuid: string
  portfolioCode: string
  activationTriggerChannel: number
  // scheduleType: 'BID' | 'PQ' | 'DEMO'
  scheduleType: ActivationScheduleType
  startAt: string
  endAt: string
  volume: number
}

export type ActivationGroupSchedulesPage = {
  activationGroupSchedules: ActivationGroupSchedule[]
  currentPage: number
  totalActivationGroupSchedules: number
}

export type GenericActivationGroupSchedulesPage = {
  activationGroupSchedules: GenericActivationGroupSchedule[]
  currentPage: number
  totalActivationGroupSchedules: number
}

export type ActivationGroupPrequalificationPattern = {
  uuid: string
  name: string
  pattern: string
  prequalifications: Prequalification[]
}

export type GenericActivationGroupPrequalificationPattern = {
  uuid: string
  name: string
  pattern: string
  prequalifications: GenericPrequalification[]
}

export type Prequalification = {
  uuid: string
  startedAt: DateTime
  endedAt: DateTime | null
  prequalificationLane: PrequalificationLane
  isMinCapacity: boolean
}

export type GenericPrequalification = {
  uuid: string
  startedAt: DateTime
  simulationStartedAt: DateTime | null
  simulationFinishedAt: DateTime | null
  abortedAt: DateTime | null
  endedAt: DateTime | null
  portfolioCode: string
  activationTriggerChannel: number
  isMinCapacity: boolean
}

export type ActivationGroupPrequalificationPatternsPage = {
  activationGroupPrequalificationPatterns: ActivationGroupPrequalificationPattern[]
  currentPage: number
  totalActivationGroupPrequalificationPatterns: number
}

export type GenericActivationGroupPrequalificationPatternsPage = {
  activationGroupPrequalificationPatterns: GenericActivationGroupPrequalificationPattern[]
  currentPage: number
  totalActivationGroupPrequalificationPatterns: number
}

export type PrequalificationsPage = {
  prequalifications: Prequalification[]
  currentPage: number
  totalCount: number
}

export type GenericPrequalificationsPage = {
  prequalifications: GenericPrequalification[]
  currentPage: number
  totalCount: number
}

export type CreatePrequalificationPattern = z.infer<ReturnType<typeof getCreatePrequalificationPatternSchema>>

export type GenericCreatePrequalificationPattern = z.infer<
  ReturnType<typeof getGenericCreatePrequalificationPatternSchema>
>

export type CreateActivationGroup = z.infer<ReturnType<typeof getCreateActivationGroupSchema>>

export type UpdateActivationGroup = z.infer<ReturnType<typeof getUpdateActivationGroupSchema>>

export type AssignResourceToActivationGroup = z.infer<ReturnType<typeof getAssignResourceToActivationGroupSchema>>

export type CreateSchedule = z.infer<ReturnType<typeof getCreateScheduleSchema>>

export type GenericCreateSchedule = z.infer<ReturnType<typeof getGenericCreateScheduleSchema>>

export type StartPrequalification = z.infer<ReturnType<typeof getStartPrequalificationSchema>>

export type AbortPrequalification = z.infer<ReturnType<typeof getAbortPrequalificationSchema>>

export type GenericStartPrequalification = z.infer<ReturnType<typeof getGenericStartPrequalificationSchema>>
