import axios from 'axios'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import PageHeader from '@/components/layouts/PageHeader'
import PublicShell from '@/components/layouts/PublicShell'
import Shell from '@/components/layouts/Shell'
import { DEFAULT_INTERFACE_LANGUAGE } from '@/constants/interfaceLanguages'
import { useAlertContext } from '@/contexts/AlertContext'
import environment from '@/environment'
import ActivationRoutes from '@/features/activation/ActivationRoutes'
import ActivationGroupRoutes from '@/features/activationGroup/ActivationGroupRoutes'
import { ProtectedRoute } from '@/features/authentication/components/ProtectedRoute'
import { ProtectedFeature } from '@/features/authentication/hooks/useHasFeatureAccess'
import Login from '@/features/authentication/pages/Login'
import BiddingRoutes from '@/features/bidding/BiddingRoutes'
import CustomersRoutes from '@/features/customer/CustomersRoutes'
import DisturbancesRoutes from '@/features/disturbance/DisturbancesRoutes'
import ExampleFeatureRoutes from '@/features/exampleFeature/ExampleFeatureRoutes'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import IntegrationsRoutes from '@/features/integration/IntegrationsRoutes'
import MeasurementsRoutes from '@/features/measurement/MeasurementsRoutes'
import PartnerRoutes from '@/features/partner/PartnerRoutes'
import ResourcesRoutes from '@/features/resource/ResourcesRoutes'
import { PrivacyPolicy } from '@/features/user/pages/PrivacyPolicy'
import { UserProfile } from '@/features/user/pages/UserProfile'
import UsersRoutes from '@/features/user/UsersRoutes'
import { LandingPage } from '@/LandingPage'

import { useAuth } from './features/authentication/contexts/AuthContext'
import LoginWithRedirect from './features/authentication/pages/LoginWithRedirect'
import BessDashboardRoutes from './features/bessDashboard/BessDashboardRoutes'
import ErrorShell from './features/error/components/ErrorShell'
import { ErrorsRouteInformation } from './features/error/constants'
import ErrorRoutes from './features/error/ErrorsRoutes'
import Error500 from './features/error/pages/Error500'
import NavigateToError from './features/error/pages/NavigateToError'
import { initGoogleAnalytics } from './features/googleAnalytics/init'
import { initSentry } from './features/sentry/init'
import { set3xxValidStatus, setDeployedVersionHeader } from './utils/axios/config'
import {
  withAuthenticationErrorInterceptor,
  withAuthorizationTokenInterceptor,
  withForbidenErrorInterceptor,
} from './utils/axios/interceptors'

function App() {
  const { isLogged, isStarting, loggedInUser, logout } = useAuth()
  const location = useLocation()
  const { i18n } = useTranslation()
  const { resetAlerts } = useAlertContext()
  const navigate = useNavigate()

  const environmentName = environment.environmentName

  useEffect(() => {
    // Google Analytics
    // We don't want Analytics to run in staging.
    if (environmentName === 'production') {
      initGoogleAnalytics()
    }

    // Sentry
    if (['staging', 'production'].includes(environmentName)) {
      initSentry(environmentName)
    }
  }, [environmentName])

  // Axios
  useEffect(() => {
    withAuthenticationErrorInterceptor(axios, handleAPIUnauthenticatedError)
    withForbidenErrorInterceptor(axios, handleAPIForbiddenError)
    withAuthorizationTokenInterceptor(axios)
    set3xxValidStatus(axios)

    if (loggedInUser?.username) {
      const deployedVersion = environment.isDevelopmentMode
        ? `development-${loggedInUser.username}`
        : environment.deployedVersion

      setDeployedVersionHeader(axios, deployedVersion)
    }
  }, [loggedInUser?.username])

  useEffect(() => {
    async function changeLanguage(userUiLanguage: string) {
      await i18n.changeLanguage(userUiLanguage)
    }

    // Set UI language to follow user preferences.
    const userUiLanguage = loggedInUser?.uiLanguage ?? DEFAULT_INTERFACE_LANGUAGE.value
    if (i18n.language !== userUiLanguage) {
      void changeLanguage(userUiLanguage)
    }
  }, [loggedInUser?.uiLanguage])

  useEffect(() => {
    // Reset alerts when the user navigates to a new page.
    resetAlerts()
  }, [location.pathname])

  // Google Analytics tracking
  useEffect(() => {
    if (environmentName === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname })
    }
  }, [environmentName, location.pathname])

  function handleAPIForbiddenError() {
    navigate(ErrorsRouteInformation[403].navigationPath)
  }

  function handleAPIUnauthenticatedError() {
    logout()
  }

  if (isStarting) {
    return <FullPageSpinner />
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorShell>
          <Error500 />
        </ErrorShell>
      }
    >
      {isLogged ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </ErrorBoundary>
  )
}

function AuthenticatedApp() {
  const { t } = useTranslation()
  const { isEnabled, isLoading, error: featureToggleError } = useFeatureToggle()
  const { error: authError } = useAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    // When redirectTo parameter exists, it's because a user tried to access to a page without being logged. This useEffect makes sure
    // that we redirect the user to the page he was trying to access after he logs in.
    if (searchParams.has('redirectTo')) {
      navigate(searchParams.get('redirectTo') as string)
    }
  }, [])

  // If there is any error when authenticating the user, we show the 500 error page
  if (authError || featureToggleError) {
    return (
      <ErrorShell>
        <Error500 />
      </ErrorShell>
    )
  }

  // When loading the feature toggle keys the first time, we want to wait until it finishes.
  // The main reason is that we need to use those feature flags to build the router. Loading the router without
  // waiting the feature flag request to be completed can end up producting unexpected behaviours.
  if (isLoading) {
    return <FullPageSpinner />
  }

  return (
    <Routes>
      <Route element={<Shell />} path="/">
        <Route index element={<LandingPage />} />
        <Route
          element={
            <>
              <PageHeader pageTitle={t('component.page_header.privacy_policy')} />
              <PrivacyPolicy />
            </>
          }
          path="/privacy-policy"
        />
        <Route
          element={
            <>
              <PageHeader pageTitle={t('component.page_header.user.user_profile')} />
              <UserProfile />
            </>
          }
          path="/profile"
        />

        {isEnabled('FLEXPORTAL_BIDDING_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.BIDDING} />}>
            <Route element={<BiddingRoutes />} path="bidding/*" />
          </Route>
        )}
        <Route element={<MeasurementsRoutes />} path="measurements/*" />

        {isEnabled('FLEXPORTAL_RESOURCES_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.RESOURCES} />}>
            <Route element={<ResourcesRoutes />} path="resources/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_CUSTOMERS_PAGE_ENABLED') && <Route element={<CustomersRoutes />} path="customers/*" />}

        {isEnabled('FLEXPORTAL_PARTNERS_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.PARTNERS} />}>
            <Route element={<PartnerRoutes />} path="partners/*" />
          </Route>
        )}

        <Route element={<ActivationRoutes />} path="activations/*" />

        <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.ADMINS} />}>
          <Route element={<UsersRoutes />} path="users/*" />
        </Route>

        {isEnabled('FLEXPORTAL_ACTIVATION_GROUPS_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.ACTIVATION_GROUPS} />}>
            <Route element={<ActivationGroupRoutes />} path="activation-groups/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_DISTURBANCES_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.DISTURBANCES} />}>
            <Route element={<DisturbancesRoutes />} path="disturbances/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_INTEGRATIONS_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.INTEGRATIONS} />}>
            <Route element={<IntegrationsRoutes />} path="integrations/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_EXAMPLE_FEATURE_ENABLED') && (
          <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.EXAMPLE_FEATURE} />}>
            <Route element={<ExampleFeatureRoutes />} path="example-feature/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_BESS_DASHBOARD_ENABLED') && (
          <Route element={<ProtectedRoute protectedFeature={ProtectedFeature.BESS_DASHBOARD} />}>
            <Route element={<BessDashboardRoutes />} path="bess-dashboard/*" />
          </Route>
        )}
      </Route>

      <Route element={<ErrorRoutes />} path="/errors/*" />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}

function UnauthenticatedApp() {
  return (
    <Routes>
      <Route element={<Login />} path="/" />
      <Route
        element={
          <PublicShell>
            <PrivacyPolicy />
          </PublicShell>
        }
        path="/privacy-policy"
      />

      <Route element={<ErrorRoutes />} path="/errors/*" />

      <Route element={<LoginWithRedirect />} path="*" />
    </Routes>
  )
}

export default App
