import type { SelectChangeEvent } from '@mui/material'
import type { TFunction } from 'i18next'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import { CountryBiddableMarketProgramMap } from '@/constants/countryBiddableMarketProgramMap'
import { FIELD_WIDTH } from '@/constants/layout'
import type { MarketProgram } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import type { MarketProgramBiddingName } from '@/features/bidding/types/bid'

type Props = { value: MarketProgramBiddingName | ''; onChange: (event: SelectChangeEvent) => void }

const MarketProgramSelector: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()

  return (
    <CustomSelectField
      showAllOption
      data-testid="market-program-selector"
      id="marketProgram"
      label={t('bidding.overview.header.market_program')}
      options={getMarketProgramOptions(t, selectedCountry)}
      sx={{ minWidth: FIELD_WIDTH }}
      value={value}
      onChange={onChange}
    />
  )
}

const getMarketProgramOptions = (t: TFunction<'translation'>, country: CountryIdentifier) =>
  CountryBiddableMarketProgramMap[country].map((market: MarketProgram) => ({
    value: market,
    id: `marketProgram-filter-item-${market}`,
    label: t(`bidding.market_program.${market}`),
  }))

export default MarketProgramSelector
