import type { InputBaseComponentProps } from '@mui/material'
import React, { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'

interface CustomIMaskInputProps {
  mask: RegExp[]
  onChange: (event: { target: { value: string } }) => void
}

const prepare = (str: string) => {
  return str.toUpperCase()
}

const CustomIMaskInput = forwardRef<
  HTMLInputElement,
  Omit<InputBaseComponentProps, 'onChange'> & CustomIMaskInputProps
>(function CustomIMaskInput({ mask, onChange, ...props }, ref) {
  return (
    <IMaskInput
      {...props}
      inputRef={ref}
      mask={mask}
      prepare={prepare}
      type="text"
      onAccept={(value: any) => onChange({ target: { value } })}
    />
  )
})

export default CustomIMaskInput
