import { alpha, Chip, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import ResourceIcon from '@/features/resource/components/ResourceIcon'
import type { ResourceType } from '@/features/resource/types'
import { getResourceTypeTranslation } from '@/features/resource/utils/resourceTypes'

type ResourceTypeChipProps = {
  resourceType: ResourceType
}

const StyledChip = styled(Chip)(({ theme }) => ({
  '&.MuiChip-root': {
    maxWidth: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.secondary.light, 0.08),
  },
  '& .MuiChip-label': {
    display: 'none',
  },
}))

function ResourceTypeChip({ resourceType }: Readonly<ResourceTypeChipProps>) {
  const { t } = useTranslation()

  return (
    <Tooltip placement="top" title={getResourceTypeTranslation(resourceType, t)}>
      <StyledChip icon={<ResourceIcon resourceType={resourceType} />} variant="filled" />
    </Tooltip>
  )
}

export default ResourceTypeChip
