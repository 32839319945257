import { CheckCircle, TableRows } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import PageHeader from '@/components/layouts/PageHeader'
import { ManualAcceptFlow } from '@/features/bidding/constants'

type Params = {
  manualAcceptFlow: ManualAcceptFlow
}

export const BidManualAcceptSuccess = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { manualAcceptFlow } = useParams<Params>()

  if (!manualAcceptFlow || !Object.values(ManualAcceptFlow).includes(manualAcceptFlow)) {
    throw new Error(`${manualAcceptFlow} is not a valid EditFlow value`)
  }

  return (
    <>
      <PageHeader
        breadcrumbItems={[{ text: t('component.page_header.bidding'), to: '/bidding' }]}
        pageTitle={t(`component.page_header.bid.manual_accept.${manualAcceptFlow}.success`)}
      />

      <MainContentContainer sx={{ textAlign: 'center' }}>
        <CheckCircle
          sx={{
            width: '100px',
            height: '100px',
            color: theme.palette.success.main,
          }}
        />
        <CustomTypography color={theme.palette.success.main} fontFamily={'Quicksand, Roboto, sans-serif'} variant="h4">
          {t(`bidding.manual_accept.save_succeeded.title`)}
        </CustomTypography>
        <CustomTypography variant="subtitle1">{t(`bidding.manual_accept.save_succeeded.subtitle`)}</CustomTypography>
      </MainContentContainer>

      <ButtonBar>
        <CustomButton component={Link} startIcon={<TableRows />} to="/bidding" variant={'outlined'}>
          {t('component.page_header.bidding')}
        </CustomButton>
      </ButtonBar>
    </>
  )
}
