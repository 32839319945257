import type { IANAZone } from 'luxon'
import { DateTime } from 'luxon'

import { WATTS_IN_MW } from '@/constants/units'
import { MARKET_TIMEZONE, Status } from '@/features/bidding/constants'
import type {
  ApiAcceptedCapacity,
  ApiBid,
  ApiBidExport,
  ApiBidHistory,
  ApiBidWithContext,
  ApiCapacityOffer,
  ApiForecast,
} from '@/features/bidding/types/api'
import type { Bid, BidPtu, Forecast, ForecastPtu } from '@/features/bidding/types/bid'
import type { BidExport } from '@/features/bidding/types/bidExports'
import type { BidHistory, BidSummary } from '@/features/bidding/types/bidHistory'
import type { GroupedBid } from '@/features/bidding/types/groupedBid'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'

const sortByPtus = (ptu1: BidPtu, ptu2: BidPtu): number => ptu1.ptu.start.valueOf() - ptu2.ptu.start.valueOf()

const toLuxonDateTime = (text: string, zone?: IANAZone) =>
  zone ? DateTime.fromISO(text, { zone: zone }) : DateTime.fromISO(text)

const toPtuWithLuxonDateTime = (ptu: { start: string; end: string }): { start: DateTime; end: DateTime } => ({
  start: toLuxonDateTime(ptu.start, MARKET_TIMEZONE),
  end: toLuxonDateTime(ptu.end, MARKET_TIMEZONE),
})

const toBidPtuWithLuxonDateTime = (
  // BidPtu with ptu start and end as string instead of DateTime
  bidPtu: Omit<BidPtu, 'ptu'> & { ptu: { start: string; end: string } },
): BidPtu => ({ ...bidPtu, ptu: toPtuWithLuxonDateTime(bidPtu.ptu) })

const bidWithLuxonDates = (bid: any): ApiBid => ({
  ...bid,
  createdAt: toLuxonDateTime(bid.createdAt),
  offeredBid: bid.offeredBid.map((bidPtu: any) => toBidPtuWithLuxonDateTime(bidPtu)).sort(sortByPtus),
  acceptedBid: bid.acceptedBid?.map((bidPtu: any) => toBidPtuWithLuxonDateTime(bidPtu)).sort(sortByPtus),
})

export const bidWithContextWithLuxonDates = (bidWithContext: any): ApiBidWithContext => ({
  context: {
    ...bidWithContext.context,
    deliveryDay: new MarketDate(bidWithContext.context.deliveryDay),
  },
  bid: bidWithLuxonDates(bidWithContext.bid),
})

export const bidHistoryWithLuxonDates = (bidHistory: any): ApiBidHistory => ({
  id: bidHistory.id,
  createdAt: toLuxonDateTime(bidHistory.createdAt),
  context: {
    ...bidHistory.context,
    deliveryDay: new MarketDate(bidHistory.context.deliveryDay),
  },
  bids: bidHistory.bids.map((bid: any) => bidWithLuxonDates(bid)),
})

export const toInternalGroupedBid = (groupedBid: any): GroupedBid => {
  const deliveryDay = new MarketDate(groupedBid.deliveryDay)

  const bids = Object.keys(groupedBid.bids).reduce((acc, activationGroupUuid) => {
    acc[activationGroupUuid] = toInternalBid({
      context: { ...groupedBid, deliveryDay, activationGroupUuid },
      bid: bidWithLuxonDates(groupedBid.bids[activationGroupUuid]),
    })
    return acc
  }, {})

  return { ...groupedBid, deliveryDay, bids }
}

export const forecastWithLuxonDates = (forecast: any): ApiForecast => ({
  id: forecast.id,
  context: { ...forecast.context, deliveryDay: new MarketDate(forecast.context.deliveryDay) },
  createdAt: toLuxonDateTime(forecast.createdAt),
  forecastPtus: forecast.forecastPtus.map((bidPtu: any) => toBidPtuWithLuxonDateTime(bidPtu)).sort(sortByPtus),
})

export const toApiCapacityOffer = (bid: Bid): ApiCapacityOffer => ({
  context: {
    portfolio: bid.portfolio,
    marketProgram: bid.marketProgram,
    deliveryDay: bid.deliveryDay.toISODate(),
    activationGroupUuid: bid.activationGroupUuid,
  },
  offeredValues: bid.offeredBid,
  status: bid.status,
})

export const toApiAcceptedCapacity = (bid: Bid): ApiAcceptedCapacity => ({
  context: {
    portfolio: bid.portfolio,
    marketProgram: bid.marketProgram,
    deliveryDay: bid.deliveryDay.toISODate(),
    activationGroupUuid: bid.activationGroupUuid,
  },
  acceptedValues: bid.acceptedBid!,
  status: bid.status,
})

export const toInternalBid = (bidWithContext: ApiBidWithContext, version?: number): Bid => ({
  ...bidWithContext.context,
  id: bidWithContext.bid.bidId,
  createdAt: bidWithContext.bid.createdAt,
  status: bidWithContext.bid.status,
  version,
  // TODO AF-689: Remove these mappings when `capacity` has been deleted from Portfolio Manager API
  offeredBid: bidWithContext.bid.offeredBid.map((bidPtu) => ({
    ptu: bidPtu.ptu,
    volume: bidPtu.volume,
    ptuChunks: bidPtu.ptuChunks,
  })),
  acceptedBid: bidWithContext.bid.acceptedBid?.map((bidPtu) => ({
    ptu: bidPtu.ptu,
    volume: bidPtu.volume,
    ptuChunks: bidPtu.ptuChunks,
  })),
})

export const toInternalForecast = (forecast: ApiForecast): Forecast => ({
  ...forecast.context,
  id: forecast.id,
  createdAt: forecast.createdAt,
  forecastPtus: roundForecast(forecast.forecastPtus),
  // TODO: Remove this unnecessary field
  status: Status.REJECTED,
})

const calculateBidHistorySummary = (bids: ApiBid[]): BidSummary => {
  // from all the versions we just take the latest one and that's the "summary" for now
  const copiedBids = [...bids]
  copiedBids.sort((bid1, bid2) => bid2.createdAt.valueOf() - bid1.createdAt.valueOf())
  const mostRecentBid = copiedBids[0]
  return {
    status: mostRecentBid.status,
    offeredBid: mostRecentBid.offeredBid,
    acceptedBid: mostRecentBid.acceptedBid,
  }
}

export const toInternalBidHistory = (bidHistory: ApiBidHistory): BidHistory => ({
  ...bidHistory.context,
  id: bidHistory.id,
  createdAt: bidHistory.createdAt,
  summary: calculateBidHistorySummary(bidHistory.bids),
  bids: bidHistory.bids
    .map((bid) => toInternalBid({ context: bidHistory.context, bid: bid }))
    .sort((bid1, bid2) => bid1.createdAt.valueOf() - bid2.createdAt.valueOf()),
})

export const toInternalBidExport = (bidExport: ApiBidExport): BidExport => ({
  ...bidExport,
  createdAt: toLuxonDateTime(bidExport.createdAt),
  deliveryDay: new MarketDate(bidExport.bidStartAt),
})

const roundForecast = (forecastPtus: ForecastPtu[]): ForecastPtu[] =>
  forecastPtus.map((forecastPtu) => ({
    ...forecastPtu,
    volume: { ...forecastPtu.volume, quantity: convertToRoundedMw(forecastPtu.volume.quantity) * WATTS_IN_MW },
  }))
