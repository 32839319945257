import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_ACTIVATION_GROUP_API, getActivationGroup } from '@/features/activationGroup/endpoints/activationGroups'
import type { ActivationGroup } from '@/features/activationGroup/types'
import type { UseQueryOptions } from '@/types/queries'

export type UseActivationGroupQueryParams = {
  activationGroupUuid: string | null
}

export type UseActivationGroupQueryResult = Omit<UseQueryResult, 'data'> & {
  activationGroup: ActivationGroup | null
}

export function useActivationGroupQuery(
  params: UseActivationGroupQueryParams,
  options?: UseQueryOptions<ActivationGroup>,
): UseActivationGroupQueryResult {
  const { data = null, ...queryResult } = useQuery<ActivationGroup>({
    ...options,
    queryFn: () => getActivationGroup(params.activationGroupUuid!),
    queryKey: [GET_ACTIVATION_GROUP_API],
    enabled: !!params.activationGroupUuid,
  })

  return {
    ...queryResult,
    activationGroup: data,
  }
}
