import { z } from 'zod'

export const ResourceSteeringDefaultsSchema = z.object({
  defaultConsumptionLevel: z.number().nullable(),
  steeringDefaultType: z.enum(['NONE', 'SET_TO_DEFAULT_LEVEL', 'RELEASE_CONTROL']),
})

const SteeringRangeEntrySchema = z
  .object({
    valueType: z.enum(['ABSOLUTE', 'DYNAMIC']),
    value: z.number().nullable(),
    minSecondsOnThisLevel: z.number().nullable(),
    maxSecondsOnThisLevel: z.number().nullable(),
  })
  .refine(
    (data) => {
      if (data.valueType === 'ABSOLUTE' && data.value === null) {
        return false
      }
      return true
    },
    {
      message: 'Value must be specified for ABSOLUTE value type',
      path: ['value'],
    },
  )

export const ResourceSteeringRangeSchema = z
  .object({
    max: SteeringRangeEntrySchema,
    min: SteeringRangeEntrySchema,
    step: SteeringRangeEntrySchema,
  })
  .refine(
    (data) => {
      return data.max.valueType === 'ABSOLUTE' || data.min.valueType === 'ABSOLUTE'
    },
    {
      message: 'At least one of Max or Min must be ABSOLUTE',
      path: ['maxmin'],
    },
  )

export type ResourceSteeringRangeSchemaType = z.infer<typeof ResourceSteeringRangeSchema> & {
  maxmin?: { message: string }
}
