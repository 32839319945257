import type { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

import type { ActivationGroup } from '@/features/activationGroup/types'
import AcceptedBidVolumePtusDataGrid from '@/features/bidding/components/manualAccept/AcceptBidVolumePtusDataGrid'
import BidManualAcceptButtonBar from '@/features/bidding/components/manualAccept/BidManualAcceptButtonBar'
import type { ManualAcceptFlow } from '@/features/bidding/constants'
import { useCalculateBidBasisQuery } from '@/features/bidding/hooks/useCalculateBidBasisQuery'
import type { BidMetaData, BidVolumes, BulkFillMode, PtuVolumes } from '@/features/bidding/types/bid'
import { combineOfferedAndForecast } from '@/features/bidding/utils/calculations/calculateBasisForNewBid'
import { calculateBulkFillCapacity } from '@/features/bidding/utils/calculations/calculateBulkFillCapacity'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import getBidMetaData from '@/features/bidding/utils/getBidMetaData'

interface BidEditFormProps {
  manualAcceptFlow: ManualAcceptFlow
  activationGroup: ActivationGroup
  deliveryDay: MarketDate
  setUnsavedChanges: (unsavedChanges: boolean) => void
}

const BidManualAcceptForm = ({
  manualAcceptFlow,
  activationGroup,
  deliveryDay,
  setUnsavedChanges,
}: BidEditFormProps) => {
  const [bidMetaData, setBidMetaData] = useState<BidMetaData>(() => getBidMetaData(deliveryDay, activationGroup))
  const [bidPtus, setBidPtus] = useState<PtuVolumes[]>([])
  const { bidBasis, isFetching: isFetchingBidBasis } = useCalculateBidBasisQuery({
    date: bidMetaData.deliveryDay,
    portfolioCode: bidMetaData.portfolio.code,
    marketProgram: bidMetaData.marketProgram,
    activationGroupUuid: bidMetaData.activationGroupUuid,
  })

  const fillAllPtus = async (bulkMode: BulkFillMode, bulkFillValue: number | undefined) => {
    if (!bulkFillValue) return

    setBidPtus((prevState) =>
      prevState.map((ptu) => {
        const acceptedCapacityWatts = calculateBulkFillCapacity(bulkMode, ptu, bulkFillValue)
        if (!acceptedCapacityWatts) return ptu

        return { ...ptu, acceptedVolume: { quantity: acceptedCapacityWatts, unit: 'WATTS' } }
      }),
    )
  }

  const processBidPtuUpdate = async (newPtu: PtuVolumes): Promise<PtuVolumes> => {
    const value = Number(newPtu.acceptedVolume?.quantity)
    const isValid = Number.isInteger(value) && value >= 0
    if (!isValid) {
      return Promise.reject(new Error('Accepted capacity value must be a positive integer'))
    }

    setBidPtus((prevState) =>
      prevState.map((prevPtu) =>
        prevPtu.ptu.start.equals(newPtu.ptu.start) ? { ...prevPtu, acceptedVolume: newPtu.acceptedVolume } : prevPtu,
      ),
    )
    setUnsavedChanges(true)
    return newPtu
  }

  useEffect(() => {
    const getCurrentAcceptedVolume = (bidBasis: BidVolumes, ptuStart: DateTime) =>
      bidBasis.acceptedBid?.find((acceptedPtu) => acceptedPtu.ptu.start.equals(ptuStart))?.volume

    const getPrefilledAcceptedVolume = (bidBasis: BidVolumes, ptuStart: DateTime) => {
      const currentAcceptedVolume = getCurrentAcceptedVolume(bidBasis, ptuStart)
      const offeredVolume = bidBasis.offeredBid.find((offeredPtu) => offeredPtu.ptu.start.equals(ptuStart))?.volume
      return currentAcceptedVolume || offeredVolume
    }

    if (bidBasis) {
      setBidMetaData({ ...bidMetaData, version: bidBasis.version })
      setBidPtus(
        combineOfferedAndForecast(bidBasis.offeredBid, bidBasis.forecast).map((ptu) => ({
          ...ptu,
          acceptedVolume: getPrefilledAcceptedVolume(bidBasis, ptu.ptu.start),
          currentAcceptedVolume: getCurrentAcceptedVolume(bidBasis, ptu.ptu.start),
        })),
      )
    }
  }, [bidBasis?.version, bidBasis?.forecast, bidBasis?.offeredBid, bidBasis?.acceptedBid])

  return (
    <div data-testid={`activation-group-bid-${bidMetaData.activationGroupUuid}`}>
      <AcceptedBidVolumePtusDataGrid
        bidMetaData={bidMetaData}
        loading={isFetchingBidBasis}
        manualAcceptFlow={manualAcceptFlow}
        processRowUpdate={processBidPtuUpdate}
        ptus={bidPtus}
        onFillAll={fillAllPtus}
      />
      <BidManualAcceptButtonBar bidMetaData={bidMetaData} manualAcceptFlow={manualAcceptFlow} ptus={bidPtus} />
    </div>
  )
}

export default BidManualAcceptForm
