import { SpeedOutlined } from '@mui/icons-material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import NumericWidget from '@/features/dashboard/components/NumericWidget'
import { NumericWidgetSkeleton } from '@/features/dashboard/components/NumericWidgetSkeleton'
import { useCurrentGridFrequencyQuery } from '@/features/dashboard/hooks/useCurrentGridFrequencyQuery'
import { usePartnersQuery } from '@/features/partner/hooks/usePartnersQuery'

export const GRID_FREQUENCY_INTERVAL = 5_000 // 5 seconds

interface GridFrequencyWidgetProps {
  location: string
}

function GridFrequencyWidget(props: Readonly<GridFrequencyWidgetProps>) {
  const { loggedInUser } = useAuth()
  const { t } = useTranslation()

  const hasCustomerUserRole = loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value
  const hasAdminRole = loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value

  const { partners } = usePartnersQuery({ enabled: !!loggedInUser?.partnerCode && !hasCustomerUserRole })
  const containsPartners = !!partners && partners.length > 0

  const { currentGridFrequency, isLoading: isGridFrequencyLoading } = useCurrentGridFrequencyQuery(
    {
      location: props.location!,
    },
    {
      enabled: (hasAdminRole || containsPartners) && !hasCustomerUserRole && !!props.location,
      refetchInterval: GRID_FREQUENCY_INTERVAL,
    },
  )

  if (isGridFrequencyLoading) {
    return <NumericWidgetSkeleton />
  }

  function formatFrequency(frequency: number | undefined) {
    if (frequency) {
      const frequencyInHz = frequency / 1000
      return `${frequencyInHz.toFixed(3)} ${t('common.units.hz')}`
    }
  }

  return (
    <NumericWidget
      disabled
      icon={<SpeedOutlined color="secondary" fontSize="large" />}
      title={t('component.dashboard.grid_frequency')}
      value={formatFrequency(currentGridFrequency?.value)}
    />
  )
}

export default GridFrequencyWidget
