import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import CustomDataGrid, { DEFAULT_FILTERS } from '@/components/dataDisplay/CustomDataGrid'
import CustomLink from '@/components/navigation/CustomLink'
import DisplayTimestampFrequencyPattern from '@/features/activationGroup/components/DisplayTimestampFrequencyPattern'
import PrequalificationPatternsDataGridToolbar from '@/features/activationGroup/components/PrequalificationPatternsDataGridToolbar'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useGenericActivationGroupPrequalificationPatternsQuery } from '@/features/activationGroup/hooks/useGenericActivationGroupPrequalificationPatternsQuery'
import type { GenericActivationGroupPrequalificationPattern } from '@/features/activationGroup/types'

import GenericCreatePrequalificationPatternDialog from './GenericCreatePrequalificationPatternDialog'

type GenericActivationGroupPatternRenderCell = GridRenderCellParams<GenericActivationGroupPrequalificationPattern>

type GenericActivationGroupPrequalificationPatternsDataGridProps = {
  activationGroupUuid: string
}

function GenericActivationGroupPrequalificationPatternsDataGrid({
  activationGroupUuid,
}: Readonly<GenericActivationGroupPrequalificationPatternsDataGridProps>) {
  const { t } = useTranslation()
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [pagination, setPagination] = useState({ pageSize: 10, page: 0 })

  const { isLoading, activationGroupPrequalificationPatterns, totalActivationGroupPrequalificationPatterns } =
    useGenericActivationGroupPrequalificationPatternsQuery({
      activationGroupUuid: activationGroupUuid ?? '',
      pagination,
    })

  const columns: GridColDef<GenericActivationGroupPrequalificationPattern>[] = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <CustomLink
          component={Link}
          to={ActivationGroupRouteInformation.ACTIVATION_GROUP_PREQUALIFICATION_PATTERN_DETAILS.navigationPath(
            activationGroupUuid,
            params.row.uuid,
          )}
        >
          {params.row.uuid}
        </CustomLink>
      ),
    },
    {
      field: 'name',
      headerName: t('activation_groups.prequalification_patterns.table.header.name'),
      flex: 1,
    },
    {
      field: 'pattern',
      headerName: t('activation_groups.prequalification_patterns.table.header.timestamp_frequency'),
      flex: 1,
      renderCell: ({ row }: GenericActivationGroupPatternRenderCell) => (
        <DisplayTimestampFrequencyPattern pattern={row.pattern} />
      ),
    },
  ]

  function handleOpenCreateDialog() {
    setOpenCreateDialog(true)
  }

  function handleCloseCreateDialog() {
    setOpenCreateDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        disableColumnSorting
        aria-label={t('activation_groups.prequalification_patterns.table.title')}
        columns={columns}
        getRowHeight={() => 'auto'}
        getRowId={(row) => row.uuid}
        initialState={DEFAULT_FILTERS}
        isLoading={isLoading}
        paginationMode="server"
        paginationModel={pagination}
        rowCount={totalActivationGroupPrequalificationPatterns}
        rows={activationGroupPrequalificationPatterns ?? []}
        slotProps={{ toolbar: { onAddNewPrequalificationPattern: handleOpenCreateDialog } }}
        slots={{ toolbar: PrequalificationPatternsDataGridToolbar }}
        onPaginationModelChange={setPagination}
      />

      <GenericCreatePrequalificationPatternDialog
        activationGroupUuid={activationGroupUuid}
        open={openCreateDialog}
        onClose={handleCloseCreateDialog}
      />
    </>
  )
}

export default GenericActivationGroupPrequalificationPatternsDataGrid
