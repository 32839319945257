import { DialogContent, DialogContentText } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'
import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { deleteUser } from '@/features/user/endpoints/users'
import type { User } from '@/features/user/types/user'
import { errorHandler } from '@/utils/errorHandler'

type DeleteUserButtonProps = {
  disabled?: boolean
  user: User
}

const DeleteUserButton = ({ disabled, user }: DeleteUserButtonProps) => {
  const { t } = useTranslation()
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false)
  const { loggedInUser } = useAuth()
  const navigate = useNavigate()
  const { pushAlert } = useAlertContext()

  function handleOpenDeleteUserDialog() {
    setShowDeleteUserDialog(true)
  }

  function handleCloseDeleteUserDialog() {
    setShowDeleteUserDialog(false)
  }

  async function handleDeleteUser() {
    try {
      if (!user.id) {
        return
      }

      if (user.id === loggedInUser?.id) {
        pushAlert({
          message: t('user_form.delete_user.errors.delete_current_user_error'),
          severity: 'error',
        })

        return
      }

      await deleteUser(user.id)

      navigate('/users')
      pushAlert({
        message: t('user_form.delete_user.success'),
        severity: 'success',
      })
    } catch (err) {
      const error = errorHandler(err, t('user_form.delete_user.errors.delete_user_error'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    } finally {
      handleCloseDeleteUserDialog()
    }
  }

  return (
    <>
      <CustomButton color={'error'} disabled={disabled} variant="outlined" onClick={handleOpenDeleteUserDialog}>
        {t('user_form.delete_user.label')}
      </CustomButton>

      <CustomDialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={showDeleteUserDialog}
        title={t('user_form.delete_user.label')}
        onClose={handleCloseDeleteUserDialog}
      >
        <DialogContent>
          <DialogContentText color={'error'} id="alert-dialog-description">
            {t('user_form.delete_user.confirmation', { username: user.username })}
          </DialogContentText>
          <br />
        </DialogContent>

        <ConfirmDialogActions onCancel={handleCloseDeleteUserDialog} onConfirm={handleDeleteUser} />
      </CustomDialog>
    </>
  )
}

export default DeleteUserButton
