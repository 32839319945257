import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-pro'
import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import MarketProgramFilter from '@/components/datagrid/MarketProgramFilter'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import type { ResourceLifecycleStage } from '@/features/resource/types'
import { getLifecycleStageOptions } from '@/features/resource/utils/lifecycleStage'
import type { MarketProgramType } from '@/types/marketProgramType'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'
import { sortOptionsAlphabetically } from '@/utils/sortOptionsAlphabetically'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends ResourceGridToolbarProps {}
}

export type ResourceGridToolbarProps = {
  lifecycleStages: ResourceLifecycleStage[]
}

const MARKET_PROGRAM_FILTER_NAME = 'marketProgram'
const LIFECYCLE_STAGE_FILTER_NAME = 'lifecycleStage'

export const DEFAULT_DATA_GRID_RESOURCE_FILTER_MODEL: GridFilterModel = {
  items: [
    {
      id: MARKET_PROGRAM_FILTER_NAME,
      field: MARKET_PROGRAM_FILTER_NAME,
      operator: 'equals',
      value: '',
    },
    {
      id: LIFECYCLE_STAGE_FILTER_NAME,
      field: LIFECYCLE_STAGE_FILTER_NAME,
      operator: 'equals',
      value: '',
    },
  ],
}

const ResourceGridToolbar = ({ lifecycleStages }: ResourceGridToolbarProps) => {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()

  const filterModel = gridFilterModelSelector(apiRef)
  const marketProgram = getFilterItemValue<MarketProgramType>(filterModel, MARKET_PROGRAM_FILTER_NAME) ?? ''
  const lifecycleStage = getFilterItemValue<ResourceLifecycleStage>(filterModel, LIFECYCLE_STAGE_FILTER_NAME) ?? ''

  const sortedLifecycleStagesOptions = useMemo(
    () => sortOptionsAlphabetically<ResourceLifecycleStage>(getLifecycleStageOptions(lifecycleStages, t)),
    [lifecycleStages],
  )

  function handleFiltersChange(item: GridFilterItem) {
    updateDataGridFilter(apiRef, item.field, item)
  }

  return (
    <GridToolbar
      filters={
        <>
          <MarketProgramFilter
            showAllOption
            value={marketProgram ?? ''}
            onChange={(event) => {
              handleFiltersChange({
                id: MARKET_PROGRAM_FILTER_NAME,
                field: MARKET_PROGRAM_FILTER_NAME,
                operator: 'equals',
                value: event.target.value,
              })
            }}
          />

          <CustomSelectField
            showAllOption
            id="lifecycle-stage"
            label={t('resources.table.filters.lifecycle_stage_label')}
            options={sortedLifecycleStagesOptions}
            sx={{ minWidth: FIELD_WIDTH }}
            value={lifecycleStage}
            onChange={(event) => {
              handleFiltersChange({
                id: LIFECYCLE_STAGE_FILTER_NAME,
                field: LIFECYCLE_STAGE_FILTER_NAME,
                operator: 'equals',
                value: event.target.value,
              })
            }}
          />
        </>
      }
    />
  )
}

export default ResourceGridToolbar
