import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Card, CardHeader, Divider, Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomIconButton from '@/components/inputs/CustomIconButton'
import CustomCardContent from '@/components/layouts/CustomCardContent'
import EditResourceModal from '@/features/resource/components/EditResourceModal'
import { RESOURCE_TYPES } from '@/features/resource/constants'
import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'
import type { Resource } from '@/features/resource/types'

type ResourceCardProps = {
  resourceUuid: string
  content?: React.ReactNode
}

type EditDialogState = {
  resource: Resource | null
  open: boolean
}

type ResourceTitlePanelProps = {
  resource: Resource
  handleEditResourceSettled: () => void
  handleEditResourceSubmit: () => void
}

export const ResourceTitlePanel = ({
  resource,
  handleEditResourceSettled,
  handleEditResourceSubmit,
}: ResourceTitlePanelProps) => {
  const { t } = useTranslation()
  const [editDialog, setEditDialog] = useState<EditDialogState>({ open: false, resource: null })

  function handleCloseDialog() {
    setEditDialog({ resource: null, open: false })
  }

  function handleEditResourceSuccess() {
    handleCloseDialog()
  }

  function handleOpenDialog(resource: Resource) {
    setEditDialog({ resource, open: true })
  }

  return (
    <CardHeader
      action={
        <>
          <CustomIconButton
            Icon={EditOutlinedIcon}
            aria-label={t('resources.table.edit_button_label')}
            color={'primary'}
            onClick={() => handleOpenDialog(resource)}
          />
          <EditResourceModal
            open={editDialog.open && editDialog.resource?.resourceID !== undefined}
            // We can assume that resource will always have an id, otherwise we should not show
            // the modal
            resourceID={editDialog.resource?.resourceID ?? ''}
            resourceName={editDialog.resource?.resourceName}
            resourceType={editDialog.resource?.resourceType}
            resourceTypeOptions={RESOURCE_TYPES}
            onClose={handleCloseDialog}
            onSettled={handleEditResourceSettled}
            onSubmit={handleEditResourceSubmit}
            onSuccess={handleEditResourceSuccess}
          />
        </>
      }
      subheader={resource.resourceName ?? t('common.unknown')}
      sx={{ paddingX: 0 }}
      title={resource.customerName ?? t('common.unknown')}
    ></CardHeader>
  )
}

const ResourceCard = ({ resourceUuid, content }: ResourceCardProps) => {
  const { resource, stopPolling, restartPolling } = useResourceWithPollingQuery({ id: resourceUuid! })

  if (resource === undefined || resource === null) {
    return null
  }

  return (
    <Card sx={{ marginY: 3 }}>
      <CustomCardContent title={resource.resourceID}>
        <Stack sx={{ flexDirection: 'column', gap: 2 }}>
          <ResourceTitlePanel
            /**
             * We restart polling when the update resource mutation is settled, which means that we restart the polling mechanism
             * independently of the mutation result (success or error)
             */
            handleEditResourceSettled={restartPolling}
            /**
             * We stop polling while the update resource mutation is running, so we wait until it finishes for activating the
             * polling again
             */
            handleEditResourceSubmit={stopPolling}
            resource={resource}
          ></ResourceTitlePanel>
          <Divider />
          {content}
        </Stack>
      </CustomCardContent>
    </Card>
  )
}

export default ResourceCard
