import { Typography } from '@mui/material'
import React from 'react'
import type { FieldErrors } from 'react-hook-form'

type ErrorMessageProps = {
  errors: FieldErrors
  name: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errors, name }) => {
  const error = errors[name]

  if (!error || !error.message) return null

  return (
    <Typography color="error" variant="caption">
      {error.message as string}
    </Typography>
  )
}

export default ErrorMessage
