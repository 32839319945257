import type { ActivationGroup } from '@/features/activationGroup/types'
import { ActivationGroupState } from '@/features/activationGroup/types'
import type { MarketProgram } from '@/features/bidding/constants'
import { Status } from '@/features/bidding/constants'
import type { Portfolio } from '@/features/bidding/types/bid'
import type { BidHistory } from '@/features/bidding/types/bidHistory'
import { getFutureDeliveryDays } from '@/features/bidding/utils/date/getFutureDeliveryDays'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

export type BiddingTodoItem = {
  deliveryDay: MarketDate
  portfolio: Portfolio
  marketProgram: MarketProgram
  activationGroups: ActivationGroup[]
  status: Status.TODO | Status.DRAFT
}

type ActivationGroupItem = {
  deliveryDay: MarketDate
  activationGroup: ActivationGroup
  status: Status.TODO | Status.DRAFT
}

export const composeBiddingTodoList = (bidHistories: BidHistory[], activationGroups: ActivationGroup[]) => {
  const activationGroupItems: ActivationGroupItem[] = []
  const deliveryDays = getFutureDeliveryDays()

  activationGroups.forEach((activationGroup) => {
    if (activationGroup.state !== ActivationGroupState.LIVE) return

    deliveryDays.forEach((deliveryDay) => {
      const existingBid = bidHistories.find(
        (history) => history.deliveryDay.equals(deliveryDay) && history.activationGroupUuid === activationGroup.uuid,
      )

      const latestVersionIsDraft = existingBid?.summary.status === Status.DRAFT
      if (!existingBid || latestVersionIsDraft) {
        activationGroupItems.push({
          deliveryDay,
          activationGroup,
          status: latestVersionIsDraft ? Status.DRAFT : Status.TODO,
        })
      }
    })
  })

  return groupActivationGroups(activationGroupItems)
}

const groupActivationGroups = (activationGroupItems: ActivationGroupItem[]): BiddingTodoItem[] =>
  activationGroupItems.reduce<BiddingTodoItem[]>((todoItems, { deliveryDay, activationGroup, status }) => {
    const existingItem = todoItems.find(
      (todoItem) =>
        todoItem.deliveryDay.equals(deliveryDay) &&
        todoItem.portfolio.code === activationGroup.portfolio.code &&
        todoItem.marketProgram === activationGroup.marketProgram &&
        !todoItem.activationGroups.some((group) => group.uuid === activationGroup.uuid),
    )

    if (existingItem) {
      existingItem.activationGroups.push(activationGroup)
    } else {
      todoItems.push({
        deliveryDay,
        portfolio: activationGroup.portfolio,
        marketProgram: activationGroup.marketProgram,
        activationGroups: [activationGroup],
        status,
      })
    }

    return todoItems
  }, [])
