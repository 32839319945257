const LOCATIONS_WITH_GRID_FREQUENCY = new Set([
  'bx1',
  'bx2',
  'bx3',
  'bx4',
  'eu',
  'no',
  'fi',
  'dk',
  'se',
  'se2',
  'se3b',
  'se4',
])

export function hasGridFrequency(location?: string): boolean {
  return LOCATIONS_WITH_GRID_FREQUENCY.has(location ?? '')
}
