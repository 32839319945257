import { WATTS_IN_KW, WATTS_IN_MW } from '@/constants/units'

export function displayValueInTheRightUnit(value: number): string {
  // Convert to kilowatts (MW)
  if (value >= WATTS_IN_MW) {
    return (value / WATTS_IN_MW).toFixed(2) + ' MW'
  }

  // Convert to kilowatts (kW)
  if (value >= WATTS_IN_KW) {
    return (value / WATTS_IN_KW).toFixed(0) + ' kW'
  }

  // Display the value as is
  return value + ' W'
}
