import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createGenericActivationGroupSchedule } from '@/features/activationGroup/endpoints/activationGroups'
import type { GenericActivationGroupSchedule, GenericCreateSchedule } from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseGenericCreateScheduleMutationResult = Omit<
  UseMutationResult<GenericActivationGroupSchedule, Error, GenericCreateSchedule>,
  'mutateAsync'
> & {
  createSchedule: UseMutationResult<GenericActivationGroupSchedule, Error, GenericCreateSchedule>['mutateAsync']
}

export function useGenericCreateScheduleMutation(
  options?: UseMutationOptions<GenericActivationGroupSchedule, GenericCreateSchedule>,
): UseGenericCreateScheduleMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: GenericCreateSchedule) => createGenericActivationGroupSchedule(variables),
  })

  return { createSchedule: mutateAsync, ...mutationResult }
}
