export const COGNITO_FIELDS = {
  USER_ID: 'sub',
  USER_IS_ACTIVE: 'custom:is_active',
  USER_NUMERAL_LANGUAGE: 'custom:numeral_language',
  USER_ROLES: 'cognito:groups',
  USER_PARTNER_CODE: 'custom:partner_code',
  USER_ALLOWED_RO_IDS: 'custom:allowed_ro_ids',
  USER_UI_LANGUAGE: 'custom:ui_language',
  USER_USERNAME: 'cognito:username',
  USER_ALLOWED_COUNTRIES: 'custom:allowed_countries',
}
