import { Outlet } from 'react-router-dom'

import { useHasFeatureAccess } from '@/features/authentication/hooks/useHasFeatureAccess'
import NavigateToError from '@/features/error/pages/NavigateToError'

function ProtectedRoute({ protectedFeature }) {
  const hasAccess = useHasFeatureAccess(protectedFeature)

  if (!hasAccess) {
    return <NavigateToError statusCode={403} />
  }

  return <Outlet />
}

export { ProtectedRoute }
