import { DateTime } from 'luxon'

import type { RevenueDataRow, RevenueTimeSeries } from '@/features/customer/types/revenue'
import { convertFromWToMW, convertToDecimalPrice } from '@/features/customer/utils/revenueUtils/formatRevenueData'

export function formatRevenueTimeSeries(timeSeries: RevenueTimeSeries | null, timeZone: string): RevenueDataRow[] {
  if (timeSeries && timeZone) {
    const revenueData: RevenueDataRow[] = []
    const capacitySeries = timeSeries.values?.[0]
    const soldCapacitySeries = timeSeries.values?.[1]
    const revenueSeries = timeSeries.values?.[2]
    const length = capacitySeries?.length
    if (capacitySeries && soldCapacitySeries && revenueSeries) {
      for (let index = 0; index < length; index += 1) {
        const timestamp = DateTime.fromISO(timeSeries.startedAt.toString(), { zone: DateTime.utc().zone })
          ?.plus({
            millisecond: timeSeries.stepSize * index,
          })
          .setZone(timeZone)
        const row = {
          id: index,
          timestamp: timestamp,
          capacity: convertFromWToMW(capacitySeries[index]),
          soldCapacity: convertFromWToMW(soldCapacitySeries[index]),
          revenue: convertToDecimalPrice(revenueSeries[index]),
        }
        revenueData.push(row)
      }
    }
    return revenueData
  }
  return []
}
