import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'

export function useErrorHandler(isError: boolean) {
  const { pushAlert } = useAlertContext()
  const { t } = useTranslation()

  useEffect(() => {
    if (isError) {
      pushAlert({
        message: t('bidding.error.unknown.text'),
        severity: 'error',
        title: t('bidding.error.unknown.title'),
      })
    }
  }, [isError])
}
