import type { DurationUnit } from 'luxon'
import { DateTime } from 'luxon'

import {
  MARKET_CLOSING_TIME,
  MARKET_CLOSING_WARNING_MINUTES_BEFORE,
  MARKET_TIMEZONE,
} from '@/features/bidding/constants'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

export enum MarketState {
  OPEN = 'OPEN',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
}

const getDurationToMarketClosing = (bidDay: MarketDate, unit: DurationUnit): number => {
  const bidClosingTime = bidDay.minus({ days: 1 }).getStartOfDay().set(MARKET_CLOSING_TIME)
  const nowInMarketTime = DateTime.now().setZone(MARKET_TIMEZONE)
  return bidClosingTime.diff(nowInMarketTime, unit).get(unit)
}

export const getStateOfMarket = (bidDay: MarketDate): MarketState => {
  const minutesUntilClosing = getDurationToMarketClosing(bidDay, 'minutes')
  if (minutesUntilClosing < 0) {
    return MarketState.CLOSED
  } else if (minutesUntilClosing < MARKET_CLOSING_WARNING_MINUTES_BEFORE) {
    return MarketState.CLOSING
  } else {
    return MarketState.OPEN
  }
}

export const getSecondsToMarketClosingWarning = (bidDay: MarketDate): number => {
  return getDurationToMarketClosing(bidDay, 'seconds') - MARKET_CLOSING_WARNING_MINUTES_BEFORE * 60
}
