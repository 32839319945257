import type { SelectChangeEvent } from '@mui/material'

import type { CustomSelectFieldProps } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'

interface SingleSelectColumnToolbarFilterProps {
  currentFilterValue: string
  field: string
  operator?: 'is' | 'contains'
  label: string
  options: string[]
  getOptionIcon?: any
  getOptionLabel?: any
  onFilterModelChange: any
  sx?: CustomSelectFieldProps<string>['sx']
}

export function SingleSelectColumnToolbarFilter({
  currentFilterValue,
  field,
  operator,
  label,
  options,
  getOptionIcon,
  getOptionLabel,
  onFilterModelChange,
  sx,
}: Readonly<SingleSelectColumnToolbarFilterProps>) {
  const handleChange = (event: SelectChangeEvent) => {
    const filterValue = event.target.value

    if (filterValue === '') {
      onFilterModelChange({
        items: [],
      })
    } else {
      onFilterModelChange({
        items: [{ id: field, field: field, operator: operator ?? 'is', value: filterValue }],
      })
    }
  }

  return (
    <CustomSelectField
      showAllOption
      id={`${field}-filter`}
      label={label}
      options={[
        ...options.map((option) => ({
          value: option,
          id: `${field}-filter-item-${option}`,
          label: getOptionLabel?.(option) ?? option,
          icon: getOptionIcon?.(option),
        })),
      ]}
      sx={{ minWidth: FIELD_WIDTH, ...sx }}
      value={currentFilterValue}
      onChange={handleChange}
    />
  )
}
