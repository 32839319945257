import axios from 'axios'
import { DateTime } from 'luxon'
import { v4 as uuidV4 } from 'uuid'

import environment from '@/environment'
import mockedMeasuredPower from '@/features/measurement/mocks/measured_power_production.json'
import type { ApiMeasuredPower, ApiResponseMeasuredPower } from '@/features/measurement/types/measuredPower'

export const GET_MEASURED_POWER_API = 'GET_POWER_MEASUREMENTS'

type GetMeasuredPowerProps = {
  startTime: DateTime
  endTime: DateTime
}

export async function getMeasuredPower({ startTime, endTime }: GetMeasuredPowerProps): Promise<ApiMeasuredPower> {
  const environmentName = environment.environmentName
  let measuredPower

  // We have the API enabled just for one customer and only in production. For this reason, we're using a mocked .json
  if (environmentName !== 'production') {
    measuredPower = mockedMeasuredPower
  } else {
    const HASOPOR_UUID = '4a6a9708-7781-4fdf-b229-8671d230d827'

    const startTimeTimestamp = startTime.toMillis()
    const endTimeTimestamp = endTime.toMillis()
    const MEASURED_POWER_API_URL = `${environment.services.insightsManagerApiUrl}/measuredPower/customer/${HASOPOR_UUID}?startTime=${startTimeTimestamp}&endTime=${endTimeTimestamp}`

    const response = await axios.get<ApiResponseMeasuredPower>(MEASURED_POWER_API_URL)
    measuredPower = response.data
  }

  const timeData =
    measuredPower?.frames?.[0].data?.[0].map((value) => {
      return DateTime.fromMillis(value).toFormat('HH:mm\nD')
    }) ?? []

  const measuredPowerSeries =
    measuredPower?.frames.map((frame) => {
      const currentController = frame.fields[1].labels?.[0]

      return {
        uuid: uuidV4(),
        name: currentController ? `${currentController.name}: ${currentController.value}` : '--',
        data: frame.data?.[1] ?? [],
      }
    }) ?? []

  return Promise.resolve({ timeData, measuredPowerSeries })
}
