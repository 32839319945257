import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, Paper, Stack, Tooltip } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomIMaskInput from '@/components/inputs/CustomIMaskInput'
import TextFieldController from '@/components/inputs/TextFieldController'
import type { CustomerSettingsFormValues } from '@/features/customer/components/settings/CustomerSettingsForm'
import { validateIban } from '@/utils/iban'

interface CustomerFinancialSettingsSectionProps {
  isFinancialInfoNeeded: boolean
}

export const CustomerFinancialSettingsSection = ({ isFinancialInfoNeeded }: CustomerFinancialSettingsSectionProps) => {
  const { t } = useTranslation()
  const { watch } = useFormContext<CustomerSettingsFormValues>()

  const ibanExists = Boolean(watch('financialInfo.iban'))
  const ibanHolderExists = Boolean(watch('financialInfo.ibanHolder'))

  const maskForIban = [
    {
      mask: 'aa00 **** **** **** **** **** **** **',
      startsWith: '',
    },
  ]

  return (
    <Box>
      <CustomTypography gutterBottom variant="h4">
        {t('customer_settings.form.financial_settings_title')}
      </CustomTypography>
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Stack noValidate component="form" maxWidth={400} spacing={2}>
          <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={2}>
            <TextFieldController
              controllerProps={{
                rules: {
                  required:
                    isFinancialInfoNeeded || ibanExists
                      ? t('customer_settings.form.financial_info.iban_holder_required_error').toString()
                      : undefined,
                },
              }}
              label={t('customer_settings.form.iban_holder_label')}
              name="financialInfo.ibanHolder"
              size="small"
            />

            <Tooltip title={t('customer_settings.form.iban_holder_hint')}>
              <HelpOutlineIcon color={'action'} />
            </Tooltip>
          </Stack>

          <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={2}>
            <TextFieldController
              InputProps={{
                inputComponent: CustomIMaskInput, // we are added the mask component here
                inputProps: {
                  mask: maskForIban,
                },
              }}
              controllerProps={{
                rules: {
                  required:
                    isFinancialInfoNeeded || ibanHolderExists
                      ? t('customer_settings.form.financial_info.iban_required_error').toString()
                      : undefined,
                  validate: (value) => {
                    if (!validateIban(value)) {
                      return t('customer_settings.form.financial_info.iban_invalid_error').toString()
                    }
                    return true
                  },
                },
              }}
              label={t('customer_settings.form.iban_label')}
              name="financialInfo.iban"
              size={'small'}
            />

            <Tooltip title={t('customer_settings.form.iban_hint')}>
              <HelpOutlineIcon color={'action'} />
            </Tooltip>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}
