import environment from '@/environment'
import { CUSTOMER, CUSTOMERS } from '@/features/integration/mocks/customers'
import type { Customer, CustomersPage } from '@/features/integration/types'

export const CUSTOMERS_API_URL = `${environment.services.integrationsManagerApiUrl}/customers`
export const GET_CUSTOMERS_API_ID = 'GET_CUSTOMERS'
export const GET_CUSTOMER_API_ID = 'GET_CUSTOMER'

// TODO: Include pagination in the API call
// const buildPagination = (pagination: GridPaginationModel) => {
//   const params = new URLSearchParams()

//   params.append('page', String(pagination.page))
//   params.append('pageSize', String(pagination.pageSize))

//   return params
// }

export async function getCustomers(): Promise<CustomersPage> {
  return Promise.resolve({
    customers: CUSTOMERS,
    currentPage: 0,
    totalCount: CUSTOMERS.length,
  })

  // TODO: Integrate with the API when it's done
  // const response = await axios.get<ApiCustomersResponse>(CUSTOMERS_API_URL, {
  //   params: buildPagination(pagination),
  // })

  // return {
  //   customers: response.data.results,
  //   currentPage: response.data.currentPage,
  //   totalCount: response.data.totalResults,
  // }
}

export async function getCustomer(): Promise<Customer> {
  return Promise.resolve(CUSTOMER)

  // TODO: Integrate with the API when it's done
  // const response = await axios.get<ApiCustomersResponse>(CUSTOMERS_API_URL, {
  //   params: buildPagination(pagination),
  // })

  // return {
  //   customers: response.data.results,
  //   currentPage: response.data.currentPage,
  //   totalCount: response.data.totalResults,
  // }
}
