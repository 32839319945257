import { customTooltipFormatter } from '@/features/bessDashboard/utils/chart'

export const COMMON_CHART_OPTIONS = {
  animation: false,
  animationDuration: 1000, // Adjust the duration as needed
  animationEasing: 'linear', // Use a linear easing function for smoother transitions
  renderer: 'canvas',
  tooltip: {
    trigger: 'axis',
    formatter: customTooltipFormatter,
  },
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: 'none',
      },
      restore: {},
      saveAsImage: {},
    },
  },
}
