import axios from 'axios'
import { DateTime } from 'luxon'

import environment from '@/environment'
import type { ActivatedResource, Activation } from '@/features/activation/types/activation'

export const GET_ACTIVATION_API_ID = 'GET_ACTIVATION'

function getCustomerApiUrl(customerUuid: string | undefined) {
  return `${environment.services.monolithGatewayManagerApiUrl}/customers/${customerUuid}`
}

export const GET_ACTIVATIONS_API_ID = 'GET_ACTIVATIONS'
export const GET_ACTIVATED_RESOURCES_API_ID = 'GET_ACTIVATED_RESOURCES'

export async function getActivations(
  customerUuid: string | undefined,
  location: string | undefined,
): Promise<Activation[]> {
  const CUSTOMER_API_URL = getCustomerApiUrl(customerUuid)
  const response = await axios.get(`${CUSTOMER_API_URL}/activations`, {
    params: {
      location,
    },
  })
  const activations = response.data

  if (!activations || activations.length === 0) {
    return []
  }

  return activations.sort(
    (a, b) =>
      (a.endedAt ? DateTime.fromISO(a.endedAt).toMillis() : 0) -
      (b.endedAt ? DateTime.fromISO(b.endedAt).toMillis() : 0),
  )
}

export async function getActivation(
  customerUuid: string,
  activationId: number,
  location?: string | undefined,
): Promise<Activation> {
  const CUSTOMER_API_URL = getCustomerApiUrl(customerUuid)
  const response = await axios.get(`${CUSTOMER_API_URL}/activation/${activationId}`, {
    params: {
      location,
    },
  })
  return response.data
}

export async function getActivatedResources(
  customerUuid?: string,
  activationId?: number,
  serviceId?: number,
  location?: string,
): Promise<ActivatedResource[]> {
  const CUSTOMER_API_URL = getCustomerApiUrl(customerUuid)
  const response = await axios.get<ActivatedResource[]>(`${CUSTOMER_API_URL}/activations/${activationId}/resources`, {
    params: {
      location,
      serviceId,
    },
  })
  const activatedResources = response.data

  if (!activatedResources || activatedResources.length === 0) {
    return []
  }

  return activatedResources.map((resource, index) => ({
    id: index,
    ...resource,
  }))
}
