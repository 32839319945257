import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import CreatePrequalificationPatternDialogForm from '@/features/activationGroup/components/CreatePrequalificationPatternDialogForm'
import { useInvalidateActivationGroupPrequalificationPatternsQuery } from '@/features/activationGroup/hooks/useActivationGroupPrequalificationPatternsQuery'
import { useCreatePrequalificationPatternMutation } from '@/features/activationGroup/hooks/useCreatePrequalificationPatternMutation'
import type { CreatePrequalificationPattern } from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type CreatePrequalificationPatternDialogProps = {
  activationGroupUuid: string
  open: boolean
  onClose: () => void
}

function CreatePrequalificationPatternDialog({
  open,
  onClose,
  activationGroupUuid,
}: Readonly<CreatePrequalificationPatternDialogProps>) {
  const { t } = useTranslation()
  const { createPrequalificationPattern, isPending } = useCreatePrequalificationPatternMutation()
  const { pushAlert } = useAlertContext()
  const invalidateActivationGroupPrequalificationPatternsQuery =
    useInvalidateActivationGroupPrequalificationPatternsQuery()

  function handleClose() {
    onClose()
  }

  async function handleSubmit(data: CreatePrequalificationPattern) {
    try {
      await createPrequalificationPattern(data)
      await invalidateActivationGroupPrequalificationPatternsQuery(data.activationGroupUuid)

      pushAlert({
        message: t('activation_groups.create_prequalification_pattern_success_message'),
        severity: 'success',
      })

      onClose()
    } catch (err: unknown) {
      const error = errorHandler(err, t('activation_groups.create_prequalification_pattern_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog
      open={open}
      title={t('activation_groups.create_prequalification_pattern_dialog.title')}
      onClose={handleClose}
    >
      <CreatePrequalificationPatternDialogForm
        activationGroupUuid={activationGroupUuid}
        isLoading={isPending}
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </CustomDialog>
  )
}

export default CreatePrequalificationPatternDialog
