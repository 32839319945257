import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

import type { ResourceLifecycleStage } from '@/features/resource/types'
import { getLifecycleStageLabel } from '@/features/resource/utils/lifecycleStage'

interface LifecycleStageChipProps {
  lifecycleStage: ResourceLifecycleStage
}

const BASE_TRANSLATION_KEY = 'component.resource_lifecycle_stage_chip'

/**
 * Displays the current resource lifecycle stage as a chip. If lifecycle stage is LIVE, a checkmark icon is displayed.
 */
const LifecycleStageChip = ({ lifecycleStage }: LifecycleStageChipProps) => {
  const { t } = useTranslation()

  return (
    <Chip
      color="primary"
      icon={
        lifecycleStage === 'LIVE' ? (
          <CheckCircleOutlinedIcon titleAccess={t(`${BASE_TRANSLATION_KEY}.live_icon`)} />
        ) : undefined
      }
      label={getLifecycleStageLabel(lifecycleStage, t)}
      sx={{ textTransform: 'uppercase' }}
      variant="outlined"
    />
  )
}

export default LifecycleStageChip
