import type { Resource } from '@/features/resource/types'

/**
 * We consider that a controller could potentially have an inaccurate measurement when:
 *
 * - It has multiple metering ports
 * - It has a single metering port, but it's not a single resource
 */
export function isMeasurementInaccurate({ meteringPorts }: Resource): boolean {
  const containsMeteringPorts = meteringPorts.length > 0
  const isSingleResource = meteringPorts.length === 1 && meteringPorts[0].isSingleResource

  return containsMeteringPorts && !isSingleResource
}
