import { isValidNumber } from 'libphonenumber-js'

// Returns true if the phone number is valid
// Returns false if the phone number is invalid
export default function validatePhone(phone: string | null, required = false): boolean {
  if (!phone && !required) {
    return true
  }
  return isValidNumber(`+${phone}`)
}
