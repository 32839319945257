import type { FC } from 'react'
import React from 'react'

import ConfirmationButton from '@/components/inputs/ConfirmationButton'
import CustomButton from '@/components/inputs/CustomButton'

type Props = {
  shouldShowConfirmation?: boolean
  children: React.ReactNode
  buttonProps: {
    disabled?: boolean
    startIcon: React.ReactNode
  }
  dialogProps: {
    title: string
    description: string
  }
  onSubmit: () => void
}

const SubmitButton: FC<Props> = ({ shouldShowConfirmation, children, buttonProps, dialogProps, onSubmit }) => {
  return shouldShowConfirmation ? (
    <ConfirmationButton buttonProps={buttonProps} dialogProps={dialogProps} onSubmit={onSubmit}>
      {children}
    </ConfirmationButton>
  ) : (
    <CustomButton {...buttonProps} variant={'contained'} onClick={onSubmit}>
      {children}
    </CustomButton>
  )
}

export default SubmitButton
