import { Divider, Skeleton, Stack } from '@mui/material'
import { randomInt } from '@mui/x-data-grid-generator'

import { FIELD_WIDTH } from '@/constants/layout'

function ActivationsChartSkeleton() {
  return (
    <Stack direction={'column'} height={500} sx={{ m: 2 }}>
      <Stack alignItems="center" direction="row" gap={4} justifyContent="flex-start">
        <Skeleton height={40} variant="rounded" width={FIELD_WIDTH} />
        <Skeleton height={40} variant="rounded" width={180} />
      </Stack>
      <Stack alignItems="flex-end" direction="row" height={400} justifyContent="center" spacing="5%">
        {Array.from({ length: 12 }, (item, i) => (
          <Skeleton key={i} height={randomInt(100, 200)} variant="rectangular" width="2%" />
        ))}
      </Stack>
      <Divider />
      <Stack alignItems="center" direction="row" height={100} justifyContent="center" spacing={2}>
        <Skeleton height={20} variant="rectangular" width={20} />
        <Skeleton sx={{ fontSize: '1rem' }} variant="text" width={130} />
        <Skeleton height={20} variant="rectangular" width={20} />
        <Skeleton sx={{ fontSize: '1rem' }} variant="text" width={130} />
      </Stack>
    </Stack>
  )
}

export default ActivationsChartSkeleton
