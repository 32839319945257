import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getAvailableRelayResources } from '@/features/customer/endpoints/availability'
import type { AvailableResource } from '@/features/customer/types/availableResource'
import type { UseQueryOptions } from '@/types/queries'

export const GET_AVAILABLE_RELAY_RESOURCES_API_ID = 'GET_AVAILABLE_RELAY_RESOURCES'

type AvailableRelayResourcesQueryParams = {
  customerUuid: string
  location: string
  marketProgramId?: number
}

export type UseAvailableRelayResourcesQueryResult = Omit<UseQueryResult, 'data'> & {
  availableRelayResources: AvailableResource[] | null
}

export function useAvailableRelayResourcesQuery(
  params: AvailableRelayResourcesQueryParams,
  options?: UseQueryOptions<AvailableResource[]>,
): UseAvailableRelayResourcesQueryResult {
  const queryFn = getAvailableRelayResources(params.customerUuid, params.marketProgramId, params.location)
  const { data = null, ...queryResults } = useQuery({
    ...options,
    queryKey: [GET_AVAILABLE_RELAY_RESOURCES_API_ID, params],
    queryFn: () => queryFn,
  })

  return {
    availableRelayResources: data,
    ...queryResults,
  }
}
