import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_PORTFOLIOS_API_ID, getPortfolios } from '@/features/bidding/endpoints/portfolios'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { Portfolio } from '@/features/bidding/types/bid'

export type UsePortfoliosQueryParams = {
  countryCode?: CountryIdentifier
}
export type UsePortfoliosQueryResult = Omit<UseQueryResult, 'data'> & {
  portfolios: Portfolio[] | null
}
export function usePortfoliosQuery(params?: UsePortfoliosQueryParams): UsePortfoliosQueryResult {
  const { data = null, ...queryResult } = useQuery({
    queryKey: [GET_PORTFOLIOS_API_ID, params],
    queryFn: () => getPortfolios(params),
    gcTime: 0,
  })

  useErrorHandler(queryResult.isError)

  return { portfolios: data, ...queryResult }
}
