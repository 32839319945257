import { ContentCopyOutlined } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { COLUMN_WITH_MEDIUM_ICON_WIDTH } from '@/constants/layout'
import { useAlertContext } from '@/contexts/AlertContext'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import BidExportFilterToolbar, {
  DEFAULT_DATA_GRID_BID_EXPORT_FILTER_MODEL,
} from '@/features/bidding/components/export/BidExportFilterToolbar'
import type { MarketProgram } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { getBidExportFile } from '@/features/bidding/endpoints/bidExports'
import { useBidExportsQuery } from '@/features/bidding/hooks/useBidExportsQuery'
import type { Volume } from '@/features/bidding/types/bid'
import type { BidExport } from '@/features/bidding/types/bidExports'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'

const CREATED_AT_FIELD = 'createdAt'

const BidExports = () => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const { pushAlert } = useAlertContext()
  const { bidExports, isLoading } = useBidExportsQuery({ countryCode: selectedCountry })

  const onCopyButtonClick = async (rowId: number) => {
    try {
      const blob = await getBidExportFile(rowId)
      const text = await blob.text()
      await navigator.clipboard.writeText(text)

      pushAlert({
        message: t('bidding.exports.file_copied_to_clipboard'),
        severity: 'success',
      })
    } catch {
      pushAlert({
        message: t('bidding.error.unknown.text'),
        severity: 'error',
        title: t('bidding.error.unknown.title'),
      })
    }
  }

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ width: '100%' }}>
            <Box display="flex" flexDirection="row" sx={{ flex: 1, justifyContent: 'left' }}>
              <CountrySelector />
            </Box>
            <ButtonBar alignment="right">
              <CustomButton
                color="inheritText"
                component={Link}
                startIcon={<AddIcon />}
                sx={{ color: 'black' }}
                to="/bidding/todo-list"
                variant="contained"
              >
                {t(`bidding.overview.add_new_bid`)}
              </CustomButton>
            </ButtonBar>
          </Box>
        }
        breadcrumbItems={[{ text: t('component.page_header.bidding'), to: '/bidding' }]}
        pageTitle={t('bidding.exports.title')}
      />

      <CustomDataGrid
        columns={getColumns(onCopyButtonClick, t)}
        initialState={{
          pagination: { paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL },
          sorting: { sortModel: [{ field: CREATED_AT_FIELD, sort: 'desc' }] },
          filter: { filterModel: DEFAULT_DATA_GRID_BID_EXPORT_FILTER_MODEL },
        }}
        isLoading={isLoading}
        rows={bidExports || []}
        slots={{ toolbar: BidExportFilterToolbar }}
      />
    </>
  )
}

const getColumns = (onCopyButtonClick: (rowId: number) => void, t: TFunction): GridColDef<BidExport>[] => [
  {
    field: CREATED_AT_FIELD,
    headerName: t('bidding.exports.header.created_at'),
    valueFormatter: (value: DateTime) => value.toFormat('dd LLL, yyyy - HH:mm'),
    flex: 1,
    filterOperators: [
      {
        value: 'equals',
        getApplyFilterFn(filterItem) {
          if (!filterItem.value) return null
          return (value: DateTime) => filterItem.value.toMillis() === value.startOf('day').toMillis()
        },
      },
    ],
  },
  {
    field: 'deliveryDay',
    headerName: t('bidding.exports.header.delivery_day'),
    valueGetter: (value: MarketDate) => value.toISODate(),
    valueFormatter: (value: string) => new MarketDate(value).getStartOfDay().toFormat('dd LLL, yyyy'),
    flex: 1,
  },
  {
    field: 'marketProgram',
    headerName: t('bidding.exports.header.market_program'),
    valueFormatter: (value: MarketProgram) => t(`bidding.market_program.${value}`),
    flex: 1,
  },
  {
    field: 'totalOfferedVolume',
    headerName: t('bidding.exports.header.capacity'),
    valueFormatter: (value: Volume) => convertToRoundedMw(value.quantity) + ' MW',
    flex: 1,
  },
  {
    field: 'actions',
    headerName: '',
    width: COLUMN_WITH_MEDIUM_ICON_WIDTH,
    sortable: false,
    renderCell: (params) => (
      <IconButton data-testid={`copy-button-${params.row.id}`} onClick={() => onCopyButtonClick(params.row.id)}>
        <ContentCopyOutlined />
      </IconButton>
    ),
  },
]

export default BidExports
