import { Box, Tab, Tabs } from '@mui/material'
import type { FC } from 'react'
import * as React from 'react'
import { useState } from 'react'

import TabPanel from '@/components/layouts/TabPanel'
import type { ActivationGroup } from '@/features/activationGroup/types'
import BidVersionPtusDataGrid from '@/features/bidding/components/BidVersionPtusDataGrid'
import type { BidHistory } from '@/features/bidding/types/bidHistory'
import { getLatestBid } from '@/features/bidding/utils/getLatestBid'

type Props = {
  bidHistories: BidHistory[]
  activationGroups: ActivationGroup[]
}
const BidConfirmTabs: FC<Props> = ({ bidHistories, activationGroups }) => {
  const [activeTab, setActiveTab] = useState(0)
  const sortedBidHistories = sortByActivationGroupCode(bidHistories, activationGroups)

  if (sortedBidHistories.length === 1) {
    return <BidVersionPtusDataGrid bidVersion={getLatestBid(sortedBidHistories[0].bids)} isLoading={false} />
  }

  return (
    <>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          style={{ minHeight: 0 }}
          value={activeTab}
          onChange={(_, newActiveTab: number) => setActiveTab(newActiveTab)}
        >
          {sortedBidHistories.map((bidHistory: BidHistory, index: number) => (
            <Tab
              key={bidHistory.activationGroupUuid}
              label={activationGroups.find((a) => a.uuid === bidHistory.activationGroupUuid)?.code}
              style={{ minHeight: 0 }}
              value={index}
            />
          ))}
        </Tabs>
      </Box>

      {sortedBidHistories.map((bidHistory, index) => (
        <TabPanel key={bidHistory.activationGroupUuid} index={index} value={activeTab}>
          <BidVersionPtusDataGrid bidVersion={getLatestBid(bidHistory.bids)} isLoading={false} />
        </TabPanel>
      ))}
    </>
  )
}

const sortByActivationGroupCode = (bidHistories: BidHistory[], activationGroups: ActivationGroup[]) =>
  bidHistories.toSorted((a, b) => {
    const activationGroup1 = activationGroups.find((ag) => ag.uuid === a.activationGroupUuid)!
    const activationGroup2 = activationGroups.find((ag) => ag.uuid === b.activationGroupUuid)!

    return activationGroup1.code.localeCompare(activationGroup2.code)
  })

export default BidConfirmTabs
