import type { ResourceType } from '@/features/resource/types'

interface ResourceIconProps {
  resourceType: ResourceType
}

/**
 * Based on a resource type, it returns the name of the icon to be displayed. All the icons
 * are stored in /public/resource-types folder.
 */
function getResourceIcon(resourceType: ResourceType): string {
  switch (resourceType) {
    case 'CEMENT_MILL':
    case 'COAL_MILL':
    case 'CONDENSER':
    case 'DEPOT_CHARGER':
    case 'ELECTRIC_FILTER':
    case 'ELECTROLYSER':
    case 'HYDROGEN_ELECTROLYSER':
    case 'GALVANISATION_POT':
    case 'HYDRO_POWER':
    case 'PUBLIC_STREET_CHARGER':
    case 'PUMP':
    case 'RAIL_SWITCH_HEATER':
    case 'RAW_MILL':
    case 'RESIDENTIAL_PUBLIC_CHARGER':
    case 'SERVER':
    case 'UNINTERRUPTIBLE_POWER_SYSTEM':
      return '/resource-types/electrical-service.svg'
    case 'BEHIND_THE_METER_BATTERY':
    case 'FRONT_OF_THE_METER_BATTERY':
      return '/resource-types/battery.svg'
    case 'COMPRESSOR':
      return '/resource-types/compressor.svg'
    case 'CYLINDER_ENGINE':
      return '/resource-types/engine.svg'
    case 'DRYER':
      return '/resource-types/dryer.svg'
    case 'ELECTRIC_BOILER':
      return '/resource-types/boiler.svg'
    case 'ELECTRIC_MOTOR':
    case 'GENERATOR':
      return '/resource-types/chp.svg'
    case 'FAN_HVAC':
    case 'WIND_TURBINE':
      return '/resource-types/windmill.svg'
    case 'FURNACE':
      return '/resource-types/furnace.svg'
    case 'GAS_ENGINE':
      return '/resource-types/gas-engine.svg'
    case 'GAS_TURBINE':
    case 'STEAM_TURBINE':
      return '/resource-types/turbine.svg'
    case 'HEATER':
      return '/resource-types/heater.svg'
    case 'HEAT_PUMP':
      return '/resource-types/heat-pump.svg'
    case 'HIGH_PERFORMANCE_COMPUTER':
      return '/resource-types/crypto-miner.svg'
    case 'LIGHT':
      return '/resource-types/light.svg'
    case 'SOLAR_PANEL':
      return '/resource-types/solar.svg'
    case 'STIRRER':
      return '/resource-types/stirrer.svg'
    case 'WOOD_PULP_REFINER':
      return '/resource-types/refiner.svg'
    default:
      return '/resource-types/unknown.svg'
  }
}

/**
 * Displays the icon of a specific kind of resource.
 */
const ResourceIcon = ({ resourceType }: ResourceIconProps) => {
  const iconUrl = getResourceIcon(resourceType)

  return <img alt={resourceType} height={24} src={iconUrl} width={24} />
}

export default ResourceIcon
