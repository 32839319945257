/**
 * Adjusts a number to the specified digit.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor#decimal_adjustment
 *
 * @param {"round" | "floor" | "ceil"} type The type of adjustment.
 * @param {number} value The number.
 * @param {number} exp The exponent (the 10 logarithm of the adjustment base).
 * @returns {number} The adjusted value.
 */
export const decimalAdjust = (type: string, value: number, exp: number): number => {
  if (!['round', 'floor', 'ceil'].includes(type.toLowerCase())) {
    throw new TypeError("The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.")
  }

  if (exp % 1 !== 0 || Number.isNaN(value)) {
    return NaN
  } else if (exp === 0) {
    return Math[type](value)
  }

  // Shift the decimal point to the right by exp
  const [magnitude, exponent = 0] = value.toString().split('e')
  const adjustedValue = Math[type](`${magnitude}e${Number(exponent) - exp}`)
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split('e')
  return Number(`${newMagnitude}e${+newExponent + exp}`)
}
