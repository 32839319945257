import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  sx?: BoxProps['sx']
}

export default function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props

  return (
    <div aria-labelledby={`tab-${index}`} hidden={value !== index} id={`tabpanel-${index}`} role="tabpanel" {...other}>
      {value === index && <Box sx={props.sx}>{children}</Box>}
    </div>
  )
}
