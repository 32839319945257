import type { GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid-pro'

/**
 * Converts a GridSortModel into a key-value object that can be used as URLSearchParams
 */
export function serializeSortModelToSearchParamsObject(sortModel: GridSortModel): Record<string, string> {
  const sortParams: Record<string, string> = sortModel.reduce((acc, sortItem) => {
    return { ...acc, [`${sortItem.field}.sort`]: sortItem.sort }
  }, {})

  return sortParams
}

/**
 * Converts URLSearchParams into a valid GridSortModel
 *
 * When a sort is not present in the search params, we apply the initial state value
 */
export function deserializeSortModelFromSearchParams(): GridSortModel | null {
  const searchParams = new URLSearchParams(window.location.search)
  const sortFilterModelFromSearchParams = Array.from(searchParams.entries())
    .filter(([sortKey]) => {
      const [, operator] = sortKey.split('.')

      return operator && operator === 'sort'
    })
    .reduce((acc, [sortKey, sortDirection]) => {
      const [field] = sortKey.split('.')

      const sortItem: GridSortItem = { field, sort: sortDirection as GridSortDirection }

      return [...acc, sortItem]
    }, [] as GridSortModel)

  return sortFilterModelFromSearchParams.length > 0 ? sortFilterModelFromSearchParams : null
}

export function deserializeSortSearchParamsObjectFromSearchParams(): Record<string, string> {
  const searchParams = new URLSearchParams(window.location.search)

  return Array.from(searchParams.entries())
    .filter(([sortKey]) => {
      const [, operator] = sortKey.split('.')

      return operator && operator === 'sort'
    })
    .reduce((acc, [sortKey, sortDirection]) => {
      const [field] = sortKey.split('.')

      return { ...acc, [`${field}.sort`]: sortDirection }
    }, {})
}
