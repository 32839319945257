import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import ActivatedResourcesGrid from '@/features/disturbance/components/ActivatedResourcesGrid'

const DisturbanceActivatedResources = () => {
  const { t } = useTranslation()
  const { uuid } = useParams()

  if (!uuid) {
    // TODO: Render not found error page
    return null
  }

  return (
    <>
      <PageHeader
        breadcrumbItems={[{ text: t('component.page_header.disturbances'), to: '/disturbances' }, { text: uuid }]}
        pageTitle={t('component.page_header.disturbance_activated_resources')}
      />

      <ActivatedResourcesGrid disturbanceUuid={uuid} />
    </>
  )
}

export default DisturbanceActivatedResources
