import type { FC } from 'react'
import { useState } from 'react'

import BidVersionPtusDataGrid from '@/features/bidding/components/BidVersionPtusDataGrid'
import BidVersionSelector from '@/features/bidding/components/bidView/BidVersionSelector'
import type { Bid } from '@/features/bidding/types/bid'
import type { BidHistory } from '@/features/bidding/types/bidHistory'
import findAcceptedBidBeforeBuyback from '@/features/bidding/utils/findAcceptedBidBeforeBuyback'

type Props = {
  bidHistory: BidHistory
}

export const BidVersionsView: FC<Props> = ({ bidHistory }) => {
  const [selectedVersion, setSelectedVersion] = useState<Bid>(getLatestVersion(bidHistory.bids))

  return (
    <BidVersionPtusDataGrid
      bidSelectorComponent={
        <BidVersionSelector
          bidVersions={bidHistory.bids}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
        />
      }
      bidVersion={selectedVersion}
      boughtBackVersion={findAcceptedBidBeforeBuyback(selectedVersion, bidHistory.bids)}
      isLoading={false}
    />
  )
}

const getLatestVersion = (bidVersions: Bid[]): Bid =>
  [...bidVersions].sort((bid1, bid2) => bid2.createdAt.valueOf() - bid1.createdAt.valueOf())[0]
