import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { UserProfileForm } from '@/features/user/components/UserProfileForm'
import { useUserQuery } from '@/features/user/hooks/useUserQuery'

export const UserProfile = () => {
  const { loggedInUser } = useAuth()
  const { t } = useTranslation()
  const userId = loggedInUser?.id as string
  const { user } = useUserQuery({ id: userId }, { enabled: loggedInUser !== null })

  return (
    <MainContentContainer>
      <CustomTypography variant={'h5'}>{t('user_profile.settings.title')}</CustomTypography>
      <Grid container>
        <Grid item lg={6} xs={12}>
          {user && <UserProfileForm user={user} />}
        </Grid>
      </Grid>
    </MainContentContainer>
  )
}
