import axios from 'axios'
import type { DateTime } from 'luxon'

import { CUSTOMERS_API_URL } from '@/features/customer/endpoints/customers'
import type { RevenuePeriod, RevenueTimeSeries } from '@/features/customer/types/revenue'

export const GET_REVENUE_TIME_SERIES_API_ID = 'GET_REVENUE_TIME_SERIES'
export const GET_REVENUE_PERIODS_API_ID = 'GET_REVENUE_PERIODS'

export async function getCustomerRevenuePeriod(
  customerUuid: string,
  location: string | undefined,
): Promise<RevenuePeriod[]> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/revenues/period`, {
    params: {
      location,
    },
  })
  return response.data
}

export async function getCustomerRevenueTimeSeries(
  customerUuid: string,
  serviceId: number,
  startTime: DateTime,
  endTime: DateTime,
  location: string | undefined,
): Promise<RevenueTimeSeries> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/revenues`, {
    params: {
      serviceId,
      startTime,
      endTime,
      location,
    },
  })
  return response.data
}
