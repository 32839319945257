import { capitalize, CardHeader, Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DateTime } from 'luxon'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import ResourceCard from '@/features/resource/components/ResourceCard'
import ResourceEstimatedPower from '@/features/resource/components/ResourceEstimatedPower'
import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'
import type { ControlPort, MeteringPort, Resource } from '@/features/resource/types'
import { getControlBoxTypeLabel } from '@/features/resource/utils/controlBoxTypes'
import { getControlTypeLabel } from '@/features/resource/utils/controlType'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

const LABEL_COLOR = '#9e9e9e'

type ResourceGeneralCardProps = {
  resourceUuid: string
}

const StyledResourceDetailRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const StyledResourceDetailHeaderRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))
const ResourceDetailTable = ({ resource }: { resource: Resource }) => {
  const { t } = useTranslation()
  const columns = useMemo(() => {
    return [
      t('resources.detail_panel.uuid'),
      t('resources.detail_panel.markets'),
      t('resources.detail_panel.verified_capacity'),
      t('resources.detail_panel.estimated_power'),
      t('resources.detail_panel.lifecycle_stage'),
      t('resources.detail_panel.status'),
      t('resources.detail_panel.resource_type'),
    ]
  }, [t])

  return (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={t('resources.detail_panel.general_information')}></CardHeader>
      <Table aria-label={t('resources.detail_panel.general_information')} size={'small'}>
        <TableHead>
          <StyledResourceDetailHeaderRow>
            {columns.map((column) => (
              <TableCell key={column}>
                <CustomTypography color={LABEL_COLOR} variant="caption">
                  {column}
                </CustomTypography>
              </TableCell>
            ))}
          </StyledResourceDetailHeaderRow>
        </TableHead>
        <TableBody>
          <StyledResourceDetailRow>
            <TableCell>{resource.resourceID}</TableCell>
            <TableCell>
              <CustomTypography sx={{ lineHeight: 'inherit' }} variant="inherit">
                {resource.marketPrograms.map((marketProgram) => t(`common.market_program.${marketProgram}`)).join(', ')}
              </CustomTypography>
            </TableCell>
            <TableCell>
              {resource.verifiedCapacity
                ? convertEnergyPower(resource.verifiedCapacity, 'kilowatts')
                : t('common.not_set')}
            </TableCell>
            <TableCell>
              <ResourceEstimatedPower resource={resource} typographyVariant={'inherit'} />
            </TableCell>
            <TableCell>
              {resource.lifecycleStage !== null
                ? capitalize(resource.lifecycleStage.toLowerCase())
                : t('common.not_set')}
            </TableCell>
            <TableCell>
              {resource.status !== null ? capitalize(resource.status.toLowerCase()) : t('common.not_set')}
            </TableCell>
            <TableCell>
              {resource.resourceType !== null ? capitalize(resource.resourceType.toLowerCase()) : t('common.not_set')}
            </TableCell>
          </StyledResourceDetailRow>
        </TableBody>
      </Table>
    </>
  )
}

const StyledMultiLineErrorsCell = styled(TableCell)(() => ({
  '&.MuiTableCell-root': {
    whiteSpace: 'pre-wrap',
  },
}))

const ResourcePortsTable = ({
  rows,
  columns,
  ariaLabel,
  title,
}: {
  rows: ReactNode
  columns: string[]
  ariaLabel: string
  title: string
}) => {
  return (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={title}></CardHeader>
      <Table aria-label={ariaLabel} size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  )
}

const ResourceControlPortsTable = ({ controlPorts }: { controlPorts: ControlPort[] }) => {
  const { t } = useTranslation()
  const columns = useMemo(() => {
    return [
      t('resources.detail_panel.controllers_header'),
      t('resources.detail_panel.ports_header'),
      t('resources.detail_panel.type_header'),
      t('resources.detail_panel.power_meters_header'),
      t('resources.detail_panel.errors_header'),
    ]
  }, [t])

  return (
    <ResourcePortsTable
      ariaLabel={t('resources.detail_panel.control_ports_table_label')}
      columns={columns}
      rows={
        <>
          {controlPorts.map((controlPort) => (
            <TableRow key={controlPort.controlPortID}>
              <TableCell>
                {controlPort.controlPortID} ({getControlBoxTypeLabel(controlPort.controlBoxType, t)})
              </TableCell>
              <TableCell>{controlPort.ports}</TableCell>
              <TableCell>{getControlTypeLabel(controlPort.category, t)}</TableCell>
              <TableCell>{controlPort.powerMeterID}</TableCell>
              <StyledMultiLineErrorsCell>{controlPort.errors ?? '-'}</StyledMultiLineErrorsCell>
            </TableRow>
          ))}
        </>
      }
      title={t('resources.detail_panel.control_ports_header')}
    />
  )
}

const ResourceMeteringPortsTable = ({ meteringPorts }: { meteringPorts: MeteringPort[] }) => {
  const { t } = useTranslation()
  const columns = useMemo(() => {
    return [
      t('resources.detail_panel.controllers_header'),
      t('resources.detail_panel.measuring_header'),
      t('resources.detail_panel.last_update_header'),
      t('resources.detail_panel.errors_header'),
    ]
  }, [t])

  return (
    <ResourcePortsTable
      ariaLabel={t('resources.detail_panel.metering_ports_table_label')}
      columns={columns}
      rows={
        <>
          {meteringPorts.map((meteringPort) => (
            <TableRow key={meteringPort.powerMeterID}>
              <TableCell>
                {meteringPort.powerMeterID} {meteringPort.controllerName && `(${meteringPort.controllerName})`}
              </TableCell>
              <TableCell>
                {meteringPort.isSingleResource
                  ? t('resources.detail_panel.single_resource')
                  : t('resources.detail_panel.multiple_resources')}
              </TableCell>
              <TableCell>
                {meteringPort.lastUpdate ? DateTime.fromISO(meteringPort.lastUpdate).toFormat('dd MMMM, HH:mm') : '-'}
              </TableCell>
              <StyledMultiLineErrorsCell>{meteringPort.errors ?? '-'}</StyledMultiLineErrorsCell>
            </TableRow>
          ))}
        </>
      }
      title={t('resources.detail_panel.metering_ports_header')}
    />
  )
}

const ResourceGeneralCard = ({ resourceUuid }: ResourceGeneralCardProps) => {
  const { resource } = useResourceWithPollingQuery({ id: resourceUuid! })

  if (resource === undefined || resource === null) {
    return null
  }

  const containsControlPorts = resource.controlPorts.length > 0
  const containsMeteringPorts = resource.meteringPorts.length > 0

  return (
    <ResourceCard
      content={
        <>
          <ResourceDetailTable resource={resource}></ResourceDetailTable>
          {(containsControlPorts || containsMeteringPorts) && (
            <>
              <Divider />
              {containsControlPorts && <ResourceControlPortsTable controlPorts={resource.controlPorts} />}
              {containsMeteringPorts && <ResourceMeteringPortsTable meteringPorts={resource.meteringPorts} />}
            </>
          )}
        </>
      }
      resourceUuid={resource.resourceID}
    />
  )
}

export default ResourceGeneralCard
