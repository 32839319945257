import type { AxiosResponse } from 'axios'
import axios from 'axios'

import environment from '@/environment'
import { FEATURE_TOGGLES } from '@/features/featureToggle/constants/featureToggleValuesForDevelopmentMode'
import type { FeatureToggles } from '@/features/featureToggle/types/featureToggle'

export const GET_FEATURE_TOGGLES_API_ID = 'GET_FEATURE_TOGGLES'

export async function getFeatureToggles(): Promise<FeatureToggles> {
  if (environment.isDevelopmentMode) {
    return Promise.resolve(FEATURE_TOGGLES)
  }

  const response: AxiosResponse<FeatureToggles> = await axios.get(
    `${environment.services.featureTogglesManagerApiUrl}/proxy`,
    {
      headers: {
        'Extra-authorization': 'sympower-unleash-proxy',
      },
    },
  )

  return {
    toggles: response.data.toggles,
  }
}
