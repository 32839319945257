import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import CustomButton from '@/components/inputs/CustomButton'
import { DEFAULT_INTERFACE_LANGUAGE } from '@/constants/interfaceLanguages'
import { USER_ROLES } from '@/constants/userRoles'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import type { Customer } from '@/features/customer/types/customer'
import CountryAccessSection from '@/features/user/components/form/CountryAccessSection'
import DeleteUserButton from '@/features/user/components/form/DeleteUserButton'
import ResetPasswordButton from '@/features/user/components/form/ResetPasswordButton'
import UserAccountAccordion from '@/features/user/components/form/UserAccountAccordion'
import UserInterfaceAccordion from '@/features/user/components/form/UserInterfaceAccordion'
import UserRightsAccordion from '@/features/user/components/form/UserRightsAccordion'
import { ERROR_NAMES, saveUser as apiSaveUser } from '@/features/user/endpoints/users'
import { getUserFormSchema } from '@/features/user/schemas'
import type { User } from '@/features/user/types/user'
import { errorHandler } from '@/utils/errorHandler'

import CredentialsSection from './form/CredentialsSection'
import CustomerAccessSection from './form/CustomerAccessSection'
import InformationSection from './form/InformationSection'
import InterfaceSection from './form/InterfaceSection'
import RoleSection from './form/RoleSection'

interface UserFormProps {
  customers: Customer[]
  user?: Partial<User>
}

export const PartnerUserForm = ({ customers, user }: UserFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loggedInUser } = useAuth()
  const form = useForm<User>({
    mode: 'onBlur',
    defaultValues: {
      id: user?.id ?? '',
      username: user?.username ?? '',
      isMFAEnabled: user?.isMFAEnabled ?? false,
      givenName: user?.givenName ?? '',
      familyName: user?.familyName ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      role: user?.role ?? undefined,
      allowedCountries: user?.allowedCountries ?? [],
      isRestricted: user?.isRestricted ?? false,
      allowedRoIds: user?.allowedRoIds ?? [],
      partnerCode: user?.partnerCode ?? loggedInUser?.partnerCode ?? undefined,
      uiLanguage: user?.uiLanguage ?? DEFAULT_INTERFACE_LANGUAGE.value,
      isActive: user?.isActive ?? true,
    },
    resolver: zodResolver(getUserFormSchema(t)),
  })
  const { pushAlert } = useAlertContext()

  const editMode = !!user

  useWatch({
    control: form.control,
  })

  async function onSubmit() {
    await prepareValuesForSubmission()
    saveUser()
  }

  function prepareValuesForSubmission(): Promise<void> {
    const partnerCodeToAssign = loggedInUser?.partnerCode

    if (!partnerCodeToAssign) {
      pushAlert({
        severity: 'error',
        message: t('user_form.form.partner_code_assignation_error', {
          partnerCode: partnerCodeToAssign,
          username: loggedInUser?.username,
        }),
      })

      return Promise.reject(new Error(t('user_form.form.partner_code_assignation_error')))
    }
    form.setValue('partnerCode', partnerCodeToAssign)

    // FIXME: reconsider what to do with numeralLanguage, for now it is the same as uiLanguage
    form.setValue('numeralLanguage', form.getValues().uiLanguage)
    return Promise.resolve()
  }

  const { mutate: saveUser, isPending } = useMutation({
    mutationFn: () => apiSaveUser(form.getValues()),
    onError: (error: any) => {
      if (error.response.data.name === ERROR_NAMES.EMAIL_EXISTS) {
        form.setError('email', {
          type: 'alreadyExists',
          message: t('user_form.form.email_exists.error').toString(),
        })
      } else if (error.response.data.name === ERROR_NAMES.USERNAME_EXISTS) {
        form.setError('username', {
          type: 'alreadyExists',
          message: t('user_form.form.username_exists.error').toString(),
        })
      } else if (error.response.data.name === ERROR_NAMES.MFA_UPDATE_FAILED) {
        form.setError('isMFAEnabled', {
          type: 'updateFailed',
          message: t('user_form.form.mfa_update_failed.error').toString(),
        })
      } else if (
        error.response.data.name === ERROR_NAMES.VALIDATION_FAILED &&
        error.response.data.message.includes('phone')
      ) {
        form.setError('phone', {
          type: 'invalid',
          message: t('user_form.form.phone_invalid.error').toString(),
        })
      }
    },
    onSuccess: () => {
      navigate('/users')
    },
  })

  function handleResetPasswordError(err: unknown) {
    const error = errorHandler(err, t('user_form.reset_password.error'))

    pushAlert({
      severity: 'error',
      message: error.message,
    })
  }

  return (
    <FormProvider {...form}>
      <Stack
        noValidate
        aria-label={t('partner_user_form.form.title')}
        component="form"
        direction="column"
        gap={3}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Box>
          <UserAccountAccordion user={user}>
            <CredentialsSection authenticationStatus={user?.authenticationStatus} editMode={editMode} />
            <InformationSection />
            {user?.id && editMode && (
              <Box>
                <ResetPasswordButton userId={user.id} onResetPasswordError={handleResetPasswordError} />
              </Box>
            )}
          </UserAccountAccordion>

          <UserRightsAccordion>
            <RoleSection
              roleOptions={[
                USER_ROLES.PARTNER_ADMINISTRATORS.value,
                USER_ROLES.RESOURCE_OWNERS.value,
                USER_ROLES.CUSTOMER_MANAGERS.value,
              ]}
            />
            <CountryAccessSection />
            <CustomerAccessSection customers={customers} />
          </UserRightsAccordion>

          <UserInterfaceAccordion defaultExpanded={!editMode}>
            <InterfaceSection />
          </UserInterfaceAccordion>
        </Box>

        <Stack direction="row" gap={1} justifyContent="space-between">
          <Stack direction="row" gap={1} justifyContent="flex-start">
            <CustomButton component={Link} disabled={isPending} to="/users" variant="outlined">
              {t('common.button.cancel')}
            </CustomButton>
            <CustomButton disabled={isPending} type="submit" variant="contained">
              {t('common.button.save')}
            </CustomButton>
          </Stack>
          {editMode && <DeleteUserButton disabled={isPending} user={user} />}
        </Stack>
      </Stack>
    </FormProvider>
  )
}
