import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_REVENUE_PERIODS_API_ID, getCustomerRevenuePeriod } from '@/features/customer/endpoints/revenues'
import type { RevenuePeriod } from '@/features/customer/types/revenue'
import type { UseQueryOptions } from '@/types/queries'

type RevenuePeriodQueryParams = {
  customerUuid: string
  location?: string
}

export type UseRevenuePeriodQueryResult = Omit<UseQueryResult, 'data'> & {
  revenuePeriods: RevenuePeriod[] | null
}

export function useRevenuePeriodQuery(
  params: RevenuePeriodQueryParams,
  options?: UseQueryOptions<RevenuePeriod[]>,
): UseRevenuePeriodQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_REVENUE_PERIODS_API_ID, params],
    queryFn: () => getCustomerRevenuePeriod(params.customerUuid, params.location),
  })
  return { revenuePeriods: data, ...queryResult }
}
