export const DEFAULT_DATA_GRID_PAGE_SIZE = 10
export const DEFAULT_DATA_GRID_PAGE_SIZE_OPTIONS = [10, 20, 50]
export const DEFAULT_DATA_GRID_FILTER_MODEL = {
  items: [],
}
export const DEFAULT_DATA_GRID_FILTER_AND_SEARCH_MODEL = {
  items: [],
  quickFilterValues: [],
}
export const DEFAULT_DATA_GRID_PAGINATION_MODEL = {
  pageSize: DEFAULT_DATA_GRID_PAGE_SIZE,
  page: 0,
}
