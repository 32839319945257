import type { GridPaginationModel } from '@mui/x-data-grid-pro'

/**
 * Converts a GridPaginationModel into a key-value object that can be used as URLSearchParams
 */
export function serializePaginationModelToSearchParamsObject(
  paginationModel: Partial<GridPaginationModel>,
): Record<string, string> {
  return { page: String(paginationModel.page), pageSize: String(paginationModel.pageSize) }
}

/**
 * Converts URLSearchParams into a valid GridPaginationModel
 *
 * When a pagination is not present in the search params, we apply the initial state value
 */
export function deserializePaginationModelFromSearchParams(): GridPaginationModel | null {
  const params = new URLSearchParams(window.location.search)

  const page = params.get('page')
  const pageSize = params.get('pageSize')

  if (!page || !pageSize) {
    return null
  }

  return { page: Number(page), pageSize: Number(pageSize) }
}

export function deserializePaginationSearchParamsObjectFromSearchParams(): Record<string, string> | null {
  const params = new URLSearchParams(window.location.search)

  const page = params.get('page')
  const pageSize = params.get('pageSize')

  if (!page || !pageSize) {
    return null
  }

  return { page: page ?? '', pageSize: pageSize ?? '' }
}

export function shouldShowPagination({ dataGridProps, initialState }) {
  // We explicity said that we don't want to show pagination
  if (dataGridProps.hideFooterPagination) {
    return false
  }

  const pageSize = getPageSize({ dataGridProps, initialState })

  // In case pageSize is null, we do not have pagination in the DataGrid
  if (!pageSize) {
    return false
  }

  const { paginationMode, rowCount, rows } = dataGridProps

  // In the server mode, we provide to the DataGrid the "rowCount" to control how many pages to show.
  // In case the "rowCount" is less than the "pageSize", we do not have enough items for more than 1 page.
  if (paginationMode === 'server') {
    return rowCount > pageSize
  }

  // In the client mode, we have all the data beforehand in the "rows" property.
  // It's just a matter of checking the amount of items and compare to the "pageSize".
  return rows.length > pageSize
}

export function getPageSize({ dataGridProps, initialState }) {
  if (dataGridProps.paginationModel?.pageSize) return dataGridProps.paginationModel.pageSize
  if (initialState?.pagination?.paginationModel?.pageSize) return initialState.pagination.paginationModel.pageSize

  return null
}
