import type { ReactNode } from 'react'

import PageAppBar from '@/components/layouts/PageAppBar'
import PageTitle from '@/components/layouts/PageTitle'

type PageHeaderWithoutBreadcrumbsProps = {
  appBarContent?: ReactNode
  pageTitle: string
}

function PageHeaderWithoutBreadcrumbs({ pageTitle, appBarContent }: Readonly<PageHeaderWithoutBreadcrumbsProps>) {
  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <PageAppBar appBarContent={appBarContent} />
    </>
  )
}

export default PageHeaderWithoutBreadcrumbs
