import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import BidConfirmPriceToolbar from '@/features/bidding/components/price/confirm/BidConfirmPriceToolbar'
import { MAX_PTUS_PER_PAGE } from '@/features/bidding/constants'
import type { Bid } from '@/features/bidding/types/bid'
import { buildHourlyPriceRowItems } from '@/features/bidding/utils/model/buildHourlyPriceRowItems'
import { getPriceBidDataGridColumns } from '@/features/bidding/utils/price/getPriceBiddingDataGridColumns'

type Props = {
  activationGroupBids: Bid[]
}

const BidConfirmPriceDataGrid: FC<Props> = ({ activationGroupBids }) => {
  const { t, i18n } = useTranslation()

  const rowItems = buildHourlyPriceRowItems(activationGroupBids)

  return (
    <CustomDataGrid
      disableDefaultHeaderBackground
      hideFooter
      columns={getPriceBidDataGridColumns(rowItems, false, t, i18n.language, true)}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: MAX_PTUS_PER_PAGE,
            page: 0,
          },
        },
      }}
      rows={rowItems}
      slotProps={{
        toolbar: {
          groupParams: { ...activationGroupBids[0], portfolioCode: activationGroupBids[0].portfolio.code },
        },
      }}
      slots={{ toolbar: BidConfirmPriceToolbar }}
    />
  )
}

export default BidConfirmPriceDataGrid
