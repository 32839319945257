import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import type { ChipProps } from '@mui/material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Stack, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { ReactNode, SyntheticEvent } from 'react'
import { useState } from 'react'

import EllipsisTypography from '@/components/dataDisplay/EllipsisTypography'

export interface CustomAccordionProps {
  title: string
  description?: string
  children?: ReactNode
  toggleNode?: ReactNode
  statusProps?: {
    label: string
    color: ChipProps['color']
    tooltipLabel?: string
    icon?: ChipProps['icon']
  }
  defaultExpanded?: boolean
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  '&.MuiAccordion-root:last-of-type': {
    borderBottom: 0,
  },
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.MuiAccordionSummary-root': {
    minHeight: '52px',
  },
}))

/**
 * Custom Accordion component extends MUI Accordion component, and includes some properties used in our particular
 * use cases. The main properties are:
 *
 * - Title [mandatory]
 * - Switch (it internally uses the Switch component) [optional]
 * - Description [optional]
 * - Status (it internally uses the Chip component) [optional]
 * - Children [optional]. If children content is not provided, the Accordion will be disabled.
 *
 * Be careful if you wrap this component around a Stack component, because the Stack component will add extra space
 * between the accordions inside when they are opened.
 */
const CustomAccordion = ({
  children,
  title,
  description,
  toggleNode,
  defaultExpanded = false,
  statusProps,
}: CustomAccordionProps) => {
  const hasChildren = children !== undefined && children !== null
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded)

  function handleAccordionChange(_: SyntheticEvent, newExpandedValue: boolean) {
    if (hasChildren) {
      setExpanded(newExpandedValue)
    }
  }

  return (
    <StyledAccordion expanded={expanded} onChange={handleAccordionChange}>
      <StyledAccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              margin: 1,
              visibility: hasChildren ? 'visible' : 'hidden',
              pointerEvents: hasChildren ? 'auto' : 'none',
            }}
            titleAccess="Expand Icon"
          />
        }
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr', width: '100%', gap: 1 }}>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', minWidth: 0 }}>
            {toggleNode}
            <EllipsisTypography sx={{ flex: 1 }} tooltipTitle={title} variant="h6">
              {title}
            </EllipsisTypography>
          </Stack>

          <Stack sx={{ minWidth: 0, alignItems: 'center', flexDirection: 'row' }}>
            <EllipsisTypography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 400,
                minWidth: '10px',
              }}
              variant="h6"
            >
              {description}
            </EllipsisTypography>
          </Stack>

          <Stack sx={{ minWidth: 0, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
            {statusProps && (
              <Tooltip title={statusProps.tooltipLabel ?? ''}>
                <Chip color={statusProps.color} icon={statusProps.icon} label={statusProps.label} variant="outlined" />
              </Tooltip>
            )}
          </Stack>
        </Box>
      </StyledAccordionSummary>

      {hasChildren && <AccordionDetails>{children}</AccordionDetails>}
    </StyledAccordion>
  )
}

export default CustomAccordion
