import { Navigate } from 'react-router-dom'

import { ErrorsRouteInformation } from '@/features/error/constants'

type NavigateToErrorProps = {
  statusCode: 404 | 403 | 500
}

const NavigateToError = ({ statusCode }: NavigateToErrorProps) => {
  return <Navigate to={ErrorsRouteInformation[statusCode].navigationPath} />
}

export default NavigateToError
