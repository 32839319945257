import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Chip, Tooltip } from '@mui/material'
import type { ChipProps } from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

import type { ResourceStatus } from '@/features/resource/types'

interface ResourceStatusChipProps {
  status: ResourceStatus
  isRestricted: boolean
}

/**
 * Displays the current resource status stage as a chip.
 */
const StatusChip = ({ status, isRestricted }: ResourceStatusChipProps) => {
  const { t } = useTranslation()
  const colorSuccess = isRestricted ? 'default' : 'success'
  const colorError = isRestricted ? 'default' : 'error'

  const props: Partial<ChipProps> =
    status === 'AVAILABLE'
      ? {
          color: colorSuccess,
          icon: <CheckCircleOutlineIcon titleAccess={t('component.resource_status_chip.online_icon')} />,
          label: t('component.resource_status_chip.available'),
          disabled: isRestricted,
        }
      : {
          color: colorError,
          icon: <ErrorOutlineIcon titleAccess={t('component.resource_status_chip.offline_icon')} />,
          label: t(`component.resource_status_chip.${status.toLowerCase() as Lowercase<ResourceStatus>}`),
          disabled: isRestricted,
        }

  const chipElement = <Chip sx={{ textTransform: 'uppercase' }} variant="outlined" {...props} />

  if (isRestricted) {
    return (
      <Tooltip disableInteractive placement="top" title={t('component.resource_status_chip.restriction_active')}>
        <Box component="span" sx={{ width: '100%' }}>
          {chipElement}
        </Box>
      </Tooltip>
    )
  }

  return chipElement
}

export default StatusChip
