import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { Activation } from '@/features/activation/types/activation'
import { GET_ACTIVATIONS_API_ID, getActivations } from '@/features/customer/endpoints/activations'
import type { UseQueryOptions } from '@/types/queries'

type ActivationsQueryParams = {
  customerUuid: string
  location?: string
}
export type UseActivationsQueryResult = Omit<UseQueryResult, 'data'> & {
  activations: Activation[] | null
}

export function useActivationsQuery(
  params: ActivationsQueryParams,
  options?: UseQueryOptions<Activation[]>,
): UseActivationsQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_ACTIVATIONS_API_ID, params],
    queryFn: () => getActivations(params.customerUuid, params.location),
  })

  return { activations: data, ...queryResult }
}
