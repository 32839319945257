const volumeOutOfBiddableVolumeRange = (
  quantityInWatts: number,
  biddableVolumeRange: { minBiddableVolume: number | undefined; maxBiddableVolume: number | undefined },
) => {
  const { minBiddableVolume, maxBiddableVolume } = biddableVolumeRange
  return (
    (minBiddableVolume && quantityInWatts !== 0 && quantityInWatts < minBiddableVolume) ||
    (maxBiddableVolume && quantityInWatts > maxBiddableVolume)
  )
}

export default volumeOutOfBiddableVolumeRange
