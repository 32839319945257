import { useTranslation } from 'react-i18next'

import error404Src from '@/assets/errors/404.png'
import ErrorPageLayout from '@/features/error/components/ErrorPageLayout'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'

const Error404 = () => {
  const { t } = useTranslation()
  const { mailto } = useWhitelabelConfigurationContext()

  return (
    <ErrorPageLayout
      image={{
        alt: t('error_pages.404.image_alt'),
        src: error404Src,
      }}
      solutions={[
        t('error_pages.404.solutions.url_typos'),
        t('error_pages.404.solutions.refresh_page'),
        t('error_pages.common.solutions.contact_support', {
          supportMail: mailto.support,
        }),
      ]}
      statusCode={404}
      title={t('error_pages.404.title')}
    />
  )
}

export default Error404
