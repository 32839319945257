import type { ReactNode } from 'react'

import BackNavigationButton from '@/components/layouts/BackNavigationButton'
import PageAppBar from '@/components/layouts/PageAppBar'
import PageTitle from '@/components/layouts/PageTitle'
import CustomBreadcrumbs from '@/components/navigation/CustomBreadcrumbs'
import type { Breadcrumb } from '@/types/breadcrumb'

type PageHeaderProps = {
  breadcrumbItems?: Breadcrumb[]
  appBarContent?: ReactNode
  pageTitle: string
}

function PageHeader({ breadcrumbItems = [], pageTitle, appBarContent }: Readonly<PageHeaderProps>) {
  return (
    <>
      {breadcrumbItems.length > 0 && <BackNavigationButton />}
      <CustomBreadcrumbs items={breadcrumbItems} showHome={true} />
      <PageTitle pageTitle={pageTitle} />
      <PageAppBar appBarContent={appBarContent} />
    </>
  )
}

export default PageHeader
