import axios from 'axios'

import environment from '@/environment'
import type { Customer, CustomerCompanyInfo, CustomerContact } from '@/features/customer/types/customer'

export const CUSTOMERS_API_URL = `${environment.services.monolithGatewayManagerApiUrl}/customers`

export const GET_CUSTOMER_API_ID = 'GET_CUSTOMER'
export const GET_CUSTOMERS_API_ID = 'GET_CUSTOMERS'

export async function getCustomers(): Promise<Customer[]> {
  const response = await axios.get(CUSTOMERS_API_URL)
  return response.data
}

export async function getCustomer(customerUuid: string, location?: string): Promise<Customer> {
  const response = await axios.get(`${CUSTOMERS_API_URL}/${customerUuid}`, {
    params: {
      location,
    },
  })
  return response.data
}

export async function saveCustomerCompanyInfo(
  customerUuid: string,
  companyInfo: CustomerCompanyInfo,
): Promise<Customer> {
  const response = await axios.put<Customer>(`${CUSTOMERS_API_URL}/${customerUuid}/company-info`, companyInfo)
  return response.data
}

export async function activateCustomer(customerUuid: string, location: string | undefined): Promise<string> {
  return axios.post<Customer, string>(`${CUSTOMERS_API_URL}/${customerUuid}/activate`, {
    params: {
      location,
    },
  })
}

export async function deactivateCustomer(customerUuid: string, location: string | undefined): Promise<string> {
  return axios.post<Customer, string>(`${CUSTOMERS_API_URL}/${customerUuid}/deactivate`, {
    params: {
      location,
    },
  })
}

export async function createCustomerContact(customerUuid: string, contact: CustomerContact): Promise<CustomerContact> {
  const response = await axios.post<CustomerContact>(`${CUSTOMERS_API_URL}/${customerUuid}/contacts`, contact)
  return response.data
}

export async function updateCustomerContact(customerUuid: string, contact: CustomerContact): Promise<CustomerContact> {
  const response = await axios.put<CustomerContact>(
    `${CUSTOMERS_API_URL}/${customerUuid}/contacts/${contact.id}`,
    contact,
  )
  return response.data
}

export async function deleteCustomerContact(
  customerUuid: string,
  contactId: number,
  location: string | undefined,
): Promise<CustomerContact> {
  const response = await axios.delete<CustomerContact>(`${CUSTOMERS_API_URL}/${customerUuid}/contacts/${contactId}`, {
    params: {
      location,
    },
  })
  return response.data
}
