import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import { isValidElement } from 'react'
import { useTranslation } from 'react-i18next'

import CustomIconButton from '@/components/inputs/CustomIconButton'

interface DataGridDetailPanelToggleProps {
  params: GridRenderCellParams
}

const DataGridDetailPanelToggle = ({ params }: DataGridDetailPanelToggleProps) => {
  const { t } = useTranslation()
  const { id, value: isExpanded } = params
  const apiRef = useGridApiContext()

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector)

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = isValidElement(contentCache[id])

  return (
    <CustomIconButton
      Icon={ExpandMoreIcon}
      aria-label={
        isExpanded
          ? t('component.data_grid_detail_panel.close_collapsible_row_label')
          : t('component.data_grid_detail_panel.open_collapsible_row_label')
      }
      disabled={!hasDetail}
      iconProps={{
        sx: {
          transform: `rotateZ(${isExpanded ? 0 : -90}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        },
      }}
      size="small"
      tabIndex={-1}
    />
  )
}

export default DataGridDetailPanelToggle
