export const ErrorsRouteInformation = {
  403: {
    navigationPath: '/errors/403',
    routePath: '/403',
  },
  404: {
    navigationPath: '/errors/404',
    routePath: '/404',
  },
  500: {
    navigationPath: '/errors/500',
    routePath: '/500',
  },
} as const
