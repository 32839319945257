import type { GridFilterItem } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SingleSelectColumnToolbarFilter } from '@/components/inputs/SingleSelectColumnToolbarFilter'
import { FIELD_WIDTH } from '@/constants/layout'
import type { UserRole } from '@/constants/userRoles'

type Props = {
  // DataGrid columns have a property called field, which acts as a unique identifier for the column.
  field: string
  value: UserRole | null
  roleOptions: UserRole[]
  onChange: (items: GridFilterItem[]) => void
}

const RoleFilter = ({ value, roleOptions, field, onChange }: Props) => {
  const { t } = useTranslation()
  const sortedRoleOptions = useMemo(() => roleOptions.toSorted((a, b) => a.localeCompare(b)), [roleOptions])

  return (
    <SingleSelectColumnToolbarFilter
      currentFilterValue={value ?? ''}
      field={field}
      getOptionLabel={(value: UserRole) => (value ? t(`users.role.${value}`) : '-')}
      label={t('users.role.label')}
      options={sortedRoleOptions}
      sx={{ maxWidth: FIELD_WIDTH }}
      onFilterModelChange={({ items }) => onChange(items)}
    />
  )
}

export default RoleFilter
