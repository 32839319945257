import { Stack } from '@mui/material'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { getActivationGroupByUuid } from '@/features/activationGroup/utils/activationGroups'
import type { BidMetaData } from '@/features/bidding/types/bid'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends BidVersionPtusToolbarProps {}
}

type BidVersionPtusToolbarProps = {
  bid: BidMetaData
  bidSelectorComponent?: ReactElement
}

const BidVersionPtusToolbar = ({ bid, bidSelectorComponent }: BidVersionPtusToolbarProps) => {
  const { t } = useTranslation()
  const { activationGroups } = useActivationGroupsQuery()

  return (
    <>
      {bidSelectorComponent ? <div style={{ padding: '8px 16px' }}>{bidSelectorComponent}</div> : null}
      <Stack direction="row" margin={2} spacing={1}>
        <TableTextSummary label={t('bidding.create_bid.select_portfolio')} text={bid.portfolio.name} />
        <TableTextSummary
          label={t('bidding.create_bid.select_market_program')}
          text={t(`bidding.market_program.${bid.marketProgram}`)}
        />
        <TableTextSummary
          label={t('bidding.overview.header.activation_group')}
          text={getActivationGroupByUuid(activationGroups, bid.activationGroupUuid)?.code ?? '-'}
        />
        <TableTextSummary label={t('bidding.results.bid_date')} text={bid.deliveryDay.getStartOfDay().toFormat('DD')} />
      </Stack>
    </>
  )
}

type TableTextSummaryProps = {
  label: string
  text: string
}

const TableTextSummary = ({ label, text }: TableTextSummaryProps) => (
  <LabeledTextField label={label} text={text} variant="filled" />
)

export default BidVersionPtusToolbar
