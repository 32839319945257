import { Route, Routes } from 'react-router-dom'

import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import ActivationGroupDetails from '@/features/activationGroup/pages/ActivationGroupDetails'
import ActivationGroupPrequalificationPatternDetails from '@/features/activationGroup/pages/ActivationGroupPrequalificationPatternDetails'
import ActivationGroupPrequalificationPatterns from '@/features/activationGroup/pages/ActivationGroupPrequalificationPatterns'
import ActivationGroupResources from '@/features/activationGroup/pages/ActivationGroupResources'
import ActivationGroupSchedules from '@/features/activationGroup/pages/ActivationGroupSchedules'
import ActivationGroupsIndex from '@/features/activationGroup/pages/ActivationGroupsIndex'
import NavigateToError from '@/features/error/pages/NavigateToError'

export default function ActivationGroupRoutes() {
  return (
    <Routes>
      <Route element={<ActivationGroupsIndex />} path={ActivationGroupRouteInformation.INDEX.routePath} />

      <Route
        element={<ActivationGroupDetails />}
        path={ActivationGroupRouteInformation.ACTIVATION_GROUP_DETAILS.routePath}
      >
        <Route index element={<ActivationGroupResources />} />
        <Route
          element={<ActivationGroupResources />}
          path={ActivationGroupRouteInformation.ACTIVATION_GROUP_RESOURCES.routePath}
        />
        <Route
          element={<ActivationGroupSchedules />}
          path={ActivationGroupRouteInformation.ACTIVATION_GROUP_SCHEDULES.routePath}
        />
        <Route
          element={<ActivationGroupPrequalificationPatterns />}
          path={ActivationGroupRouteInformation.ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS.routePath}
        />
      </Route>

      <Route
        element={<ActivationGroupPrequalificationPatternDetails />}
        path={ActivationGroupRouteInformation.ACTIVATION_GROUP_PREQUALIFICATION_PATTERN_DETAILS.routePath}
      />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
