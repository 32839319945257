import { DialogContent, DialogContentText } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'
import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import { deleteServiceRule } from '@/features/customer/endpoints/serviceRules'
import { errorHandler } from '@/utils/errorHandler'

type DeleteServiceRuleButtonProps = {
  customerUuid: string
  serviceId: number | undefined
  serviceRuleId: number | undefined
  onDeleteSuccess: () => void
}

const DeleteServiceRuleButton = ({
  customerUuid,
  serviceId,
  serviceRuleId,
  onDeleteSuccess,
}: DeleteServiceRuleButtonProps) => {
  const { t } = useTranslation()
  const [showDeleteServiceRuleDialog, setShowDeleteServiceRuleDialog] = useState(false)

  const { pushAlert } = useAlertContext()

  function handleOpenDeleteServiceRuleDialog() {
    setShowDeleteServiceRuleDialog(true)
  }

  function handleCloseDeleteServiceRuleDialog() {
    setShowDeleteServiceRuleDialog(false)
  }

  async function handleDeleteServiceRule() {
    try {
      await deleteServiceRule(customerUuid, serviceId, serviceRuleId)

      onDeleteSuccess()
      pushAlert({
        message: t('customer_details.tabs.availability.delete.success'),
        severity: 'success',
      })
    } catch (err) {
      const error = errorHandler(err, t('customer_details.tabs.availability.delete.error'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    } finally {
      handleCloseDeleteServiceRuleDialog()
    }
  }

  return (
    <>
      <CustomButton color={'error'} variant="outlined" onClick={handleOpenDeleteServiceRuleDialog}>
        {t('common.button.delete')}
      </CustomButton>

      <CustomDialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={showDeleteServiceRuleDialog}
        title={t('customer_details.tabs.availability.delete.confirm_dialog_title')}
        onClose={handleCloseDeleteServiceRuleDialog}
      >
        <DialogContent>
          <DialogContentText color={'error'} id="alert-dialog-description">
            {t('customer_details.tabs.availability.delete.confirm_dialog_text')}
          </DialogContentText>
          <br />
        </DialogContent>

        <ConfirmDialogActions onCancel={handleCloseDeleteServiceRuleDialog} onConfirm={handleDeleteServiceRule} />
      </CustomDialog>
    </>
  )
}

export default DeleteServiceRuleButton
