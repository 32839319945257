import type { UseQueryResult } from '@tanstack/react-query'
import { useIsFetching, useQuery } from '@tanstack/react-query'

import type { MarketProgram } from '@/features/bidding/constants'
import { findBid, GET_BID_API_ID } from '@/features/bidding/endpoints/bids'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { Bid } from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import type { UseQueryOptions } from '@/types/queries'

type BidQueryParams = {
  date: MarketDate
  portfolioCode: string
  marketProgram: MarketProgram
  activationGroupUuid?: string
}
export type UseFindBidQueryResult = Omit<UseQueryResult, 'data'> & {
  bid?: Bid | null
}

export function useBidQuery(params: BidQueryParams, options?: UseQueryOptions<Bid | null>) {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BID_API_ID, params],
    queryFn: () => findBid(params.date, params.portfolioCode, params.marketProgram, params.activationGroupUuid),
  })

  useErrorHandler(queryResult.isError)

  return { bid: data, ...queryResult }
}

export function useIsFetchingBidQuery(): boolean {
  const bidFetchingCount = useIsFetching({ queryKey: [GET_BID_API_ID] })

  return bidFetchingCount > 0
}
