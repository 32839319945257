import { Card, CardActionArea, CardContent, Skeleton, Stack } from '@mui/material'

export const NumericWidgetSkeleton = () => {
  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CardActionArea>
        <CardContent>
          <Skeleton height={30} variant={'text'} width={'100%'} />
          <Stack alignContent={'center'} alignItems={'center'} direction={'row'} spacing={1} sx={{ mt: 2, mb: 1 }}>
            <Skeleton height={35} variant="circular" width={35} />
            <Skeleton height={30} variant="rounded" width={'30%'} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
