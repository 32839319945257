import type { SelectChangeEvent } from '@mui/material/Select'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import { useCustomersQuery } from '@/features/customer/hooks/useCustomersQuery'
import { getCustomerIdOptions } from '@/features/customer/utils/getCustomerSortedOptions'
import { sortOptionsAlphabetically } from '@/utils/sortOptionsAlphabetically'

export function CustomerSelector({ customerUuid }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>('')
  const { customers } = useCustomersQuery()

  const options =
    customers && customers.length > 0 ? sortOptionsAlphabetically<string>(getCustomerIdOptions(customers)) : []

  useEffect(() => {
    if (customers && customers.length > 0) {
      setSelectedCustomer(customerUuid)
    }
  }, [customers])

  function changeCustomer(evt: SelectChangeEvent) {
    const newCustomerUuid = evt.target.value
    const newPath = pathname.replace(customerUuid, newCustomerUuid)
    navigate(newPath)
  }

  return (
    <CustomSelectField
      autoWidth
      id="customers"
      label={t('customer_details.customer_selector')}
      options={options}
      size="medium"
      sx={{ minWidth: FIELD_WIDTH }}
      value={selectedCustomer}
      variant="outlinedWhite"
      onChange={changeCustomer}
    />
  )
}
