import ReactECharts from 'echarts-for-react'
import { useTranslation } from 'react-i18next'

import type { MeasuredPowerSerie } from '@/features/measurement/types/measuredPower'
import { displayValueInTheRightUnit } from '@/features/measurement/utils/units'

const COLOR_PALETTE = ['#9a7fd1', '#5ab1ef', '#edafda', '#93b7e3', '#a5e7f0', '#cbb0e3']
const LINE_COLOR = '#A3A3A3'

type MeasuredPowerChartProps = {
  measuredPowerSeries: MeasuredPowerSerie[]
  showOnlyTotal: boolean
  timeData: string[]
}

function MeasuredPowerChart({ measuredPowerSeries, showOnlyTotal, timeData }: Readonly<MeasuredPowerChartProps>) {
  const { t } = useTranslation()

  const commonProps = {
    type: 'line',
    smooth: true,
  }

  const series = showOnlyTotal
    ? {
        ...commonProps,
        data: getTotalSerieData(measuredPowerSeries),
        name: t('measurements.chart.total_measured_power'),
      }
    : measuredPowerSeries.map((serie) => ({
        ...commonProps,
        ...serie,
      }))

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    legend: {
      bottom: 0,
      itemGap: 30,
      show: true,
      itemWidth: 15,
      icon: 'rect',
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        saveAsImage: {},
      },
    },
    animation: false,
    color: COLOR_PALETTE,
    notMerge: true,
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: timeData,
      axisLine: {
        lineStyle: {
          color: LINE_COLOR,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: displayValueInTheRightUnit,
      },
      axisPointer: {
        snap: true,
      },
      axisLine: {
        lineStyle: {
          color: LINE_COLOR,
        },
      },
      min: 'dataMin',
    },
    series,
  }

  return <ReactECharts notMerge={true} option={option} style={{ height: '400px' }} />
}

function getTotalSerieData(measuredPowerSeries: MeasuredPowerSerie[]) {
  const totals: number[] = []
  const seriesSize = measuredPowerSeries.length

  for (let i = 0; i < seriesSize; i++) {
    const serie = measuredPowerSeries[i]
    const serieDataSize = serie.data.length

    for (let j = 0; j < serieDataSize; j++) {
      totals[j] = (totals[j] ?? 0) + serie.data[j]
    }
  }

  return totals
}

export default MeasuredPowerChart
