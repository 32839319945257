import { DialogContent, DialogContentText } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'
import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import { resetPassword } from '@/features/user/endpoints/users'

type ResetPasswordButtonProps = {
  userId: string
  onResetPasswordSuccess?: () => void
  onResetPasswordError?: (error: unknown) => void
}

const ResetPasswordButton = ({ userId, onResetPasswordError, onResetPasswordSuccess }: ResetPasswordButtonProps) => {
  const { t } = useTranslation()
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false)

  const { pushAlert } = useAlertContext()

  function handleOpenResetPasswordDialog() {
    setShowResetPasswordDialog(true)
  }

  function handleCloseResetPasswordDialog() {
    setShowResetPasswordDialog(false)
  }

  async function handleResetPassword() {
    try {
      await resetPassword(userId)

      pushAlert({
        message: t('user_form.reset_password.success'),
        severity: 'success',
      })

      if (onResetPasswordSuccess) {
        onResetPasswordSuccess()
      }
    } catch (err) {
      pushAlert({
        message: t('user_form.reset_password.error'),
        severity: 'error',
      })

      if (onResetPasswordError) {
        onResetPasswordError(err)
      }
    } finally {
      handleCloseResetPasswordDialog()
    }
  }

  return (
    <>
      <CustomButton variant="outlined" onClick={handleOpenResetPasswordDialog}>
        {t('user_form.reset_password.label')}
      </CustomButton>

      <CustomDialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={showResetPasswordDialog}
        title={t('user_form.reset_password.label')}
        onClose={handleCloseResetPasswordDialog}
      >
        <DialogContent>
          <DialogContentText color={'error'} id="alert-dialog-description">
            {t('user_form.reset_password.confirmation')}
          </DialogContentText>
          <br />
          <DialogContentText id="alert-dialog-description">{t('user_form.reset_password.hint')}</DialogContentText>
        </DialogContent>

        <ConfirmDialogActions onCancel={handleCloseResetPasswordDialog} onConfirm={handleResetPassword} />
      </CustomDialog>
    </>
  )
}

export default ResetPasswordButton
