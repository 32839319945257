import type { TextFieldProps } from '@mui/material'
import { TextField } from '@mui/material'
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

export type FileFieldControllerProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  TextFieldProps,
  'name'
> & {
  name: TName
  // It enables showing field validation errors. Its default value is true
  showError?: boolean
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'render' | 'name'>
}

function FileFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  showError = true,
  controllerProps,
  fullWidth,

  ...fieldProps
}: FileFieldControllerProps<TFieldValues, TName>) {
  const { control } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, onChange, ...field }, fieldState: { error, invalid } }) => (
        <TextField
          {...field}
          error={invalid && showError}
          fullWidth={fullWidth ?? true}
          helperText={error?.message}
          name={name}
          value={fieldProps.inputProps?.multiple ? value?.map((value) => value.fileName).join(',') : value?.fileName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { files } = event.target
            const selectedFiles = files as FileList

            if (selectedFiles && selectedFiles.length > 0) {
              onChange(fieldProps.inputProps?.multiple ? selectedFiles : selectedFiles[0])
            }
          }}
          {...fieldProps}
          type="file"
        />
      )}
    />
  )
}

export default FileFieldController
