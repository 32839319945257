import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Stack } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CheckboxController from '@/components/inputs/CheckboxController'
import { USER_ROLES } from '@/constants/userRoles'
import environment from '@/environment'
import type { User } from '@/features/user/types/user'

const AVAILABLE_COUNTRIES = environment.allCountries

export default function CountryAccessSection() {
  const { t } = useTranslation()
  const { control, getValues, formState, setValue, trigger } = useFormContext<User>()

  const { allowedCountries, role } = getValues()

  const isHidden = role == undefined || role !== USER_ROLES.ELECTRICIANS.value

  function triggerCountryValidation() {
    void trigger('allowedCountries')
  }

  function handleCountry(event: ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target

    if (checked) {
      allowedCountries?.push(value)
    } else {
      allowedCountries?.splice(allowedCountries?.indexOf(value), 1)
    }

    setValue('allowedCountries', allowedCountries)
    triggerCountryValidation()
  }

  async function toggleAllCountries(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setValue('allowedCountries', AVAILABLE_COUNTRIES)
    } else {
      setValue('allowedCountries', [])
    }
  }

  function isIndeterminate() {
    if (allowedCountries != null) {
      return allowedCountries.length > 0 && allowedCountries.length < AVAILABLE_COUNTRIES.length
    }
    return false
  }

  return isHidden ? null : (
    <FormGroup>
      <Stack direction="column" gap={2}>
        <CustomTypography variant="h6">{t('user_form.form.title_country_access')}</CustomTypography>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowedCountries?.length === AVAILABLE_COUNTRIES.length}
              indeterminate={isIndeterminate()}
              onChange={(event) => {
                toggleAllCountries(event).then(() => void triggerCountryValidation())
              }}
            />
          }
          label={t('user_form.form.country_access_all')}
        />
      </Stack>

      <Stack direction="column" gap={2}>
        <FormControl component="fieldset" variant="standard">
          <FormGroup row>
            {AVAILABLE_COUNTRIES.map((country) => (
              <CheckboxController
                key={country}
                checked={allowedCountries?.includes(country)}
                controllerProps={{ control }}
                label={t(`common.country.${country}`)}
                name="allowedCountries"
                showError={false}
                value={country}
                onChange={handleCountry}
              />
            ))}
          </FormGroup>
        </FormControl>
        <FormHelperText error>{formState.errors.allowedCountries?.message}</FormHelperText>
      </Stack>
    </FormGroup>
  )
}
