import type { ResourceGlobalFilters, ResourceType } from '@/features/resource/types'

export const COUNTRY_CODE_FILTER_NAME = 'countryCode'
export const CUSTOMER_ID_FILTER_NAME = 'customerId'
export const RESOURCE_TYPE_FILTER_NAME = 'resourceType'

export function deserializeGlobalFiltersFromSearchParams(): ResourceGlobalFilters {
  const params = new URLSearchParams(window.location.search)

  return {
    countryCode: params.get(COUNTRY_CODE_FILTER_NAME) ?? '',
    customerId: params.get(CUSTOMER_ID_FILTER_NAME) ?? '',
    resourceType: (params.get(RESOURCE_TYPE_FILTER_NAME) as ResourceType | null) ?? undefined,
  }
}

export function deserializeGlobalFilterSearchParamsObject(): Record<string, string> {
  const params = new URLSearchParams(window.location.search)

  return {
    countryCode: params.get(COUNTRY_CODE_FILTER_NAME) ?? '',
    customerId: params.get(CUSTOMER_ID_FILTER_NAME) ?? '',
    resourceType: params.get(RESOURCE_TYPE_FILTER_NAME) ?? '',
  }
}

export function serializeGlobalFiltersToSearchParamsObject(
  globalFilters: ResourceGlobalFilters,
): Record<string, string> {
  return {
    [COUNTRY_CODE_FILTER_NAME]: globalFilters.countryCode ?? '',
    [CUSTOMER_ID_FILTER_NAME]: globalFilters.customerId ?? '',
    [RESOURCE_TYPE_FILTER_NAME]: globalFilters.resourceType ?? '',
  }
}
