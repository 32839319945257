import { useTheme } from '@mui/material/styles'
import type { FC } from 'react'

import type { Volume } from '@/features/bidding/types/bid'
import { convertToRoundedMw, round } from '@/features/bidding/utils/calculations/convertToRoundedMw'

type Props = {
  row: { offeredVolume: Volume; acceptedVolume?: Volume }
}

export const AcceptedQuantityDiffCell: FC<Props> = ({ row }) => {
  const theme = useTheme()

  const acceptedWatts = row.acceptedVolume?.quantity
  if (!acceptedWatts) return <span>{'-'}</span>

  const acceptedMw = convertToRoundedMw(acceptedWatts)
  const offeredWatts = row.offeredVolume.quantity
  const diff = offeredWatts ? acceptedMw - convertToRoundedMw(offeredWatts) : 0

  return (
    <span style={diff !== 0 ? { color: theme.palette.warning.main } : {}}>
      {`${acceptedMw} MW`}
      {diff !== 0 && (
        <span style={{ color: theme.palette.warning.main }}>{` (${diff > 0 ? '+' : ''}${round(diff)})`}</span>
      )}
    </span>
  )
}
