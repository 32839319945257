import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid'
import { gridFilterModelSelector, GridToolbarFilterButton, useGridApiContext } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import GridSearchOutlined from '@/components/dataDisplay/GridSearchOutlined'
import GridToolbar from '@/components/dataDisplay/GridToolbar'
import { SingleSelectColumnToolbarFilter } from '@/components/inputs/SingleSelectColumnToolbarFilter'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends SelectedCustomersDataGridToolbarProps {}
}

type SelectedCustomersDataGridToolbarProps = {
  locations: string[]
  partnerNames: string[]
  showExtraColumns: boolean
}

const PARTNER_FILTER_NAME = 'partner'
const LOCATION_FILTER_NAME = 'location'

export const DEFAULT_DATA_GRID_SELECTED_CUSTOMERS_FILTER_MODEL: GridFilterModel = {
  items: [
    { id: PARTNER_FILTER_NAME, field: PARTNER_FILTER_NAME, operator: 'is', value: '' },
    { id: LOCATION_FILTER_NAME, field: LOCATION_FILTER_NAME, operator: 'is', value: '' },
  ],
  quickFilterValues: [],
}

const SelectedCustomersDataGridToolbar = ({
  locations,
  partnerNames,
  showExtraColumns,
}: SelectedCustomersDataGridToolbarProps) => {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()
  const filterModel = gridFilterModelSelector(apiRef)
  const partner = getFilterItemValue(filterModel, PARTNER_FILTER_NAME) ?? ''
  const location = getFilterItemValue(filterModel, LOCATION_FILTER_NAME) ?? ''

  function handleFilterChange(filterName: string, items: GridFilterItem[]) {
    updateDataGridFilter(apiRef, filterName, items[0])
  }

  return (
    <GridToolbar
      filters={
        <>
          <GridSearchOutlined
            label={t('common.navigation.search')}
            placeholder={t('users.customer.search.placeholder')}
          />
          {showExtraColumns && (
            <>
              <SingleSelectColumnToolbarFilter
                currentFilterValue={partner}
                field="partner"
                label={t('common.partner')}
                options={partnerNames}
                onFilterModelChange={({ items }) => handleFilterChange(PARTNER_FILTER_NAME, items)}
              />
              <SingleSelectColumnToolbarFilter
                currentFilterValue={location}
                field="location"
                label={t('common.location')}
                options={locations}
                onFilterModelChange={({ items }) => handleFilterChange(LOCATION_FILTER_NAME, items)}
              />
              <GridToolbarFilterButton />
            </>
          )}
        </>
      }
    />
  )
}

export default SelectedCustomersDataGridToolbar
