import { ContactsOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCustomersQuery } from '@/features/customer/hooks/useCustomersQuery'
import NumericWidget from '@/features/dashboard/components/NumericWidget'
import { NumericWidgetSkeleton } from '@/features/dashboard/components/NumericWidgetSkeleton'

function CustomerCountWidget() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { customers, isLoading } = useCustomersQuery()

  if (isLoading) {
    return <NumericWidgetSkeleton />
  }

  return (
    <NumericWidget
      icon={<ContactsOutlined color="secondary" fontSize="large" />}
      title={t('component.navbar.customers')}
      value={customers?.length ?? 0}
      onClick={() => navigate(`/customers`)}
    />
  )
}

export default CustomerCountWidget
