import type { TFunction } from 'i18next'

import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import type { ResourceLifecycleStage } from '@/features/resource/types'

const BASE_TRANSLATION_KEY = 'component.resource_lifecycle_stage_chip'

export function getLifecycleStageLabel(lifecycleStage: ResourceLifecycleStage, t: TFunction<'translation'>): string {
  switch (lifecycleStage) {
    case 'DRAFT':
      return t(`${BASE_TRANSLATION_KEY}.draft`)
    case 'LIVE':
      return t(`${BASE_TRANSLATION_KEY}.live`)
    case 'DECOMMISSIONED':
      return t(`${BASE_TRANSLATION_KEY}.decommissioned`)
    case 'INTEGRATED':
      return t(`${BASE_TRANSLATION_KEY}.integrated`)
    case 'VERIFIED':
      return t(`${BASE_TRANSLATION_KEY}.verified`)
    default:
      return t(`${BASE_TRANSLATION_KEY}.unknown`)
  }
}

export function getLifecycleStageOptions(
  lifecycleStages: ResourceLifecycleStage[],
  t: TFunction<'translation'>,
): SelectFieldOption<ResourceLifecycleStage>[] {
  return lifecycleStages.map((lifecycleStage) => ({
    id: lifecycleStage,
    label: getLifecycleStageLabel(lifecycleStage, t),
    value: lifecycleStage,
  }))
}
