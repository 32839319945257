import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import type { EditableTextFieldProps } from '@/features/customer/components/EditableTextField'
import EditableTextField from '@/features/customer/components/EditableTextField'

export type EditableTextFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Omit<EditableTextFieldProps, 'name'> & {
  name: TName
  // It enables showing field validation errors. Its default value is true
  showError?: boolean
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'render' | 'name'>
}

function EditableTextFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  showError = true,
  controllerProps,
  fullWidth,
  ...fieldProps
}: EditableTextFieldControllerProps<TFieldValues, TName>) {
  const { control } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error, invalid } }) => (
        <EditableTextField
          {...field}
          error={showError && invalid}
          fullWidth={fullWidth ?? true}
          helperText={error?.message}
          name={name}
          value={value ?? ''}
          {...fieldProps}
        />
      )}
    />
  )
}

export default EditableTextFieldController
