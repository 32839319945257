import { Box } from '@mui/material'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import PageHeader from '@/components/layouts/PageHeader'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import ExternalReferenceButtons from '@/features/bidding/components/ExternalReferenceButtons'
import BidManualAcceptTabs from '@/features/bidding/components/manualAccept/BidManualAcceptTabs'
import { ALL_RESULTS, ManualAcceptFlow } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useBidHistoriesPageQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import { usePortfoliosQuery } from '@/features/bidding/hooks/usePortfoliosQuery'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'
import { logMessageInSentry } from '@/features/sentry/utils/sendExceptions'

type Params = {
  manualAcceptFlow: ManualAcceptFlow
}

function BidManualAccept() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedCountry } = useBiddingContext()

  const { manualAcceptFlow } = useParams<Params>()
  const groupParams = getGroupedBidParamsFromUrl(location)
  const { portfolios } = usePortfoliosQuery({ countryCode: selectedCountry })
  const { bidHistoriesPage } = useBidHistoriesPageQuery(
    {
      pagination: { pageSize: ALL_RESULTS, page: 0 },
      sorting: [],
      filter: {
        items: groupParams
          ? [
              { field: 'portfolio', operator: 'equals', value: groupParams.portfolioCode },
              { field: 'marketProgram', operator: 'equals', value: groupParams.marketProgram },
              { field: 'deliveryDay', operator: 'equals', value: groupParams.deliveryDay },
            ]
          : [],
      },
      countryFilter: selectedCountry,
    },
    { enabled: !!groupParams },
  )

  useEffect(() => {
    if (!manualAcceptFlow || !Object.values(ManualAcceptFlow).includes(manualAcceptFlow)) {
      logMessageInSentry(`${manualAcceptFlow} is not a valid EditFlow value`)
      navigate('/bidding', { replace: true })
    }
  }, [manualAcceptFlow, navigate])

  useEffect(() => {
    if (!groupParams) navigate('/bidding', { replace: true })
  }, [groupParams, navigate])

  if (!bidHistoriesPage || !portfolios || !groupParams || !manualAcceptFlow) {
    return <FullPageSpinner />
  }

  return (
    <>
      <PageHeader
        appBarContent={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box display="flex" flexDirection="row" sx={{ flex: 1, justifyContent: 'left' }}>
              <CountrySelector disabled />
            </Box>
            <ExternalReferenceButtons
              marketProgram={groupParams.marketProgram}
              portfolio={portfolios.find((portfolio) => portfolio.code === groupParams.portfolioCode)!}
            />
          </Box>
        }
        breadcrumbItems={[{ text: t('component.page_header.bidding'), to: '/bidding' }]}
        pageTitle={t('component.page_header.bid.create.label')}
      />
      <BidManualAcceptTabs
        bidHistories={bidHistoriesPage.bidHistories}
        groupParams={groupParams}
        manualAcceptFlow={manualAcceptFlow}
      />
    </>
  )
}

export default BidManualAccept
