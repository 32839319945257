import Chip from '@mui/material/Chip'
import type { Theme } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import type { ComponentProps } from 'react'

type CustomChipProps = ComponentProps<typeof Chip>

function getCustomStyles(theme: Theme, { variant, color }: CustomChipProps) {
  if (variant === 'outlinedInactive') {
    return { color: theme.palette.text.disabled }
  }

  if (variant === 'filled' && color === 'default') {
    return { color: theme.palette.text.primary }
  }

  if (variant === 'outlined' && color === 'default') {
    return { color: theme.palette.text.secondary }
  }

  return {}
}

/**
 * It customizes the Chip component from MUI by adding a new variant called "outlinedInactive".
 *
 * The outlinedInactive variant is the same as the outlined variant, but it only has one available color.
 */
const CustomChip = (props: CustomChipProps) => {
  const { variant, color, sx } = props
  const theme = useTheme()

  if (variant === 'outlinedInactive') {
    return <Chip {...props} color="default" sx={{ ...sx, ...getCustomStyles(theme, props) }} variant="outlined" />
  }

  return <Chip {...props} color={color} sx={{ ...sx, ...getCustomStyles(theme, props) }} variant={variant} />
}

export default CustomChip
