import { Box } from '@mui/material'
import React from 'react'

import type { CustomSelectFieldProps } from '@/components/inputs/CustomSelectField'

type Alignment = 'center' | 'left' | 'right'

type ButtonBarProps = {
  children: React.ReactNode
  alignment?: Alignment
  sx?: CustomSelectFieldProps<string>['sx']
}

const ButtonBar: React.FC<ButtonBarProps> = ({ children, alignment = 'center', sx }) => (
  <Box
    display="flex"
    flexDirection="row"
    flexWrap="wrap"
    gap={2}
    my={2}
    sx={{ ...sx, width: '100%', justifyContent: alignment }}
  >
    {children}
  </Box>
)

export default ButtonBar
