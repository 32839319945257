import type { AxiosError } from 'axios'

type APIErrorResponse = {
  errorType?: string
  message?: string
}

export function errorHandler(err: unknown, genericMessage: string): Error {
  function handleAxiosError(err: AxiosError, genericMessage: string) {
    const errorData = err.response?.data

    if (errorData && typeof errorData === 'string') {
      return new Error(errorData)
    }

    if (errorData && typeof errorData === 'object') {
      return new Error((errorData as APIErrorResponse)?.message ?? genericMessage)
    }

    return new Error(genericMessage)
  }

  const errorType = typeof err

  if (errorType === 'object') {
    return handleAxiosError(err as AxiosError, genericMessage)
  }

  return new Error(genericMessage)
}
