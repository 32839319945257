import type { GridColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import type { Customer, Integration } from '@/features/integration/types'

import CustomerControllersGridToolbar, {
  DEFAULT_DATA_GRID_CUSTOMER_CONTROLLERS_FILTER_MODEL,
} from './CustomerControllersGridToolbar'

type CustomerControllersGridProps = {
  customer: Customer
}

function CustomerControllersGrid({ customer }: Readonly<CustomerControllersGridProps>) {
  const { t } = useTranslation()

  const columns: GridColDef<Integration>[] = [
    {
      field: 'uuid',
      headerName: '',
      flex: 1,
    },
    {
      field: 'description',
      headerName: '',
      flex: 1,
    },
    {
      field: 'status',
      headerName: '',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
    },
  ]
  const tableTitle = t('integration_customer_details.customer_controllers.table.title', { customerName: customer.name })

  return (
    <CustomDataGrid
      aria-label={tableTitle}
      columnHeaderHeight={0}
      columns={columns}
      getRowId={(row) => row.uuid}
      initialState={{
        filter: {
          filterModel: DEFAULT_DATA_GRID_CUSTOMER_CONTROLLERS_FILTER_MODEL,
        },
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
      }}
      rows={[]}
      slots={{
        toolbar: CustomerControllersGridToolbar,
      }}
      sx={{
        '& .MuiDataGrid-columnHeader': {
          display: 'none',
        },
      }}
      title={tableTitle}
    />
  )
}

export default CustomerControllersGrid
