import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_MARKET_PROGRAMS_API_ID, getMarketPrograms } from '@/features/customer/endpoints/marketPrograms'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { UseQueryOptions } from '@/types/queries'

export type UseMarketProgramsQueryResult = Omit<UseQueryResult, 'data'> & {
  marketPrograms: MarketProgram[] | null
}

export function useMarketProgramsQuery(options?: UseQueryOptions<MarketProgram[]>): UseMarketProgramsQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_MARKET_PROGRAMS_API_ID],
    queryFn: () => getMarketPrograms(),
  })

  return { marketPrograms: data, ...queryResult }
}

export function useInvalidateMarketProgramsQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_MARKET_PROGRAMS_API_ID] })
}
