import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  GET_ACTIVATION_GROUP_RESOURCES_API,
  getActivationGroupResources,
} from '@/features/activationGroup/endpoints/activationGroups'
import type { ApiActivationGroupResource } from '@/features/activationGroup/types'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { UseQueryOptions } from '@/types/queries'

type UseActivationGroupResourcesQueryParams = {
  activationGroupUuid: string
}

export type UseActivationGroupResourcesQueryResult = Omit<UseQueryResult, 'data'> & {
  resources: ApiActivationGroupResource[] | null
}
export function useActivationGroupResourcesQuery(
  params: UseActivationGroupResourcesQueryParams,
  options?: UseQueryOptions<ApiActivationGroupResource[]>,
): UseActivationGroupResourcesQueryResult {
  const { data = null, ...queryResult } = useQuery<ApiActivationGroupResource[]>({
    ...options,
    queryFn: () => getActivationGroupResources(params.activationGroupUuid),
    queryKey: [GET_ACTIVATION_GROUP_RESOURCES_API, params],
  })

  useErrorHandler(queryResult.isError)

  return {
    ...queryResult,
    resources: data,
  }
}

export function useInvalidateActivationGroupResourcesQuery(params: UseActivationGroupResourcesQueryParams) {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_ACTIVATION_GROUP_RESOURCES_API, params] })
}
