import { useTheme } from '@mui/material/styles'
import type { FC } from 'react'

import type { Volume } from '@/features/bidding/types/bid'
import { convertToRoundedMw, round } from '@/features/bidding/utils/calculations/convertToRoundedMw'

type Props = {
  row: { offeredVolume: Volume; forecastedVolume?: Volume }
}

export const OfferedCapacityDiffCell: FC<Props> = ({ row }) => {
  const theme = useTheme()

  const offeredMw = convertToRoundedMw(row.offeredVolume.quantity)
  const forecastedWatts = row.forecastedVolume?.quantity
  const diff = forecastedWatts ? offeredMw - convertToRoundedMw(forecastedWatts) : 0

  return (
    <span>
      {`${offeredMw} MW`}
      {diff !== 0 && (
        <span style={{ color: theme.palette.warning.main }}>{` (${diff > 0 ? '+' : ''}${round(diff)})`}</span>
      )}
    </span>
  )
}
