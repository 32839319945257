import type { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useIsFetching, useQuery, useQueryClient } from '@tanstack/react-query'

import type { BidHistoriesFilter } from '@/features/bidding/endpoints/bids'
import { GET_BID_HISTORIES_API_ID, getBidHistories } from '@/features/bidding/endpoints/bids'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { BidHistoriesPage } from '@/features/bidding/types/bidHistory'
import type { UseQueryOptions } from '@/types/queries'

type BidHistoriesPageQueryParams = {
  pagination: GridPaginationModel
  sorting: GridSortModel
  filter: GridFilterModel
  countryFilter: CountryIdentifier
}

export type UseBidHistoriesPageQueryResult = Omit<UseQueryResult, 'data'> & {
  bidHistoriesPage?: BidHistoriesPage
}

const buildBidHistoriesFilter = (filterModel: GridFilterModel): BidHistoriesFilter =>
  filterModel.items.reduce((filter, item) => {
    if ('activationGroup' === item.field) {
      filter['activationGroupUuid'] = item.value
    } else if ('portfolio' === item.field) {
      filter['portfolioCode'] = item.value
    } else {
      filter[item.field] = item.value
    }
    return filter
  }, {})

export function useBidHistoriesPageQuery(
  params: BidHistoriesPageQueryParams,
  options?: UseQueryOptions<BidHistoriesPage>,
): UseBidHistoriesPageQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BID_HISTORIES_API_ID, params],
    queryFn: () =>
      getBidHistories(params.pagination, params.sorting, buildBidHistoriesFilter(params.filter), params.countryFilter),
  })

  useErrorHandler(queryResult.isError)

  return { bidHistoriesPage: data, ...queryResult }
}

export function useInvalidateBidHistoriesQuery() {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries({ queryKey: [GET_BID_HISTORIES_API_ID] })
}

export const useIsFetchingBidHistories = (): boolean => useIsFetching({ queryKey: [GET_BID_HISTORIES_API_ID] }) > 0
