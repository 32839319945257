import { useMutation } from '@tanstack/react-query'

import { createRestriction } from '@/features/customer/endpoints/restrictions'
import type { CreateRestrictionRequest } from '@/features/customer/types/restriction'

export type UseCreateRestrictionMutationResult = ReturnType<typeof useCreateRestrictionMutation>

export function useCreateRestrictionMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; restriction: CreateRestrictionRequest }) =>
      createRestriction(variables.customerUuid, variables.restriction),
  })

  return {
    createRestriction: mutateAsync,
    ...mutationResult,
  }
}
