import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import type { Bid } from '@/features/bidding/types/bid'

type Props = {
  selectedVersion: Bid
  bidVersions: Bid[]
  setSelectedVersion: (newBidVersion: Bid) => void
}

const BidVersionSelector: FC<Props> = ({ selectedVersion, bidVersions, setSelectedVersion }) => {
  const { t } = useTranslation()
  const sortedBidVersions = bidVersions.toSorted((bid1, bid2) => bid1.createdAt.valueOf() - bid2.createdAt.valueOf())

  const onVersionChange = (newVersionId: number | string | undefined) => {
    if (!newVersionId) return

    const newBidVersion = bidVersions.find((bidVersion) => bidVersion.id === newVersionId)

    if (newBidVersion) {
      setSelectedVersion(newBidVersion)
    }
  }

  return (
    <CustomSelectField
      id="version-selector"
      label={t('bidding.results.version')}
      options={sortedBidVersions.map((version) => ({
        id: String(version.id),
        value: version.id,
        label: t('bidding.results.bid_version', {
          versionNumber: sortedBidVersions.indexOf(version) + 1,
          date: version.createdAt.toFormat('DD'),
        }),
      }))}
      size="medium"
      sx={{ minWidth: 90 }}
      value={selectedVersion.id}
      onChange={(event) => onVersionChange(event.target.value)}
    />
  )
}

export default BidVersionSelector
