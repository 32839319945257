import type { Theme } from '@mui/material'
import type { ButtonProps } from '@mui/material/Button'
import Button from '@mui/material/Button'
import type { ElementType } from 'react'

import { useMuiTheme } from '@/features/whitelabel/hooks/useMuiTheme'

export type CustomButtonProps<C extends ElementType> = Omit<ButtonProps<C, { component?: C }>, 'color'> & {
  color?: ButtonProps['color'] | CustomColor
}

const CUSTOM_COLORS = new Set(['inheritText', 'inheritWhite'])

function CustomButton<C extends ElementType>({ color = 'primary', variant, ...props }: CustomButtonProps<C>) {
  const theme = useMuiTheme()

  if (CUSTOM_COLORS.has(color)) {
    const colors = getCustomColors(theme, color as CustomColor)

    switch (variant) {
      case 'contained': {
        return (
          <Button
            {...props}
            color="inherit"
            sx={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            variant={variant}
          />
        )
      }
      case 'outlined': {
        return (
          <Button
            {...props}
            color="inherit"
            sx={{
              backgroundColor: 'transparent',
              color: colors.text,
              border: `1px solid ${colors.text}`,
            }}
            variant={variant}
          />
        )
      }
      // variant text
      default: {
        return (
          <Button
            {...props}
            color="inherit"
            sx={{
              backgroundColor: 'transparent',
              color: colors.text,
            }}
            variant={variant}
          />
        )
      }
    }
  }

  return <Button {...props} color={color as ButtonProps['color']} variant={variant} />
}

export default CustomButton

type CustomColor = 'inheritText' | 'inheritWhite'

function getCustomColors(theme: Theme, color: CustomColor) {
  if (color === 'inheritText') {
    return {
      background: theme.palette.background.paper,
      text: theme.palette.text.primary,
    }
  }

  // inheritWhite
  return {
    background: theme.palette.grey[300],
    text: theme.palette.common.white,
  }
}
