import axios from 'axios'

import { getCountryAsAlpha2Code } from '@/constants/country'
import environment from '@/environment'
import type { UsePortfoliosQueryParams } from '@/features/bidding/hooks/usePortfoliosQuery'
import type { Portfolio } from '@/features/bidding/types/bid'

const PORTFOLIO_MANAGER_API_URL = environment.services.portfolioManagerApiUrl

export const GET_PORTFOLIOS_API_ID = 'GET_PORTFOLIOS'

export const getPortfolios = async (params?: UsePortfoliosQueryParams): Promise<Portfolio[]> =>
  (await axios.get(`${PORTFOLIO_MANAGER_API_URL}/portfolio`, { params: buildPortfolioParams(params) })).data

const buildPortfolioParams = (params?: UsePortfoliosQueryParams) => {
  const urlParams = new URLSearchParams()
  if (params?.countryCode) {
    urlParams.append('countryCode', getCountryAsAlpha2Code(params.countryCode))
  }
  return urlParams
}
