import { Parser } from '@json2csv/plainjs'
import fileDownload from 'js-file-download'
import { useTranslation } from 'react-i18next'

import CsvIcon from '@/assets/icons/csv-download.svg'
import CustomButton from '@/components/inputs/CustomButton'
import type { RevenueDataRow } from '@/features/customer/types/revenue'

type ExportCsvButtonProps = {
  data: RevenueDataRow[]
  year: string
  marketProgramName: string
  customerName: string
}
const CsvDownloadIcon = () => <img alt="Csv Icon" src={CsvIcon} />

export function ExportCsvButton({ data, year, marketProgramName, customerName }: Readonly<ExportCsvButtonProps>) {
  const { t } = useTranslation()
  const exportCsv = () => {
    const translatedTimestamp = t('customer_revenue.timestamp')
    const translatedCapacity = t('customer_revenue.capacity')
    const translatedSoldCapacity = t('customer_revenue.sold_capacity')
    const translatedRevenue = t('customer_revenue.revenue.label')
    const columnLabels = [translatedTimestamp, translatedCapacity, translatedSoldCapacity, translatedRevenue]

    const csvData: Record<string, any> = []
    for (const row of data) {
      csvData.push({
        [translatedTimestamp]: row.timestamp,
        [translatedCapacity]: row.capacity.toFixed(2),
        [translatedSoldCapacity]: row.soldCapacity.toFixed(2),
        [translatedRevenue]: row.revenue.toFixed(2),
      })
    }

    const parser = new Parser({
      fields: columnLabels,
    })
    const csv = parser.parse(csvData)
    const translatedRevenues = t('customer_revenue.csv_export.revenues')
    const fileName = `${translatedRevenues} - ${year} - ${marketProgramName} - ${customerName}.csv`
    return fileDownload(csv, fileName)
  }

  return (
    <CustomButton startIcon={<CsvDownloadIcon />} variant="outlinedWhite" onClick={exportCsv}>
      {t('customer_revenue.csv_export.export')}
    </CustomButton>
  )
}
