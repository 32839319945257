import { FormGroup, Grid } from '@mui/material'
import type { SelectChangeEvent } from '@mui/material/Select'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import type { UserRole } from '@/constants/userRoles'
import { USER_ROLES } from '@/constants/userRoles'
import type { User } from '@/features/user/types/user'

interface RoleSectionProps {
  roleOptions: UserRole[]
}

export default function RoleSection({ roleOptions }: Readonly<RoleSectionProps>) {
  const { t } = useTranslation()
  const { setValue, trigger, control } = useFormContext<User>()

  function triggerRoleValidation() {
    void trigger('role')
  }

  function handleRole(event: SelectChangeEvent) {
    setValue('role', event.target.value as UserRole, { shouldValidate: true })
    resetValues()
    preselectCountryIfApplicable(event.target.value)
    turnOnMfaIfAdminOrPartnerAdmin(event.target.value)
    setIsRestrictedToTrueForResourceOwnerAndCustomerManager(event.target.value)
    triggerRoleValidation()
  }

  function resetValues() {
    setValue('allowedRoIds', [])
    setValue('allowedCountries', [])
    setValue('isRestricted', false)
    setValue('partnerCode', '')
  }

  function preselectCountryIfApplicable(role: string) {
    if (role === USER_ROLES.ACTIVATION_AGENTS.value) {
      setValue('allowedCountries', ['nl'])
    }
  }

  function turnOnMfaIfAdminOrPartnerAdmin(role: string) {
    if (role === USER_ROLES.ADMINISTRATORS.value || role === USER_ROLES.PARTNER_ADMINISTRATORS.value) {
      setValue('isMFAEnabled', true)
    }
  }

  function setIsRestrictedToTrueForResourceOwnerAndCustomerManager(role: string) {
    if (role === USER_ROLES.RESOURCE_OWNERS.value || role === USER_ROLES.CUSTOMER_MANAGERS.value) {
      setValue('isRestricted', true)
    }
  }

  return (
    <FormGroup>
      <Grid container>
        <Grid item md={5} sm={12} xs={12}>
          <Controller
            control={control}
            name="role"
            render={({ field: { value, ref: fieldRef, ...field }, fieldState: { error, invalid } }) => (
              <CustomSelectField
                {...field}
                ref={fieldRef}
                fullWidth
                error={invalid}
                helperText={error?.message}
                id="rights-role"
                label={t('user_form.form.rights_role_label')}
                name="role"
                options={roleOptions.map((role) => ({
                  id: role,
                  value: role,
                  label: t(`users.role.${role}`),
                  tooltip: t(`users.role.${role as UserRole}_description`),
                }))}
                value={value ?? ''}
                onChange={handleRole}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormGroup>
  )
}
