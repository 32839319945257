import type { BiddingGlobalFilters } from '@/features/bidding/types'

export const COUNTRY_CODE_FILTER_NAME = 'countryCode'

export function deserializeGlobalFiltersFromSearchParams(): BiddingGlobalFilters {
  const params = new URLSearchParams(window.location.search)

  return {
    countryCode: (params.get(COUNTRY_CODE_FILTER_NAME) as CountryIdentifier) ?? '',
  }
}

export function deserializeGlobalFilterSearchParamsObject(): Record<string, string> {
  const params = new URLSearchParams(window.location.search)

  return {
    countryCode: params.get(COUNTRY_CODE_FILTER_NAME) ?? '',
  }
}

export function serializeGlobalFiltersToSearchParamsObject(
  globalFilters: BiddingGlobalFilters,
): Record<string, string> {
  return {
    [COUNTRY_CODE_FILTER_NAME]: globalFilters.countryCode ?? '',
  }
}
