import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import type { SelectChangeEvent } from '@mui/material'
import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import type { ActivationGroup } from '@/features/activationGroup/types'
import { ActivationGroupState } from '@/features/activationGroup/types'
import { useIncludeFieldBasedOnNordicsFcrdDownStaticToggle } from '@/features/bidding/hooks/useIncludeFieldBasedOnNordicsFcrdDownStaticToggle'
import type { MarketProgramBiddingName } from '@/features/bidding/types/bid'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends ActivationGroupsDataGridToolbarProps {}
}

export type ActivationGroupsDataGridToolbarProps = {
  onAddNewActivationGroup: () => void
  activationGroups: ActivationGroup[] | null
}

const ActivationGroupsDataGridToolbar = ({
  onAddNewActivationGroup,
  activationGroups,
}: ActivationGroupsDataGridToolbarProps) => {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()

  useIncludeFieldBasedOnNordicsFcrdDownStaticToggle()

  const filterModel = gridFilterModelSelector(apiRef)
  const marketProgram = getFilterItemValue<MarketProgramBiddingName>(filterModel, 'marketProgram') ?? ''
  const portfolio = getFilterItemValue<string>(filterModel, 'portfolio.code') ?? ''
  const state = getFilterItemValue<string>(filterModel, 'state') ?? ''

  const handleFilterChange = (field: string, value: string) => {
    updateDataGridFilter(apiRef, field, { id: field, field, operator: 'equals', value })
  }

  const blankOption = { value: '', label: '-', id: `filter-item-blank` }

  return (
    <GridToolbar
      actions={
        <CustomIconButton
          Icon={AddOutlinedIcon}
          aria-label={t('activation_groups.create_activation_group_button_label')}
          color="primary"
          variant={'solid'}
          onClick={onAddNewActivationGroup}
        />
      }
      filters={
        <>
          <CustomSelectField
            data-testid="state-selector"
            id="state"
            label={t('activation_groups.table.header.state')}
            options={[blankOption, ...getStateOptions(t)]}
            sx={{ minWidth: FIELD_WIDTH }}
            value={state}
            onChange={(event: SelectChangeEvent) => handleFilterChange('state', event.target.value)}
          />
          <CustomSelectField
            data-testid="portfolio-selector"
            id="portfolio.code"
            label={t('bidding.overview.header.portfolio')}
            options={[blankOption, ...getPortfolioCodeOptions(activationGroups)]}
            sx={{ minWidth: FIELD_WIDTH }}
            value={portfolio}
            onChange={(event: SelectChangeEvent) => handleFilterChange('portfolio.code', event.target.value)}
          />
          <CustomSelectField
            data-testid="market-program-selector"
            id="marketProgram"
            label={t('bidding.overview.header.market_program')}
            options={[blankOption, ...getMarketProgramOptions(activationGroups, t)]}
            sx={{ minWidth: FIELD_WIDTH }}
            value={marketProgram}
            onChange={(event: SelectChangeEvent) => handleFilterChange('marketProgram', event.target.value)}
          />
        </>
      }
    />
  )
}

const getStateOptions = (t: TFunction<'translation'>): SelectFieldOption<string>[] =>
  Object.values(ActivationGroupState).map((value) => ({
    id: value,
    label: t(`activation_groups.state.${value}`),
    value: value,
  }))

const filterUniqueSelectFields = <T extends { value: string }>(selectFields: T[]): T[] => {
  const uniqueSelectFields = new Set<string>()
  return selectFields.filter((selectField) => {
    if (uniqueSelectFields.has(selectField.value)) {
      return false
    }
    uniqueSelectFields.add(selectField.value)
    return true
  })
}

const getPortfolioCodeOptions = (activationGroups: ActivationGroup[] | null): SelectFieldOption<string>[] => {
  const selectFields = (activationGroups || []).map((activationGroup) => ({
    id: activationGroup.portfolio.code,
    value: activationGroup.portfolio.code,
    label: activationGroup.portfolio.code,
  }))
  return filterUniqueSelectFields(selectFields)
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label))
}

const getMarketProgramOptions = (activationGroups: ActivationGroup[] | null, t: TFunction<'translation'>) => {
  const selectFields = (activationGroups || []).map((activationGroup) => ({
    id: activationGroup.marketProgram,
    value: t(`bidding.market_program.${activationGroup.marketProgram}`),
    label: t(`bidding.market_program.${activationGroup.marketProgram}`),
  }))
  return filterUniqueSelectFields(selectFields)
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label))
}

export default ActivationGroupsDataGridToolbar
