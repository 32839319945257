import type { TFunction } from 'i18next'

import type { ControlBoxType } from '@/features/resource/types'

export function getControlBoxTypeLabel(controlBoxType: ControlBoxType | null, t: TFunction<'translation'>): string {
  if (!controlBoxType || controlBoxType === 'UNKNOWN') {
    return t('common.unknown')
  }

  return t(
    `resources.control_box_types.${controlBoxType.toLowerCase() as Lowercase<Exclude<ControlBoxType, 'UNKNOWN'>>}`,
  )
}
