import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'

const commonProps = {
  component: Link,
  disableRipple: true,
  iconPosition: 'start',
  style: { minHeight: 0 },
} as const

type ResourceTabsProps = {
  activeTab: string
}

export const ResourceTabs = ({ activeTab }: ResourceTabsProps) => {
  const { resourceUuid } = useParams()
  const { t } = useTranslation()

  const { resource } = useResourceWithPollingQuery({ id: resourceUuid! })

  const batteryControlPort = resource?.controlPorts.find((port) => port.controlBoxType === 'BATTERY')

  return (
    <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs aria-label="resource-tabs" value={activeTab}>
        <Tab
          {...commonProps}
          label={t('resources.tabs.general')}
          to={ResourceRouteInformation.RESOURCE_GENERAL.navigationPath(resourceUuid!)}
          value={ResourceRouteInformation.RESOURCE_GENERAL.routePath}
        />
        <Tab
          {...commonProps}
          label={t('resources.tabs.steering')}
          to={ResourceRouteInformation.RESOURCE_STEERING.navigationPath(resourceUuid!)}
          value={ResourceRouteInformation.RESOURCE_STEERING.routePath}
        />
        {batteryControlPort && (
          <Tab
            {...commonProps}
            label={t('resources.tabs.battery_steering')}
            to={ResourceRouteInformation.RESOURCE_BATTERY_STEERING.navigationPath(
              resourceUuid!,
              batteryControlPort.controlPortID,
            )}
            value={ResourceRouteInformation.RESOURCE_BATTERY_STEERING.routePath}
          />
        )}
      </Tabs>
    </Box>
  )
}
