import { Box, Stack } from '@mui/material'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { CustomerCompanyInfo } from '@/features/customer/components/companyInfo/CustomerCompanyInfo'
import { CustomerContacts } from '@/features/customer/components/companyInfo/CustomerContacts'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'

export const CompanyInfo = () => {
  const { loggedInUser } = useAuth()
  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid },
    { enabled: Boolean(customerUuid) },
  )
  const { t } = useTranslation()

  function getPageContent() {
    if (!customer || isFetchingCustomer) return null

    return (
      <Box my={3} sx={{ width: '100%' }}>
        <Stack sx={{ flexDirection: 'row', width: '100%', gap: 2 }}>
          <Paper sx={{ p: 3, height: 'fit-content', width: '100%' }}>
            <CustomerCompanyInfo viewOnly customer={customer} />
          </Paper>

          <Paper sx={{ p: 3, height: 'fit-content', width: '100%' }}>
            <CustomerContacts viewOnly customer={customer} />
          </Paper>
        </Stack>
      </Box>
    )
  }

  return (
    <>
      <PageHeader breadcrumbItems={[]} pageTitle={t('component.page_header.company_info')} />
      {getPageContent()}
    </>
  )
}
