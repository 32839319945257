import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import type { ActivationGroupDetailsPageConfig } from '@/features/activationGroup/pages/ActivationGroupDetails'

const commonProps = {
  component: Link,
  disableRipple: true,
  iconPosition: 'start',
  style: { minHeight: 0 },
} as const

type ActivationGroupTabsProps = {
  pageConfig: ActivationGroupDetailsPageConfig
}

export const ActivationGroupTabs = ({ pageConfig }: ActivationGroupTabsProps) => {
  const { activationGroupUuid } = useParams()
  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs aria-label="activationGroup-tabs" value={pageConfig.activeTab}>
        <Tab
          {...commonProps}
          label={t('activation_groups.tabs.resources')}
          to={ActivationGroupRouteInformation.ACTIVATION_GROUP_RESOURCES.navigationPath(activationGroupUuid!)}
          value={ActivationGroupRouteInformation.ACTIVATION_GROUP_RESOURCES.routePath}
        />
        <Tab
          {...commonProps}
          label={t('activation_groups.tabs.schedules')}
          to={ActivationGroupRouteInformation.ACTIVATION_GROUP_SCHEDULES.navigationPath(activationGroupUuid!)}
          value={ActivationGroupRouteInformation.ACTIVATION_GROUP_SCHEDULES.routePath}
        />
        <Tab
          {...commonProps}
          label={t('activation_groups.tabs.prequalification_patterns')}
          to={ActivationGroupRouteInformation.ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS.navigationPath(
            activationGroupUuid!,
          )}
          value={ActivationGroupRouteInformation.ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS.routePath}
        />
      </Tabs>
    </Box>
  )
}
