import type { GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro'
import { createContext, useContext, useRef } from 'react'

import {
  deserializeFilterSearchParamsObjectFromSearchParams,
  serializeFilterModelToSearchParamsObject,
} from '@/utils/datagrid/filters'
import {
  deserializePaginationSearchParamsObjectFromSearchParams,
  serializePaginationModelToSearchParamsObject,
} from '@/utils/datagrid/pagination'
import {
  deserializeSortSearchParamsObjectFromSearchParams,
  serializeSortModelToSearchParamsObject,
} from '@/utils/datagrid/sorting'
import { syncUrlManagerBuilder } from '@/utils/syncUrlManager'

type DataGridSyncUrlManager = {
  syncFilterSearchParams: (newFilterModel: GridFilterModel) => void
  syncPaginationSearchParams: (newPaginationModel: Partial<GridPaginationModel>) => void
  syncSortSearchParams: (newSortModel: GridSortModel) => void
  syncGlobalFilterSearchParams: (newGlobalFilters: Record<string, string>) => void
}

type DataGridSyncUrlManagerProviderProps = {
  deserializeGlobalFilterSearchParamsObject?: () => Record<string, string>
  children: React.ReactNode
}

type DataGridSyncUrlManagerBuildParams = {
  deserializeGlobalFilterSearchParamsObject?: () => Record<string, string>
}

export const DataGridSyncUrlManagerContext = createContext<DataGridSyncUrlManager>({
  syncFilterSearchParams: () => {},
  syncPaginationSearchParams: () => {},
  syncSortSearchParams: () => {},
  syncGlobalFilterSearchParams: () => {},
})

function dataGridSyncUrlManagerBuilder({
  deserializeGlobalFilterSearchParamsObject,
}: DataGridSyncUrlManagerBuildParams) {
  const syncUrlManager = syncUrlManagerBuilder()

  let filterParams: Record<string, string> | null = deserializeFilterSearchParamsObjectFromSearchParams()
  let paginationParams: Record<string, string> | null = deserializePaginationSearchParamsObjectFromSearchParams()
  let sortParams: Record<string, string> | null = deserializeSortSearchParamsObjectFromSearchParams()
  let globalFilterParams: Record<string, string> | null = deserializeGlobalFilterSearchParamsObject?.() ?? {}

  function syncUrl() {
    syncUrlManager.setParams({
      ...filterParams,
      ...paginationParams,
      ...sortParams,
      ...globalFilterParams,
    })

    syncUrlManager.syncUrl()
  }

  return {
    syncFilterSearchParams(newFilterModel: GridFilterModel) {
      filterParams = serializeFilterModelToSearchParamsObject(newFilterModel)

      syncUrl()
    },

    syncPaginationSearchParams(newPaginationModel: Partial<GridPaginationModel>) {
      paginationParams = serializePaginationModelToSearchParamsObject(newPaginationModel)

      syncUrl()
    },

    syncSortSearchParams(newSortModel: GridSortModel) {
      sortParams = serializeSortModelToSearchParamsObject(newSortModel)

      syncUrl()
    },

    syncGlobalFilterSearchParams(newGlobalFilters: Record<string, string>) {
      globalFilterParams = newGlobalFilters

      // Reset pagination params when global filters change
      if (paginationParams) {
        paginationParams = { ...paginationParams, page: '0' }
      }

      syncUrl()
    },
  }
}

/**
 * It provides a context to manage the sync between the changes on the
 * DataGrid and the url. CustomDataGrid component is actually using the hook
 * useDataGridSyncUrlManager to automatically sync the url when pagination, filtering
 * and sorting changes happen.
 *
 * Although it's main use case is to sync the DataGrid with the url, it can be used
 * to sync any component. For example, it can be useful to sync the url after a change
 * on filters from the AppBar content.
 */
export const DataGridSyncUrlManagerProvider = ({
  children,
  deserializeGlobalFilterSearchParamsObject,
}: DataGridSyncUrlManagerProviderProps) => {
  const { current: syncUrlManager } = useRef(
    dataGridSyncUrlManagerBuilder({ deserializeGlobalFilterSearchParamsObject }),
  )

  return (
    <DataGridSyncUrlManagerContext.Provider value={syncUrlManager}>{children}</DataGridSyncUrlManagerContext.Provider>
  )
}

export function useDataGridSyncUrlManager() {
  return useContext(DataGridSyncUrlManagerContext)
}
