import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'

export function useIncludeFieldBasedOnNordicsFcrdDownStaticToggle() {
  const { isEnabled } = useFeatureToggle()

  return (field: string, match: string) => {
    return field != match || isEnabled('FLEX_PORTAL_NORDICS_FCRD_DOWN_BIDDING_ENABLED')
  }
}

export function useIncludeFieldBasedOnSwedenMfrrUpToggle() {
  const { isEnabled } = useFeatureToggle()

  return (field: string, match: string) => {
    return field != match || isEnabled('FLEX_PORTAL_SWEDEN_MFRR_UP_BIDDING_ENABLED')
  }
}
