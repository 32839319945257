import { fetchAuthSession } from 'aws-amplify/auth'
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios'

// Refresh user session
// https://github.com/aws-amplify/amplify-js/issues/2560#issuecomment-657251846
export function withAuthorizationTokenInterceptor(client: AxiosInstance) {
  client.interceptors.request.use(
    async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig<any>> => {
      try {
        const { tokens } = await fetchAuthSession()

        if (!tokens) {
          return config
        }

        const { accessToken } = tokens

        config.headers.Authorization = `Bearer ${accessToken}`

        return config
      } catch {
        return config
      }
    },
  )
}

export function withAuthenticationErrorInterceptor(client: AxiosInstance, onError: () => void) {
  // Add a 401 response interceptor
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        onError()
      }
      return Promise.reject(error)
    },
  )
}

export function withForbidenErrorInterceptor(client: AxiosInstance, onError: () => void) {
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 403) {
        onError()
      }
      return Promise.reject(error)
    },
  )
}
