import { Stack } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import type { ComponentProps, ReactNode } from 'react'

import PageHeader from './PageHeader'

type PageHeaderProps = ComponentProps<typeof PageHeader>

type PageProps = {
  pageHeaderProps?: PageHeaderProps
  children: ReactNode
  isLoading?: boolean
}

const Page = ({ pageHeaderProps, children, isLoading = false }: PageProps) => {
  if (isLoading) {
    return (
      <Stack sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <CircularProgress title="Full page spinner" />
      </Stack>
    )
  }
  return (
    <>
      {pageHeaderProps && <PageHeader {...pageHeaderProps} />}

      {children}
    </>
  )
}

export default Page
