import axios from 'axios'

import environment from '@/environment'
import type { WhitelabelConfiguration } from '@/features/whitelabel/types/whitelabelConfiguration'

const WHITELABEL_API_URL = environment.apiInternalBaseUrl + '/public/api/v1/whitelabel'

export async function getWhitelabelConfiguration(hostname: string): Promise<WhitelabelConfiguration> {
  const response = await axios.get(`${WHITELABEL_API_URL}/${hostname}`)
  return response.data
}
