import AddIcon from '@mui/icons-material/Add'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import { Box } from '@mui/material'
import type { GridFilterModel, GridPaginationModel } from '@mui/x-data-grid-pro'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { useDataGridSyncUrlManager } from '@/contexts/DataGridSyncUrlManagerContext'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { DEFAULT_DATA_GRID_BIDS_FILTER_MODEL } from '@/features/bidding/components/BidsFilterToolbar'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import BidsOverviewDataGrid from '@/features/bidding/components/overview/BidsOverviewDataGrid'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { usePortfoliosQuery } from '@/features/bidding/hooks/usePortfoliosQuery'
import { serializeGlobalFiltersToSearchParamsObject } from '@/features/bidding/utils/globalFilters'
import { deserializeFilterModelFromSearchParams } from '@/utils/datagrid/filters'
import { deserializePaginationModelFromSearchParams } from '@/utils/datagrid/pagination'

const BiddingOverview = () => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const { activationGroups } = useActivationGroupsQuery({ countryCode: selectedCountry })
  const { portfolios } = usePortfoliosQuery({ countryCode: selectedCountry })
  const syncUrlManager = useDataGridSyncUrlManager()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    () => deserializePaginationModelFromSearchParams() ?? DEFAULT_DATA_GRID_PAGINATION_MODEL,
  )
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    () => deserializeFilterModelFromSearchParams() ?? DEFAULT_DATA_GRID_BIDS_FILTER_MODEL,
  )

  function handleSelectedCountryChange(countryCode: CountryIdentifier) {
    setPaginationModel({
      ...paginationModel,
      page: 0,
    })
    setFilterModel(DEFAULT_DATA_GRID_BIDS_FILTER_MODEL)
    syncUrlManager.syncGlobalFilterSearchParams(serializeGlobalFiltersToSearchParamsObject({ countryCode }))
  }

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ width: '100%' }}>
            <Box display="flex" flexDirection="row" sx={{ flex: 1, justifyContent: 'left' }}>
              <CountrySelector onChange={handleSelectedCountryChange} />
            </Box>
            <ButtonBar alignment="right" sx={{ flex: 1 }}>
              <CustomButton
                color="inheritWhite"
                component={Link}
                startIcon={<GetAppOutlinedIcon />}
                to="/bidding/exports"
                variant="outlined"
              >
                {t(`bidding.exports.title`)}
              </CustomButton>
              <CustomButton
                color="inheritText"
                component={Link}
                startIcon={<AddIcon />}
                sx={{ color: 'black' }}
                to="/bidding/todo-list"
                variant="contained"
              >
                {t(`bidding.overview.add_new_bid`)}
              </CustomButton>
            </ButtonBar>
          </Box>
        }
        pageTitle={t('component.page_header.bidding')}
      />

      <BidsOverviewDataGrid
        activationGroups={activationGroups}
        filterModel={filterModel}
        paginationModel={paginationModel}
        portfolios={portfolios}
        selectedCountry={selectedCountry}
        setFilterModel={setFilterModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  )
}

export default BiddingOverview
