import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { Activation } from '@/features/activation/types/activation'
import { GET_ACTIVATION_API_ID, getActivation } from '@/features/customer/endpoints/activations'
import type { UseQueryOptions } from '@/types/queries'

type ActivationQueryParams = {
  customerUuid: string
  activationId: number
  location?: string
}
export type UseActivationQueryResult = Omit<UseQueryResult, 'data'> & {
  activation: Activation | null
}

export function useActivationQuery(
  params: ActivationQueryParams,
  options?: UseQueryOptions<Activation>,
): UseActivationQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_ACTIVATION_API_ID, params],
    queryFn: () => getActivation(params.customerUuid, params.activationId, params.location),
  })

  return { activation: data, ...queryResult }
}
