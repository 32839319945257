import type { SelectChangeEvent } from '@mui/material'
import type { GridFilterModel } from '@mui/x-data-grid'
import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import DeliveryWindowSelector from '@/features/bidding/components/DeliveryWindowSelector'
import MarketProgramSelector from '@/features/bidding/components/MarketProgramSelector'
import type { MarketProgramBiddingName } from '@/features/bidding/types/bid'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

export const DEFAULT_DATA_GRID_BID_EXPORT_FILTER_MODEL: GridFilterModel = {
  items: [
    {
      id: 'createdAt',
      field: 'createdAt',
      operator: 'equals',
      value: null,
    },
    {
      id: 'deliveryDay',
      field: 'deliveryDay',
      operator: 'equals',
      value: null,
    },
    {
      id: 'marketProgram',
      field: 'marketProgram',
      operator: 'equals',
      value: null,
    },
  ],
}

const BidExportFilterToolbar = () => {
  const apiRef = useGridApiContext()
  const { t, i18n } = useTranslation()

  const filterModel = gridFilterModelSelector(apiRef)
  const deliveryDay = getFilterItemValue<string>(filterModel, 'deliveryDay') ?? ''
  const marketProgram = getFilterItemValue<MarketProgramBiddingName>(filterModel, 'marketProgram') ?? ''

  const handleFilterChange = (field: string, value: string | DateTime | null) => {
    updateDataGridFilter(apiRef, field, { id: field, field, operator: 'equals', value })
  }

  return (
    <GridToolbar
      filters={
        <>
          <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
            <DatePicker
              format="DD"
              label={t('bidding.exports.header.created_at')}
              onChange={(value) => handleFilterChange('createdAt', value)}
            />
          </LocalizationProvider>
          <DeliveryWindowSelector
            value={deliveryDay}
            onChange={(event: SelectChangeEvent) => handleFilterChange('deliveryDay', event.target.value)}
          />
          <MarketProgramSelector
            value={marketProgram}
            onChange={(event: SelectChangeEvent) => handleFilterChange('marketProgram', event.target.value)}
          />
        </>
      }
    />
  )
}

export default BidExportFilterToolbar
