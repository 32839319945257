import type { GpsLocation } from '@/types/gpsLocation'

const MULTIPLIER = 100000

export function fromGoogleMapsCoordinates(gmapsLocation) {
  // Convert float to int32 and rename
  return {
    lat: gmapsLocation.lat() * MULTIPLIER,
    lon: gmapsLocation.lng() * MULTIPLIER,
  }
}

export function toGoogleMapsCoordinates(location: GpsLocation) {
  if (location.lat && location.lon) {
    // Convert int32 to float and rename
    return {
      lat: location.lat / MULTIPLIER,
      lng: location.lon / MULTIPLIER,
    }
  }
}
