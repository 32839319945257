import type { GridColDef } from '@mui/x-data-grid'
import type { DateTime as DateTimeType } from 'luxon'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import type { MeasuredPowerSerie } from '@/features/measurement/types/measuredPower'
import { displayValueInTheRightUnit } from '@/features/measurement/utils/units'

type LatestMeasurementsDataGridProps = {
  isLoading: boolean
  measuredPowerSeries: MeasuredPowerSerie[]
  startTime: DateTimeType
  endTime: DateTimeType
}

function LatestMeasurementsDataGrid({
  endTime,
  isLoading,
  measuredPowerSeries,
  startTime,
}: Readonly<LatestMeasurementsDataGridProps>) {
  const { t } = useTranslation()

  const data = measuredPowerSeries.map((serie) => {
    let minPower = Number.MAX_SAFE_INTEGER
    let maxPower = Number.MIN_SAFE_INTEGER

    serie.data.forEach((value) => {
      if (value < minPower) minPower = value
      if (value > maxPower) maxPower = value
    })

    return {
      uuid: serie.uuid,
      name: serie.name,
      lastMeasured: serie.data[serie.data.length - 1],
      minPower,
      maxPower,
    }
  })

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('measurements.latest_measurements_table.power_meter_name'),
      flex: 2,
    },
    {
      field: 'lastMeasured',
      headerName: t('measurements.latest_measurements_table.last_measured'),
      flex: 1,
      valueFormatter: displayValueInTheRightUnit,
    },
    {
      field: 'minPower',
      headerName: t('measurements.latest_measurements_table.min_power'),
      flex: 1,
      valueFormatter: displayValueInTheRightUnit,
    },
    {
      field: 'maxPower',
      headerName: t('measurements.latest_measurements_table.max_power'),
      flex: 1,
      valueFormatter: displayValueInTheRightUnit,
    },
  ]

  return (
    <CustomDataGrid
      columns={columns}
      getRowId={(row) => row.uuid}
      initialState={{
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
      }}
      isLoading={isLoading}
      rows={data}
      title={t('measurements.latest_measurements_table.title', {
        startTime: startTime.toLocaleString(DateTime.DATETIME_SHORT),
        endTime: endTime.toLocaleString(DateTime.DATETIME_SHORT),
      })}
    />
  )
}

export default LatestMeasurementsDataGrid
