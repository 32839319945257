import { DateTime } from 'luxon'

import { convertToTimeZoneDateTime, formatDateTimeWithLocale } from '@/utils/time'

export function formatLocalizedDateTimeMedWithSeconds(
  customerTimeZone: string,
  language: string,
  dateTimeISO?: string,
) {
  if (!dateTimeISO) {
    return '-'
  }
  const timeZoneDateTime = convertToTimeZoneDateTime(customerTimeZone, dateTimeISO)
  return formatDateTimeWithLocale(timeZoneDateTime, language, DateTime.DATETIME_MED_WITH_SECONDS)
}
