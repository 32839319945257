import type { CountryCode } from '@/components/dataDisplay/FlagIcon'

export interface InterfaceLanguage {
  value: Language
  name: string
  countryCode: CountryCode
}

const ENGLISH: InterfaceLanguage = {
  value: 'en',
  name: 'English',
  countryCode: 'gb',
}

const DUTCH: InterfaceLanguage = {
  value: 'nl',
  name: 'Dutch',
  countryCode: 'nl',
}

const FINNISH: InterfaceLanguage = {
  value: 'fi',
  name: 'Suomi',
  countryCode: 'fi',
}

const GREEK: InterfaceLanguage = {
  value: 'el',
  name: 'Greek',
  countryCode: 'gr',
}

const SWEDISH: InterfaceLanguage = {
  value: 'sv',
  name: 'Svenska',
  countryCode: 'se',
}

const HUNGARIAN: InterfaceLanguage = {
  value: 'hu',
  name: 'Hungary',
  countryCode: 'hu',
}

export const INTERFACE_LANGUAGES = [DUTCH, ENGLISH, FINNISH, GREEK, SWEDISH, HUNGARIAN]

export const INTERFACE_LANGUAGES_KEYS = {
  DUTCH,
  ENGLISH,
  FINNISH,
  GREEK,
  SWEDISH,
  HUNGARIAN,
}
export const DEFAULT_INTERFACE_LANGUAGE = ENGLISH

export type Language = 'el' | 'en' | 'fi' | 'nl' | 'sv' | 'hu'
