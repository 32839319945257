import type { GridFilterModel, GridPaginationModel } from '@mui/x-data-grid'
import axios from 'axios'

import { getCountryAsAlpha2Code } from '@/constants/country'
import { PORTFOLIO_MANAGER_API_URL } from '@/features/bidding/endpoints/bids'
import type { GroupedBidsPage } from '@/features/bidding/types/groupedBid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { toInternalGroupedBid } from '@/features/bidding/utils/model/modelConversion'
import { getFilterItemValue } from '@/utils/datagrid/filters'

export type GroupedBidsPageQueryParams = {
  country: CountryIdentifier
  filter: GridFilterModel
  pagination: GridPaginationModel
}

export const getGroupedBids = async (params: GroupedBidsPageQueryParams): Promise<GroupedBidsPage> => {
  const response = await axios.get(`${PORTFOLIO_MANAGER_API_URL}/grouped-bid`, {
    params: buildGroupedBidsParams(params),
  })
  return {
    groupedBids: response.data.groupedBids.map((groupedBid: any) => toInternalGroupedBid(groupedBid)),
    currentPage: response.data.currentPage,
    totalCount: response.data.totalCount,
  }
}

const buildGroupedBidsParams = (params: GroupedBidsPageQueryParams) => {
  const urlParams = new URLSearchParams()

  urlParams.append('page', String(params.pagination.page))
  urlParams.append('pageSize', String(params.pagination.pageSize))
  urlParams.append('countryCode', getCountryAsAlpha2Code(params.country))

  const portfolio = getFilterItemValue(params.filter, 'portfolio')
  const marketProgram = getFilterItemValue(params.filter, 'marketProgram')
  const deliveryDay = getFilterItemValue<MarketDate>(params.filter, 'deliveryDay')

  if (portfolio) urlParams.append('portfolioCode', portfolio)
  if (marketProgram) urlParams.append('marketProgram', marketProgram)
  if (deliveryDay) urlParams.append('deliveryDay', deliveryDay.toISODate())

  return urlParams
}
