import type { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { z } from 'zod'

import type { AvailabilityStatus } from '@/features/availability/types/availabilityStatus'
import type { ServiceRule } from '@/features/availability/types/serviceRule'

const luxonDateTime = z.preprocess((arg) => {
  if (arg instanceof DateTime) {
    return arg.toJSDate()
  }
  return arg
}, z.date())

export type ServiceRuleForm = Pick<ServiceRule, 'serviceId' | 'limitValue'> & {
  id?: number
  startDate: DateTime
  endDate: DateTime
  startTime: DateTime
  endTime: DateTime
  exceptionDates: ExceptionDate[]
  recurringDays: string[]
  selectedResources: number[]
  location: string
  status?: AvailabilityStatus
}

export type ExceptionDate = {
  range: boolean
  startDate: DateTime
  endDate?: DateTime
}

export const getServiceRuleFormSchema = (t: TFunction) =>
  z
    .object({
      startDate: luxonDateTime,
      endDate: luxonDateTime,
      startTime: luxonDateTime,
      endTime: luxonDateTime,
      limitValue: z.number(),
      exceptionDates: z.array(
        z
          .object({
            range: z.boolean(),
            startDate: luxonDateTime,
            endDate: luxonDateTime.optional(),
          })
          .refine(
            (schema) => {
              return !schema.range || schema.endDate
            },
            {
              message: t('customer_details.tabs.availability.common.form.error.end_date_required'),
              path: ['endDate'],
            },
          )
          .refine(
            (schema) => {
              if (schema.range) {
                return schema.endDate && schema.startDate <= schema.endDate
              }
              return true
            },
            {
              message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
              path: ['endDate'],
            },
          ),
      ),
      selectedResources: z.array(z.number()),
      recurringDays: z.array(z.string()),
      location: z.string(),
    })
    .refine(
      (schema) => {
        return schema.startDate <= schema.endDate
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
        path: ['endDate'],
      },
    )
    .refine(
      (schema) => {
        return schema.endTime.getTime() > schema.startTime.getTime()
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_time_before_start_time'),
        path: ['endTime'],
      },
    )
    .refine(
      (schema) => {
        return schema.recurringDays.length > 0
      },
      {
        message: t('customer_details.tabs.availability.availability_details.dialog.error.recurring_days_required'),
        path: ['recurringDays'],
      },
    )
