import type { Location } from 'react-router-dom'

import type { MarketProgram } from '@/features/bidding/constants'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import { logMessageInSentry } from '@/features/sentry/utils/sendExceptions'

const getGroupedBidParamsFromUrl = (location: Location<any>): GroupedBidParams | null => {
  const searchParams = new URLSearchParams(location.search)

  const deliveryDay = searchParams.get('deliveryDay')
  const marketProgram = searchParams.get('marketProgram')
  const portfolioCode = searchParams.get('portfolioCode')

  if (deliveryDay && marketProgram && portfolioCode) {
    return {
      marketProgram: marketProgram as MarketProgram,
      deliveryDay: new MarketDate(deliveryDay),
      portfolioCode,
    }
  }

  logMessageInSentry(
    `Bid create URL params missing: deliveryDay: ${deliveryDay}, marketProgram: ${marketProgram}, portfolioCode: ${portfolioCode}`,
  )
  return null
}

export default getGroupedBidParamsFromUrl
