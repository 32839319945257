import { ManualAcceptFlow, Status } from '@/features/bidding/constants'
import type { Bid } from '@/features/bidding/types/bid'

export const isManuallyAcceptable = (status: Status): boolean => {
  return status == Status.OFFERED
}

export const isManuallyEditable = (status: Status): boolean => {
  return [Status.MANUALLY_EDITED, Status.MANUALLY_ACCEPTED, Status.ACCEPTED, Status.PARTIALLY_ACCEPTED].includes(status)
}

export const updateBidStatus = (bid: Bid, manualAcceptFlow: ManualAcceptFlow): Bid => {
  switch (manualAcceptFlow) {
    case ManualAcceptFlow.CREATE:
      return {
        ...bid,
        status: Status.MANUALLY_ACCEPTED,
      }
    case ManualAcceptFlow.EDIT:
      return {
        ...bid,
        status: Status.MANUALLY_EDITED,
      }
    default:
      throw new Error(`Invalid edit flow: ${manualAcceptFlow}`)
  }
}
