import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createActivationGroupPrequalificationPattern } from '@/features/activationGroup/endpoints/activationGroups'
import type {
  ActivationGroupPrequalificationPattern,
  CreatePrequalificationPattern,
} from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseCreatePrequalificationPatternMutationResult = Omit<
  UseMutationResult<ActivationGroupPrequalificationPattern, Error, CreatePrequalificationPattern>,
  'mutateAsync'
> & {
  createPrequalificationPattern: UseMutationResult<
    ActivationGroupPrequalificationPattern,
    Error,
    CreatePrequalificationPattern
  >['mutateAsync']
}

export function useCreatePrequalificationPatternMutation(
  options?: UseMutationOptions<ActivationGroupPrequalificationPattern, CreatePrequalificationPattern>,
): UseCreatePrequalificationPatternMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: CreatePrequalificationPattern) => createActivationGroupPrequalificationPattern(variables),
  })

  return { createPrequalificationPattern: mutateAsync, ...mutationResult }
}
