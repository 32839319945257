import { FormGroup, Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TextFieldController from '@/components/inputs/TextFieldController'
import type { User, UserAuthenticationStatusEnum } from '@/features/user/types/user'

interface CredentialsSectionProps {
  editMode: boolean
  authenticationStatus?: UserAuthenticationStatusEnum
}

export default function CredentialsSection({ editMode }: Readonly<CredentialsSectionProps>) {
  const { t } = useTranslation()
  const { setValue } = useFormContext<User>()

  return (
    <FormGroup>
      <Grid container gap={3}>
        <Grid item md={5} sm={12}>
          <TextFieldController
            required
            disabled={editMode}
            label={t('user_form.form.username_label')}
            name="username"
            onChange={(event) => {
              // no spaces allowed
              const value = event.target.value.replace(/\s+/g, '')

              setValue('username', value)
            }}
          />
        </Grid>
      </Grid>
    </FormGroup>
  )
}
