import { isIBAN } from 'validator'

// Returns true if the iban is valid
// Returns false if the iban is invalid
export function validateIban(iban: string | null): boolean {
  if (!iban) {
    return true
  }
  const ibanWithoutSpaces = formatIban(iban)
  return isIBAN(ibanWithoutSpaces)
}

// Removes inserted white spaces from iban
export function formatIban(iban: string | null): string {
  if (!iban) {
    return ''
  }
  return iban.replace(/ /g, '')
}
