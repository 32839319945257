import CheckIcon from '@mui/icons-material/CheckOutlined'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import { Box, Stack, Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import FloatTextField from '@/components/inputs/FloatTextField'

type ReadOnlyBoxProps = {
  formattedPrice: string
  disabled?: boolean
  onEdit: () => void
  onDelete: () => void
}

type WritableBoxProps = {
  initialPrice: number
  error?: string
  onSubmit: (price: number) => void
  onChange?: (price: number | null) => void
  onCancel: () => void
}

export type Mode = 'view' | 'edit'

export type EditablePriceBoxProps = {
  initialPrice: number
  currency: string
  locales: string
  error?: string
  initialMode?: Mode
  disabled?: boolean
  onSubmit: (prevPrice: number, newPrice: number) => void
  onPriceChange?: (prevPrice: number, price: number | null) => void
  onDelete?: (price: number) => void
  onCancel?: () => void
  onModeChange?: (newMode: Mode) => void
}

const ReadOnlyBox = ({ formattedPrice, disabled, onEdit, onDelete }: ReadOnlyBoxProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Box sx={{ flex: 1, paddingX: 1.25 }}>
        <CustomTypography variant="body2">{formattedPrice}</CustomTypography>
      </Box>

      <Stack direction="row" gap={1}>
        <CustomIconButton
          Icon={EditIcon}
          disabled={disabled}
          iconProps={{ titleAccess: t('component.editable_price_box.edit') }}
          onClick={onEdit}
        />
        <CustomIconButton
          Icon={DeleteIcon}
          disabled={disabled}
          iconProps={{ titleAccess: t('component.editable_price_box.delete') }}
          onClick={onDelete}
        />
      </Stack>
    </>
  )
}

const WritableBox = ({ initialPrice, error, onCancel, onChange, onSubmit }: WritableBoxProps) => {
  const { t } = useTranslation()
  const [price, setPrice] = useState<number | null>(initialPrice)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // Focus the input field when the component is mounted
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  function handlePriceChange(newPrice: number | undefined) {
    setPrice(newPrice ?? null)
    onChange?.(newPrice ?? null)
  }

  // It submits the new price if there is not any validation error
  function handlePriceSubmit() {
    if (!error) {
      onSubmit(price!)
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handlePriceSubmit()
    }
  }

  return (
    <>
      <Tooltip
        disableFocusListener
        disableTouchListener
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'error.main',
            },
          },
        }}
        placement="top"
        title={error ?? ''}
      >
        <Box sx={{ flex: 1, paddingX: 1.25 }}>
          <FloatTextField
            ref={inputRef}
            error={Boolean(error)}
            initialValue={price?.toString() ?? ''}
            numberOfDecimals={2}
            textFieldProps={{
              placeholder: t('component.editable_price_box.input_price_placeholder'),
              onKeyDown: handleKeyDown,
            }}
            onValueChange={handlePriceChange}
          />
        </Box>
      </Tooltip>

      <Stack direction="row" gap={1}>
        <CustomIconButton
          Icon={CloseIcon}
          iconProps={{ titleAccess: t('component.editable_price_box.cancel') }}
          variant="solid"
          onClick={onCancel}
        />
        <CustomIconButton
          Icon={CheckIcon}
          color="success"
          disabled={Boolean(error) || price === null}
          iconProps={{ titleAccess: t('component.editable_price_box.submit') }}
          variant="solid"
          onClick={handlePriceSubmit}
        />
      </Stack>
    </>
  )
}

const EditablePriceBox = ({
  initialPrice,
  currency,
  locales,
  initialMode,
  error,
  disabled,
  onPriceChange,
  onDelete,
  onSubmit,
  onCancel,
  onModeChange,
}: EditablePriceBoxProps) => {
  const [mode, setMode] = useState<Mode>(initialMode ?? 'view')
  const formattedPrice = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    unitDisplay: 'narrow',
  }).format(initialPrice)

  function handlePriceDelete() {
    onDelete?.(initialPrice)
  }

  function handleEditModeChange() {
    const newMode = 'edit'

    setMode(newMode)
    onModeChange?.(newMode)
  }

  function handleViewModeChange() {
    const newMode = 'view'

    setMode(newMode)
    onModeChange?.(newMode)
  }

  function handlePriceChange(newPrice: number | null) {
    onPriceChange?.(initialPrice, newPrice)
  }

  function handleCancel() {
    onCancel?.()
    handleViewModeChange()
  }

  function handleSubmit(newPrice: number) {
    handleViewModeChange()
    onSubmit(initialPrice, newPrice)
  }

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      {mode === 'view' ? (
        <ReadOnlyBox
          disabled={disabled}
          formattedPrice={formattedPrice}
          onDelete={handlePriceDelete}
          onEdit={handleEditModeChange}
        />
      ) : (
        <WritableBox
          error={error}
          initialPrice={initialPrice}
          onCancel={handleCancel}
          onChange={handlePriceChange}
          onSubmit={handleSubmit}
        />
      )}
    </Stack>
  )
}

export default EditablePriceBox
