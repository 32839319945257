import type { UseQueryResult } from '@tanstack/react-query'
import { useIsFetching, useQuery, useQueryClient } from '@tanstack/react-query'

import type { GroupedBidsPageQueryParams } from '@/features/bidding/endpoints/groupedBids'
import { getGroupedBids } from '@/features/bidding/endpoints/groupedBids'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { GroupedBidsPage } from '@/features/bidding/types/groupedBid'
import type { UseQueryOptions } from '@/types/queries'

const GET_BID_GROUPS_API_ID = 'GET_BID_GROUPS'

export type UseGroupedBidsPageQueryResult = Omit<UseQueryResult, 'data'> & {
  groupedBidsPage: GroupedBidsPage | null
}

export const useGroupedBidsPageQuery = (
  params: GroupedBidsPageQueryParams,
  options?: UseQueryOptions<GroupedBidsPage>,
): UseGroupedBidsPageQueryResult => {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BID_GROUPS_API_ID, params],
    queryFn: () => getGroupedBids(params),
  })

  useErrorHandler(queryResult.isError)
  return { groupedBidsPage: data ?? null, ...queryResult }
}

export const useInvalidateGroupedBidsPageQuery = () => {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries({ queryKey: [GET_BID_GROUPS_API_ID] })
}

export const useIsFetchingGroupedBids = (): boolean => useIsFetching({ queryKey: [GET_BID_GROUPS_API_ID] }) > 0
