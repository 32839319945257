import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { getActivationGroupByUuid } from '@/features/activationGroup/utils/activationGroups'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'

type MinMaxBiddableVolumesTooltipProps = {
  activationGroupUuid: string
}

const MinMaxBiddableVolumesTooltip = ({ activationGroupUuid }: MinMaxBiddableVolumesTooltipProps) => {
  return (
    <Tooltip title={<TooltipTitle activationGroupUuid={activationGroupUuid} />}>
      <div data-testid="tooltip-trigger">
        <HelpOutlineIcon color="primary" fontSize="medium" />
      </div>
    </Tooltip>
  )
}

function TooltipTitle({ activationGroupUuid }) {
  const { t } = useTranslation()
  const { activationGroups } = useActivationGroupsQuery()
  const activationGroup = getActivationGroupByUuid(activationGroups, activationGroupUuid)

  return (
    <div aria-label="tooltip-content">
      {t(`bidding.bidding_limits.min_value`, {
        value: convertValueToMWOrDash(activationGroup?.biddableVolumeRange?.min),
      })}
      <br />
      {t(`bidding.bidding_limits.max_value`, {
        value: convertValueToMWOrDash(activationGroup?.biddableVolumeRange?.max),
      })}
    </div>
  )
}

function convertValueToMWOrDash(value: number | undefined) {
  return value ? convertToRoundedMw(value) : '-'
}

export default MinMaxBiddableVolumesTooltip
