import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import CustomButton from '@/components/inputs/CustomButton'
import { DEFAULT_INTERFACE_LANGUAGE } from '@/constants/interfaceLanguages'
import { USER_ROLES } from '@/constants/userRoles'
import { useAlertContext } from '@/contexts/AlertContext'
import type { Customer } from '@/features/customer/types/customer'
import type { Partner } from '@/features/partner/types/partner'
import DeleteUserButton from '@/features/user/components/form/DeleteUserButton'
import ResetPasswordButton from '@/features/user/components/form/ResetPasswordButton'
import UserAccountAccordion from '@/features/user/components/form/UserAccountAccordion'
import UserInterfaceAccordion from '@/features/user/components/form/UserInterfaceAccordion'
import UserRightsAccordion from '@/features/user/components/form/UserRightsAccordion'
import { ERROR_NAMES, saveUser as apiSaveUser } from '@/features/user/endpoints/users'
import { getUserFormSchema } from '@/features/user/schemas'
import type { User } from '@/features/user/types/user'
import { UserAuthenticationStatusEnum } from '@/features/user/types/user'
import { errorHandler } from '@/utils/errorHandler'

import CountryAccessSection from './form/CountryAccessSection'
import CredentialsSection from './form/CredentialsSection'
import CustomerAccessSection from './form/CustomerAccessSection'
import InformationSection from './form/InformationSection'
import InterfaceSection from './form/InterfaceSection'
import PartnerAccessSection from './form/PartnerAccessSection'
import RoleSection from './form/RoleSection'

interface UserFormProps {
  customers: Customer[]
  partners: Partner[]
  user?: Partial<User>
}

export const UserForm = ({ customers, partners, user }: UserFormProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pushAlert } = useAlertContext()

  const editMode = !!user
  const isPasswordResetAllowed = user?.authenticationStatus === UserAuthenticationStatusEnum.FORCECHANGEPASSWORD
  const allowedRoles = Object.values(USER_ROLES).map((role) => role.value)

  function isUserAdminOrPartnerAdmin() {
    return user?.role && (user.role === 'administrators' || user.role === 'partner_administrators')
  }

  const form = useForm<User>({
    mode: 'onBlur',
    defaultValues: {
      id: user?.id ?? '',
      username: user?.username ?? '',
      isMFAEnabled: isUserAdminOrPartnerAdmin() || (user?.isMFAEnabled ?? false),
      givenName: user?.givenName ?? '',
      familyName: user?.familyName ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      role: user?.role ?? undefined,
      allowedCountries: user?.allowedCountries ?? [],
      isRestricted: user?.isRestricted ?? false,
      allowedRoIds: user?.allowedRoIds ?? [],
      partnerCode: user?.partnerCode ?? undefined,
      uiLanguage: user?.uiLanguage ?? DEFAULT_INTERFACE_LANGUAGE.value,
      isActive: user?.isActive ?? true,
    },
    resolver: zodResolver(getUserFormSchema(t)),
  })

  useWatch({
    control: form.control,
  })

  function getPartnerCode(customerUuid: string | undefined) {
    return customers.find((customer) => customer.uuid === customerUuid)?.partner?.partnerCode
  }

  const { mutate: saveUser, isPending } = useMutation({
    mutationFn: () => apiSaveUser(form.getValues()),
    onError: (error: any) => {
      if (error.response.data.name === ERROR_NAMES.EMAIL_EXISTS) {
        form.setError('email', {
          type: 'alreadyExists',
          message: t('user_form.form.email_exists.error').toString(),
        })
      } else if (error.response.data.name === ERROR_NAMES.USERNAME_EXISTS) {
        form.setError('username', {
          type: 'alreadyExists',
          message: t('user_form.form.username_exists.error').toString(),
        })
      } else if (error.response.data.name === ERROR_NAMES.MFA_UPDATE_FAILED) {
        form.setError('isMFAEnabled', {
          type: 'updateFailed',
          message: t('user_form.form.mfa_update_failed.error').toString(),
        })
      } else if (
        error.response.data.name === ERROR_NAMES.VALIDATION_FAILED &&
        error.response.data.message.includes('phone')
      ) {
        form.setError('phone', {
          type: 'invalid',
          message: t('user_form.form.phone_invalid.error').toString(),
        })
      }
    },
    onSuccess: () => {
      navigate('/users')
    },
  })

  function onSubmit() {
    const role = form.getValues('role') ?? ''

    if (role === USER_ROLES.CUSTOMER_MANAGERS.value && form.getValues('partnerCode') === '') {
      form.setValue('partnerCode', undefined)
    }

    if (role === USER_ROLES.RESOURCE_OWNERS.value && !form.getValues('partnerCode')) {
      const allowedRoIds = form.getValues('allowedRoIds') ?? []
      const lastSelectedCustomerId = allowedRoIds.at(0)

      if (lastSelectedCustomerId) {
        form.setValue('partnerCode', getPartnerCode(lastSelectedCustomerId))
      }
    }

    // FIXME: reconsider what to do with numeralLanguage, for now it is the same as uiLanguage
    form.setValue('numeralLanguage', form.getValues().uiLanguage)

    saveUser()
  }

  function handleResetPasswordError(err: unknown) {
    const error = errorHandler(err, t('user_form.reset_password.error'))

    pushAlert({
      severity: 'error',
      message: error.message,
    })
  }

  return (
    <FormProvider {...form}>
      <Stack
        noValidate
        aria-label={t('user_form.form.title')}
        component="form"
        direction="column"
        gap={3}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Box>
          <UserAccountAccordion user={user}>
            <CredentialsSection authenticationStatus={user?.authenticationStatus} editMode={editMode} />
            <InformationSection />
            {user?.id && editMode && isPasswordResetAllowed && (
              <Box>
                <ResetPasswordButton userId={user.id} onResetPasswordError={handleResetPasswordError} />
              </Box>
            )}
          </UserAccountAccordion>

          <UserRightsAccordion>
            <RoleSection roleOptions={allowedRoles} />
            <CountryAccessSection />
            <PartnerAccessSection partners={partners} />
            <CustomerAccessSection customers={customers} partners={partners} />
          </UserRightsAccordion>

          <UserInterfaceAccordion defaultExpanded={!editMode}>
            <InterfaceSection />
          </UserInterfaceAccordion>
        </Box>

        <Stack direction="row" gap={1} justifyContent="space-between">
          <Stack direction="row" gap={1} justifyContent="flex-start">
            <CustomButton component={Link} disabled={isPending} to="/users" variant="outlined">
              {t('common.button.cancel')}
            </CustomButton>
            <CustomButton disabled={isPending} type="submit" variant="contained">
              {t('common.button.save')}
            </CustomButton>
          </Stack>
          {editMode && <DeleteUserButton disabled={isPending} user={user} />}
        </Stack>
      </Stack>
    </FormProvider>
  )
}
