import { Tooltip } from '@mui/material'
import type { ElementType, ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import type { CustomTypographyProps } from '@/components/dataDisplay/CustomTypography'
import CustomTypography from '@/components/dataDisplay/CustomTypography'

type EllipsisTypographyProps<C extends ElementType> = CustomTypographyProps<C> & {
  tooltipTitle?: ReactNode
}

/**
 * An extended version of the CustomTypography that truncates the text. If a tooltipTitle is provided, it will show a
 * tooltip with the full text when it is truncated.
 */
function EllipsisTypography<C extends ElementType>({ tooltipTitle, ...typographyProps }: EllipsisTypographyProps<C>) {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false)
  const ref = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (ref.current && Boolean(tooltipTitle)) {
      const el = ref.current

      setIsEllipsisActive(el.offsetWidth < el.scrollWidth)
    }
  }, [tooltipTitle])

  const typographyElement = <CustomTypography {...typographyProps} ref={ref} noWrap />

  return tooltipTitle && isEllipsisActive ? (
    <Tooltip placement="top" title={tooltipTitle}>
      {typographyElement}
    </Tooltip>
  ) : (
    typographyElement
  )
}

export default EllipsisTypography
