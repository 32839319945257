import type { IntegrationType } from '@/features/integration/types'

interface IntegrationIconProps {
  integrationType: IntegrationType
}

/**
 * Based on a integration type, it returns the name of the icon to be displayed. All the icons
 * are stored in /public/integration-types folder.
 */
function getIntegrationIcon(integrationType: IntegrationType): string {
  switch (integrationType) {
    case 'CLOUD':
      return '/integration-types/cloud.svg'
    case 'HARDWARE':
      return '/integration-types/hardware.svg'
    case 'PARTNER':
      return '/integration-types/partner.svg'
    default:
      return '/integration-types/unknown.svg'
  }
}

/**
 * Displays the icon of a specific kind of resource.
 */
const IntegrationIcon = ({ integrationType }: IntegrationIconProps) => {
  const iconUrl = getIntegrationIcon(integrationType)

  return <img alt={integrationType} height={24} src={iconUrl} width={24} />
}

export default IntegrationIcon
