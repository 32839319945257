import type { GridColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import type { ExampleFeatureValue } from '@/features/exampleFeature/types'

type ExampleFeatureGridProps = {
  exampleFeatureValues: ExampleFeatureValue[]
}

function ExampleFeatureGrid({ exampleFeatureValues }: Readonly<ExampleFeatureGridProps>) {
  const { t } = useTranslation()

  const columns: GridColDef<ExampleFeatureValue>[] = [
    {
      field: 'name',
      headerName: t('common.name'),
      flex: 2,
    },
    {
      field: 'monday',
      headerName: 'Monday',
      flex: 1,
      valueGetter: (_, row) => {
        return row.data[0]
      },
    },
    {
      field: 'tuesday',
      headerName: 'Tuesday',
      flex: 1,
      valueGetter: (_, row) => {
        return row.data[1]
      },
    },
    {
      field: 'wednesday',
      headerName: 'Wednesday',
      flex: 1,
      valueGetter: (_, row) => {
        return row.data[2]
      },
    },
    {
      field: 'thursday',
      headerName: 'Thursday',
      flex: 1,
      valueGetter: (_, row) => {
        return row.data[3]
      },
    },
    {
      field: 'friday',
      headerName: 'Friday',
      flex: 1,
      valueGetter: (_, row) => {
        return row.data[4]
      },
    },
    {
      field: 'saturday',
      headerName: 'Saturday',
      flex: 1,
      valueGetter: (_, row) => {
        return row.data[5]
      },
    },
    {
      field: 'sunday',
      headerName: 'Sunday',
      flex: 1,
      valueGetter: (_, row) => {
        return row.data[6]
      },
    },
  ]

  return (
    <CustomDataGrid
      disableColumnMenu
      disableColumnSorting
      aria-label={t('disturbance_details.activated_resources_table.title')}
      columns={columns}
      getRowId={(row) => row.name}
      initialState={{
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
      }}
      rows={exampleFeatureValues ?? []}
    />
  )
}

export default ExampleFeatureGrid
