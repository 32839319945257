import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid'
import { gridFilterModelSelector, GridToolbarFilterButton, useGridApiContext } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import GridSearchOutlined from '@/components/dataDisplay/GridSearchOutlined'
import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CountryFilter from '@/components/datagrid/CountryFilter'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends PartnersDataGridToolbarProps {}
}

const COUNTRY_CODE_FILTER_NAME = 'countryCode'

type PartnersDataGridToolbarProps = {
  countryOptions: CountryIdentifier[]
  onClickAddNewPartner: () => void
}

export const DEFAULT_DATA_GRID_PARTNERS_FILTER_MODEL: GridFilterModel = {
  items: [
    {
      id: 'countryCode',
      field: 'countryCode',
      operator: 'is',
      value: '',
    },
  ],
  quickFilterValues: [],
}

function PartnersDataGridToolbar({ countryOptions, onClickAddNewPartner }: Readonly<PartnersDataGridToolbarProps>) {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()

  const filterModel = gridFilterModelSelector(apiRef)
  const countryFilterValue = getFilterItemValue(filterModel, COUNTRY_CODE_FILTER_NAME) ?? ''

  function handleFilterChange(filterName: string, items: GridFilterItem[]) {
    updateDataGridFilter(apiRef, filterName, items[0])
  }

  return (
    <GridToolbar
      actions={
        <CustomIconButton
          Icon={AddOutlinedIcon}
          aria-label={t('partners.add_new_partner')}
          color="primary"
          variant={'solid'}
          onClick={onClickAddNewPartner}
        />
      }
      filters={
        <>
          <GridSearchOutlined
            label={t('common.navigation.search')}
            placeholder={t('partners.search.placeholder')}
            quickFilterParser={(searchInput: string) =>
              searchInput
                .split(',')
                .map((value) => value.trim())
                .filter((value) => value !== '')
            }
          />

          <CountryFilter
            countryOptions={countryOptions}
            field="countryCode"
            value={countryFilterValue}
            onChange={(items) => handleFilterChange(COUNTRY_CODE_FILTER_NAME, items)}
          />

          <GridToolbarFilterButton />
        </>
      }
    />
  )
}

export default PartnersDataGridToolbar
