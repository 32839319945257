import type { CSSProperties } from 'react'

type BottomCircleProps = {
  color: string
  style?: CSSProperties
}

const BottomCircle = ({ color, style }: BottomCircleProps) => {
  return (
    <svg fill="none" height="132" style={style} viewBox="0 0 287 132" width="287" xmlns="http://www.w3.org/2000/svg">
      <mask
        height="132"
        id="mask0_292_31842"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="287"
        x="0"
        y="0"
      >
        <rect fill={color} height="132" width="287" />
      </mask>
      <g mask="url(#mask0_292_31842)">
        <path
          clipRule="evenodd"
          d="M91.4823 404.037C202.723 404.037 292.902 313.858 292.902 202.617C292.902 91.376 202.723 1.19734 91.4823 1.19734C-19.7588 1.19734 -109.938 91.376 -109.938 202.617C-109.938 313.858 -19.7588 404.037 91.4823 404.037ZM91.4825 317.598C154.984 317.598 206.463 266.119 206.463 202.617C206.463 139.115 154.984 87.6365 91.4825 87.6365C27.9805 87.6365 -23.498 139.115 -23.498 202.617C-23.498 266.119 27.9805 317.598 91.4825 317.598Z"
          fill={color}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M91.4823 404.037C202.723 404.037 292.902 313.858 292.902 202.617C292.902 91.376 202.723 1.19734 91.4823 1.19734C-19.7588 1.19734 -109.938 91.376 -109.938 202.617C-109.938 313.858 -19.7588 404.037 91.4823 404.037ZM91.4825 317.598C154.984 317.598 206.463 266.119 206.463 202.617C206.463 139.115 154.984 87.6365 91.4825 87.6365C27.9805 87.6365 -23.498 139.115 -23.498 202.617C-23.498 266.119 27.9805 317.598 91.4825 317.598Z"
          fill={color}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M91.4823 404.037C202.723 404.037 292.902 313.858 292.902 202.617C292.902 91.376 202.723 1.19734 91.4823 1.19734C-19.7588 1.19734 -109.938 91.376 -109.938 202.617C-109.938 313.858 -19.7588 404.037 91.4823 404.037ZM91.4825 317.598C154.984 317.598 206.463 266.119 206.463 202.617C206.463 139.115 154.984 87.6365 91.4825 87.6365C27.9805 87.6365 -23.498 139.115 -23.498 202.617C-23.498 266.119 27.9805 317.598 91.4825 317.598Z"
          fill={color}
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

export default BottomCircle
