import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_BID_EXPORTS_API_KEY, getBidExports } from '@/features/bidding/endpoints/bidExports'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { BidExport } from '@/features/bidding/types/bidExports'

export type UseBidExportsQueryResult = Omit<UseQueryResult, 'data'> & {
  bidExports: BidExport[] | null
}

export const useBidExportsQuery = (params: { countryCode: CountryIdentifier }): UseBidExportsQueryResult => {
  const { data = null, ...queryResult } = useQuery({
    queryKey: [GET_BID_EXPORTS_API_KEY, params],
    queryFn: () => getBidExports(params),
  })

  useErrorHandler(queryResult.isError)
  return { bidExports: data, ...queryResult }
}
