import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { useAlertContext } from '@/contexts/AlertContext'
import ResourceCard from '@/features/resource/components/ResourceCard'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { updateBatterySoC } from '@/features/resource/endpoints/resources'
import { useBatteryInfoWithPollingQuery } from '@/features/resource/hooks/useBatteryInfoWithPollingQuery'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

const LABEL_COLOR = '#9e9e9e'

const StyledResourceDetailRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const StyledResourceDetailHeaderRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const BatterySteeringPage = () => {
  const { resourceUuid, controlPortId } = useParams()
  const { pushAlert } = useAlertContext()
  const { setActivePage } = useResourceDetails()
  const { t } = useTranslation()

  const [desiredSoC, setDesiredSoC] = useState<string>('')
  const [isSoCSteeringActive, setIsSoCSteeringActive] = useState(false)

  const { batteryInfo } = useBatteryInfoWithPollingQuery({ controlPortId: controlPortId! })

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_BATTERY_STEERING.routePath)
  }, [setActivePage])

  const handleSoCUpdate = async () => {
    const socValue = parseFloat(desiredSoC) / 100
    if (socValue >= 0 && socValue <= 1) {
      try {
        await updateBatterySoC(resourceUuid!, socValue)
        pushAlert({
          message: t('resources.battery.success_update_soc'),
          severity: 'success',
        })
        setIsSoCSteeringActive(false)
        setDesiredSoC('')
      } catch (error) {
        console.error('Failed to update battery SOC', error)
        pushAlert({
          message: t('resources.battery.errors_update_soc'),
          severity: 'error',
        })
      }
    }
  }

  const columns = useMemo(
    () => [
      t('resources.battery.state_of_charge'),
      t('resources.battery.state_of_health'),
      t('resources.battery.rated_energy'),
      t('resources.battery.energy_to_full_charge'),
      t('resources.battery.max_power_from_grid'),
      t('resources.battery.max_power_to_grid'),
    ],
    [t],
  )

  if (!batteryInfo) return null

  const isInvalidSoC = !desiredSoC || parseFloat(desiredSoC) < 0 || parseFloat(desiredSoC) > 100
  return (
    <ResourceCard
      content={
        <>
          <CardHeader sx={{ paddingX: 0 }} title={t('resources.battery.information')} />
          <Table aria-label={t('resources.battery.information')} size="small">
            <TableHead>
              <StyledResourceDetailHeaderRow>
                {columns.map((column) => (
                  <TableCell key={column}>
                    <CustomTypography color={LABEL_COLOR} variant="caption">
                      {column}
                    </CustomTypography>
                  </TableCell>
                ))}
              </StyledResourceDetailHeaderRow>
            </TableHead>
            <TableBody>
              <StyledResourceDetailRow>
                <TableCell>{`${batteryInfo.stateOfCharge}`}</TableCell>
                <TableCell>{`${batteryInfo.stateOfHealth}`}</TableCell>
                <TableCell>{`${batteryInfo.ratedEnergy} W`}</TableCell>
                <TableCell>{`${batteryInfo.energyToFullCharge} W`}</TableCell>
                <TableCell>{`${batteryInfo.maxUsablePower} W`}</TableCell>
                <TableCell>{`${batteryInfo.minUsablePower} W`}</TableCell>
              </StyledResourceDetailRow>
            </TableBody>
          </Table>
          {isSoCSteeringActive ? (
            <Card sx={{ bgcolor: 'rgba(20, 132, 160, 0.04)' }}>
              <CardHeader title={t('resources.battery.set_soc')} />
              <CardContent>
                <Stack direction="row">
                  <Box flexBasis="25%">
                    <TextField
                      fullWidth
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      placeholder={t('resources.battery.enter_desired_soc')}
                      type="number"
                      value={desiredSoC}
                      onChange={(e) => setDesiredSoC(e.target.value)}
                    />
                  </Box>
                </Stack>
                <Box display="flex" justifyContent="flex-start" mt={2}>
                  <Button disabled={isInvalidSoC} variant="contained" onClick={handleSoCUpdate}>
                    {t('resources.battery.steer_to_soc')}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          ) : (
            <Box mt={2}>
              <Button variant="outlined" onClick={() => setIsSoCSteeringActive(true)}>
                {t('resources.battery.start_steering')}
              </Button>
            </Box>
          )}
        </>
      }
      resourceUuid={resourceUuid!}
    />
  )
}

export default BatterySteeringPage
