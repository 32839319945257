import { Box } from '@mui/material'
import type { FC } from 'react'
import * as React from 'react'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import OfferedBidForm from '@/features/bidding/components/capacity/offer/OfferedBidForm'
import { useCalculateBidBasisQuery } from '@/features/bidding/hooks/useCalculateBidBasisQuery'
import type { BidMetaData, BidPtu } from '@/features/bidding/types/bid'
import { combineOfferedAndForecast } from '@/features/bidding/utils/calculations/calculateBasisForNewBid'

type Props = {
  bidMetaData: BidMetaData
  bidsToCopy: BidPtu[]
}

const OfferedBidFormDataFetcher: FC<Props> = ({ bidMetaData, bidsToCopy }) => {
  const { bidBasis, isFetching: isFetchingBidBasis } = useCalculateBidBasisQuery({
    date: bidMetaData.deliveryDay,
    portfolioCode: bidMetaData.portfolio.code,
    marketProgram: bidMetaData.marketProgram,
    activationGroupUuid: bidMetaData.activationGroupUuid,
  })

  if (isFetchingBidBasis || !bidBasis) {
    return (
      <Box>
        <FullPageSpinner />
      </Box>
    )
  }

  return (
    <OfferedBidForm
      bidMetaData={{ ...bidMetaData, version: bidBasis?.version }}
      pastPtusToCopy={bidsToCopy}
      ptus={combineOfferedAndForecast(bidBasis.offeredBid, bidBasis.forecast)}
    />
  )
}

export default OfferedBidFormDataFetcher
