import { Box } from '@mui/material'
import type { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ONE_YEAR_AGO, TODAY } from '@/constants/dateTime'
import { DURATION_WITH_PLUS_PREFIX_FORMAT } from '@/constants/dateTimeFormats'
import { Period } from '@/constants/period'
import { ActivationsChart } from '@/features/activation/components/ActivationsChart'
import { ActivationsDataGrid } from '@/features/activation/components/ActivationsDataGrid'
import type { Activation } from '@/features/activation/types/activation'
import { getDistinctMarketPrograms } from '@/features/activation/utils/activationCharts'
import { formatLocalizedDateTimeMedWithSeconds } from '@/features/activation/utils/formatLocalizedDateTimeMedWithSeconds'
import { NoMarketProgram } from '@/features/customer/components/NoMarketProgram'
import { useActivationsQuery } from '@/features/customer/hooks/useActivationsQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import { useMarketProgramsQuery } from '@/features/customer/hooks/useMarketProgramsQuery'
import CustomerActivationsAppBar from '@/features/customer/pages/components/CustomerActivationsAppBar'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { MarketProgramType } from '@/types/marketProgramType'
import getDuration, { convertToTimeZoneDateTime } from '@/utils/time'

function getFormattedDuration(startedAt?: string, endedAt?: string): string {
  if (!startedAt || !endedAt) {
    return '-'
  }
  return getDuration(startedAt, endedAt)
    .toFormat(DURATION_WITH_PLUS_PREFIX_FORMAT)
    .replace('00h', '')
    .replace('00m', '')
}

function getMarketProgramTypes(
  marketPrograms?: MarketProgram[] | null,
): Map<number | undefined, MarketProgramType | undefined> {
  return new Map(marketPrograms?.map((mp) => [mp.id, mp.type]))
}

export const CustomerActivations = () => {
  const { customer, customerDetailsCommonBreadcrumbs, isFetchingCustomer, setPageConfig } = useCustomerDetails()
  const { t, i18n } = useTranslation()
  const [dateOfFirstActivation, setDateOfFirstActivation] = useState<DateTime>(TODAY)
  const [fromDate, setFromDate] = useState<DateTime>(ONE_YEAR_AGO)
  const [toDate, setToDate] = useState<DateTime>(TODAY)
  const [periodSelection, setPeriodSelection] = useState<Period>(Period.PAST_12_MONTHS)

  const { customerSettings } = useCustomerSettingsQuery({ uuid: customer.uuid! })

  const { activations, isFetching: isFetchingActivations } = useActivationsQuery(
    {
      customerUuid: customer.uuid!,
      location: customer.location,
    },
    { enabled: Boolean(customer.uuid) && !isFetchingCustomer },
  )

  const { marketPrograms, isFetching: isFetchingMarketPrograms } = useMarketProgramsQuery()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.activations'),
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      appBarContent: (
        <CustomerActivationsAppBar
          dateOfFirstActivation={dateOfFirstActivation}
          fromDate={fromDate}
          periodSelection={periodSelection}
          setFromDate={setFromDate}
          setPeriodSelection={setPeriodSelection}
          setToDate={setToDate}
          toDate={toDate}
        />
      ),
      activeTab: CustomerDetailsRouteInformation.ACTIVATIONS.navigationPath,
    })
  }, [dateOfFirstActivation, fromDate, toDate, periodSelection])

  const firstActivation = activations?.[0] ?? null

  useEffect(() => {
    if (firstActivation?.endedAt) {
      setDateOfFirstActivation(
        convertToTimeZoneDateTime(customerSettings?.localization.timeZone ?? 'UTC', firstActivation.endedAt),
      )
    }
  }, [firstActivation])

  const activationsToShow: Activation[] | undefined = useMemo(
    () =>
      activations
        ?.filter((a) => a.endedAt && DateTime.fromISO(a.endedAt) > fromDate && DateTime.fromISO(a.endedAt) < toDate)
        .reverse(),
    [activations, fromDate, toDate],
  )

  const columns: GridColDef<Activation>[] = [
    {
      field: 'startedAt',
      headerName: t('customer_details.activations.resources_activated'),
      flex: 1,
      valueFormatter: (value: Activation['startedAt']) =>
        formatLocalizedDateTimeMedWithSeconds(customerSettings?.localization.timeZone ?? '', i18n.language, value),
    },
    {
      field: 'endedAt',
      headerName: t('customer_details.activations.resources_deactivated'),
      flex: 1,
      valueGetter: (value: Activation['endedAt'], activation: Activation) => {
        return value ? getFormattedDuration(activation.startedAt, value) : '-'
      },
    },
    {
      field: 'serviceId',
      headerName: t('common.market_program.label'),
      flex: 1,
      type: 'singleSelect',
      valueGetter: (value: Activation['serviceId']) => getMarketProgramTypes(marketPrograms).get(value),
      valueOptions: Array.from(getMarketProgramTypes(marketPrograms).values()).map((mp) => mp as string),
      getOptionLabel: (value) => (value ? t(`common.market_program.${value as MarketProgramType}`) : '-'),
    },
  ]

  if (!customer || isFetchingCustomer) return null

  const marketProgramTypes = getMarketProgramTypes(marketPrograms)

  if (!isFetchingMarketPrograms && marketProgramTypes.size === 0) {
    return <NoMarketProgram />
  }

  return (
    <Box mt={3} sx={{ width: '100%' }}>
      <ActivationsChart
        activations={activationsToShow ?? []}
        fromDate={fromDate}
        isLoading={isFetchingCustomer || isFetchingActivations || isFetchingMarketPrograms}
        marketPrograms={marketProgramTypes}
        toDate={toDate}
      />
      <ActivationsDataGrid
        columns={columns}
        isLoading={isFetchingCustomer || isFetchingActivations || isFetchingMarketPrograms}
        marketProgramOptions={getDistinctMarketPrograms(activationsToShow ?? [], marketProgramTypes)}
        rowNavigateTo={(params: GridRowParams<Activation>) => {
          const path = CustomerDetailsRouteInformation.ACTIVATION_DETAILS.navigationPath
            .replace(':activationId', params.row.id!.toString())
            .replace(':customerUuid', customer.uuid as string)

          return `/${path}`
        }}
        rows={activationsToShow ?? []}
      />
    </Box>
  )
}
