import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import DisturbanceGrid from '@/features/disturbance/components/DisturbanceGrid'

const DisturbancesIndex = () => {
  const { t } = useTranslation()

  return (
    <>
      <PageHeader pageTitle={t('component.page_header.disturbances')} />

      <DisturbanceGrid />
    </>
  )
}

export default DisturbancesIndex
