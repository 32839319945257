import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createGenericActivationGroupPrequalificationPattern } from '@/features/activationGroup/endpoints/activationGroups'
import type {
  GenericActivationGroupPrequalificationPattern,
  GenericCreatePrequalificationPattern,
} from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseGenericCreatePrequalificationPatternMutationResult = Omit<
  UseMutationResult<GenericActivationGroupPrequalificationPattern, Error, GenericCreatePrequalificationPattern>,
  'mutateAsync'
> & {
  createPrequalificationPattern: UseMutationResult<
    GenericActivationGroupPrequalificationPattern,
    Error,
    GenericCreatePrequalificationPattern
  >['mutateAsync']
}

export function useGenericCreatePrequalificationPatternMutation(
  options?: UseMutationOptions<GenericActivationGroupPrequalificationPattern, GenericCreatePrequalificationPattern>,
): UseGenericCreatePrequalificationPatternMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: GenericCreatePrequalificationPattern) =>
      createGenericActivationGroupPrequalificationPattern(variables),
  })

  return { createPrequalificationPattern: mutateAsync, ...mutationResult }
}
