import type { ChipProps } from '@mui/material'
import { Chip, Tooltip } from '@mui/material'
import type { BadgeProps } from '@mui/material/Badge'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

type ChipWithIconAndQuantityProps = {
  icon: any
  quantity: number
  tooltipText?: string
}

const StyledChip = styled(Chip)<ChipProps>(() => ({
  '&': {
    justifyContent: 'flex-start',
    height: '30px',
    width: '56px',
  },
  '& .MuiChip-label': {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}))

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '&': {
    paddingRight: '14px',
    width: '44px',
  },
  '& .MuiBadge-badge': {
    background: theme.palette.secondary.light,
    right: 8,
    top: 12,
    padding: 0,
  },
}))

function ChipWithIconAndQuantity({ icon, quantity, tooltipText }: Readonly<ChipWithIconAndQuantityProps>) {
  return (
    <Tooltip placement="top" title={tooltipText}>
      <StyledChip
        color="default"
        label={
          <StyledBadge badgeContent={quantity} color="secondary">
            {icon}
          </StyledBadge>
        }
      />
    </Tooltip>
  )
}

export default ChipWithIconAndQuantity
