import type { ButtonProps } from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { GridExpandMoreIcon, gridFilteredDescendantCountLookupSelector, GridKeyboardArrowRight } from '@mui/x-data-grid'
import type { DataGridProProps } from '@mui/x-data-grid-pro'
import { useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import type { RevenueDataRow } from '@/features/customer/types/revenue'
import { formatDateTimeWithLocale } from '@/utils/time'

type RevenueMonthlyDataGridProps = {
  data: RevenueDataRow[]
}

function RevenueMonthlyDataGrid({ data }: Readonly<RevenueMonthlyDataGridProps>) {
  const { t, i18n } = useTranslation()

  const days = [...new Set(data.map((row) => row.timestamp.day))]
  const daySummaryRows: RevenueDataRow[] = days.map((day, index) => {
    const dayData = data.filter((row) => row.timestamp.day === day)
    const capacity = dayData.reduce((acc, row) => acc + row.capacity, 0)
    const soldCapacity = dayData.reduce((acc, row) => acc + row.soldCapacity, 0)
    const revenue = dayData.reduce((acc, row) => acc + row.revenue, 0)
    return {
      id: index + 10000,
      timestamp: dayData[0].timestamp,
      capacity: capacity,
      soldCapacity: soldCapacity,
      revenue: revenue,
      path: [dayData[0].timestamp.day.toString()],
      isSummaryRow: true,
    } as RevenueDataRow
  })

  const rowsWithPath = data.map(
    (row) =>
      ({
        ...row,
        path: [row.timestamp.day.toString(), row.timestamp.toMillis().toString()],
        isSummaryRow: false,
      }) as RevenueDataRow,
  )

  const rows = [...daySummaryRows, ...rowsWithPath]

  const columns: GridColDef[] = [
    {
      field: 'timestamp',
      headerName: t('customer_revenue.day'),
      flex: 1,
      type: 'string',
      valueGetter: getDateValueGetter(),
    },
    {
      field: 'capacity',
      headerName: t('customer_revenue.capacity'),
      flex: 1,
      valueFormatter: getValueFormatter(),
    },
    {
      field: 'soldCapacity',
      headerName: t('customer_revenue.sold_capacity'),
      flex: 1,
      valueFormatter: getValueFormatter(),
    },
    {
      field: 'revenue',
      headerName: t('customer_revenue.revenue.label'),
      flex: 1,
      valueFormatter: getValueFormatter(),
    },
  ]

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    width: 60,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  }

  function getDateValueGetter() {
    return (_, row: RevenueDataRow) => {
      return row.isSummaryRow
        ? formatDateTimeWithLocale(row.timestamp, i18n.language, { day: '2-digit' })
        : formatDateTimeWithLocale(row.timestamp, i18n.language, DateTime.TIME_24_SIMPLE)
    }
  }

  function getValueFormatter() {
    return (value: number) => value.toFixed(2)
  }

  return (
    <CustomDataGrid
      disableGutterTop
      treeData
      clickableRows={{ isRowClickable: () => true, isRowSelectable: () => true }}
      columns={columns}
      getTreeDataPath={(row) => row.path ?? []}
      groupingColDef={groupingColDef}
      includeWrapper={false}
      initialState={{
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
      }}
      rows={rows ?? []}
    />
  )
}

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode } = props
  const apiRef = useGridApiContext()
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector)
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (rowNode.type !== 'group') {
      return
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
    setExpanded(!rowNode.childrenExpanded)
    apiRef.current.setCellFocus(id, field)
    event.stopPropagation()
  }

  return filteredDescendantCount > 0 ? (
    <IconButton size="small" tabIndex={-1} onClick={handleClick}>
      {expanded ? <GridExpandMoreIcon fontSize="inherit" /> : <GridKeyboardArrowRight fontSize="inherit" />}
    </IconButton>
  ) : (
    <></>
  )
}

export default RevenueMonthlyDataGrid
