import type { GridFilterItem } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import FlagIcon from '@/components/dataDisplay/FlagIcon'
import { SingleSelectColumnToolbarFilter } from '@/components/inputs/SingleSelectColumnToolbarFilter'

type Props = {
  // DataGrid columns have a property called field, which acts as a unique identifier for the column.
  field: string
  countryOptions: CountryIdentifier[]
  value: string
  onChange: (items: GridFilterItem[]) => void
}

const CountryFilter = ({ countryOptions, field, value, onChange }: Props) => {
  const { t } = useTranslation()
  const countrySortedOptions = useMemo(
    () => countryOptions.toSorted((a, b) => t(`common.country.${a}`).localeCompare(t(`common.country.${b}`))),
    [countryOptions],
  )

  return (
    <SingleSelectColumnToolbarFilter
      currentFilterValue={value}
      field={field}
      getOptionIcon={(value) => <FlagIcon countryCode={value} />}
      getOptionLabel={(value: CountryIdentifier) => (value ? t(`common.country.${value}`) : t('common.all'))}
      label={t('common.country.label')}
      options={countrySortedOptions}
      onFilterModelChange={({ items }) => onChange(items)}
    />
  )
}

export default CountryFilter
