import type { DesktopDateTimePickerProps, PickerValidDate } from '@mui/x-date-pickers'
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type DesktopDateTimePickerControllerProps<
  TDate extends PickerValidDate,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Omit<DesktopDateTimePickerProps<TDate>, 'name'> & {
  name: TName
  // It enables showing field validation errors. Its default value is true
  showError?: boolean
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'render' | 'name'>
}

function DesktopDateTimePickerController<
  TDate extends PickerValidDate,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  showError = true,
  controllerProps,
  slotProps,
  ...fieldProps
}: DesktopDateTimePickerControllerProps<TDate, TFieldValues, TName>) {
  const { control } = useFormContext<TFieldValues>()
  const { i18n } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error, invalid } }) => (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <DesktopDateTimePicker
            {...field}
            slotProps={{
              ...slotProps,
              textField: {
                ...(slotProps?.textField ?? {}),
                error: showError && invalid,
                helperText: error?.message,
              },
            }}
            value={value ?? null}
            {...fieldProps}
          />
        </LocalizationProvider>
      )}
    />
  )
}

export default DesktopDateTimePickerController
