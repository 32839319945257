import type { AlertColor } from '@mui/material'
import { Alert, Snackbar, Stack } from '@mui/material'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

type AlertParams = {
  uuid: string
  message: string
  title?: string
  severity: AlertColor
  isPersistent?: boolean
  action?: ReactNode
}

type AlertContextType = {
  pushAlert: (params: Omit<AlertParams, 'uuid'>) => void
  resetAlerts: () => void
}

const AUTO_HIDE_DURATION = 6000
// Maximum number of alerts that can be displayed at the same time.
const MAX_VISIBLE_ALERTS = 3

export const AlertContext = createContext<AlertContextType>({ pushAlert: () => {}, resetAlerts: () => {} })

export const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [alerts, setAlerts] = useState<AlertParams[]>([])
  const [persistentAlert, setPersistentAlert] = useState<AlertParams | null>(null)
  const [isReset, setIsReset] = useState(false)

  function pushAlert(newAlert: Omit<AlertParams, 'uuid'>) {
    if (newAlert.isPersistent) {
      setPersistentAlert({ ...newAlert, uuid: uuidV4() })
      return
    }

    setAlerts((prev) => [...prev, { ...newAlert, uuid: uuidV4() }])
  }

  function resetAlerts() {
    if (alerts.length > 0) {
      setIsReset(true)
      setOpen(false)
    }

    if (persistentAlert !== null) {
      setPersistentAlert(null)
    }
  }

  function popAlert() {
    if (isReset) {
      setAlerts([])
      setIsReset(false)
    } else {
      setAlerts((prevState) => prevState.slice(1))
    }
  }

  function handleSnackbarClose() {
    setAlerts([])
    setIsReset(false)
  }

  useEffect(() => {
    setOpen(alerts.length > 0 || persistentAlert !== null)
  }, [alerts, persistentAlert])

  return (
    <AlertContext.Provider value={{ pushAlert, resetAlerts }}>
      {children}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={AUTO_HIDE_DURATION}
        open={open}
        onClose={persistentAlert !== null ? undefined : handleSnackbarClose}
      >
        <Stack flexDirection="column" gap={2}>
          {persistentAlert && (
            <Alert action={persistentAlert.action} elevation={6} severity={persistentAlert.severity} variant="filled">
              {persistentAlert.title && <CustomTypography variant="h3">{persistentAlert.title}</CustomTypography>}
              <CustomTypography variant="body1">{persistentAlert.message}</CustomTypography>
            </Alert>
          )}

          {alerts.slice(0, MAX_VISIBLE_ALERTS).map((alert) => (
            <Alert
              key={alert.uuid}
              action={alert.action}
              elevation={6}
              severity={alert.severity}
              variant="filled"
              onClose={popAlert}
            >
              {alert?.title && <CustomTypography variant="h3">{alert.title}</CustomTypography>}
              <CustomTypography variant="body1">{alert.message}</CustomTypography>
            </Alert>
          ))}
        </Stack>
      </Snackbar>
    </AlertContext.Provider>
  )
}

export function useAlertContext() {
  return useContext(AlertContext)
}
