import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'

export function getYearSortedOptions(years: string[]): SelectFieldOption<string>[] {
  return years
    .toSorted((n1, n2) => parseInt(n2) - parseInt(n1))
    .map((year, index) => ({
      id: index.toString(),
      label: year.toString(),
      value: year.toString(),
    }))
}
