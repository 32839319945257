import { Close, SaveOutlined } from '@mui/icons-material'
import { DialogActions, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'

type FormDialogActionsProps = {
  isLoading?: boolean
  onCancel: () => void
  onSubmit?: () => void
}

const FormDialogActions = ({ isLoading, onCancel, onSubmit }: FormDialogActionsProps) => {
  const { t } = useTranslation()

  return (
    <DialogActions>
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>
        <CustomButton
          color="primary"
          disabled={isLoading}
          startIcon={<SaveOutlined />}
          type={onSubmit ? 'button' : 'submit'}
          variant="contained"
          onClick={onSubmit}
        >
          {t('common.button.save')}
        </CustomButton>
        <CustomButton
          color="primary"
          disabled={isLoading}
          startIcon={<Close />}
          type="reset"
          variant="outlined"
          onClick={onCancel}
        >
          {t('common.button.cancel')}
        </CustomButton>
      </Stack>
    </DialogActions>
  )
}

export default FormDialogActions
