import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormDialogActions from '@/components/feedback/FormDialogActions'
import FileFieldController from '@/components/inputs/FileFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { getGenericCreatePrequalificationPatternSchema } from '@/features/activationGroup/schemas'
import type { GenericCreatePrequalificationPattern } from '@/features/activationGroup/types'

type GenericCreatePrequalificationPatternDialogFormProps = {
  activationGroupUuid: string
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: GenericCreatePrequalificationPattern) => void
}

/**
 * Contains all the logic related to the form to create a new prequalification pattern.
 *
 * It is used within the CreatePrequalificationPatternDialog component.
 */
const GenericCreatePrequalificationPatternDialogForm = ({
  activationGroupUuid,
  isLoading,
  onCancel,
  onSubmit,
}: Readonly<GenericCreatePrequalificationPatternDialogFormProps>) => {
  const { t } = useTranslation()
  const form = useForm<GenericCreatePrequalificationPattern>({
    defaultValues: {
      activationGroupUuid,
      name: '',
    },
    resolver: zodResolver(getGenericCreatePrequalificationPatternSchema(t)),
  })

  function handleSubmit(data: GenericCreatePrequalificationPattern) {
    onSubmit(data)
  }

  return (
    <FormProvider {...form}>
      <form
        noValidate
        aria-label={t('activation_groups.create_schedule_form.title')}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextFieldController required id="name" label={t('common.name')} name="name" />

          <FileFieldController
            required
            id="pattern"
            inputProps={{
              'data-testid': 'prequalification-pattern-file-upload',
            }}
            name="pattern"
          />
        </DialogContent>

        <FormDialogActions isLoading={isLoading} onCancel={onCancel} />
      </form>
    </FormProvider>
  )
}

export default GenericCreatePrequalificationPatternDialogForm
