import type { GridPaginationModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_CUSTOMERS_API_ID, getCustomers } from '@/features/integration/endpoints/customers'
import type { Customer, CustomersPage } from '@/features/integration/types'
import type { UseQueryOptions } from '@/types/queries'

type UseCustomersQueryParams = {
  pagination: GridPaginationModel
}

export type UseCustomersQueryResult = Omit<UseQueryResult, 'data'> & {
  customers: Customer[]
  currentPage: number
  totalCount: number
}

export function useCustomersQuery(
  params: UseCustomersQueryParams,
  options?: UseQueryOptions<CustomersPage>,
): UseCustomersQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryFn: () => getCustomers(),
    queryKey: [GET_CUSTOMERS_API_ID, params],
  })

  return {
    ...queryResult,
    customers: data?.customers ?? [],
    currentPage: data?.currentPage ?? 0,
    totalCount: data?.totalCount ?? 0,
  }
}
