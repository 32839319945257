import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'
import { MARKET_CLOSING_TIME_AS_STRING } from '@/features/bidding/constants'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import {
  getSecondsToMarketClosingWarning,
  getStateOfMarket,
  MarketState,
} from '@/features/bidding/utils/getStateOfMarket'

export function useMarketClosingWarning() {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  return {
    showMarketClosingWarning(bidDay: MarketDate) {
      const stateOfMarket = getStateOfMarket(bidDay)

      switch (stateOfMarket) {
        case MarketState.CLOSING:
          pushAlert({
            severity: 'warning',
            message: t('bidding.market_closed.preemptive_warning', { time: MARKET_CLOSING_TIME_AS_STRING }),
            isPersistent: true,
          })
          break
        case MarketState.CLOSED:
          pushAlert({
            severity: 'warning',
            message: t('bidding.market_closed.warning', { time: MARKET_CLOSING_TIME_AS_STRING }),
            isPersistent: true,
          })
          break
        case MarketState.OPEN:
          setTimeout(
            () => {
              pushAlert({
                severity: 'warning',
                message: t('bidding.market_closed.preemptive_warning', { time: MARKET_CLOSING_TIME_AS_STRING }),
                isPersistent: true,
              })
            },
            getSecondsToMarketClosingWarning(bidDay) * 1000,
          )
          break
      }
    },
  }
}
