import type { GridFilterItem, GridFilterModel, GridFilterOperator } from '@mui/x-data-grid'
import { gridFilterModelSelector } from '@mui/x-data-grid'
import type { GridApiCommunity } from '@mui/x-data-grid/internals'
import type { MutableRefObject } from 'react'

export const OPERATOR_VALUE_HAS_ANY_OF = 'hasAnyOf'

function getSearchParamValueFromFilterItem(filterItem: GridFilterItem): string {
  if (filterItem.operator === OPERATOR_VALUE_HAS_ANY_OF) {
    return filterItem.value.join(',')
  }

  return String(filterItem.value)
}

function getFilterItemValueFromSearchParamValue(
  searchParamValue: string,
  filterItemOperator: GridFilterItem['operator'],
) {
  if (filterItemOperator === OPERATOR_VALUE_HAS_ANY_OF) {
    return searchParamValue.split(',')
  }

  return searchParamValue
}

export function updateDataGridFilter(
  apiRef: MutableRefObject<GridApiCommunity>,
  filterName: string,
  filterItem?: GridFilterItem,
) {
  const prevFilterModel = gridFilterModelSelector(apiRef)
  const restFilters = prevFilterModel.items.filter((item) => item.field !== filterName)

  if (!filterItem?.value) {
    apiRef.current.setFilterModel({
      ...prevFilterModel,
      items: restFilters,
    })

    return
  }

  apiRef.current.setFilterModel({
    ...prevFilterModel,
    items: [...restFilters, filterItem],
  })
}

export function updateDataGridFilters(
  apiRef: MutableRefObject<GridApiCommunity>,
  filterNames: string[],
  filterItems: GridFilterItem[],
) {
  const prevFilterModel = gridFilterModelSelector(apiRef)
  const restFilters = prevFilterModel.items.filter(
    (item) => !filterNames.some((filterName) => filterName === item.field),
  )

  apiRef.current.setFilterModel({
    ...prevFilterModel,
    items: [...restFilters, ...filterItems],
  })
}

export function getGridMultiselectHasAnyOfOperator(): GridFilterOperator {
  return {
    label: 'has any of',
    value: OPERATOR_VALUE_HAS_ANY_OF,
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value.length) {
        return null
      }

      return (values: string[]) => {
        return values.some((value) => filterItem.value.includes(value))
      }
    },
  }
}

export function getFilterItemValue<V = string>(
  filterModel: GridFilterModel,
  fieldName: string,
  operators?: string[],
): V | null {
  return (
    filterModel.items
      .filter((item) => !operators || operators.includes(item.operator))
      .find((item) => item.field === fieldName)?.value ?? null
  )
}

export function getFilterItemValues<V = string>(
  filterModel: GridFilterModel,
  fieldName: string,
  operators?: string[],
): V[] {
  return filterModel.items
    .filter((item) => item.field === fieldName)
    .filter((item) => !operators || operators.includes(item.operator))
    .map((item) => item.value) as V[]
}

/**
 * Converts a GridFilterModel into a key-value object that can be used as URLSearchParams
 */
export function serializeFilterModelToSearchParamsObject(filterModel: GridFilterModel): Record<string, string> {
  const filterParams: Record<string, string> = filterModel.items.reduce((acc, item) => {
    return { ...acc, [`${item.field}.${item.operator}`]: getSearchParamValueFromFilterItem(item) }
  }, {})

  if (filterModel.quickFilterValues) {
    filterParams['search.equals'] = filterModel.quickFilterValues ? filterModel.quickFilterValues.join(',') : ''
  }

  return filterParams
}

/**
 * Converts URLSearchParams into a valid GridFilterModel
 *
 * When a filter is not present in the search params, we apply the initial state value
 */
export function deserializeFilterModelFromSearchParams(): GridFilterModel | null {
  const searchParams = new URLSearchParams(window.location.search)
  const filterModelFromSearchParams = Array.from(searchParams.entries())
    .filter(([filterKey]) => {
      const [, operator] = filterKey.split('.')

      return operator && operator !== 'sort'
    })
    .reduce(
      (acc, [filterKey, filterValue]) => {
        const [field, operator] = filterKey.split('.')

        if (field === 'search') {
          return {
            ...acc,
            quickFilterValues: filterValue.split(',').filter((value) => value !== ''),
          }
        }

        const newFilterItem: GridFilterItem = {
          id: field,
          field,
          operator,
          value: getFilterItemValueFromSearchParamValue(filterValue, operator),
        }

        return {
          ...acc,
          items: [...acc.items, newFilterItem],
        }
      },
      { items: [] } as GridFilterModel,
    )

  const { items, quickFilterValues } = filterModelFromSearchParams

  if (items.length === 0 && !quickFilterValues) {
    return null
  }

  return filterModelFromSearchParams
}

export function deserializeFilterSearchParamsObjectFromSearchParams(): Record<string, string> {
  const searchParams = new URLSearchParams(window.location.search)

  return Array.from(searchParams.entries())
    .filter(([filterKey]) => {
      const [, operator] = filterKey.split('.')

      return operator && operator !== 'sort'
    })
    .reduce((acc, [filterKey, filterValue]) => {
      return { ...acc, [filterKey]: filterValue }
    }, {})
}
