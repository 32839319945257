import Typography from '@mui/material/Typography'
import type { GridColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import type { MarketProgram } from '@/features/customer/types/marketProgram'

type Resource = {
  id: number
  name: string
  type?: string
  power: number
}

interface ResourcesDataGridProps {
  resources: Resource[]
  marketProgram: MarketProgram
}

const WATTS_IN_KW = 1000

export const ResourcesDataGrid = (props: ResourcesDataGridProps) => {
  const { t } = useTranslation()
  const { resources, marketProgram } = props

  const columns: GridColDef<Resource>[] = [
    {
      field: 'name',
      headerName: t('common.resources'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'type',
      headerName: t('common.type'),
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'marketProgram',
      headerName: t('common.market_program.label'),
      flex: 1,
      valueGetter: () => marketProgram.name,
      disableColumnMenu: true,
    },
    {
      field: 'power',
      headerName: t('common.power'),
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: (value: number) => {
        const valueInMw = value / WATTS_IN_KW
        return `${+valueInMw.toFixed(2)} kW`
      },
    },
  ]

  return (
    <>
      <Typography sx={{ mt: 2 }} variant="h6">
        {t('customer_details.tabs.availability.affected_resources')}
      </Typography>

      <CustomDataGrid
        disableColumnSorting
        columns={columns}
        includeWrapper={false}
        initialState={{
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
        }}
        rows={resources}
      />
    </>
  )
}
