import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import CreateScheduleDialogForm from '@/features/activationGroup/components/CreateScheduleDialogForm'
import { useInvalidateActivationGroupSchedulesQuery } from '@/features/activationGroup/hooks/useActivationGroupSchedulesQuery'
import { useCreateScheduleMutation } from '@/features/activationGroup/hooks/useCreateScheduleMutation'
import type { CreateSchedule } from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type CreateScheduleDialogProps = {
  activationGroupUuid: string
  open: boolean
  onClose: () => void
}

const CreateScheduleDialog = ({ open, onClose, activationGroupUuid }: CreateScheduleDialogProps) => {
  const { t } = useTranslation()
  const { createSchedule, isPending } = useCreateScheduleMutation()
  const { pushAlert } = useAlertContext()
  const invalidateActivationGroupSchedulesQuery = useInvalidateActivationGroupSchedulesQuery()

  function handleClose() {
    onClose()
  }

  async function handleSubmit(data: CreateSchedule) {
    try {
      await createSchedule(data)
      await invalidateActivationGroupSchedulesQuery(data.activationGroupUuid)

      pushAlert({
        message: t('activation_groups.create_schedule_success_message'),
        severity: 'success',
      })

      onClose()
    } catch (err: unknown) {
      const error = errorHandler(err, t('activation_groups.create_schedule_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog open={open} title={t('activation_groups.create_schedule_dialog.title')} onClose={handleClose}>
      <CreateScheduleDialogForm
        activationGroupUuid={activationGroupUuid}
        isLoading={isPending}
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </CustomDialog>
  )
}

export default CreateScheduleDialog
