import DeleteIcon from '@mui/icons-material/Delete'
import { CardActions, Stack } from '@mui/material'
import type { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'

type CustomCardActionsProps = ComponentProps<typeof CardActions> & {
  deleteProps?: {
    onDelete: () => void
    text?: string
    withIcon?: boolean
  }
}

/**
 * It customizes the CardActions component from MUI. The Sympower customizations are:
 *
 * - Forces the delete action to be on the left side of the card actions.
 * - Adds the children property to the right side of the card actions. Children prop on this component
 * represents the actions that the user can perform.
 * - It modifies the padding of the component.
 */
const CustomCardActions = ({ children, sx, deleteProps, ...props }: CustomCardActionsProps) => {
  const { t } = useTranslation()

  return (
    <CardActions
      {...props}
      disableSpacing
      sx={{
        ...sx,
        padding: 3,
        paddingTop: 1,
        gap: 1,
        justifyContent: 'space-between',
      }}
    >
      {deleteProps?.onDelete && (
        <CustomButton
          color="error"
          startIcon={deleteProps.withIcon ? <DeleteIcon /> : null}
          variant="outlined"
          onClick={deleteProps.onDelete}
        >
          {deleteProps.text || t('common.button.delete')}
        </CustomButton>
      )}

      <Stack flexDirection="row" flexGrow={1} gap={1} justifyContent="flex-end">
        {children}
      </Stack>
    </CardActions>
  )
}

export default CustomCardActions
