import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import type { JSX } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomButton from '@/components/inputs/CustomButton'

interface GridBulkActionsBarProps {
  /**
   *  It should be a React.Fragment with buttons or icons that repeat the actions that can be performed on the selected rows.
   *  It is important to use a Fragment when having more than one action in order to avoid breaking the predefined
   *  styles.
   */
  actions?: JSX.Element
  countSelectedRows: number
  closeButtonProps: {
    variant: 'outlined' | 'icon'
    onClick: () => void
  }
}

/**
 * GridBulkActionsBar component displays the number of selected rows and actions to perform on them. It is meant to be
 * used in a data grid.
 */
const GridBulkActionsBar = ({ countSelectedRows, actions, closeButtonProps }: GridBulkActionsBarProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  if (countSelectedRows === 0) {
    return null
  }

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.primary['50'],
        gap: 1.5,
        p: 1.5,
      }}
    >
      <Stack sx={{ flexDirection: 'row', gap: 1 }}>{actions}</Stack>

      <Stack sx={{ flexDirection: 'row', gap: 1.5, alignItems: 'center', justifyContent: 'center' }}>
        <CustomTypography sx={{ color: 'text.primary' }} variant="body1">
          {t('component.grid_bulk_actions_bar.selected_rows', { count: countSelectedRows })}
        </CustomTypography>

        {closeButtonProps.variant === 'outlined' && (
          <CustomButton color="primary" startIcon={<CloseIcon />} variant="outlined" onClick={closeButtonProps.onClick}>
            {t('common.navigation.close')}
          </CustomButton>
        )}

        {closeButtonProps.variant === 'icon' && (
          <IconButton color="primary" onClick={closeButtonProps.onClick}>
            <CloseIcon titleAccess={t('common.navigation.close')} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

export default GridBulkActionsBar
