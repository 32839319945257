import type { ThemeOptions } from '@mui/material/styles'

import type { Typography } from '@/features/whitelabel/types/typography'

export const FONT_WEIGHTS = '300,400,500,700'

export const typographyOptions: (typography: Typography) => ThemeOptions['typography'] = (typography: Typography) => {
  return {
    h1: {
      fontSize: '1.25rem',
      fontFamily: getHeadingFontFamily(typography),
      fontWeight: 700,
      lineHeight: 1.2,
      fontStyle: 'normal',
      letterSpacing: '-0.8px',
    },
    h2: {
      fontSize: '1.125rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: '-0.8px',
    },
    h3: {
      fontSize: '1rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: '-0.5px',
    },
    h4: {
      fontSize: '1rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.6,
      letterSpacing: '-0.5px',
    },
    h5: {
      fontSize: '0.9375rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: '-0.3px',
    },
    h6: {
      fontSize: '0.9375rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 1.6,
      letterSpacing: '-0.3px',
    },
    caption: {
      fontSize: '0.75rem',
      fontFamily: getMainFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.4px',
    },
    overline: {
      fontSize: '0.75rem',
      fontFamily: getMainFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 2.66,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: '0.1px',
    },
    subtitle1: {
      fontSize: '1rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '0.875rem',
      fontFamily: getMainFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.17px',
    },
    body1: {
      fontSize: '1rem',
      fontFamily: getMainFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.15px',
    },
    custom: {
      fontSize: '0.875rem',
      fontFamily: getMainFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.17px',
    },
    accordionTitle: {
      fontSize: '0.9375rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: '-0.2px',
    },
    accordionDescription: {
      fontSize: '0.875rem',
      fontFamily: getHeadingFontFamily(typography),
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.6,
      letterSpacing: '-0.2px',
    },
  }
}

function getMainFontFamily(typography: Typography): string {
  return `${typography.main}, ${typography.fallback ?? 'sans-serif'}`
}

function getHeadingFontFamily(typography: Typography): string {
  return `${typography.heading ?? typography.main}, ${typography.fallback ?? 'sans-serif'}`
}
