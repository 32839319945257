import Grid from '@mui/material/Grid'
import type { FC } from 'react'
import { useState } from 'react'

import OfferedBidFormDataFetcher from '@/features/bidding/components/capacity/offer/OfferedBidFormDataFetcher'
import PastBidPtusDataGrid from '@/features/bidding/components/capacity/offer/PastBidPtusDataGrid'
import type { BidMetaData, BidPtu } from '@/features/bidding/types/bid'

type Props = {
  bidMetaData: BidMetaData
}

const CreateBidForm: FC<Props> = ({ bidMetaData }) => {
  const [bidsToCopy, setBidsToCopy] = useState<BidPtu[]>([])

  const copyPtusToBidFromPastBid = async (pastPtus: BidPtu[]) => {
    setBidsToCopy(pastPtus)
  }

  return (
    <Grid container data-testid={`activation-group-bid-${bidMetaData.activationGroupUuid}`} spacing={2}>
      <Grid item xs={5}>
        <PastBidPtusDataGrid
          bidMetaData={bidMetaData}
          copyPtusToBid={(ptus: BidPtu[]) => copyPtusToBidFromPastBid(ptus)}
        />
      </Grid>
      <Grid item data-testid={'BiddingForm'} xs={7}>
        <OfferedBidFormDataFetcher bidMetaData={bidMetaData} bidsToCopy={bidsToCopy} />
      </Grid>
    </Grid>
  )
}

export default CreateBidForm
