import './hourlyPriceDataGrid.css'

import type { DateTime } from 'luxon'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { WATTS_IN_MW } from '@/constants/units'
import BiddingPricesDialog from '@/features/bidding/components/price/offer/BiddingPricesDialog'
import HourlyPriceDataGridToolbar from '@/features/bidding/components/price/offer/HourlyPriceDataGridToolbar'
import { MAX_PTUS_PER_PAGE } from '@/features/bidding/constants'
import { useActivationGroupBidsQuery } from '@/features/bidding/hooks/useActivationGroupBidsQuery'
import { useInvalidateGroupedBidsPageQuery } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import { useSaveBidsMutation } from '@/features/bidding/hooks/useSaveBidsMutation'
import type { Bid } from '@/features/bidding/types/bid'
import { roundWattsToNearestMwUnit } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { decimalAdjust } from '@/features/bidding/utils/calculations/decimalAdjust'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import type { HourlyPriceRowItem } from '@/features/bidding/utils/model/buildHourlyPriceRowItems'
import { buildHourlyPriceRowItems } from '@/features/bidding/utils/model/buildHourlyPriceRowItems'
import { getPriceBidDataGridColumns } from '@/features/bidding/utils/price/getPriceBiddingDataGridColumns'
import splitTotalPriceChunkOfferByActivationGroup from '@/features/bidding/utils/price/splitTotalPriceChunkOfferByActivationGroup'
import { updateBidPrices } from '@/features/bidding/utils/price/updateBidPrices'

type Props = {
  groupParams: GroupedBidParams
}

const HourlyPriceDataGrid: FC<Props> = ({ groupParams }) => {
  const { t, i18n } = useTranslation()
  const [priceColumnsModalOpen, setPriceColumnsModalOpen] = useState(false)

  const { activationGroupBids, isLoading } = useActivationGroupBidsQuery(groupParams)
  const { saveBids } = useSaveBidsMutation()
  const invalidateCachedQueryStateOfGroupedBids = useInvalidateGroupedBidsPageQuery()

  const processRowUpdate = async (
    updatedRowItem: HourlyPriceRowItem,
    oldRowItem: HourlyPriceRowItem,
  ): Promise<HourlyPriceRowItem> => {
    const changedPriceCapacity = updatedRowItem.priceCapacities.find((newPriceCapacity) =>
      oldRowItem.priceCapacities.find(
        (oldPriceCapacity) =>
          oldPriceCapacity.price === newPriceCapacity.price &&
          oldPriceCapacity.capacityMw !== newPriceCapacity.capacityMw,
      ),
    )

    if (changedPriceCapacity) {
      const updatedBids = await updatePtuCapacities(
        activationGroupBids,
        updatedRowItem.ptuStart,
        changedPriceCapacity.price,
        changedPriceCapacity.capacityMw,
      )
      await saveUpdatedBids(updatedBids)
    }

    return Promise.resolve(updatedRowItem)
  }

  const updatePriceColumns = async (updatedPrices: Record<number, number | null>): Promise<void> => {
    const updatedBids: Bid[] = activationGroupBids.map((bid) => updateBidPrices(bid, updatedPrices))
    await saveUpdatedBids(updatedBids)
  }

  const onBulkCapacityFillHandler = async (value: number | undefined, priceColumn: number | undefined) => {
    if (!value || !priceColumn) return

    const updatedBids = activationGroupBids.map((group: Bid) => {
      group.offeredBid.forEach((bid) => {
        const ptuStart = bid.ptu.start
        const splitCapacityByActivationGroup = splitPtuCapacityByActivationGroup(activationGroupBids, value, ptuStart)
        const priceChunk = bid.ptuChunks!.find((chunk) => chunk.offeredPrice === priceColumn)!
        priceChunk.offeredVolume.quantity = splitCapacityByActivationGroup[group.activationGroupUuid]
      })
      return group
    })

    await saveUpdatedBids(updatedBids)
  }

  const saveUpdatedBids = async (updatedBids: Bid[]) => {
    await saveBids(updatedBids)
    await invalidateCachedQueryStateOfGroupedBids()
  }

  const rowItems = buildHourlyPriceRowItems(activationGroupBids)

  return (
    <>
      <CustomDataGrid
        disableDefaultHeaderBackground
        hideFooter
        columns={getPriceBidDataGridColumns(rowItems, true, t, i18n.language)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: MAX_PTUS_PER_PAGE,
              page: 0,
            },
          },
        }}
        isLoading={isLoading}
        processRowUpdate={processRowUpdate}
        rows={rowItems}
        slotProps={{
          toolbar: {
            deliveryDay: groupParams.deliveryDay,
            onFill: onBulkCapacityFillHandler,
            onSetPriceColumnsClick: () => setPriceColumnsModalOpen(true),
            priceColumns: getUniquePrices(rowItems),
          },
        }}
        slots={{ toolbar: HourlyPriceDataGridToolbar }}
      />

      {priceColumnsModalOpen && (
        <BiddingPricesDialog
          initialPrices={rowItems[0].priceCapacities.map((pc) => pc.price)}
          locales={i18n.language}
          open={priceColumnsModalOpen}
          onClose={() => setPriceColumnsModalOpen(false)}
          onSubmit={updatePriceColumns}
        />
      )}
    </>
  )
}

async function updatePtuCapacities(
  activationGroupBids: Bid[],
  ptuStart: DateTime,
  price: number,
  newCapacityMw: number,
): Promise<Bid[]> {
  const splitCapacityByActivationGroup = splitPtuCapacityByActivationGroup(activationGroupBids, newCapacityMw, ptuStart)
  return activationGroupBids.map((bid) => {
    const offeredPtu = bid.offeredBid.find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))!
    const priceChunk = offeredPtu.ptuChunks!.find((priceChunk) => priceChunk.offeredPrice === price)!

    priceChunk.offeredVolume.quantity = splitCapacityByActivationGroup[bid.activationGroupUuid]
    return bid
  })
}

function splitPtuCapacityByActivationGroup(activationGroupBids: Bid[], newCapacityMw: number, ptuStart: DateTime) {
  const newCapacityInWatts = decimalAdjust('round', newCapacityMw * WATTS_IN_MW, -1)
  const roundedNewCapacityInWatts = roundWattsToNearestMwUnit(newCapacityInWatts)
  const offeredCapacityByActivationGroup = activationGroupBids.map((bid) => ({
    activationGroupUuid: bid.activationGroupUuid,
    offered: bid.offeredBid.find((p) => p.ptu.start.equals(ptuStart))!.volume.quantity,
  }))
  return splitTotalPriceChunkOfferByActivationGroup(roundedNewCapacityInWatts, offeredCapacityByActivationGroup)
}

function getUniquePrices(rowItems: HourlyPriceRowItem[]) {
  const prices = rowItems.flatMap((rowItem) => rowItem.priceCapacities.map((priceCapacity) => priceCapacity.price))
  return Array.from(new Set(prices)).sort((a, b) => a - b)
}

export default HourlyPriceDataGrid
